export default {
   /*
   *  图片  
   */
  // 添加分组
   addPictureGroup: {
    method:'post',
    url:'/v1/cms/PictureGroup'
  },
  // 查询分组列表
  getPictureGroup: {
    method:'get',
    url:'/v1/cms/PictureGroup'
  },
  // 图片列表
  getPictureList: {
    method:'get',
    url:'/v1/cms/Picture'
  },
  // 新增图片
  addPicture: {
    method:'post',
    url:'/v1/cms/Picture'
  },
  // 获取视频分组列表
  getVideoGroupList: {
    method: 'get',
    url: '/v1/cms/MovieGroup'
  },
  // 添加视频分组
  addVideoGroup: {
    method:'post',
    url:'/v1/cms/MovieGroup'
  },
  // 视频列表
  getVideoList: {
    method:'post',
    url:'/v1/cms/Movie'
  },
   // 新增视频
   addVideo: {
    method:'post',
    url:'/v1/cms/Movie'
  },
   // 新增文件
   addFile: {
    method:'post',
    url:'/v1/cms/File'
  },
}