<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item >设置</el-breadcrumb-item>
      <el-breadcrumb-item  :to="{ path: '/menuSet' }">菜单管理</el-breadcrumb-item>
      <el-breadcrumb-item>新建菜单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form ref="form" :model="basicInfoForm" label-width="100px" :rules="rules">
        <el-form-item label="菜单名称" prop="name">
          <el-input v-model="basicInfoForm.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="菜单URL" prop="name">
          <el-input v-model="basicInfoForm.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="菜单等级" prop="name">
          <el-select v-model="basicInfoForm.region" placeholder="请选择">
            <el-option label="部门A" value="shanghai"></el-option>
            <el-option label="部门B" value="beijing"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" >保存</el-button>
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'addMenu',
  data () {
    return {
      basicInfoForm: {}
    }
  },
  methods: {}
}
</script>
<style scoped>
.el-form {
  width: 400px;
}
</style>