<template>
  <div class="inventoryManage">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>商品</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/inventoryManage' }"
        >实物库存</el-breadcrumb-item
      >
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="库存类型">
          <el-select
            v-model="customerGroupForm.search_type"
            placeholder="请选择"
          >
            <el-option label="减少库存" :value="1"></el-option>
            <el-option label="增加库存" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作类型">
          <el-select
            v-model="customerGroupForm.action_type"
            placeholder="请选择"
          >
            <el-option label="用户下单" :value="1"></el-option>
            <el-option label="用户退单" :value="2"></el-option>
            <el-option label="后台操作" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作日期">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="search_keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          label="商品描述"
          :align="'center'"
          prop="nickName"
          width="300"
        >
          <template slot-scope="scope">
            <div class="nickAndAvatar">
              <el-image :src="scope.row.image[0].image"></el-image>
              <div style="width: 200px">
                <el-tooltip :content="scope.row.nickName" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.nickName }}
                  </p>
                </el-tooltip>
                <p>规格：红色*套餐1</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="code"
          label="商品编码"
          :align="'center'"
          width="200"
        >
        </el-table-column>
        <el-table-column prop="stock" label="数量" :align="'center'" sortable>
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.type == 1">
              + {{ scope.row.stock }}</span
            >
            <span style="color: red" v-if="scope.row.type == 2">
              - {{ scope.row.stock }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="after_stock"
          label="可用库存"
          :align="'center'"
          sortable
          width="200"
        >
        </el-table-column>
        <el-table-column prop="type" label="库存类型" :align="'center'">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.type == 1"
              >增加库存</span
            >
            <span style="color: red" v-if="scope.row.type == 2">减少库存</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="action_type"
          label="操作类型"
          :align="'center'"
          width="200"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.action_type == 1">用户下单</span>
            <span v-if="scope.row.action_type == 2">用户退单</span>
            <span v-if="scope.row.action_type == 3">后台操作</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="操作时间"
          :align="'center'"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="order_sn"
          label="单号"
          :align="'center'"
          width="150"
        >
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "inventoryRecords",
  components: { pagination },
  data() {
    return {
      customerGroupForm: {
        search_start_time: "",
        search_end_time: "",
        search_keyword: "",
        action_type: "",
        search_type: "",
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsStock?goods_uuid=${this.$route.query.uuid}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.customerGroupForm.search_keyword}&search_type=${this.customerGroupForm.search_type}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&action_type=${this.customerGroupForm.action_type}`,
      })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.inventoryManage .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
.dataTable {
  position: relative;
  padding-top: 80px;
}
.dataTable .tabs {
  position: absolute;
  top: 35px;
  left: 20px;
}
.dataTable .tabs p {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.dataTable .tabs p:after {
  content: "";
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.dataTable .tabs p.on:after {
  background: #409eff;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
</style>
