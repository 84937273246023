<template>
  <!-- 添加客户 -->
  <div class="addAccount">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/accountManagement' }"
        >账号管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>添加账号</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicInfo">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="头像">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest"
          >
          <img
              v-if="basicInfoForm.avatar != ''"
              :src="basicInfoForm.avatar"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseBanner">从素材库选择</el-button>
        </el-form-item>

        <el-form-item label="员工id" prop="employee_number">
          <el-input
            v-model="basicInfoForm.employee_number"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="basicInfoForm.name"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input
            v-model="basicInfoForm.mobile"
            placeholder="可作为登录账号，请正确输入手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="密码"
          :prop="$route.query.uuid != null ? '' : 'password'"
        >
          <el-input
            v-model="basicInfoForm.password"
            type="password"
            :placeholder="
              $route.query.uuid != null
                ? '编辑账号的时候输入密码即代表修改密码'
                : '请输入'
            "
          ></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select v-model="basicInfoForm.sex" placeholder="请选择">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出生日期">
          <el-date-picker
            v-model="basicInfoForm.birthday"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="员工角色" prop="group_id">
          <el-select v-model="basicInfoForm.group_id" placeholder="请选择">
            <el-option
              v-for="(jtem, i) in roleGroupList"
              :key="i"
              :label="jtem"
              :value="i"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属部门" prop="department_uuid">
          <el-select
            v-model="basicInfoForm.department_uuid"
            placeholder="请选择"
          >
            <!-- <el-option-group
              v-for="group in department"
              :key="group.uuid"
              :label="group.name"
              :value="group.uuid"
            > -->
            <el-option
              v-for="item in department"
              :key="item.uuid"
              :label="item.name"
              :value="item.uuid"
            >
            </el-option>
            <!-- </el-option-group> -->
          </el-select>
        </el-form-item>
        <el-form-item label="职位" prop="job">
          <el-input v-model="basicInfoForm.job" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="员工状态" prop="status" placeholder="请输入">
          <el-switch
            v-model="basicInfoForm.status"
            active-text="启用"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
        <!-- <el-form-item label="订单提醒" prop="is_order_notice" placeholder="请输入">
          <el-switch
            v-model="basicInfoForm.is_order_notice"
            active-text="启用"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray">
          </el-switch>
        </el-form-item>  -->
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog" />
     
    </el-dialog>
  </div>
</template>
<script>
import imageDialog from "../../../components/imageDialog.vue";
export default {
  name: "addAccount",
  components: { imageDialog },
  data() {
    return {
      imgType: "",
      dialogVisible: false,
      imageUrl: "",
      roleGroupList: [],
      basicInfoForm: {
        avatar: "",
        nick_name: "",
        employee_number: "",
        name: "",
        password: "",
        mobile: "",
        sex: "",
        birthday: "",
        group_id: "",
        department_uuid: "",
        job: "",
        status: 1,
        is_order_notice: 1,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      department: [],
      rules: {
        name: { required: true, message: "请输入昵称", trigger: "blur" },
        password: { required: true, message: "请输入", trigger: "blur" },
        mobile: { required: true, message: "请输入", trigger: "blur" },
        department_uuid: { required: true, message: "请输入", trigger: "blur" },
        group_id: { required: true, message: "请输入", trigger: "blur" },
        job: { required: true, message: "请输入", trigger: "blur" },
        employee_number: { required: true, message: "请输入", trigger: "blur" },
      },
    };
  },
  created() {
    if (this.$route.query.uuid != null) {
      this.getInfo();
    }
    this.getRoleList();
    this.getData();
  },
  methods: {
    closeImageDialog () {
      this.dialogVisible = false
    },
    confirmImageDialog (item) {
      this.basicInfoForm.avatar = item[0].url
      this.dialogVisible = false

    },
    httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.avatar =  res[0].url;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    beforeAvatarUpload(file) {
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/AdminUser/${this.$route.query.uuid}`,
      })
        .then((res) => {
          for (let i in res.data.result) {
            this.basicInfoForm[i] = res.data.result[i];
          }
          if (this.basicInfoForm.birthday.search("0000") != "-1") {
            this.basicInfoForm.birthday = "";
          }
          this.basicInfoForm.status = Number(this.basicInfoForm.status);
          this.basicInfoForm.is_order_notice = Number(
            this.basicInfoForm.is_order_notice
          );
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    selectImg(item) {
      this.basicInfoForm.avatar = item.url;
      this.dialogVisible = false;
    },
    // 选择banner
    chooseBanner() {
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible = true;
    },
    // 角色列表
    getRoleList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/AdminAuthGroupSelect`,
      })
        .then((res) => {
          this.roleGroupList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取部门
    getData() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Department`,
      })
        .then((res) => {
          this.department = res.data.result;
          console.log(this.department);
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (!this.$route.query.uuid) {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/AdminUser`,
            })
              .then((res) => {
                this.$message.success("保存成功!");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/AdminUser/${this.$route.query.uuid}`,
            })
              .then((res) => {
                this.$message.success("保存成功!");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.addAccount {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 16px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form {
  width: 400px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar-uploader {
  position: relative;
}
.avatar-uploader .el-icon-delete {
  font-size: 20px;
  position: absolute;
  right: -40px;
  bottom: 0px;
}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;

}
>>>.el-upload--picture-card i {
  font-size: 16px;
}
.upload-demo{
  border: 1px dashed #409eff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}
</style>
