<template>
  <!-- 我的资产明细报告 -->
  <div class="assetsDetailReport">
    <div class="container">
      <h3 class="title-t">我的资产明细报告</h3>
      <div class="row-container">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-card :class="{ 'active': active }">
              <p class="card-tit">报告风格</p>
              <p class="card-con">风格一</p>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card>
              <p class="card-tit">报告风格</p>
              <p class="card-con">风格二</p>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card>
              <p class="card-tit">报告风格</p>
              <p class="card-con">风格三</p>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div style="margin-bottom: 20px;">
        <el-button type="primary">选中风格</el-button>
        <el-button type="primary">下载图片</el-button>
      </div>
      <div>图片</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'assetsDetailReport',
  data() {
    return {
      uuid: this.$route.query.uuid,
      active: true
    }
  },

}
</script>
<style scoped>
.assetsDetailReport {
  background: #f2f2f2;
}

.container {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}

.row-container {
  padding: 20px 0;
}

.active .card-tit {
  color: #fff;
}

.card-tit {
  text-align: center;
  color: #909399;
}

.card-con {
  text-align: center;
  font-size: 18px;
}

.active.el-card {
  background-color: #E6A23C;
  color: #fff;
}
</style>