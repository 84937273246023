<template>
  <div class="noticeList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容</el-breadcrumb-item>
      <el-breadcrumb-item>通知公告</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/noticeList' }"
        >通知列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>列表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="dataTable">
      <div class="tabs flex">
        <p
          v-for="(item, index) in tabs"
          :key="index"
          @click="addClass(index)"
          :class="{ on: currentClass === index }"
        >
          {{ item }}
        </p>
      </div>
      <div class="flex space-between">
        <el-form
          :inline="true"
          :model="customerGroupForm"
          class="demo-form-inline"
        >
          <el-form-item class="keyword">
            <el-input
              placeholder="请输入要搜索的内容"
              clearable
              v-model="customerGroupForm.search_keyword"
              @input="getNoticeList(currentClass + 1)"
            ></el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-button type="primary" @click="$router.push('/massMessage')"
            >群发消息</el-button
          >
        </div>
      </div>

      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          label="标题"
          :align="'center'"
          prop="name"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="create_time" label="发布时间" :align="'center'">
        </el-table-column>

        <el-table-column prop="admin.name" label="发布人" :align="'center'">
        </el-table-column>
        <el-table-column prop="read_number" label="已读人数" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.read_number }} / {{ scope.row.total_number }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          fixed="right"
          width="250"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.status == 1"
              @click="$router.push(`/readList?uuid=${scope.row.uuid}`)"
              >已读列表</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.status == 2 && scope.row.delete_time == null"
              @click="toEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.delete_time == null"
              @click="deleted(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";

export default {
  name: "noticeList",
  components: {
    pagination,
  },
  data() {
    return {
      customerGroupForm: {
        search_keyword: "",
        search_status: "",
      },
      status: 1,
      tabs: ["已发布", "定时发布", "已删除"],
      currentClass: 0,
      labelOptions: [
        {
          label: "启用",
          value: "ok",
        },
        {
          label: "禁用",
          value: "no",
        },
      ],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
      },
    };
  },
  created() {
    this.getNoticeList(this.status);
  },
  methods: {
    // 编辑跳转
    toEdit(row) {
      row.attachment = JSON.stringify(row.attachment);
      row.user = JSON.stringify(row.user);
      this.$router.push({
        name: "massMessage",
        query: { ...row },
      });
    },
    addClass(index) {
      this.currentClass = index;
      if (index === 0) {
        this.status = 1;
      } else if (index === 1) {
        this.status = 2;
      } else {
        this.status = 3;
      }
      this.getNoticeList(this.status);
    },
    deleted(row) {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.axios({
          method: "delete", //请求方法
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/Notice/${row.uuid}`,
        })
          .then(() => {
            this.$message.success("删除成功！");
            this.getNoticeList(this.status);
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    getData(data, queryStry, mark, tableData, msg) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data,
        url: ` https://time.api.boyalife.cn/v1/cms/Notice${mark}${queryStry}`,
      })
        .then((res) => {
          if (msg !== "") {
            this.$message.success(msg);
          }
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 列表
    getNoticeList() {
      let data = {};
      let queryStry = `search_status=${this.status}&search_keyword=${this.customerGroupForm.search_keyword}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}`;
      this.getData(data, queryStry, "?", this.tableData, "");
    },
    // 新增群发消息
    // 编辑
    // 删除
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getNoticeList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getNoticeList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.noticeList .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
.dataTable {
  padding-top: 80px;
}
.dataTable .tabs {
  top: 20px;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
</style>
