<template>
  <el-menu
    :collapse="collapsed"
    collapse-transition
    router
    :default-active="$route.path"
    unique-opened
    class="el-menu-vertical-demo"
    background-color="#000c17"
    text-color="#fff"
    active-text-color="#ffd04b"
    v-if="allmenu.length > 0"
  >
    <div class="logobox">
      <!-- <div class="logo"></div> -->
    </div>
    <div v-for="menu in allmenu" :key="menu.id">
      <el-submenu v-if="menu.children.length > 0" :index="menu.id">
        <template slot="title">
          <!-- <i class="iconfont" :class="menu.icon"></i> -->
          <span>{{ menu.menu_name }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-for="chmenu in menu.children"
            :index="'' + chmenu.menu_url"
            :key="chmenu.menuid"
          >
            <!-- <i class="iconfont" :class="chmenu.icon"></i> -->
            <span>{{ chmenu.menu_name }}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item
        :index="menu.menu_url"
        v-else
        :key="menu.menu_url"
        :popper-class="'noChildMenu'"
      >
        <!-- <i class="iconfont" :class="menu.icon"></i> -->
        <span slot="title">{{ menu.menu_name }}</span>
      </el-menu-item>
    </div>
  </el-menu>
</template>
<script>
import api from "@/api";
export default {
  name: "leftnav",
  props: {
    allmenu: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      collapsed: false
    };
  },
  // 创建完毕状态(里面是操作)
  created() {
    console.log(this.allmenu);
    // this.$api.menu.getMenu().then(res => {
    //   console.log(res)
    // })
    let res = {
      success: true,
      data: [],
      // data: [
      //   {
      //     menuid: 1,
      //     icon: 'el-icon-s-home',
      //     menuname: '工作台',
      //     menus: [],
      //     url: 'stage',
      //   },
      //   {
      //     menuid: 2,
      //     icon: 'el-icon-s-custom',
      //     menuname: '客户',
      //     hasThird: null,
      //     url: null,
      //     menus: [
      //       {
      //         menuid: 3,
      //         icon: 'icon-order-manage',
      //         menuname: '客户列表',
      //         hasThird: 'N',
      //         url: 'customerList',
      //         menus: null
      //       },
      //       {
      //         menuid: 4,
      //         icon: 'icon-order-manage',
      //         menuname: '客户群组',
      //         hasThird: 'N',
      //         url: 'customerGroupList',
      //         menus: null
      //       },
      //       {
      //         menuid: 422,
      //         icon: 'icon-order-manage',
      //         menuname: '客户标签',
      //         hasThird: 'N',
      //         url: 'customerLabel',
      //         menus: null
      //       },
      //       {
      //         menuid: 5,
      //         icon: 'icon-order-manage',
      //         menuname: '会员等级',
      //         hasThird: 'N',
      //         url: 'memberLevelList',
      //         menus: null
      //       },
      //       {
      //         menuid: 6,
      //         icon: 'icon-order-manage',
      //         menuname: '会员权益',
      //         hasThird: 'N',
      //         url: 'memberBenefitsList',
      //         menus: null
      //       },
      //       {
      //         menuid: 7,
      //         icon: 'icon-order-manage',
      //         menuname: '实名认证',
      //         hasThird: 'N',
      //         url: 'realNameAuthentication',
      //         menus: null
      //       },
      //       {
      //         menuid: 83353,
      //         icon: 'icon-order-manage',
      //         menuname: '注销审核',
      //         hasThird: 'N',
      //         url: 'cancelAudit',
      //         menus: null
      //       },
      //       {
      //         menuid: 43252,
      //         icon: 'icon-order-manage',
      //         menuname: '客户导入',
      //         hasThird: 'N',
      //         url: 'customerImport',
      //         menus: null
      //       },
      //       {
      //         menuid: 852657,
      //         icon: 'icon-order-manage',
      //         menuname: '积分规则',
      //         hasThird: 'N',
      //         url: 'integralRule',
      //         menus: null
      //       },
      //       {
      //         menuid: 824325,
      //         icon: 'icon-order-manage',
      //         menuname: '成长值规则',
      //         hasThird: 'N',
      //         url: 'growthValueRules',
      //         menus: null
      //       },
      //       {
      //         menuid: 9,
      //         icon: 'icon-order-manage',
      //         menuname: '客户资料',
      //         hasThird: 'N',
      //         url: 'customerMaterial',
      //         menus: null
      //       },
      //       {
      //         menuid: 10,
      //         icon: 'icon-order-manage',
      //         menuname: '管理师列表',
      //         hasThird: 'N',
      //         url: 'managerMasterList',
      //         menus: null
      //       }
      //     ]
      //   },
      //   {
      //     menuid: 11,
      //     icon: 'el-icon-document',
      //     menuname: '内容',
      //     hasThird: null,
      //     url: null,
      //     menus: [
      //       {
      //         menuid: 72,
      //         icon: '',
      //         menuname: '资讯列表',
      //         hasThird: 'N',
      //         url: 'informationList',
      //         menus: null
      //       },
      //       {
      //         menuid: 722,
      //         icon: '',
      //         menuname: '早报列表',
      //         hasThird: 'N',
      //         url: 'morningPaperList',
      //         menus: null
      //       },
      //       {
      //         menuid: 711,
      //         icon: '',
      //         menuname: '资讯分类',
      //         hasThird: 'N',
      //         url: 'informationClassification',
      //         menus: null
      //       },
      //       {
      //         menuid: 74,
      //         icon: '',
      //         menuname: '敏感词库',
      //         hasThird: 'N',
      //         url: 'sensitiveWordsList',
      //         menus: null
      //       },
      //       {
      //         menuid: 75,
      //         icon: '',
      //         menuname: 'banner',
      //         hasThird: 'N',
      //         url: 'bannerList',
      //         menus: null
      //       },
      //       {
      //         menuid: 76,
      //         icon: '',
      //         menuname: '广告',
      //         hasThird: 'N',
      //         url: 'advertisementManageList',
      //         menus: null
      //       },
      //       {
      //         menuid: 2214,
      //         icon: '',
      //         menuname: '用户反馈',
      //         hasThird: 'N',
      //         url: 'userFeedbackList',
      //         menus: null
      //       },
      //       {
      //         menuid: 2324,
      //         icon: '',
      //         menuname: '消息公告',
      //         hasThird: 'N',
      //         url: 'NoticeList',
      //         menus: null
      //       },
      //     ]
      //   },
      //   {
      //     menuid: 212,
      //     icon: 'el-icon-office-building',
      //     menuname: '门店',
      //     hasThird: null,
      //     url: null,
      //     menus: [
      //       {
      //         menuid: 72,
      //         icon: '',
      //         menuname: '店铺列表',
      //         hasThird: 'N',
      //         url: 'storeList',
      //         menus: null
      //       },
      //       {
      //         menuid: 7223,
      //         icon: '',
      //         menuname: '品牌列表',
      //         hasThird: 'N',
      //         url: 'brandList',
      //         menus: null
      //       },
      //       {
      //         menuid: 7452,
      //         icon: '',
      //         menuname: '供应商列表',
      //         hasThird: 'N',
      //         url: 'supplierList',
      //         menus: null
      //       },
      //     ]
      //   },
      //   {
      //     menuid: 812,
      //     icon: 'el-icon-timer',
      //     menuname: '实验室',
      //     hasThird: null,
      //     url: null,
      //     menus: [
      //       {
      //         menuid: 843222,
      //         icon: '',
      //         menuname: '实验室列表',
      //         hasThird: 'N',
      //         url: 'laboratoryList',
      //         menus: null
      //       }
      //     ]
      //   },
      //   {
      //     menuid: 12,
      //     icon: 'el-icon-s-cooperation',
      //     menuname: '项目',
      //     hasThird: null,
      //     url: null,
      //     menus: [
      //       {
      //         menuid: 743222,
      //         icon: '',
      //         menuname: '项目列表',
      //         hasThird: 'N',
      //         url: 'projectList',
      //         menus: null
      //       },
      //       {
      //         menuid: 753452,
      //         icon: '',
      //         menuname: '项目分类',
      //         hasThird: 'N',
      //         url: 'projectCagotery',
      //         menus: null
      //       },
      //       {
      //         menuid: 75452,
      //         icon: '',
      //         menuname: '属性库',
      //         hasThird: 'N',
      //         url: 'ProjectAttributeDatabase',
      //         menus: null
      //       },
      //       {
      //         menuid: 72,
      //         icon: '',
      //         menuname: '项目评价',
      //         hasThird: 'N',
      //         url: 'projectEvaluateList',
      //         menus: null
      //       }
      //     ]
      //   },
      //   {
      //     menuid: 13,
      //     icon: 'el-icon-s-cooperation',
      //     menuname: '细胞',
      //     hasThird: null,
      //     url: null,
      //     menus: [
      //       {
      //         menuid: 72,
      //         icon: '',
      //         menuname: '细胞列表',
      //         hasThird: 'N',
      //         url: 'cellList',
      //         menus: null
      //       },
      //       // {
      //       //   menuid: 79,
      //       //   icon: '',
      //       //   menuname: '进度管理',
      //       //   hasThird: 'N',
      //       //   url: 'processManage',
      //       //   menus: null
      //       // }
      //     ]
      //   },
      //   {
      //     menuid: 14,
      //     icon: 'el-icon-s-cooperation',
      //     menuname: '商品',
      //     hasThird: null,
      //     url: null,
      //     menus: [
      //       {
      //         menuid: 843220,
      //         icon: '',
      //         menuname: '实物商品列表',
      //         hasThird: 'N',
      //         url: 'physicalCommodityList',
      //         menus: null
      //       },
      //       {
      //         menuid: 805432,
      //         icon: '',
      //         menuname: '虚拟商品列表',
      //         hasThird: 'N',
      //         url: 'virtualGoodsList',
      //         menus: null
      //       },
      //       {
      //         menuid: 80,
      //         icon: '',
      //         menuname: '实物库存管理',
      //         hasThird: 'N',
      //         url: 'inventoryManage',
      //         menus: null
      //       },
      //       {
      //         menuid: 8122,
      //         icon: '',
      //         menuname: '商品分类',
      //         hasThird: 'N',
      //         url: 'commodityCategoty',
      //         menus: null
      //       },
      //       {
      //         menuid: 8133,
      //         icon: '',
      //         menuname: '属性库',
      //         hasThird: 'N',
      //         url: 'commodityAttributeDatabase',
      //         menus: null
      //       },
      //       {
      //         menuid: 8135433,
      //         icon: '',
      //         menuname: '素材库',
      //         hasThird: 'N',
      //         url: 'materialLibrary',
      //         menus: null
      //       },
      //       {
      //         menuid: 8144,
      //         icon: '',
      //         menuname: '商品评价',
      //         hasThird: 'N',
      //         url: 'commodityEvaluate',
      //         menus: null
      //       },
      //     ]
      //   },
      //   {
      //     menuid: 152,
      //     icon: 'el-icon-s-cooperation',
      //     menuname: '订单',
      //     hasThird: null,
      //     url: null,
      //     menus: [
      //       {
      //         menuid: 81221,
      //         icon: '',
      //         menuname: '商品订单',
      //         hasThird: 'N',
      //         url: 'commodityOrder',
      //         menus: null
      //       },
      //       {
      //         menuid: 82434,
      //         icon: '',
      //         menuname: '虚拟商品订单',
      //         hasThird: 'N',
      //         url: 'virtualGoodsOrder',
      //         menus: null
      //       },
      //       {
      //         menuid: 82546,
      //         icon: '',
      //         menuname: '项目订单',
      //         hasThird: 'N',
      //         url: 'projectOrder',
      //         menus: null
      //       },
      //       // {
      //       //   menuid: 5643683,
      //       //   icon: '',
      //       //   menuname: '服务订单',
      //       //   hasThird: 'N',
      //       //   url: 'serviceOrder',
      //       //   menus: null
      //       // },
      //       {
      //         menuid: 8232,
      //         icon: '',
      //         menuname: '售后管理',
      //         hasThird: 'N',
      //         url: 'afterSaleManage',
      //         menus: null
      //       },
      //       {
      //         menuid: 5484,
      //         icon: '',
      //         menuname: '运费模板',
      //         hasThird: 'N',
      //         url: 'freightTemplate',
      //         menus: null
      //       },
      //       {
      //         menuid: 6584,
      //         icon: '',
      //         menuname: '退货地址',
      //         hasThird: 'N',
      //         url: 'returnAddress',
      //         menus: null
      //       },
      //       {
      //         menuid: 85,
      //         icon: '',
      //         menuname: '售后原因',
      //         hasThird: 'N',
      //         url: 'afterSalesReason',
      //         menus: null
      //       },
      //       {
      //         menuid: 86,
      //         icon: '',
      //         menuname: '通用设置',
      //         hasThird: 'N',
      //         url: 'generalSet',
      //         menus: null
      //       },
      //     ]
      //   },
      //   {
      //     menuid: 1533,
      //     icon: 'el-icon-s-cooperation',
      //     menuname: '营销',
      //     hasThird: null,
      //     url: null,
      //     menus: [
      //       {
      //         menuid: 3211,
      //         icon: '',
      //         menuname: '活动列表',
      //         hasThird: 'N',
      //         url: 'activityList',
      //         menus: null
      //       },
      //       {
      //         menuid: 323431,
      //         icon: '',
      //         menuname: '优惠券',
      //         hasThird: 'N',
      //         url: 'couponList',
      //         menus: null
      //       },
      //       {
      //         menuid: 81,
      //         icon: '',
      //         menuname: '任务列表',
      //         hasThird: 'N',
      //         url: 'taskList',
      //         menus: null
      //       },
      //       {
      //         menuid: 823435,
      //         icon: '',
      //         menuname: '签到有礼',
      //         hasThird: 'N',
      //         url: 'signPolite',
      //         menus: null
      //       },
      //       {
      //         menuid: 82,
      //         icon: '',
      //         menuname: '邀请有礼',
      //         hasThird: 'N',
      //         url: 'invitateIsPolite',
      //         menus: null
      //       },
      //       {
      //         menuid: 8433,
      //         icon: '',
      //         menuname: '老会员奖励',
      //         hasThird: 'N',
      //         url: 'oldMemberRewards',
      //         menus: null
      //       },
      //       {
      //         menuid: 84,
      //         icon: '',
      //         menuname: '新会员奖励',
      //         hasThird: 'N',
      //         url: 'newMemberRewards',
      //         menus: null
      //       },
      //       {
      //         menuid: 85,
      //         icon: '',
      //         menuname: '代币记录',
      //         hasThird: 'N',
      //         url: 'tokensRecord',
      //         menus: null
      //       },
      //       {
      //         menuid: 86,
      //         icon: '',
      //         menuname: '积分记录',
      //         hasThird: 'N',
      //         url: 'integralRecord',
      //         menus: null
      //       },
      //       {
      //         menuid: 832127,
      //         icon: '',
      //         menuname: '勋章列表',
      //         hasThird: 'N',
      //         url: 'medalList',
      //         menus: null
      //       },
      //       {
      //         menuid: 87,
      //         icon: '',
      //         menuname: '储值卡管理',
      //         hasThird: 'N',
      //         url: 'storedValueCardManage',
      //         menus: null
      //       },
      //       {
      //         menuid: 88,
      //         icon: '',
      //         menuname: '储值购买记录',
      //         hasThird: 'N',
      //         url: 'storedValuePurchaseRecords',
      //         menus: null
      //       },
      //     ]
      //   },
      //   {
      //     menuid: 15343,
      //     icon: 'el-icon-s-cooperation',
      //     menuname: '推广',
      //     hasThird: null,
      //     url: null,
      //     menus: [
      //       {
      //         menuid: 123181,
      //         icon: '',
      //         menuname: '推广员列表',
      //         hasThird: 'N',
      //         url: 'promoterList',
      //         menus: null
      //       },
      //       {
      //         menuid: 1181,
      //         icon: '',
      //         menuname: '推广员审核',
      //         hasThird: 'N',
      //         url: 'promoterExamine',
      //         menus: null
      //       },
      //       {
      //         menuid: 1231,
      //         icon: '',
      //         menuname: '推广订单记录',
      //         hasThird: 'N',
      //         url: 'promotionOrderRecords',
      //         menus: null
      //       },
      //       {
      //         menuid: 12181,
      //         icon: '',
      //         menuname: '推广设置',
      //         hasThird: 'N',
      //         url: 'promotionSet',
      //         menus: null
      //       },
      //     ]
      //   },
      //   {
      //     menuid: 15231,
      //     icon: 'el-icon-s-cooperation',
      //     menuname: '数据',
      //     hasThird: null,
      //     url: null,
      //     menus: [
      //       {
      //         menuid: 8365,
      //         icon: '',
      //         menuname: '交易数据',
      //         hasThird: 'N',
      //         url: 'transactionData',
      //         menus: null
      //       },
      //       {
      //         menuid: 84,
      //         icon: '',
      //         menuname: '商品概括',
      //         hasThird: 'N',
      //         url: 'productOverview',
      //         menus: null
      //       },
      //       {
      //         menuid: 85,
      //         icon: '',
      //         menuname: '商品报表',
      //         hasThird: 'N',
      //         url: 'productReport',
      //         menus: null
      //       },
      //       {
      //         menuid: 86,
      //         icon: '',
      //         menuname: '类目分析',
      //         hasThird: 'N',
      //         url: 'categoryAnalysis',
      //         menus: null
      //       },
      //       {
      //         menuid: 87,
      //         icon: '',
      //         menuname: '项目概括',
      //         hasThird: 'N',
      //         url: 'projectOverview',
      //         menus: null
      //       },
      //       {
      //         menuid: 88,
      //         icon: '',
      //         menuname: '客户分析',
      //         hasThird: 'N',
      //         url: 'customerAnalysis',
      //         menus: null
      //       },
      //       {
      //         menuid: 89,
      //         icon: '',
      //         menuname: '会员分析',
      //         hasThird: 'N',
      //         url: 'memberAnalysis',
      //         menus: null
      //       },
      //       {
      //         menuid: 90,
      //         icon: '',
      //         menuname: '流量分析',
      //         hasThird: 'N',
      //         url: 'flowAnalysis',
      //         menus: null
      //       },
      //       {
      //         menuid: 91432,
      //         icon: '',
      //         menuname: '留存分析',
      //         hasThird: 'N',
      //         url: 'retentionAnalysis',
      //         menus: null
      //       },
      //       {
      //         menuid: 92,
      //         icon: '',
      //         menuname: '积分分析',
      //         hasThird: 'N',
      //         url: 'integralAnalysis',
      //         menus: null
      //       },
      //       {
      //         menuid: 93,
      //         icon: '',
      //         menuname: '推广员分析',
      //         hasThird: 'N',
      //         url: 'promoterAnalysis',
      //         menus: null
      //       },
      //     ]
      //   },
      //   {
      //     menuid: 154345,
      //     icon: 'el-icon-s-cooperation',
      //     menuname: '财务',
      //     hasThird: null,
      //     url: null,
      //     menus: [
      //       {
      //         menuid: 87673,
      //         icon: '',
      //         menuname: '交易流水',
      //         hasThird: 'N',
      //         url: 'transactionFlow',
      //         menus: null
      //       },
      //       {
      //         menuid: 887683,
      //         icon: '',
      //         menuname: '提现审核',
      //         hasThird: 'N',
      //         url: 'withdrawalReview',
      //         menus: null
      //       },
      //       // {
      //       //   menuid: 7456783,
      //       //   icon: '',
      //       //   menuname: '开票列表',
      //       //   hasThird: 'N',
      //       //   url: 'invoicingList',
      //       //   menus: null
      //       // },
      //     ]
      //   },
      //   {
      //     menuid: 15645,
      //     icon: 'el-icon-s-cooperation',
      //     menuname: '设置',
      //     hasThird: null,
      //     url: null,
      //     menus: [
      //       {
      //         menuid: 90,
      //         icon: '',
      //         menuname: '账号管理',
      //         hasThird: 'N',
      //         url: 'accountManagement',
      //         menus: null
      //       },
      //       {
      //         menuid: 9541,
      //         icon: '',
      //         menuname: '角色管理',
      //         hasThird: 'N',
      //         url: 'rolesManagement',
      //         menus: null
      //       },
      //       {
      //         menuid: 6574,
      //         icon: '',
      //         menuname: '部门管理',
      //         hasThird: 'N',
      //         url: 'departmentManagement',
      //         menus: null
      //       },
      //       {
      //         menuid: 9741,
      //         icon: '',
      //         menuname: '协议管理',
      //         hasThird: 'N',
      //         url: 'protocolManagement',
      //         menus: null
      //       },
      //       {
      //         menuid: 97413,
      //         icon: '',
      //         menuname: '博雅简介',
      //         hasThird: 'N',
      //         url: 'boyaIntroduce',
      //         menus: null
      //       },
      //       {
      //         menuid: 97412,
      //         icon: '',
      //         menuname: '产品与服务',
      //         hasThird: 'N',
      //         url: 'productService',
      //         menus: null
      //       },
      //       {
      //         menuid: 97411,
      //         icon: '',
      //         menuname: '联系我们',
      //         hasThird: 'N',
      //         url: 'contactUs',
      //         menus: null
      //       },
      //       {
      //         menuid: 857535,
      //         icon: '',
      //         menuname: '短信签名',
      //         hasThird: 'N',
      //         url: 'smsSignature',
      //         menus: null
      //       },
      //       {
      //         menuid: 875753,
      //         icon: '',
      //         menuname: '短信模板',
      //         hasThird: 'N',
      //         url: 'smsTemplate',
      //         menus: null
      //       },
      //       {
      //         menuid: 53422,
      //         icon: '',
      //         menuname: '短信黑名单',
      //         hasThird: 'N',
      //         url: 'smsBlackList',
      //         menus: null
      //       },
      //       {
      //         menuid: 634,
      //         icon: '',
      //         menuname: '消息配置',
      //         hasThird: 'N',
      //         url: 'messageConfiguration',
      //         menus: null
      //       },
      //       {
      //         menuid: 6547,
      //         icon: '',
      //         menuname: '页面配置',
      //         hasThird: 'N',
      //         url: 'miniProgramPageConfig',
      //         menus: null
      //       },
      //       {
      //         menuid: 54353,
      //         icon: '',
      //         menuname: '分享配置',
      //         hasThird: 'N',
      //         url: 'shareSet',
      //         menus: null
      //       },
      //       {
      //         menuid: 2434,
      //         icon: '',
      //         menuname: '常见问题',
      //         hasThird: 'N',
      //         url: 'usualAskQuestion',
      //         menus: null
      //       },
      //       {
      //         menuid: 646547,
      //         icon: '',
      //         menuname: '操作日志',
      //         hasThird: 'N',
      //         url: 'operationLog',
      //         menus: null
      //       },
      //       {
      //         menuid: 24234,
      //         icon: '',
      //         menuname: '修改密码',
      //         hasThird: 'N',
      //         url: 'changePassword',
      //         menus: null
      //       },
      //       {
      //         menuid: 765856,
      //         icon: '',
      //         menuname: '站内信',
      //         hasThird: 'N',
      //         url: 'notifications',
      //         menus: null
      //       },
      //       {
      //         menuid: 24232,
      //         icon: '',
      //         menuname: '菜单配置',
      //         hasThird: 'N',
      //         url: 'menuSet',
      //         menus: null
      //       }
      //     ]
      //   }
      // ],
      msg: "success"
    };
    // this.allmenu = res.data

    // menu(localStorage.getItem('logintoken'))
    //   .then(res => {
    //     console.log(JSON.stringify(res))
    //     if (res.success) {
    //       this.allmenu = res.data
    //     } else {
    //       this.$message.error(res.msg)
    //       return false
    //     }
    //   })
    //   .catch(err => {
    //     this.$message.error('菜单加载失败，请稍后再试！')
    //   })
    // 监听
    // this.$root.Bus.$on('toggle', value => {
    //   this.collapsed = !value
    // })
  },
  methods: {
    toStage() {
      this.$router.push("/stage");
      console.log(2);
    }
  }
};
</script>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  min-height: 400px;
  height: 100%;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
  text-align: left;
}

.el-menu-item-group__title {
  padding: 0px;
}

.el-menu-bg {
  background-color: #000c17 !important;
}

.el-menu {
  border: none;
}

.el-submenu__title i.iconfont,
.el-menu-item i.iconfont {
  margin: 0 4px;
  display: inline-block;
  font-size: 18px;
  margin-right: 9px;
}

.el-submenu + .el-submenu {
  border-top: 2px solid #000;
}

.el-menu-item-group .el-menu-item {
  background: #000 !important;
  color: #c9c9c9 !important;
  font-size: 12px;
}

.logobox {
  color: #9d9d9d;
  font-size: 20px;
  text-align: center;
  padding: 20px 0px;
}

.logoimg {
  height: 40px;
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin: 10px auto;
  background: url(../../static/images/logo.jpg) no-repeat center;
  background-size: 100%;
}

.noChildMenu span {
  font-size: 16px !important;
}
</style>
