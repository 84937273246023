<template>
  <div class="inputBox" style="border: 1px solid #DCDFE6;">
    <el-input
      v-model="val.min"
      @input="val.min=val.min.replace(/[^\d]/g,' ')"
      @blur="handleVal"
      :placeholder="placeholderMin"
      :disabled="disabled"
    ></el-input>
    <span class="color-999">~</span>
    <el-input
      v-model="val.max"
      @input="val.max=val.max.replace(/[^\d]/g,' ')"
      @blur="handleVal"
      :placeholder="placeholderMax"
      :disabled="disabled"
    ></el-input>
  </div>
</template>
<script>
export default {
  name: "rangeInput",
  data() {
    return {
      val: {
        min: '',
        max: '',
      },
    };
  },
  props: {
    minValue: {
      type: String,
      default: ''
    },
    maxValue: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholderMin: {
      type: String,
      default: "请输入",
    },
    placeholderMax: {
      type: String,
      default: "请输入",
    },
  },
  created () {
    if (this.minValue != '') {
      this.val.min = this.minValue
    }
    if (this.maxValue != '') {
      this.val.max = this.maxValue
    }
  },
  model: {
    prop: "val",
    event: "input",
  },
  methods: {
    handleVal() {
      const _this = this;
      if (_this.val.min == "" || _this.val.min == undefined || _this.val.min == null ||
          _this.val.max == "" || _this.val.max == undefined || _this.val.max == null) {
            _this.$emit("input", _this.val);
      } else {
        if (Number(_this.val.min) >= Number(_this.val.max)) {
          _this.$message({
            type: 'warning',
            message: '区间最小值需要小于最大值!',
            onClose: function () {
              _this.val.min = '';
              _this.val.max = '';
            }
          });
          return
        }
        _this.$emit("input", _this.val);
      }
    },
  },
};
</script>
<style >
.inputBox {
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
}
.inputBox .el-input__inner{
  border: none!important;

}
.inputBox .el-input {
  width: 40%;
}
.ec-input-range-divider {
  display: inline-block;
    width: 7px;
    height: 1px;
    background: #c0c4cc;
    margin-bottom: 4px;
}

</style>