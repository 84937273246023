<template>
  <div class="detailsOfStoredValue">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item>储值收益</el-breadcrumb-item>
      <el-breadcrumb-item>储值订单记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="储值状态">
          <el-select
            v-model="customerGroupForm.search_status"
            placeholder="请选择"
          >
            <el-option label="待使用" :value="1"></el-option>
            <el-option label="使用中" :value="2"></el-option>
            <el-option label="已结束" :value="3"></el-option>
            <el-option label="申请退卡" :value="4"></el-option>
            <el-option label="已退卡" :value="5"></el-option>
            <el-option label="待支付" :value="6"></el-option>
            <el-option label="退款中" :value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期搜索">
          <el-date-picker
            class="mg-l-10"
            v-model="customerGroupForm.dataTime"
            :clearable="false"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="订单搜索" class="keyword">
          <el-input
            style="width: 200px; margin-left: 10px"
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium" @click="exportExcel">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        @sort-change="visitorSortChange"
        style="width: 100%"
      >
        <el-table-column label="储值卡描述" prop="nickName" width="300">
          <template slot-scope="scope">
            <p class="ellipsis font-18">
              {{ scope.row.name }}
            </p>
            <p>储值卡编号：{{ scope.row.code }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="user"
          label="储值人"
          :align="'center'"
          width="200"
        >
          <template slot-scope="scope">
            <router-link
              :to="'/customerInfo?uuid=' + scope.row.user.uuid"
              v-if="scope.row.user !== null"
            >
              <div style="margin: 0 auto; width: 150px">
                <div class="flex align-center" v-if="scope.row.user !== null">
                  <el-image fit="cover" :src="scope.row.user.avatar"></el-image>
                  <div class="text-left">
                    <p>{{ scope.row.user.nick_name }}</p>
                    <p>{{ scope.row.user.mobile }}</p>
                  </div>
                </div>
              </div>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="money" label="储值卡金额" :align="'center'">
          <template slot-scope="scope"> ￥{{ scope.row.money }} </template>
        </el-table-column>
        <el-table-column prop="give_money" label="赠送金额" :align="'center'">
          <template slot-scope="scope"> ￥{{ scope.row.give_money }} </template>
        </el-table-column>
        <el-table-column
          prop="total_money"
          label="储值余额"
          :align="'center'"
          sortable
        >
          <template slot-scope="scope">
            ￥{{ scope.row.total_money }}
          </template>
        </el-table-column>
        <el-table-column prop="status_name" label="储值状态" :align="'center'">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.status == 1"
              >待使用</span
            >
            <span style="color: red" v-else-if="scope.row.status == 4"
              >申请退卡</span
            >
            <span style="color: red" v-else-if="scope.row.status == 7"
              >退卡审核中</span
            >
            <span v-else>{{ scope.row.status_name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="pay_time"
          label="储值时间"
          :align="'center'"
          width="160"
          sortable
        >
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          fixed="right"
          width="150"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="
                $router.push('/detailsOfStoredValue?uuid=' + scope.row.uuid)
              "
              >储值明细</el-button
            >
            <el-button type="text" v-if="scope.row.status == 4"
              ><span @click.stop="pass(scope.row)">同意</span> /
              <span @click="refuse(scope.row)">拒绝</span></el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog title="拒绝退款" :visible.sync="dialogVisible" width="30%">
      <h5 class="mg-l-30 mg-b-10">退卡方式: 仅退款</h5>
      <h5 class="mg-l-30 mg-b-10">
        退款金额：<span style="color: red">{{ total_money }}</span>
      </h5>
      <h5 class="mg-l-30 mg-b-10">退款方式: 原支付渠道退回</h5>
      <el-form
        :model="form"
        :rules="rules"
        inline
        label-width="93px"
        ref="form"
      >
        <el-form-item label="拒绝原因" prop="refuseReason">
          <el-select v-model="form.refuseReason" placeholder="请选择">
            <el-option label="不支持无理由" :value="1"></el-option>
            <el-option label="协商一致" :value="2"></el-option>
            <el-option label="其他" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="拒绝原因" prop="formrefuseDsc">
          <el-input type="textarea" rows="5" v-model="form.refuseDsc" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "detailsOfStoredValue",
  components: {
    pagination,
  },
  data() {
    return {
      dialogVisible: false,
      value: "",
      total_money: "",
      customerGroupForm: {
        search_status: "",
        search_start_time: "",
        search_end_time: "",
        search_keyword: "",
        search_order: "create_time desc",
        search_key: "",
      },
      uuid: "",
      form: {
        refuse_reason: "",
        status: 3,
        refuse_desc: "",
        refuseReason: "",
        refuseDsc: "",
      },
      rules: {
        refuseReason: {
          required: true,
          message: "请选择原因",
          trigger: "change",
        },
        refuseDsc: { required: true, message: "请选择原因", trigger: "change" },
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 导出
    exportExcel() {
      this.axios({
        method: "post", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/StoredCard/export?search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}&search_status=${this.customerGroupForm.search_status}`,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let currentTime = new Date();
          let year = currentTime.getFullYear();
          let month = currentTime.getMonth() + 1;
          let day = currentTime.getDate();
          let formattedDate = `${year}/${month
            .toString()
            .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;

          link.download = `储值订单记录 - ${formattedDate}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    visitorSortChange(column) {
      console.log(column);
      if (column.order == "descending") {
        if (column.prop == "total_money") {
          this.customerGroupForm.search_order = "total_money desc";
        } else {
          this.customerGroupForm.search_order = "create_time desc";
        }
      } else {
        if (column.prop == "total_money") {
          this.customerGroupForm.search_order = "total_money Asc";
        } else {
          this.customerGroupForm.search_order = "create_time Asc";
        }
      }
      // this.customerGroupForm.search_key = column.prop
      this.getList();
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/StoredCard?search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}&search_status=${this.customerGroupForm.search_status}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_order=${this.customerGroupForm.search_order}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.form.refuse_reason = this.form.refuseReason;
          this.form.refuse_desc = this.form.refuseDsc;
          console.log(this.form);
          delete this.form.refuseDsc;
          delete this.form.refuseReason;
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.form,
            url: ` https://time.api.boyalife.cn/v1/cms/StoredCard/refund/${this.uuid}`,
          })
            .then(() => {
              this.$message.success("操作成功！");
              this.dialogVisible = false;
              this.getList();
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
    pass(row) {
      let html = `
        <h5 class="mg-l-30 mg-b-10">退卡方式: 仅退款</h5>
        <h5 class="mg-l-30 mg-b-10">退款金额：<span style="color: red">${row.total_money}</span></h5>
        <h5 class="mg-l-30 mg-b-10">退款方式: 原支付渠道退回</h5>
      `;
      this.$confirm(html, "同意退款", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.axios({
          method: "put", //请求方法
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            status: 5,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/StoredCard/refund/${row.uuid}`,
        })
          .then((res) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.getList();
          })
          .catch((err) => {
            this.$message.error("退款金额超限");
          });
      });
    },
    refuse(row) {
      this.dialogVisible = true;
      this.total_money = row.total_money;
      this.uuid = row.uuid;
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 28px;
  max-height: 28px;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}
.detailsOfStoredValue .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
.dataTable {
  position: relative;
  padding-top: 80px;
}
.nickAndAvatar .el-image {
  border-radius: 0;
}

>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
</style>
