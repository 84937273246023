<template>
  <el-dialog
    title="短信推送"
    :visible.sync="visible"
    width="30%"
    center="true"
    @closed="handleClose">
    <div class="stepList boxLayout">
      <el-steps :space="'50%'" :active="active" finish-status="success" align-center>
        <el-step title="填写发送信息"></el-step>
        <el-step title="确认发送信息"></el-step>
      </el-steps>
    </div>
    <el-form ref="form" :model="basicInfoForm" label-width="150px" :rules="rules" v-if="active === 0">
      <el-form-item label="接收人">
        <el-radio-group v-model="basicInfoForm.resource">
          <el-radio label="选择客户"></el-radio>
          <el-radio label="选择人群"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="已选择" prop="name">
          <el-tag
            :key="tag.uuid"
            v-for="tag in customerList"
            :disable-transitions="false"
            >
            {{tag.nick_name}}
          </el-tag>
          <el-button class="mg-b-20" size="mini" @click="selectCustomerDialogVisible = true"> + 添加收件人</el-button>
        </el-form-item>
      <el-form-item label="短信签名">
        <el-select v-model="basicInfoForm.region" placeholder="请选择">
          <el-option label="部门A" value="shanghai"></el-option>
          <el-option label="部门B" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="短信模板">
        <el-select v-model="basicInfoForm.template" placeholder="请选择">
          <el-option label="部门A" value="shanghai"></el-option>
          <el-option label="部门B" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="变量设置" v-if="basicInfoForm.template !== ''">
        <div class="box">
          <div class="arial flex space-around">
            <p>变量名称</p>
            <p>变量内容</p>
          </div>
          <div class="arial flex space-around">
            <p>${name}</p>
            <p><el-input></el-input></p>
          </div>
          <div class="arial flex space-around">
            <p>${order_code}</p>
            <p><el-input></el-input></p>
          </div>
          <div class="arial flex space-around">
            <p>${appid}</p>
            <p><el-input></el-input></p>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="msg" v-if="active === 1">
      <div class="flex">
        <span>接收人：</span>
        <p>王xx</p>
      </div>
      <div class="flex">
        <span>短信签名：</span>
        <p>egewgefewf</p>
      </div>
      <div class="flex">
        <span>短信模板：</span>
        <p>gergwfewf</p>
      </div>
      <div class="flex">
        <span>${name}：</span>
        <p>${appid}</p>
      </div>
      <div class="flex">
        <span>${order_code}：</span>
        <p>${appid}</p>
      </div>
      <div class="flex">
        <span>${appid}：</span>
        <p>${appid}</p>
      </div>

    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" v-if="active === 1" @click="active = 0">上一步</el-button>

      <el-button type="primary" v-if="active === 0" @click="active = 1">下一步</el-button>

      <el-button type="primary" v-if="active === 1" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'smsPushDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    dialogVisible (n) {
      if ( !n) {
        this.active = 0
      }
      this.visible = n
    }
  },
  data () {
    return {
      visible: false,
      active: 0,
      customerList: [],
      basicInfoForm: {
        template: ''
      }
    }
  },
  created () {
    console.log(this.basicInfoForm)
  },
  methods: {
    handleClose () {
      this.visible = false
      this.$emit('closeDialog', this.visible)
    }
  }
}
</script>
<style scoped>
>>>.el-dialog {
  min-width: 600px;
}
>>> .el-form {
  width: 100%!important;
}
>>> .el-form-item {
  margin-bottom: 20px!important;
}
.box {
  padding: 10px;
  background: rgb(217, 246, 253);
}
.arial {
  margin-bottom: 10px;
}
.arial p:first-child {
  width: 150px;
}
.arial p:last-child {
  width: 200px;
}
.msg {
  width: 300px;
  margin: 0 auto;
}
.msg span {
  width:120px;
  display: inline-block;
  text-align: right;
}
</style>