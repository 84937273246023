import httpRequest from './httpRequest'

const generatorRequest = ({ method, url }) => {
    if (method === 'get') {
        return params => httpRequest.get(url, { params })
    } else if (method === 'post') {
        return data => httpRequest.post(url, data)
    } else if (method === 'put') {
        return data => httpRequest.put(url, data)
    } else {
        return null
    }
}

const localModulesFiles = require.context('./modules', true, /\.js$/)
const localModule = localModulesFiles.keys().reduce((modules, modulePath) => {
    const value = localModulesFiles(modulePath)
    const moduleName = modulePath.replace(/^\.\/(.*)\.js$/, '$1')
    modules[moduleName] = Object.keys(value.default).reduce((subModules, key) => {
        subModules[key] =
            typeof value.default[key].fn === 'function' ? value.default[key].fn : generatorRequest(value.default[key])
        return subModules
    }, {})
    return modules
}, {})

export default localModule
