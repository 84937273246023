<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>客户</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
      <el-breadcrumb-item>全部客户</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询 -->
    <div class="search-container">
      <span class="moreOption" @click="operateMoreOptionBtn"
        >{{ moreOptionTips }} <i class="el-icon-d-arrow-right"></i
      ></span>
      <div class="search-box" :class="{ auto: moreOption }">
        <el-form :inline="true" :model="customerForm" class="demo-form-inline">
          <el-form-item label="关键字搜索" class="search_keyword">
            <el-input
              placeholder="请输入内容"
              v-model="customerForm.search_keyword"
              class="input-with-select"
            >
              <i slot="append" class="el-icon-search"></i>
            </el-input>
          </el-form-item>
          <el-form-item label="客户等级">
            <el-select v-model="customerForm.search_level" placeholder="请选择">
              <el-option v-for="(jtem, i) in levelList" :key="i" :label="jtem" :value="i">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户标签" prop="search_label">
            <el-cascader
              :options="labelList"
              v-model="search_label"
              @change="getLabelValue"
              :props="props"
              collapse-tags
              :show-all-levels="false"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="客户来源">
            <el-select v-model="customerForm.search_source" placeholder="请选择">
              <el-option label="小程序注册" :value="1"></el-option>
              <el-option label="后台添加" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户性别">
            <el-select v-model="customerForm.search_sex" placeholder="请选择">
              <el-option
                v-for="jtem in sexList"
                :key="jtem.uuid"
                :label="jtem.name"
                :value="jtem.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="累计订单金额">
            <rangeInput
              ref="ipt1"
              v-model="number1"
              @input="rangeNumber"
              :placeholderMin="'最低金额'"
              :placeholderMax="'最高金额'"
            />
          </el-form-item>
          <el-form-item label="累计订单数量">
            <rangeInput
              ref="ipt2"
              @input="orderNumber"
              :placeholderMin="'最低次数'"
              :placeholderMax="'最高次数'"
            />
          </el-form-item>
          <el-form-item label="注册日期">
            <el-date-picker
              v-model="dataTime"
              type="daterange"
              align="right"
              unlink-panels
              @change="getTimes"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="最近消费日期">
            <el-date-picker
              v-model="consumDate"
              type="daterange"
              align="right"
              unlink-panels
              @change="getTimes2"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="最近无消费客户">
            <el-date-picker
              v-model="consumDate2"
              type="daterange"
              align="right"
              unlink-panels
              @change="getTimes3"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="客户年龄段">
            <rangeInput
              ref="ipt3"
              @input="ageNumber"
              :placeholderMin="'最低次数'"
              :placeholderMax="'最高次数'"
            />
          </el-form-item>
          <el-form-item label="客户余额">
            <rangeInput
              ref="ipt4"
              @input="countNumber"
              :placeholderMin="'最低金额'"
              :placeholderMax="'最高金额'"
            />
          </el-form-item>
          <el-form-item label="可用积分">
            <rangeInput
              ref="ipt5"
              @input="ingerNumber"
              :placeholderMin="'最低金额'"
              :placeholderMax="'最高金额'"
            />
          </el-form-item>
          <el-form-item label="最近将过期积分">
            <el-date-picker
              v-model="consumption"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="实名认证" prop="search_is_real_name">
            <el-select v-model="customerForm.search_is_real_name" placeholder="请选择">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否注销">
            <el-select v-model="customerForm.search_is_cancel" placeholder="请选择">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户身份">
            <el-select v-model="customerForm.search_user_role" placeholder="请选择">
              <el-option label="游客" :value="1"></el-option>
              <el-option label="会员" :value="2"></el-option>
              <el-option label="推广员" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="人群筛选">
            <el-select v-model="customerForm.search_group" placeholder="请选择">
              <el-option
                v-for="(jtem, i) in userGroupList"
                :key="i"
                :label="jtem"
                :value="i"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList">查询</el-button>
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="text-right mg-b-20">
        <el-button type="primary" @click="$router.push('/addCustomer')"
          >添加客户</el-button
        >

        <el-button type="primary" :disabled="selectNum == 0" @click="batchSetManage"
          >批量设置管理</el-button
        >
        <el-button type="primary" :disabled="selectNum == 0" @click="batchSetSeller"
          >批量设置销售</el-button
        >
        <el-button @click="dialogTableVisible = true" :disabled="selectNum == 0"
          >批量添加标签</el-button
        >
        <el-button @click="batchRefuse" :disabled="selectNum == 0"
          >批量冻结客户</el-button
        >
        <el-button @click="showIntegralVisible" :disabled="selectNum == 0"
          >批量增减</el-button
        >
        <el-button @click="dialogLevelVisible = true" :disabled="selectNum == 0"
          >批量设置等级</el-button
        >
      </div>

      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        row-key="getRowKeys"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" :align="'center'" width="55"> </el-table-column>
        <el-table-column label="客户头像/昵称" :align="'center'" width="160">
          <template slot-scope="scope">
            <router-link :to="`/customerInfo?uuid=${scope.row.uuid}`">
              <div class="nickAndAvatar">
                <el-image
                  :src="scope.row.avatar"
                  v-if="scope.row.avatar !== null"
                ></el-image>
                <p>
                  <span>{{ scope.row.nick_name }}</span> <br />
                  <!-- <span>id: {{ scope.row.uuid }}</span> -->
                </p>
              </div>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="注册手机号码" :align="'center'" width="110">
        </el-table-column>
        <el-table-column prop="level.name" label="客户等级" :align="'center'">
        </el-table-column>
        <el-table-column
          prop="label"
          label="客户标签"
          :align="'center'"
          width="110"
          show-overflow-tooltip
        >
          <template slot-scope="scope" v-if="scope.row.label.length > 0">
            <el-tag
              class="mg-r-10"
              type="success"
              v-for="item in scope.row.label"
              :key="item.uuid"
              >{{ item.name }}&nbsp;/&nbsp;</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="consume_number"
          label="累计消费次数"
          :align="'center'"
          width="110"
        >
        </el-table-column>
        <el-table-column
          prop="consume_price"
          label="累计消费金额"
          :align="'center'"
          width="110"
        >
        </el-table-column>
        <el-table-column prop="user_money" label="会员资产" :align="'center'">
        </el-table-column>
        <el-table-column prop="user_score" label="积分" :align="'center'">
        </el-table-column>
        <el-table-column prop="user_role" label="客户身份" :align="'center'">
          <template slot-scope="scope">
            <span v-if="scope.row.user_role == 1 || scope.row.user_role == 0">游客</span>
            <span v-else-if="scope.row.user_role == 2">会员</span>
            <span v-else-if="scope.row.user_role == 3">推广员</span>
          </template>
        </el-table-column>
        <el-table-column prop="is_freezed" label="是否冻结" :align="'center'">
          <template slot-scope="scope">
            <span v-if="scope.row.is_freezed == 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column prop="is_cancel" label="注销状态" :align="'center'">
          <template slot-scope="scope">
            <span v-if="scope.row.is_cancel == 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="last_consumption_time"
          label="最近消费时间"
          :align="'center'"
          width="160"
        >
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="注册时间"
          :align="'center'"
          width="160"
        >
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="200px">
          <template slot-scope="scope">
            <div>
              <el-button
                @click="$router.push('/customerInfo?uuid=' + scope.row.uuid)"
                type="text"
                size="small"
                >查看详情</el-button
              >
              <el-button type="text" size="small">|</el-button>
              <el-button type="text" size="small" @click="markLabels(scope.row)"
                >添加标签</el-button
              >
            </div>
            <div>
              <el-button
                type="text"
                v-if="scope.row.is_freezed == 2 || scope.row.is_freezed == 0"
                size="small"
                @click="refuse(scope.row)"
                >冻结</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.is_freezed == 1"
                size="small"
                @click="refuse(scope.row)"
                >解冻</el-button
              >
              <el-button type="text" size="small">|</el-button>
              <el-button
                type="text"
                size="small"
                @click="$router.push('/familyAssets?uuid=' + scope.row.uuid)"
                >家庭资产</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog title="批量增减操作" :visible.sync="dialogIntegralVisible" width="50%">
      <el-form ref="Integral" :model="ingerForm" class="mg-t-20" :rules="ingerFormRules">
        <el-form-item label="操作对象" prop="set_type">
          <el-radio v-model="ingerForm.set_type" :label="1" border>积分</el-radio>
          <el-radio v-model="ingerForm.set_type" :label="2" border>成长值</el-radio>
          <el-radio v-model="ingerForm.set_type" :label="3" border>权益金</el-radio>
        </el-form-item>
        <el-form-item prop="type" label="操作类型">
          <el-radio v-model="ingerForm.type" :label="1" border>增加</el-radio>
          <el-radio v-model="ingerForm.type" :label="2" border>减少</el-radio>
        </el-form-item>
        <el-form-item prop="amount" :label="ingerForm.set_type == 1 ? '积分' : (ingerForm.set_type == 2 ? '成长值' : '权益金')">
          <el-input
            v-model="ingerForm.amount"
            autocomplete="off"
            placeholder="请输入数值"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="ingerForm.remark"
            autocomplete="off"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogIntegralVisible = false">取 消</el-button>
        <el-button type="primary" @click="setIntegral('Integral')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="批量设置管理员"
      :visible.sync="dialogManageVisible"
      inline
      width="30%"
    >
      <el-radio-group class="mg-t-20" v-model="isAdmin">
        <el-radio :label="1">设置小程序管理员</el-radio>
        <el-radio :label="2">取消小程序管理员</el-radio>
      </el-radio-group>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogManageVisible = false">取 消</el-button>
        <el-button type="primary" @click="setManage">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="批量设置销售"
      :visible.sync="dialogSellerVisible"
      inline
      width="30%"
    >
      <el-radio-group class="mg-t-20" v-model="is_seller">
        <el-radio :label="1">设置销售员</el-radio>
        <el-radio :label="2">取消销售员</el-radio>
      </el-radio-group>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSellerVisible = false">取 消</el-button>
        <el-button type="primary" @click="setSeller">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="批量设置等级" :visible.sync="dialogLevelVisible" inline width="30%">
      <el-form :model="customerForm" class="mg-t-20">
        <el-form-item label="客户等级">
          <el-select v-model="level" placeholder="请选择">
            <el-option v-for="(jtem, i) in levelList" :key="i" :label="jtem" :value="i">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogLevelVisible = false">取 消</el-button>
        <el-button type="primary" @click="setLevel">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="选择标签"
      :visible.sync="dialogTableVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <markLabel
        :uuid="uuid"
        :uuids="selectUuids"
        :selectNum="selectNum"
        :tags="tags"
        @closeDialog="closeDialog"
      />
    </el-dialog>
  </div>
</template>
<script>
import rangeInput from "../../../components/rangeInput.vue";
import markLabel from "../../../components/markLabel.vue";
import pagination from "../../../components/pagination.vue";
export default {
  name: "customerList",
  components: { rangeInput, markLabel, pagination },
  data() {
    return {
      isAdmin: 1,
      is_seller: 1,
      dialogManageVisible: false,
      dialogSellerVisible: false,
      level: "",
      uuid: "",
      uuids: [],
      sexList: [],
      tags: [],
      search_label: "",
      dataTime: "",
      consumDate: "",
      consumDate2: "",
      consumption: "",
      selectNum: 0,
      selectUuids: [],
      levelList: [],
      labelList: [],
      userGroupList: [],
      dialogTableVisible: false,
      dialogIntegralVisible: false,
      dialogLevelVisible: false,
      moreOptionTips: "展开更多选项",
      moreOption: false,
      number1: [],
      ingerForm: {
        set_type: 1,
        type: 1,
        amount: "",
        remark: "",
        uuids: [],
      },
      ingerFormRules: {
        amount: { required: true, message: "请输入数量", trigger: "blur" },
      },
      customerForm: {
        search_level: "",
        search_sex: "",
        search_is_real_name: "",
        search_is_cancel: "",
        search_user_role: "",
        search_source: "",
        search_label: "",
        search_group: "",
        search_keyword: "",
        search_start_consume_price: "",
        search_end_consume_price: "",
        search_start_consume_number: "",
        search_end_consume_number: "",
        search_start_time: "",
        search_end_time: "",
        search_start_consume_time: "",
        search_end_consume_time: "",
        search_start_no_consume_time: "",
        search_end_no_consume_time: "",
        search_start_user_money: "",
        search_end_user_money: "",
        search_start_user_score: "",
        search_end_user_score: "",
        search_start_age: "",
        search_end_age: "",
      },
      props: {
        label: "name",
        value: "uuid",
        children: "label",
      },
      ingerList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
    this.getSex();
    this.getLevel();
    this.getLabel();
    this.getUserGroup();
    if (Object.keys(this.$route.query.length > 0)) {
      let params = this.$route.query;
      for (let i in params) {
        this.customerForm[i] = JSON.parse(JSON.stringify(params[i]));
      }
      this.getList();
    }
  },
  methods: {
    // 设置销售
    setSeller() {
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          is_seller: this.is_seller,
          uuids: this.selectUuids,
        },
        url: `https://time.api.boyalife.cn/v1/cms/User/set_seller`,
      }).then((res) => {
        this.dialogSellerVisible = false;
        this.getList();
        this.$message.success("设置成功！");
      });
    },
    // 设置管理
    setManage() {
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          is_admin: this.isAdmin,
          uuids: this.selectUuids,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/User/set_admin`,
      }).then((res) => {
        this.dialogManageVisible = false;
        this.getList();
        this.$message.success("设置成功！");
      });
    },
    // 批量设置管理
    batchSetManage() {
      this.dialogManageVisible = true;
      console.log(this.selectUuids);
      // this.$confirm("确认设置为小程序管理员吗?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      //   this.axios({
      //     method: "post", //请求方法
      //     headers: {
      //       "Content-Type": "application/json",
      //       "X-Access-Token": this.storage.get("__token"),
      //       Authorization: `Bearer ${this.storage.get("__token")}`,
      //     },
      //     data: {
      //       is_admin: "",
      //       uuids: this.selectUuids,
      //     },
      //     url: ` https://time.api.boyalife.cn/v1/cms/User/set_admin`,
      //   }).then((res) => {
      //     this.$message.success("设置成功！");
      //   });
      // });
    },
    batchSetSeller() {
      this.dialogSellerVisible = true;
    },
    getSex() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.basicInfoForm,
        url: ` https://time.api.boyalife.cn/v1/cms/UserProfile/ade2d2b666f75232466890e075e26616`,
      }).then((res) => {
        let params = res.data.result;
        this.sexList = params.option;
      });
    },
    clear() {
      this.dataTime = "";
      this.consumDate = "";
      this.consumDate2 = "";
      this.consumption = "";
      this.search_label = "";
      this.$refs["ipt1"].val.min = "";
      this.$refs["ipt1"].val.max = "";
      this.$refs["ipt2"].val.min = "";
      this.$refs["ipt2"].val.max = "";
      this.$refs["ipt3"].val.min = "";
      this.$refs["ipt3"].val.max = "";
      this.$refs["ipt4"].val.min = "";
      this.$refs["ipt4"].val.max = "";
      this.$refs["ipt5"].val.min = "";
      this.$refs["ipt5"].val.max = "";

      for (let i in this.customerForm) {
        this.customerForm[i] = "";
      }
      this.getList();
    },
    showIntegralVisible() {
      this.dialogIntegralVisible = true;
      this.ingerForm.amount = "";
      this.ingerForm.remark = "";
    },
    // 增减积分
    setIntegral(Integral) {
      this.$refs[Integral].validate((valid) => {
        if (valid) {
          this.axios({
            method: "post", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.ingerForm,
            url: `https://time.api.boyalife.cn/v1/cms/User/mset`,
          })
            .then((res) => {
              this.$message.success("操作成功！");
              this.dialogIntegralVisible = false;
              this.getList();
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
    markLabels(row) {
      this.dialogTableVisible = true;
      this.uuid = row.uuid;
      this.tags = row.label;
    },
    setLevel() {
      if (this.level == "") {
        this.$message.warning("请选择等级！");
        return false;
      }
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          level: this.level,
          uuids: this.selectUuids,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/User/level_branch`,
      })
        .then((res) => {
          this.$message.success("操作成功！");
          this.dialogLevelVisible = false;
          this.getList();
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
      this.dialogLevelVisible = true;
    },
    // 展开更多选项
    operateMoreOptionBtn() {
      this.moreOption = !this.moreOption;
      if (this.moreOption) {
        this.moreOptionTips = "收起更多选项";
      } else {
        this.moreOptionTips = "展开更多选项";
      }
    },
    getLabelValue(v) {
      this.customerForm.search_label = v[v.length - 1];
    },
    // 选择时间
    getTimes(v) {
      this.customerForm.search_start_time = v[0] + " 00:00:00";
      this.customerForm.search_end_time = v[1] + " 23:59:59";
    },
    // 选择时间
    getTimes2(v) {
      this.customerForm.search_start_consume_time = v[0] + " 00:00:00";
      this.customerForm.search_end_consume_time = v[1] + " 23:59:59";
    },
    // 选择时间
    getTimes3(v) {
      this.customerForm.search_start_no_consume_time = v[0] + " 00:00:00";
      this.customerForm.search_end_no_consume_time = v[1] + " 23:59:59";
    },
    // 会员人群
    getUserGroup() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserGroupSelect`,
      })
        .then((res) => {
          this.userGroupList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getLevel() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserLevelSelect`,
      })
        .then((res) => {
          this.levelList = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    getLabel() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserLabelGroupTree`,
      })
        .then((res) => {
          this.labelList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 列表
    getList() {
      let queryStr = `search_level=${this.customerForm.search_level}&search_sex=${this.customerForm.search_sex}&search_is_real_name=${this.customerForm.search_is_real_name}&search_is_cancel=${this.customerForm.search_is_cancel}&search_user_role=${this.customerForm.search_user_role}&search_source=${this.customerForm.search_source}&search_label=${this.customerForm.search_label}&search_group=${this.customerForm.search_group}&search_keyword=${this.customerForm.search_keyword}&search_start_consume_price=${this.customerForm.search_start_consume_price}&search_end_consume_price=${this.customerForm.search_end_consume_price}&search_start_consume_number=${this.customerForm.search_start_consume_number}&search_end_consume_number=${this.customerForm.search_end_consume_number}&search_start_time=${this.customerForm.search_start_time}&search_end_time=${this.customerForm.search_end_time}&search_start_consume_time=${this.customerForm.search_start_consume_time}&search_end_consume_time=${this.customerForm.search_end_consume_time}&search_start_no_consume_time=${this.customerForm.search_start_no_consume_time}&search_end_no_consume_time=${this.customerForm.search_end_no_consume_time}&search_start_user_money=${this.customerForm.search_start_user_money}&search_end_user_money=${this.customerForm.search_end_user_money}&search_start_user_score=${this.customerForm.search_start_user_score}&search_end_user_score=${this.customerForm.search_end_user_score}&search_start_age=${this.customerForm.search_start_age}&search_end_age=${this.customerForm.search_end_age}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}
      `;
      this.getData("get", {}, queryStr, "?", this.tableData, "", "/User");
    },
    getData(methods, data, queryStry, mark, tableData, msg, api) {
      this.axios({
        method: methods, //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data,
        url: ` https://time.api.boyalife.cn/v1/cms/${api}${mark}${queryStry}`,
      })
        .then((res) => {
          if (msg !== "") {
            this.$message.success(msg);
          }
          if (tableData !== null) {
            this.tableData = res.data.result;
          }
          if (methods == "delete" || methods == "post" || methods == "put") {
            this.getList();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    refuse(row) {
      let status = "";
      let html = "";
      if (row.is_freezed == 1) {
        html = "是否解冻客户，解冻后客户可以购买商品。";
        status = 2;
      } else {
        status = 1;
        html = "冻结后客户将无法购买商品，确定要冻结此客户吗?";
      }
      this.$confirm(html, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.getData(
            "put",
            { status },
            row.uuid,
            "/",
            null,
            "操作成功",
            "User/freezed"
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消冻结",
          });
        });
      //  https://time.api.boyalife.cn/v1/cms/User/freezed_branch
    },
    // 批量
    batchRefuse() {
      this.$confirm("冻结后客户将无法购买商品，确定要冻结此客户吗?", "冻结确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.getData(
            "post",
            { uuids: this.selectUuids, status: 1 },
            this.selectUuids,
            "/",
            null,
            "操作成功",
            "User/freezed_branch"
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消冻结",
          });
        });
    },
    // 消费金额
    rangeNumber(val) {
      this.customerForm.search_start_consume_price = val.min;
      this.customerForm.search_end_consume_price = val.max;
    },
    // 订单数量
    orderNumber(val) {
      this.customerForm.search_start_consume_number = val.min;
      this.customerForm.search_end_consume_number = val.max;
    },
    // 年龄
    ageNumber(val) {
      this.customerForm.search_start_age = val.min;
      this.customerForm.search_end_age = val.max;
    },
    // 余额
    countNumber(val) {
      this.customerForm.search_start_user_money = val.min;
      this.customerForm.search_end_user_money = val.max;
    },
    // 余额
    ingerNumber(val) {
      this.customerForm.search_start_user_score = val.min;
      this.customerForm.search_end_user_score = val.max;
    },
    // 表格颜色设置
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      } else {
        return "";
      }
    },
    handleSelectionChange(rows) {
      this.selectNum = rows.length;
      this.selectUuids = rows.map((item) => {
        return item.uuid;
      });
      this.ingerForm.uuids = this.selectUuids;
      this.ingerList = rows.map((item) => item.user_score);
    },
    getRowKeys(row) {
      return row.case_id;
    },
    closeDialog() {
      this.dialogTableVisible = false;
      this.getList();
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
  padding-top: 40px;
  position: relative;
}

.search-container .el-input-group__append {
  background: none;
  cursor: pointer;
}

.search-container .keyword .el-input__inner {
  border-right: none !important;
}

.search-container .el-form--inline .el-form-item__label {
  color: #999;
}

.search-box {
  height: 170px;
  overflow: hidden;
}

.search-box.auto {
  height: auto;
}

.search-container .moreOption {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
  color: chocolate;
}

.el-table .warning-row {
  background: #f4fcff;
}

.el-table .el-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 5px;
}

.el-table .nickAndAvatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
}
</style>
