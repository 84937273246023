import { render, staticRenderFns } from "./massMessage.vue?vue&type=template&id=1170e407&scoped=true"
import script from "./massMessage.vue?vue&type=script&lang=js"
export * from "./massMessage.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./massMessage.vue?vue&type=style&index=1&id=1170e407&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1170e407",
  null
  
)

export default component.exports