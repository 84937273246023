<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/smsSignature' }"
        >短信签名</el-breadcrumb-item
      >
      <el-breadcrumb-item>短信配置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="150px"
        :rules="rules"
      >
        <el-form-item label="账号名称" prop="name">
          <el-input
            v-model="basicInfoForm.name"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="AccessKeyId" prop="access_key">
          <el-input
            v-model="basicInfoForm.access_key"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="AccessKeySecret" prop="secret_key">
          <el-input
            v-model="basicInfoForm.secret_key"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "smsConfiguration",
  data() {
    return {
      basicInfoForm: {
        name: "",
        access_key: "",
        secret_key: "",
      },
      rules: {
        name: { required: true, message: "请输入", trigger: "blur" },
        access_key: { required: true, message: "请输入", trigger: "blur" },
        secret_key: { required: true, message: "请输入", trigger: "blur" },
      },
    };
  },
  methods: {
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.basicInfoForm,
            url: ` https://time.api.boyalife.cn/v1/cms/SmsSetting/${this.basicInfoForm.uuid}`,
          })
            .then((res) => {
              this.$message.success("编辑成功！");
              this.$router.go(-1);
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.el-form {
  width: 400px;
}
</style>
