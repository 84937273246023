<template>
  <div class="serviceOrder">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item >订单</el-breadcrumb-item>
      <el-breadcrumb-item >订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>全部订单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form :inline="true" :model="customerGroupForm" class="demo-form-inline">
        <el-form-item label="日期搜索">
          <el-date-picker
            v-model="customerGroupForm.registerDate"
            type="daterange"
            align="right"
            unlink-panels
            @click="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input placeholder="请输入内容" v-model="customerGroupForm.search_keyword">
            <i slot="append" class="el-icon-search"></i>
            </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList">查询</el-button>
        <el-button size="medium" @click="clearForm">清空</el-button>
        <el-button size="medium">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="tabs flex">
        <p v-for="(item,index) in tabs" :key="item" @click="addClass(index)" :class="{'on': currentClass === index}">{{ item }}</p>
      </div>
      <div class="text-right mg-b-20">
        <el-button type="primary" @click="addRemark">批量备注</el-button>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        tooltip-effect="dark"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          label="订单信息"
          :align="'center'"
          width="500"
          >
          <template slot-scope="scope">
              <div class="product flex space-between">
                <div class="flex space-around align-center">
                  <el-image :src="scope.row.image.image"></el-image>
                  <div style="width: 80%" class="text-left">
                      <p class="font-12 ellipsis">
                        {{ scope.row.goods_name }}
                      </p>
                  </div>
                </div>
                <div class="font-12 mg-l-20" style="width: 150px">
                  <p>{{scope.row.price}}</p>
                  <p>× {{scope.row.number}}</p>
                </div>
              </div>
              <div class="orderInfo">
                <div class="flex space-between align-center">
                  <div class="flex">
                    <p class="mg-r-10 font-12">下单时间: {{ scope.row.create_time }}</p>
                    <p class="font-12">订单编号：{{scope.row.sn}}</p>
                  </div>
                </div>
              </div>
          </template>
        </el-table-column>
        <el-table-column
          label="预约人信息"
          prop="address"
        >
        <template slot-scope="scope">
          <p>买家：{{ scope.row.name }}</p>
          <p>地址：{{scope.row.province}}{{scope.row.city}}{{scope.row.area}}{{ scope.row.address }}</p>

        </template>
        </el-table-column>
        <el-table-column
          label="状态"
          prop="status_name"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column 
          label="操作"
          :align="'center'">
          <template slot-scope="scope">
            <el-button size="mini" type="text" v-if="scope.row.status == 0" @click="showDialog(scope.row)">处理预约</el-button>
            <el-button size="mini" type="text" @click="$router.push('/serviceOrderInfo?uuid=' + scope.row.uuid)">预约信息</el-button>
            <el-button size="mini" type="text" @click="addRemark">备注</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog title="处理预约" :visible.sync="closeDialog" width="50%">
      <el-form ref="iform" :model="form" label-width="100px" :rules="rules2">
        <el-form-item label="操作" prop="status">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option label="接受预约" value="1"></el-option>
            <el-option label="关闭预约" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理人" prop="deal_by">
          <el-input v-model="form.deal_by"></el-input>
        </el-form-item>
        <el-form-item label="金额" prop="price">
          <el-input v-model="form.price" type="number"></el-input>
        </el-form-item>
        <el-form-item label="补充说明">
          <el-input
            type="textarea"
            resize="none"
            rows="5"
            v-model="form.deal_memo"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeDialog = false">关闭</el-button>
        <el-button size="mini" type="primary" @click="ItemAppoint('iform')"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";

export default {
  name: 'serviceOrder',
  components: {
    pagination
  },
  data () {
    return {
      tabs: ['全部', '待处理', ' 已处理', ' 已关闭'],
      closeDialog: false,
      customerGroupForm: {
        search_start_time: '',
        search_end_time: '',
        search_keyword: '',
        search_status: ''
      },
      form: {
        status: '1',
        deal_by: '',
        price: '',
        deal_memo: '',
      },
      rules2: {
        deal_by: { required: true, message: "请输入", blur: "change" },
        price: { required: true, message: "请填写", blur: "blur" },
      },
      dialogVisible: false,
      value: 5,
      currentClass: 0,
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      id: ''
    }
  },
  created () {
    this.getList()
  },
  methods: {
    clearForm() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },
    showDialog (row) {
      this.closeDialog = true
      this.id = row.uuid
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    // 预约
    ItemAppoint(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.form,
            url: ` https://time.api.boyalife.cn/v1/cms/GoodsAppoint/deal/${this.id}`,
          })
            .then((res) => {
              this.$message.success("操作成功");
              this.getList();
              this.closeDialog = false;
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
    addClass (index) {
      this.currentClass = index
      if (index != 0) {
        this.customerGroupForm.search_status = index - 1
      } else {
        this.customerGroupForm.search_status = ''

      }
    },
    onCopy() {
      this.$copyText('3333').then(
	      e=>{
	        this.$message.success('复制成功!');
	      },
	      e=>{
	        this.$message.success('复制失败!');
	      }
      )
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsAppoint?search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_status=${this.customerGroupForm.search_status}&search_keyword=${this.customerGroupForm.search_keyword}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}`,
      })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
    //  添加备注
    addRemark() {
        this.$prompt('备注信息',  '添加备注', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputType: 'textarea',
          inputPlaceholder: '请输入',
          inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
          inputErrorMessage: '请输入备注'
        }).then(({ value }) => {
          this.$message({
            type: 'success',
            message: '提交成功！'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });       
        });
    },
  }
}
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,.1);
  border-radius: 8px;
  font-size: 12px!important;
}
>>> .el-input-group {
  width: 400px;
}
.serviceOrder .el-table .product  .el-image {
  width: 60px;
  height: auto;
  border-radius: 0!important;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.img img {
  width: 104px;
  height: 86px;
  border-radius: 8px;
}
>>>.el-table tr {
  position: relative;
  width: 100%;
}
>>> .el-table .el-table__cell {
  position: static;
}
>>>.el-table td .cell {
  padding-top: 40px;
}
.orderInfo {
  width: 100%;
  background: #f6f9ff;
  padding: 5px 20px;
  box-sizing: content-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}
.copy {
  display: inline-block;
  padding: 2px 5px;
  margin-left: 20px;
  border-radius: 4px;
  border: 1px solid #666;
  color: #666;
  font-size: 12px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp:2;
  text-overflow: ellipsis;
}
</style>