<template>
  <el-upload
    ref="upload"
    class="upload-demo"
    action="#"
    :accept="'jpg,jpeg,png'"
    :show-file-list="false"
    :before-upload="beforeAvatarUpload"
    list-type="picture-card"
    multiple
    :http-request="httpRequest"
  >

    <i  class="el-icon-plus avatar-uploader-icon">本地上传</i>
  </el-upload>
</template>
<script>
export default {
  name: "upload",
  data () {
    return {
      url: [],
      formData: ''
    }
  },
  methods: {
    httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.$emit('getImageUrl', res[0].url)
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    beforeAvatarUpload(file) {
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
  }

}
</script>
<style scoped>
img {
  width: 100%;
  height: 100%;
}
>>>.el-upload--picture-card i {
  font-size: 16px;
  line-height: 178px;

}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
.upload-demo{
  border: 1px dashed #409eff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}
</style>
