<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>站内信</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <h3 class="title-t mg-b-30">通知中心</h3>
      <div class="list">
        <div
          class="flex space-between"
          v-for="item in infoList.data"
          :key="item"
        >
          <h6 class="font-12"><span></span>{{ item.content }}</h6>
          <span class="font-12 color-999">{{ item.create_time }}</span>
        </div>
      </div>
      <pagination
        :total="infoList.total"
        :per_page="infoList.per_page"
        :current_page="infoList.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "notifications",
  components: { pagination },
  data() {
    return {
      infoList: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/StationMessage?page_index=${this.infoList.current_page}&page_size=${this.infoList.per_page}`,
      })
        .then((res) => {
          this.infoList = res.data.result;
          console.log(res);
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    handleSizeChange(v) {
      this.infoList.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.infoList.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.flex.space-between {
  padding: 10px 0;
  border-bottom: 1px solid rgb(234, 233, 233);
}
h6 span {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #0099ff;
  margin-right: 5px;
}
</style>
