<template>
  <div class="addProject">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>项目</el-breadcrumb-item>
      <el-breadcrumb-item>项目管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/projectList'">项目列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{ pageType }}项目</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="stepList boxLayout">
      <el-steps
        :space="'50%'"
        :active="active"
        finish-status="success"
        align-center
      >
        <el-step title="编辑项目基本信息"></el-step>
        <el-step title="编辑项目详情"></el-step>
        <el-step title="编辑项目参数"></el-step>
        <el-step title="编辑售后保障"></el-step>
      </el-steps>
    </div>
    <div class="info mg-t-20" v-if="active === 0">
      <el-form
        ref="basicInfoForm"
        :model="basicInfoForm"
        label-width="80px"
        :rules="rules"
      >
        <div class="boxLayout">
          <h3 class="title-t mg-b-30">基础信息</h3>
          <el-form-item label="项目图片" prop="image">
            <span slot="label"><span style="color: red">*</span>项目图片</span>
            <uploadImagesBtn @getImageUrl="getImageUrl" />
            <el-button  type="text"  @click="chooseBanners(false)">从素材库选择</el-button>
            <div
              class="cover_image_list flex"
              v-if="basicInfoForm.image.length > 0"
            >
              <draggable v-model="basicInfoForm.image" group="image">
                <div
                  class="img"
                  v-for="(item, index) in basicInfoForm.image"
                  :key="index"
                >
                  <i class="el-icon-delete" @click.stop="deleteImg(index)"></i>
                  <el-image :src="item" fit="cover"></el-image>
                </div>
              </draggable>
            </div>
            <p class="font-12 color-999">
              建议尺寸：800*800像素，拖拽图片可以调整顺序，最多上传15张
            </p>
          </el-form-item>
          <el-form-item label="封面图" prop="image">
            <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest"
          >
          <img
              v-if="basicInfoForm.cover != ''"
              :src="basicInfoForm.cover"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseBanners(true)">从素材库选择</el-button>
            <!-- <el-button class="avatar-uploader" @click="chooseCover">
              <img
                v-if="basicInfoForm.cover !== ''"
                :src="basicInfoForm.cover"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <i
                v-if="basicInfoForm.cover !== ''"
                class="el-icon-delete"
                @click.stop="basicInfoForm.cover = ''"
              ></i>
            </el-button> -->
            <p class="font-12 color-999">建议尺寸：690x325像素</p>
          </el-form-item>
          <el-form-item label="项目视频">
            <el-button class="avatar-uploader video" @click="chooseVideo">
              <video v-if="basicInfoForm.video !== ''" width="178px" controls>
                <source :src="basicInfoForm.video" />
              </video>
              <i
                class="el-icon-delete"
                v-if="basicInfoForm.video !== ''"
                @click.stop="basicInfoForm.video = ''"
              ></i>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-button>
            <p class="font-12 color-999">视频最长支持10分钟</p>
          </el-form-item>
          <el-form-item label="项目名称" prop="name">
            <el-input v-model="basicInfoForm.name"></el-input>
          </el-form-item>
          <el-form-item label="项目编码">
            <el-input v-model="basicInfoForm.code"></el-input>
          </el-form-item>
          <el-form-item label="权重">
            <el-input v-model="basicInfoForm.sort" type="number"></el-input>
          </el-form-item>
          <el-form-item label="项目分类" prop="category_uuid">
            <el-select
              v-model="basicInfoForm.category_uuid"
              placeholder="请选择"
            >
              <el-option-group
                v-for="group in options"
                :key="group.uuid"
                :label="group.name"
                :value="group.uuid"
              >
                <el-option
                  v-for="item in group.children"
                  :key="item.uuid"
                  :label="item.name"
                  :value="item.uuid"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item label="项目类型" prop="type">
            <el-select v-model="basicInfoForm.type" placeholder="请选择">
              <el-option label="存储项目" :value="1"></el-option>
              <el-option label="技术服务" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收款商户" prop="merch">
            <el-select
              v-model="basicInfoForm.merch"
              placeholder="请选择"
            >
              <el-option
                v-for="item in merchOption"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属套餐">
            <el-select
              multiple
              collapse-tags
              v-model="basicInfoForm.boya_tc"
              placeholder="请选择"
            >
              <el-option
                v-for="(jtem, i) in tcList"
                :key="i"
                :label="jtem.CNAME"
                :value="jtem.CNAME"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="投放人群" prop="group_uuid">
            <el-select
              multiple
              collapse-tags
              v-model="basicInfoForm.group_uuid"
              placeholder="请选择"
            >
              <el-option
                v-for="(jtem, i) in userGroupList"
                :key="i"
                :label="jtem"
                :value="i"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择品牌" prop="supplier_uuid">
            <el-select
              v-model="basicInfoForm.supplier_uuid"
              placeholder="请选择"
            >
              <el-option
                v-for="jtem in brandList"
                :key="jtem.uuid"
                :label="jtem.name"
                :value="jtem.uuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="适用门店" prop="shop_uuid">
            <el-select
              multiple
              collapse-tags
              v-model="basicInfoForm.shop_uuid"
              placeholder="请选择"
            >
              <el-option
                v-for="item in storeList"
                :key="item.uuid"
                :label="item.name"
                :value="item.uuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="添加标签" prop="label_uuid">
            <el-tag v-for="tag in tags" :key="tag.uuid" :type="tag.type">
              {{ tag.name }}
            </el-tag>
            <span
              style="color: cornflowerblue; cursor: pointer"
              @click="labelDialogVisible = true"
            >
              <span v-if="tags.length > 0">+ 编辑标签</span>
              <span v-else>+ 添加标签</span>
            </span>
          </el-form-item>
        </div>
        <div class="boxLayout mg-t-20">
          <h3 class="title-t mg-b-20">规格信息</h3>
          <el-form-item label="规格设置">
            <!-- <el-button type="primary" @click="addUnit">添加规格</el-button> -->
            <el-select
              v-model="attributeValue"
              multiple
              filterable
              placeholder="添加规格"
              value-key="uuid"
              @change="selectAttribute"
              @remove-tag="deleteAttribute"
            >
              <el-option
                v-for="item in attributeList"
                :key="item.uuid"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
            <SkuForm
              :key="randomDate"
              ref="skuForm"
              :source-attribute="sourceAttribute"
              :structure="structure"
              :attribute.sync="attribute"
              :sku.sync="sku"
              :isEdit="isEdit"
              async
            >
              <template #image="slotProps">
                <el-button
                  class="avatar-uploader"
                  @click="chooseSpecImg(slotProps)"
                >
                  <img
                    v-if="slotProps.row.image !== ''"
                    :src="slotProps.row.image"
                    class="avatar small"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <i
                    v-if="slotProps.row.image !== ''"
                    class="el-icon-delete"
                    @click.stop="slotProps.row.image = ''"
                  ></i>
                </el-button>
              </template>
            </SkuForm>
            <!-- <specs @getAttributeData="getAttributeData" ref="spec" :attributes="basicInfoForm.attribute" :newTemplateDatas="basicInfoForm.specs"/> -->
            <p class="font-12 color-999 mg-t-10" v-show="attribute.length > 2">
              批量设置，输入完后按Enter键确认
            </p>
          </el-form-item>
          <el-form-item label="零售价" prop="price">
            <el-input
              v-model="basicInfoForm.price"
              class="ipt"
              placeholder="请输入"
              ><template slot="prepend">元</template></el-input
            >
            <br />
          </el-form-item>
          <el-form-item label="划线价">
            <el-input
              v-model="basicInfoForm.crossed_price"
              class="ipt"
              placeholder="请输入"
              ><template slot="prepend">元</template></el-input
            >
            <br />
            <el-checkbox v-model="basicInfoForm.show_crossed_price"
              >是否显示划线价</el-checkbox
            >
          </el-form-item>
          <el-form-item label="支付方式" prop="pay_type">
            <el-checkbox
              v-model="basicInfoForm.only_cash"
              :checked="true"
              disabled
              >现金</el-checkbox
            >
          </el-form-item>
          <el-form-item label="销量" prop="sales_number">
            <span>{{ basicInfoForm.sales_number }}</span>
          </el-form-item>
        </div>
        <div class="boxLayout mg-t-20">
          <h3 class="title-t mg-b-20">其它设置</h3>
          <el-form-item label="上架设置">
            <el-radio-group v-model="basicInfoForm.status">
              <el-radio :label="1">立即上架</el-radio>
              <el-radio :label="2">暂不上架</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="开售时间">
            <el-radio-group v-model="basicInfoForm.shelf_type">
              <el-radio :label="1">立即开售</el-radio>
              <el-radio :label="2">定时开售</el-radio>
              <el-date-picker
                v-model="basicInfoForm.shelf_time"
                v-if="basicInfoForm.shelf_type == 2"
                type="datetime"
                placeholder="选择时间"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                default-time="12:00:00"
              >
              </el-date-picker>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="隐藏销量">
            <el-checkbox v-model="basicInfoForm.hide_sales"
              >不显示该项目销量</el-checkbox
            >
            <el-input
              v-model="basicInfoForm.sales_number"
              class="ipt mg-l-20"
              placeholder="请输入"
              ><template slot="prepend">件</template></el-input
            >
            <br />
          </el-form-item>
          <el-form-item label="隐藏项目">
            <el-checkbox v-model="basicInfoForm.hide_item"
              >设置项目不在商城中显示</el-checkbox
            >
          </el-form-item>
          <el-form-item label="项目推广">
            <el-checkbox v-model="basicInfoForm.item_extend"
              >允许插入资讯内容中推广</el-checkbox
            >
          </el-form-item>
          <el-form-item label="推广佣金">
            <el-switch
              v-model="basicInfoForm.extend_commission"
              active-color="#13ce66"
              :active-value="1"
              :inactive-value="2"
              inactive-color="gray"
            >
            </el-switch>
            <p class="font-12 color-999">启用后，商品推广可获得佣金</p>
          </el-form-item>
          <!-- <el-form-item label="定制报告">
            <el-switch
              v-model="basicInfoForm.custom_report"
              active-color="#13ce66"
              :active-value="1"
              :inactive-value="2"
              inactive-color="gray">
            </el-switch>
            <p class="font-12 color-999">启用后，可预约定制报告</p>
          </el-form-item>
          <el-form-item label="定制价格" v-if="basicInfoForm.custom_report == 1" :prop="basicInfoForm.custom_report == 1 ? 'custom_price' : ''">
            <el-input v-model="basicInfoForm.custom_price" class="ipt" placeholder="请输入"><template slot="prepend">元</template></el-input> <br>
          </el-form-item> -->
        </div>
      </el-form>
    </div>
    <div class="info boxLayout mg-t-20" v-show="active === 1">
      <h3 class="title-t mg-b-30">项目详情</h3>
      <wangEditorTool ref="detailEditor" :content="basicInfoForm.item_detail" />
    </div>
    <div class="info boxLayout mg-t-20" v-show="active === 2">
      <h3 class="title-t mg-b-30">项目参数</h3>
      <wangEditorTool
        ref="parameterEditor"
        :content="basicInfoForm.item_parameter"
      />
    </div>
    <div class="info boxLayout mg-t-20" v-show="active === 3">
      <h3 class="title-t mg-b-30">售后保障</h3>
      <wangEditorTool ref="saleEditor" :content="basicInfoForm.after_sale" />
    </div>
    <div class="operateBtn">
      <el-button type="primary" size="mini" v-if="active !== 0" @click="toPrev"
        >上一步</el-button
      >
      <el-button type="primary" v-if="active !== 3" size="mini" @click="toNext"
        >下一步</el-button
      >
      <el-button
        type="primary"
        size="mini"
        v-if="active === 3"
        @click="sumbitForm('basicInfoForm')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <MaterialLibrary
        :type="imgType"
        @selectImg="selectImg"
        @insertVideoHandler="insertVideoHandler"
      />
      <span slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogImageVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog
     :isSingle="isSingle" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog"
      />
   
    </el-dialog>
    <el-dialog
      title="选择标签"
      :visible.sync="labelDialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <markLabel
        @closeDialog="closeDialog"
        :tags="tags"
        :type="'addCustomer'"
      />
    </el-dialog>
  </div>
</template>
<script>
import MaterialLibrary from "../../../components/materialLibraryDialog/index";
import wangEditorTool from "../../../components/wangEditorTool.vue";
import markLabel from "../../../components/markLabel.vue";
import draggable from "vuedraggable";
import specs from "../../../components/specs.vue";
import uploadImagesBtn from "../../../components/uploadImagesBtn.vue";
// import SkuForm from 'vue-sku-form'
import SkuForm from "../../../components/skuForm.vue";
import imageDialog from "../../../components/imageDialog.vue";
export default {
  name: "addProject",
  components: {
    wangEditorTool,
    draggable,
    MaterialLibrary,
    markLabel,
    specs,
    SkuForm,
    uploadImagesBtn,
    imageDialog
  },
  data() {
    return {
      isSingle: false,
      dialogImageVisible: false,
      active: 0,
      loading: false,
      randomDate: new Date().getTime(),
      sourceAttribute: [],
      attributeList: [],
      attributeValue: [],
      structure: [],
      attribute: [],
      sku: [],
      isEdit: false,
      tags: [],
      imgType: "",
      imgIndex: "",
      title: "选择图片",
      slotProps: {},
      dialogVisible: false,
      labelDialogVisible: false,
      options: [],
      storeList: [],
      userGroupList: [],
      brandList: [],
      insertImgType: "",
      // 添加规格
      basicInfoForm: {
        boya_tc: [],
        cover: "",
        type: 1,
        merch: '',
        uuid: "",
        supplier_uuid: "",
        only_cash: true,
        cash_score: false,
        customize_score: false,
        shop_uuid: [],
        sales_number: "0",
        video: "",
        image: [],
        name: "",
        category_uuid: "",
        code: "",
        price: "",
        crossed_price: "",
        show_crossed_price: false,
        label_uuid: "",
        score: "",
        shelf_type: 1,
        status: 1,
        shelf_time: null,
        hide_sales: false,
        hide_item: "",
        item_extend: false,
        sort: "",
        extend_commission: "",
        // custom_report: '',
        // custom_price: '',
        item_detail: "",
        item_parameter: "",
        after_sale: "",
        attribute: [],
        unitList: [],
        group_uuid: "",
        specs: []
      },
      search_keyword: "",
      specificateOptions: [],
      editor: null,
      html: "<p>hello</p>",
      toolbarConfig: {},
      editorConfig: { placeholder: "请输入内容..." },
      mode: "default",
      tcList: [],
      // 添加规格新参
      pageType: "",
      rules: {
        merch:  { required: true, message: "请选择", trigger: "change" },
        boya_tc: { required: true, message: "请选择", trigger: "change" },
        name: { required: true, message: "请输入项目名称", trigger: "blur" },
        category_uuid: {
          required: true,
          message: "请选择项目分类",
          trigger: "change"
        },
        type: { required: true, message: "请选择项目类型", trigger: "change" },
        price: { required: true, message: "请输入零售价", trigger: "blur" },
        // custom_price: {required: true, message: '请输入定制价格', trigger: 'blur'},
        unitList: [],
        unitAll: [],
        table: [],
        tableHeader: [],
        merchOption: [],
        specs: {
          price: [
            { required: true, message: "请输入商品售价", trigger: "blur" }
          ],
          retail_price: [
            { required: true, message: "请输入零售价", trigger: "blur" }
          ],
          stock: [{ required: true, message: "请输入库存值", trigger: "blur" }]
          // img: [{ required: true, message: "请上传规格图片", trigger: "blur" }]
        }
      }
    };
  },
  created() {
    this.getAttribute();
    this.getData();
    this.getBrand();
    this.getStoreList();
    this.getUserGroup();
    this.getMerch()
  },
  mounted() {
    this.getTcData();
    if (Object.keys(this.$route.query).length !== 0) {
      this.getList();

      this.pageType = "编辑";
      this.isEdit = true;
      let params = this.$route.query;
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Item/${params.uuid}`
      })
        .then(res => {
          let d = res.data.result;
          for (let i in d) {
            this.basicInfoForm[i] = JSON.parse(JSON.stringify(d[i]));
          }
          this.basicInfoForm.image = this.basicInfoForm.image.map(
            item => item.image
          );
          if (this.basicInfoForm.boya_tc == null ) {
            this.basicInfoForm.boya_tc = []
          }
          this.basicInfoForm.merch = Number(this.basicInfoForm.merch)
          this.basicInfoForm.only_cash =
            this.basicInfoForm.only_cash == 1 ? true : false;
          this.basicInfoForm.cash_score =
            this.basicInfoForm.cash_score == 1 ? true : false;
          this.basicInfoForm.customize_score =
            this.basicInfoForm.customize_score == 1 ? true : false;
          this.basicInfoForm.show_crossed_price =
            this.basicInfoForm.show_crossed_price == 1 ? true : false;
          this.basicInfoForm.hide_sales =
            this.basicInfoForm.hide_sales == 1 ? true : false;
          this.basicInfoForm.item_extend =
            this.basicInfoForm.item_extend == 1 ? true : false;
          if (this.basicInfoForm.shelf_type == 1) {
            this.basicInfoForm.shelf_time = null;
          }
          this.basicInfoForm.shelf_time = new Date(
            this.basicInfoForm.shelf_time
          );
          if (this.basicInfoForm.boya_tc.length > 0) {

          this.basicInfoForm.boya_tc = this.basicInfoForm.boya_tc.split(',')
          }
          console.log(this.basicInfoForm.boya_tc, 'tc')
          this.basicInfoForm.shelf_type = Number(this.basicInfoForm.shelf_type);
          this.basicInfoForm.type = Number(this.basicInfoForm.type);
          this.basicInfoForm.status = Number(this.basicInfoForm.status);
          this.basicInfoForm.hide_sales =
            this.basicInfoForm.hide_sales == 1 ? true : false;
          this.basicInfoForm.hide_item =
            this.basicInfoForm.hide_item == 1 ? true : false;
          this.basicInfoForm.item_extend =
            this.basicInfoForm.item_extend == 1 ? true : false;
          this.basicInfoForm.extend_commission = Number(
            this.basicInfoForm.extend_commission
          );
          // this.basicInfoForm.custom_report = Number(this.basicInfoForm.custom_report)
          this.$forceUpdate();
          this.tags = this.basicInfoForm.label;
          if (this.basicInfoForm.group.length > 0) {
            this.basicInfoForm.group_uuid = this.basicInfoForm.group.map(
              item => {
                return item.uuid;
              }
            );
          }
          this.attributeValue = [];
          this.basicInfoForm.attrkey.map(item => {
            this.attributeList.map(jtem => {
              if (item.item_attribute_uuid == jtem.uuid) {
                this.attributeValue.push(jtem);
              }
            });
          });
          this.sourceAttribute = [];
          this.attributeValue.map(item => {
            let obj = {};
            obj.name = item.name;
            obj.item = item.values.split(",");
            this.sourceAttribute.push(obj);
            this.randomDate = new Date().getTime();
          });
          // setTimeout(() => {
          //   this.$refs.skuForm.init();
          // }, 50);
          if (this.basicInfoForm.shop.length > 0) {
            this.basicInfoForm.shop_uuid = this.basicInfoForm.shop.map(item => {
              return item.uuid;
            });
          }
          this.attribute = [];
          this.basicInfoForm.attrkey.map(item => {
            let obj = {
              name: "",
              item: []
            };
            obj.name = item.name;
            item.attrvalue.map(jtem => {
              obj.item.push(jtem.name);
            });
            this.attribute.push(obj);
          });
          this.sku = [];
          this.randomDate = new Date().getTime();

          let obj2 = {};
          console.log(this.basicInfoForm.specs);
          this.basicInfoForm.specs.map(item => {
            // delete item.specs
            for (let i in item) {
              if (i != "extenders" && i != "user_levels") {
                obj2[i] = item[i];
              }
              if (i == "extenders") {
                for (let j in item[i]) {
                  obj2[j] = item[i][j];
                }
              }
              if (i == "user_levels") {
                for (let o in item[i]) {
                  obj2[o] = item[i][o];
                }
              }
            }
            console.log(obj2);

            this.sku.push(JSON.parse(JSON.stringify(obj2)));
            console.log(this.sku);
          });
          setTimeout(() => {
            this.$refs.skuForm.init();
          }, 1000);
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    } else {
      this.getList();
      this.isEdit = false;
      this.pageType = "新增";
      setTimeout(() => {
        this.$refs.skuForm.init();
      }, 1000);
    }
  },
  watch: {
    totalCount(n) {
      this.basicInfoForm.sales_number = n;
    }
  },
  computed: {
    totalCount() {
      let count = 0;
      // this.basicInfoForm.specs.map(item => {
      //   count += Number(item.sales)
      // })
      return count;
    }
  },
  methods: {
    getMerch () {
      this.merchOption = []
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: `https://time.api.boyalife.cn/v1/cms/Item/merchs`
      })
        .then(res => {
   
            this.merchOption=res.data.result
        })
    },
    httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.cover =  res[0].url;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    beforeAvatarUpload(file) {
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    chooseBanners (v) {
      this.isSingle = v
      this.dialogImageVisible = true
    },
    closeImageDialog () {
      this.dialogImageVisible = false
    },
    getImageUrl (item) {
      this.basicInfoForm.image.push(item)
    },
    confirmImageDialog (item) {
      if (!this.isSingle) {
        item.map(i => {
          this.basicInfoForm.image.push(i.url)
        })
      } else {
        this.basicInfoForm.cover = item[0].url

      }
    
      this.dialogImageVisible = false

    },
    // 获取属性
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ItemAttribute?search_keyword=${this.search_keyword}`
      })
        .then(res => {
          let d = res.data.result.data;
          this.attributeList = d;
          // this.specificateOptions = d
          // this.sourceAttribute = []
          // d.map((item) => {
          //   let obj = {}
          //   obj.name = item.name
          //   obj.item = item.values.split(',')
          //   this.sourceAttribute.push(obj)
          //   this.randomDate = new Date().getTime()
          // })
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    selectAttribute(v) {
      this.sourceAttribute = [];
      v.map(item => {
        let obj = {};
        obj.name = item.name;
        obj.item = item.values.split(",");
        this.sourceAttribute.push(obj);
        this.randomDate = new Date().getTime();
      });
      setTimeout(() => {
        this.$refs.skuForm.init();
      }, 50);
    },
    deleteAttribute(v) {
      this.attribute.map((item, index) => {
        for (let i in item) {
          if (item[i] == v.name) {
            this.attribute.splice(index, 1);
            return;
          }
        }
      });
      if (this.attribute.length == 0) this.sku = [];
    },
    getBrand() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/SupplierTree`
      })
        .then(res => {
          this.brandList = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    closeDialog(v) {
      this.tags = JSON.parse(JSON.stringify(v));
      this.labelDialogVisible = false;
    },
    getUserGroup() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserGroupSelect`
      })
        .then(res => {
          this.userGroupList = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    toPrev() {
      this.active--;
      if (this.active == 0) {
        this.basicInfoForm.item_detail = this.$refs.detailEditor.html;
      } else if (this.active == 1) {
        this.basicInfoForm.item_parameter = this.$refs.parameterEditor.html;
      } else if (this.active == 2) {
        this.basicInfoForm.after_sale = this.$refs.saleEditor.html;
      }
    },
    toNext() {
      if (this.active == 0) {
        this.$refs.basicInfoForm.validate(valid => {
          if (valid) {
            if (this.basicInfoForm.image.length < 1) {
              this.$message.error("请至少上传一张项目图片");
              return false;
            } else {
              this.$refs.skuForm.validate(valid => {
                if (valid) {
                  this.basicInfoForm.attribute = [];
                  this.attribute.map(item => {
                    let obj = {};
                    obj.key = item.name;
                    obj.value = item.item;
                    this.basicInfoForm.attribute.push(obj);
                  });
                  this.basicInfoForm.specs = JSON.parse(
                    JSON.stringify(this.sku)
                  );
                  console.log(this.basicInfoForm.specs);
                  this.active++;
                } else {
                  return false;
                }
              });
            }
          } else {
            return false;
          }
        });
      } else {
        if (this.active == 1) {
          this.basicInfoForm.item_detail = this.$refs.detailEditor.html;
        }
        if (this.active == 2) {
          this.basicInfoForm.item_parameter = this.$refs.parameterEditor.html;
        }
        this.active++;
      }
    },
    getAttribute() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/AttributeHeader`
      })
        .then(res => {
          let d = res.data.result;
          this.structure = [];
          d.map(item => {
            let obj = {};
            obj.name = item.key;
            obj.label = item.header;
            if (item.key == "image") {
              obj.type = "slot";
            } else {
              obj.type = "input";
            }
            obj.batch = true;

            if (item.key == "retail_price" || item.key == "stock") {
              obj.required = true;
            } else {
              obj.required = false;
            }
            this.structure.push(obj);
            this.randomDate = new Date().getTime();
          });
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取项目分类
    getData() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ItemCategory`
      })
        .then(res => {
          this.options = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取套餐列表
    getTcData() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Item/boya_tc`
      })
        .then(res => {
          this.tcList = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 选择spec_img
    chooseSpecImg(slotProps) {
      this.slotProps = slotProps;
      this.insertImgType = "other";
      // this.imgIndex = index
      this.title = "选择图片";
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible = true;
      // this.basicInfoForm.specs[index].image =
    },
    // 选择banner
    chooseBanner() {
      this.title = "选择图片";
      this.imgType = "chooseThumbnail_image";
      this.insertImgType = "projectImg";
      this.dialogVisible = true;
    },
    // 选择banner
    chooseCover() {
      this.title = "选择图片";
      this.imgType = "chooseThumbnail_image";
      this.insertImgType = "coverImg";
      this.dialogVisible = true;
    },
    chooseVideo() {
      this.imgType = "chooseVideo";
      this.dialogVisible = true;
      this.title = "选择视频";
    },
    selectImg(item) {
      this.dialogVisible = false;
      if (this.insertImgType == "projectImg") {
        this.basicInfoForm.image.push(item.url);
      } else if (this.insertImgType == "coverImg") {
        this.basicInfoForm.cover = item.url;
      } else {
        this.slotProps.row.image = item.url;
      }
    },
    insertVideoHandler(url) {
      this.dialogVisible = false;
      this.basicInfoForm.video = url;
    },
    // 删除图片
    deleteImg(index) {
      this.basicInfoForm.image.splice(index, 1);
    },

    // 获取门店下拉
    getStoreList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Shop`
      })
        .then(res => {
          this.storeList = res.data.result.data;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },

    sumbitForm() {
      if (this.tags.length > 0) {
        this.basicInfoForm.label_uuid = this.tags.map(item => {
          return item.uuid;
        });
      }
      this.basicInfoForm.boya_tc = this.basicInfoForm.boya_tc.join(',')

      this.basicInfoForm.after_sale = this.$refs.saleEditor.html;
      this.basicInfoForm.only_cash =
        this.basicInfoForm.only_cash == true ? 1 : 2;
      this.basicInfoForm.cash_score =
        this.basicInfoForm.cash_score == true ? 1 : 2;
      this.basicInfoForm.customize_score =
        this.basicInfoForm.customize_score == true ? 1 : 2;
      this.basicInfoForm.show_crossed_price =
        this.basicInfoForm.show_crossed_price == true ? 1 : 2;
      this.basicInfoForm.hide_sales =
        this.basicInfoForm.hide_sales == true ? 1 : 2;
      this.basicInfoForm.item_extend =
        this.basicInfoForm.item_extend == true ? 1 : 2;
      this.basicInfoForm.specs.map((item, index) => {
        delete item.sku;
      });
      if (this.$route.query.uuid) {
        delete this.basicInfoForm.attrkey;
        delete this.basicInfoForm.unitList;
        delete this.basicInfoForm.group;
        delete this.basicInfoForm.label;
        delete this.basicInfoForm.shop;
        delete this.basicInfoForm.delete_time;
        delete this.basicInfoForm.create_time;
        delete this.basicInfoForm.update_time;
        delete this.basicInfoForm.publish_time;
        delete this.basicInfoForm.uuid;
        delete this.basicInfoForm.category;
        delete this.basicInfoForm.supplier;
        this.axios({
          method: "put", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`
          },
          data: this.basicInfoForm,
          url: ` https://time.api.boyalife.cn/v1/cms/Item/${this.$route.query.uuid}`
        })
          .then(res => {
            this.$message.success("编辑成功！");
            this.$router.go(-1);
          })
          .catch(err => {
            this.$message.error(err.response.data.error.message);
          });
      } else {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`
          },
          data: this.basicInfoForm,
          url: ` https://time.api.boyalife.cn/v1/cms/Item`
        })
          .then(res => {
            this.$message.success("新增成功！");
            this.$router.go(-1);
          })
          .catch(err => {
            this.$message.error(err.response.data.error.message);
          });
      }
    }
  }
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.addProject {
  margin-bottom: 80px;
}
.video {
  position: relative;
}
/* .info >>> .el-form .el-form-item__content > .el-input {
    width: 500px;
  } */
.info >>> .el-form .table .el-form-item__content > .el-input {
  width: auto;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.el-form-item .avatar-uploader {
  padding: 10px;
}
.avatar-uploader-icon {
  font-size: 16px;
  color: #8c939d;
  width:178px;
  height:178px;
  line-height:178px;
  text-align: center;
}
.avatar-uploader span {
  position: relative;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar.small {
  width: 50px;
  height: 50px;
}
.specificationsBox {
  padding: 20px;
  border: 1px dashed rgb(223, 223, 223);
}
.addBtn {
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(223, 223, 223);
}
.el-tag {
  margin-right: 10px;
  margin-bottom: 5px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.bdb {
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(232, 232, 232);
}
>>> .ipt.el-input {
  width: 300px !important;
}
.table {
  width: 100%;
  border: 1px solid #dcdcdc;
  text-align: center;
  border-collapse: collapse;
}

.table th {
  border: 1px solid #dcdcdc;
}
.table td {
  min-width: 100px;
  border: 1px solid #dcdcdc;
  padding: 10px;
}
.cover_image_list > div {
  flex-wrap: wrap;
  margin-top: 20px;
  display: flex;
}
.cover_image_list .img {
  position: relative;
  border: 1px solid #dcdcdc;
  width: 220px;
  height: 220px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.cover_image_list .img .el-icon-delete {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  z-index: 2;
  cursor: pointer;
}
.cover_image_list .img .el-image {
  width: 100%;
  height: 100%;
}
.avatar-uploader {
  position: relative;
}
.avatar-uploader .el-icon-delete,
.video .el-icon-delete {
  font-size: 20px;
  position: absolute;
  right: -24px;
  bottom: 0px;
}
.tableBox {
  width: 100%;
  overflow-x: auto;
}
.table .el-icon-delete {
  font-size: 14px;
  right: -20px;
}
>>> .el-select {
  width: 400px;
}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
.upload-demo{
  border: 1px dashed #409eff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}
</style>
