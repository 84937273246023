import axios from 'axios'
import getServerConf from '@/config/server'
/**
 * 默认函数错误监听函数
 * @param {Error} error
 * @returns
 */
function defaultErrorHandler(error) {
    return Promise.reject(error)
}

/**
 * 默认请求拦截函数
 * @param {XhrConfig} config
 * @returns
 */
function defaultIntercepterRequest(config) {
    return config
}

/**
 * 默认请求返回拦截函数
 * @param {XhrResponse} response
 * @returns
 */
function defaultIntercepterResponse(response) {
    return Promise.resolve(response)
}

/**
 * httpRequest工厂方法
 * @param {HttpRequestFactoryOptions} options
 * @returns {HttpRequest} 返回一个httpRequest实例
 */
export default function(options) {
    // 初始化钩子函数
    if (!options) options = {}
    if (!options.axiosConf) options.axiosConf = {}
    if (!options.intercepterRequest || typeof options.intercepterRequest !== 'function')
        options.intercepterRequest = defaultIntercepterRequest
    if (!options.intercepterResponse || typeof options.intercepterResponse !== 'function')
        options.intercepterResponse = defaultIntercepterResponse
    if (!options.errorHandler || typeof options.errorHandler !== 'function') options.errorHandler = defaultErrorHandler
    const { axiosConf, errorHandler, intercepterRequest, intercepterResponse } = options

    // 获取当前服务端请求配置
    let tempConf = getServerConf()

    // 初始化配置项
    Object.keys(axiosConf).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(tempConf, key)) {
            tempConf[key] = axiosConf[key]
        }
    })
    let request = axios.create(tempConf)
    request.interceptors.request.use(intercepterRequest, errorHandler)
    request.interceptors.response.use(intercepterResponse, errorHandler)
    return request
}
