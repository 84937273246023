<template>
  <!-- 添加客户 -->
  <div class="invitateIsPolite">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item>邀请有礼</el-breadcrumb-item>
      <el-breadcrumb-item>推荐设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicInfo">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="order_sn" label="订单号" :show-overflow-tooltip="true"> </el-table-column>
  
        <el-table-column prop="status_show" label="订单状态" :align="'center'">
        </el-table-column>
        <el-table-column prop="inviter_mobile" label="推荐人手机号" :align="'center'">
        </el-table-column>
        <el-table-column prop="user_mobile" label="被推荐人手机号" :align="'center'">
        </el-table-column>
        <el-table-column prop="inviter_name" label="推荐人信息" :align="'center'">
        </el-table-column>
        <el-table-column prop="user_name" label="被推荐人信息" :align="'center'">
        </el-table-column>
        <el-table-column
          prop="finish_time"
          label="订单完成时间"
          :align="'center'"
          width="170"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          fixed="right"
          width="140"
        >
          <template slot-scope="scope">
            <span>已发放{{scope.row.award_times}}次</span>
            <el-button size="mini" type="text" v-if="scope.row.award_times > 0" @click="rewardList(scope.row)">发放记录</el-button>
            <el-button size="mini" type="text" @click="toReward(scope.row)">发放奖励</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog
      title="发送奖励"
      :visible.sync="rewardvisible"
      width="60%"
      :close-on-click-modal="false"
    >
    <el-form
        :model="form"
        :rules="rules"
        label-width="93px"
        ref="form"
      >
        <el-form-item label="退款原因" prop="type">
          <el-checkbox-group v-model="form.type" @change="getVal">
            <div class="flex mg-b-20 align-center">
              <el-checkbox label="1">优惠券</el-checkbox>
              <el-select class="mg-l-20" v-model="form.coupon_uuid" placeholder="请选择">
                <el-option v-for="item in couponOption" :key="item.uuid" :label="item.name" :value="item.uuid" ></el-option>
              </el-select>
            </div>
            <div class="flex mg-b-20 align-center">
              <el-checkbox label="2">积分</el-checkbox>
              <el-input class="mg-l-20" type="number" v-model="form.score" placeholder="请输入积分数量"></el-input>
            </div>
            <div class="flex mg-b-20 align-center">
              <el-checkbox label="3">life币</el-checkbox>
              <el-input class="mg-l-20" type="number" v-model="form.life" placeholder="请输入life币数量"></el-input>
            </div>
            <div class="flex mg-b-20 align-center">
              <el-checkbox label="4">权益金</el-checkbox>
              <el-input class="mg-l-20" type="number" v-model="form.rights" placeholder="请输入权益金数量"></el-input>
            </div>
            <!-- <div class="flex mg-b-20 align-center">
              <el-checkbox label="5">成长值</el-checkbox>
              <el-input class="mg-l-20" type="number" v-model="form.growth" placeholder="请输入成长值数量"></el-input>
            </div> -->
          </el-checkbox-group>
        </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="rewardvisible = false">取 消</el-button>
          <el-button type="primary" @click="submit('form')">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog
      title="发送记录"
      :visible.sync="sendVisible"
      width="60%"
      :close-on-click-modal="false"
    >
    <el-table
        :data="rewardLists"
        border
        style="width: 100%"
      >
        <el-table-column prop="order_uuid" label="订单uuid" :show-overflow-tooltip="true"> </el-table-column>
        <el-table-column prop="type" label="发放类型" :align="'center'">
          <template  slot-scope="scope">
             <div v-if="scope.row.coupon_uuid != ''">优惠券</div>
             <div v-if="scope.row.score != '' && scope.row.score != '0'">积分</div>
             <div v-if="scope.row.life != '' && scope.row.life != '0'">life币</div>
             <div v-if="scope.row.rights != '' && scope.row.rights != '0'">权益金</div>
             <div v-if="scope.row.growth != '' && scope.row.growth != '0'">成长值</div>


          </template>
        </el-table-column>
        <el-table-column prop="coupon_name" label="奖励" :align="'center'">
          <template  slot-scope="scope">
            <div v-if="scope.row.coupon_uuid != ''">{{scope.row.coupon_name}}</div>
            <div v-if="scope.row.score != '' && scope.row.score != '0'">{{scope.row.score}}积分</div>
            <div v-if="scope.row.life != '' && scope.row.life != '0'">{{scope.row.life}}life币</div>
            <div v-if="scope.row.rights != '' && scope.row.rights != '0'">{{scope.row.rights}}权益金</div>
            <div v-if="scope.row.growth != '' && scope.row.growth != '0'">{{scope.row.growth}}成长值</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="发放时间"
          :align="'center'"
          width="170"
        >
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";

export default {
  name: "invitateIsPolite",
  components: {pagination},
  data() {
    return {
      sendVisible: false,
      rewardvisible: false,
      imageUrl: "",
      isShow: false,
      checkList: [],
      couponOption: [],
      form: {
        coupon_uuid: '',
        score: '',
        life: '',
        type: [],
        order_uuid: '',
        rights: '',
        growth: ''
      },
      rules: {
        type: {
          required: true,
          message: "请选择",
          trigger: "change",
        }
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      rewardLists: []
    };
  },
  created() {
    this.getCouponList()
    this.getList()
    
  },
  methods: {
    getVal (v) {
      console.log(v)
    },
    getList () {
      this.axios({
      method: "get", //请求方法
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": this.storage.get("__token"),
        Authorization: `Bearer ${this.storage.get("__token")}`,
      },
      url: `https://time.api.boyalife.cn/v1/cms/ItemInvite/list`,
    })
      .then((res) => {
        this.tableData = res.data.result
      })
      .catch((err) => {
        this.$message.error(err.response.data.error.message);
      });
    },
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          console.log(this.form.type)
          if (this.form.type.includes('1') && this.form.coupon_uuid == '') {
            this.$message.error('请选择优惠券')
            return false
          }
          if (this.form.type.includes('2') && this.form.score == '') {
            this.$message.error('请填写积分')
            return false
          }
          if (this.form.type.includes('3') && this.form.life == '') {
            this.$message.error('请填写life币')
            return false
          }
          if (this.form.type.includes('4') && this.form.rights == '') {
            this.$message.error('请填写权益金')
            return false
          }
          if (this.form.type.includes('5') && this.form.growth == '') {
            this.$message.error('请填写成长值')
            return false
          }
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.form,
              url: `https://time.api.boyalife.cn/v1/cms/ItemInvite/give`,
            })
              .then((res) => {
                this.$message.success("发放成功！");
                for (let i in this.form) {
                  this.form[i] = ''
                }
                this.form.type = []
                this.rewardvisible = false;
                this.getList();
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
    
      });
    },
    getCouponList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Coupon?page_index=1&page_size=9999`,
      })
        .then((res) => {
          this.couponOption = res.data.result.data;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
    rewardList (item) {
      this.sendVisible = true
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: `https://time.api.boyalife.cn/v1/cms/ItemAward?order_uuid=` + item.uuid ,
      })
        .then((res) => {
          this.rewardLists = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    toReward(item) {
      this.rewardvisible = true
      this.form.order_uuid = item.uuid
    },
  },
};
</script>
<style scoped>
.invitateIsPolite {
  background: #f2f2f2;
}
.basicInfo {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form {
  width: 100%;
}
>>> .ipt {
  width: 200px;
  margin: 0 10px;
}
>>> .el-checkbox {
  margin-right: 0;
}
p {
  line-height: 40px;
}
</style>
