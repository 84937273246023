<template>
  <div class="cancelAudit">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>客户</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
      <el-breadcrumb-item>注销审核</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="客户等级">
          <el-select
            v-model="customerGroupForm.search_level"
            placeholder="请选择"
          >
            <el-option
              v-for="(jtem, i) in levelList"
              :key="i"
              :label="jtem"
              :value="i"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户标签">
          <el-cascader
            :options="labelList"
            v-model="value3"
            :props="props"
            @change="getCheckedNodes"
            :emitPath="false"
            collapse-tags
            filterable
            :show-all-levels="false"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="提交日期">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
            class="input-with-select"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="客户身份">
          <el-select
            v-model="customerGroupForm.search_role"
            placeholder="请选择"
          >
            <el-option label="游客" :value="1"></el-option>
            <el-option label="会员" :value="2"></el-option>
            <el-option label="推广员" :value="3"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getAuditList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="text-right mg-b-20">
        <el-button
          type="primary"
          @click="pass(selectUuids, 'batch')"
          :disabled="selectNum == 0"
          >审核通过</el-button
        >
        <el-button
          @click="refuse(selectUuids, 'batch')"
          :disabled="selectNum == 0"
          >审核不通过</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="客户头像/昵称" :align="'center'" width="160">
          <template slot-scope="scope">
            <router-link :to="'/customerInfo'">
              <div class="nickAndAvatar">
                <el-image
                  :src="scope.row.user.avatar"
                  v-if="scope.row.user.avatar != null"
                ></el-image>
                <p>
                  <span>{{ scope.row.user.nick_name }}</span>
                </p>
              </div>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="user"
          label="注册手机号码"
          :align="'center'"
          width="110"
        >
          <template slot-scope="scope">
            {{ scope.row.user.mobile }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.user.nick_name }}
          </template>
        </el-table-column>
        <el-table-column
          prop="user.level.name"
          label="会员等级"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column
          prop="user.user_role"
          label="会员身份"
          :align="'center'"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.user.user_role == 1">游客</span>
            <span v-else-if="scope.row.user.user_role == 2">会员</span>
            <span v-else>推广员</span>
          </template>
        </el-table-column>
        <el-table-column prop="cause" label="注销原因" :align="'center'">
        </el-table-column>
        <el-table-column prop="status" label="审核状态" :align="'center'">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">待审核</span>
            <span style="color: #2ed477" v-else-if="scope.row.status == 2"
              >审核通过</span
            >
            <span style="color: red" v-else>审核不通过</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="update_time"
          label="提交时间"
          :align="'center'"
          width="160"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          width="180"
          fixed="right"
          :align="'center'"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 1"
              @click="pass(scope.row.uuid, 'single')"
              type="text"
              size="small"
              >审核通过</el-button
            >
            <el-button
              v-if="scope.row.status == 1"
              type="text"
              size="small"
              @click="refuse(scope.row.uuid, 'single')"
              >审核不通过</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "cancelAudit",
  components: {
    pagination,
  },
  data() {
    return {
      customerGroupForm: {
        search_level: "",
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
        search_label: "",
        search_role: "",
      },
      levelList: [],
      labelList: [],
      selectUuids: [],
      selectNum: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      props: {
        label: "name",
        value: "uuid",
        children: "label",
        checkStrictly: true,
      },
      value3: "",
    };
  },
  created() {
    this.getAuditList();
    this.getLabel();
    this.getLevel();
  },
  methods: {
    getCheckedNodes(v) {
      if (v.length > 1) {
        this.customerGroupForm.search_label = v[1];
      } else {
        this.customerGroupForm.search_label = v[0];
      }
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    handleSelectionChange(rows) {
      this.selectNum = rows.length;
      this.selectUuids = rows.map((item) => {
        return item.uuid;
      });
    },
    getLabelValue(v) {
      this.customerGroupForm.search_label = v[v.length - 1];
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.value3 = "";
      this.getAuditList();
    },
    getLevel() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserLevelSelect`,
      })
        .then((res) => {
          this.levelList = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    getLabel() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserLabelGroupTree`,
      })
        .then((res) => {
          this.labelList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getData(methods, data, queryStry, mark, tableData, msg) {
      this.axios({
        method: methods, //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data,
        url: ` https://time.api.boyalife.cn/v1/cms/UserLogout${mark}${queryStry}`,
      })
        .then((res) => {
          if (msg !== "") {
            this.$message.success(msg);
          }
          if (tableData !== null) {
            this.tableData = res.data.result;
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getAuditList() {
      let querystr = `search_role=${this.customerGroupForm.search_role}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_level=${this.customerGroupForm.search_level}&search_label=${this.customerGroupForm.search_label}`;
      this.getData("get", {}, querystr, "?", this.tableData, "");
    },
    pass(uuids, type) {
      let uuid = [];
      if (type == "single") {
        uuid = [uuids];
      } else {
        uuid = uuids;
      }
      this.$confirm("确定审核通过该用户的注销审核吗?", "审核通过确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            branch_status: 2,
            branch_uuids: uuid,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/UserLogout/branch`,
        })
          .then((res) => {
            this.selectUuids = [];
            uuids = "";
            this.$message.success("操作成功！");
            this.getAuditList();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    refuse(uuids, type) {
      let uuid = [];
      if (type == "single") {
        uuid = [uuids];
      } else {
        uuid = uuids;
      }
      this.$prompt("确定不通过该用户的注销审核吗？", "审核不通过确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        inputPlaceholder: "请输入不通过理由",
        inputType: "textarea",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "理由不能为空",
      })
        .then(({ value }) => {
          this.axios({
            method: "post", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: {
              branch_status: 3,
              branch_uuids: uuid,
              reason: value,
            },
            url: ` https://time.api.boyalife.cn/v1/cms/UserLogout/branch`,
          })
            .then((res) => {
              this.selectUuids = [];
              uuids = "";
              this.$message.success("操作成功！");
              this.getAuditList();
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getAuditList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getAuditList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
</style>
