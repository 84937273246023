<template>
  <div class="flex bbox">
    <div class="menu">
      <dl>
        <dt class="flex space-between mg-b-20">
          <span>全部分组</span>
          <i class="el-icon-circle-plus-outline" @click="addGroup"></i>
        </dt>
        <dd
          :class="{ current: activeIndex === index }"
          v-for="(item, index) in list"
          :key="index"
          @click="changeMenu(item, index)"
        >
          <div class="flex space-between align-center">
            <div class="flex align-center">
              <i class="el-icon-video-camera-solid"></i>
              <span>{{ item.name }}({{ item.movie_count }})</span>
            </div>
            <div class="btn">
              <el-button type="text" @click="editGroup(item)">编辑</el-button>
              <el-button type="text" @click="delGroup(item)">删除</el-button>
            </div>
          </div>
        </dd>
      </dl>
    </div>
    <div class="image mg-l-20">
      <div v-if="activeIndex !== ''">
        <el-form
          :inline="true"
          :model="customerGroupForm"
          class="demo-form-inline"
        >
          <el-form-item label="创建日期">
            <el-date-picker
              v-model="customerGroupForm.time"
              type="daterange"
              align="right"
              @change="getTimes"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="关键字" class="keyword">
            <el-input
              placeholder="请输入内容"
              v-model="customerGroupForm.search_keyword"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn mg-t-10">
          <el-button type="primary" size="medium" @click="getVideoList"
            >查询</el-button
          >
          <el-button size="medium" @click="clearForm">清空</el-button>
        </div>
      </div>
      <div v-if="activeIndex !== ''">
        <div v-if="videoList.data.length > 0">
          <el-row :gutter="20" class="mg-t-20" v-loading="loading">
            <el-col :span="6" v-for="item in videoList.data" :key="item.uuid">
              <div class="img" @click="showVideo(item)">
                <div class="l">
                  <i class="el-icon-video-play"></i>
                </div>
                <video width="100%" height="150px" :src="item.url" :poster="item.cover"></video>
              </div>
              <div class="info">
                <div class="top">
                  <h4 class="font-14">{{ item.name }}</h4>
                  <p class="font-12 color-999">300.60K 1440*2410</p>
                </div>
                <div class="bottom flex space-around align-center">
                  <el-button type="text" @click="showVideoDialog(item)"
                    >编辑</el-button
                  >
                  <el-divider direction="vertical"></el-divider>
                  <el-button type="text" @click="delVideo(item)"
                    >删除</el-button
                  >
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="text-center mg-t-20">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="videoList.current_index"
              :page-sizes="[12, 24, 36, 48, 60]"
              :page-size="Number(videoList.per_page)"
              layout="total, sizes, prev, pager, next, jumper"
              :total="videoList.total"
            >
            </el-pagination>
          </div>
        </div>
        <div v-else>
          <el-empty description="暂无视频"></el-empty>
        </div>
      </div>
       <div v-else>
          <el-empty description="请先选择分组"></el-empty>
        </div>
      <div class="upload-demo">
        <el-button type="primary" icon="el-icon-plus" @click="showUploadDialog"
          >添加视频</el-button
        >
      </div>
    </div>
    <div class="layout" v-show="showVideoPlayer">
      <i class="el-icon-circle-close" @click="closeVideo"></i>
      <div id="dplayer"></div>
    </div>

    <el-dialog
      title="编视频信息"
      :visible.sync="videoDialogVisible"
      width="30%"
    >
      <div class="flex align-center mg-t-20">
        <span style="width: 100px">视频名称</span>
        <el-input placeholder="请输入" v-model="videoName"> </el-input>
      </div>
      <div class="flex align-center mg-t-20">
        <span style="width: 80px">选择分组</span>
        <el-select
          v-model="editGroup_uuid"
          placeholder="请选择"
          @change="changeGroupUuid"
        >
          <el-option
            v-for="item in list"
            :key="item.uuid"
            :label="item.name"
            :value="item.uuid"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="videoDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitVideoInfo">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
      <div class="flex align-center mg-t-20">
        <span style="width: 80px">分组名称</span>
        <el-input placeholder="请输入" v-model="videoGroupName"> </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitAddGroup(submitType)"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="上传视频"
      :visible.sync="uploadDialog"
      width="70%"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <uploadVideoDialog @closeUploadDialog="closeUploadDialog" />
    </el-dialog>
  </div>
</template>
<script>
import DPlayer from "dplayer";
import "vue-dplayer/dist/vue-dplayer.css";
import uploadVideoDialog from "../uploadVideoDialog.vue";
export default {
  name: "",
  components: {uploadVideoDialog},
  data() {
    return {
      loading: false,
      uploadDialog: false,
      acceptType: ".mp4,.mpeg4,.flv,.rmvb,.wmv,.swf",
      formData: "",
      name: "",
      group_uuid: "",
      editGroup_uuid: "",
      videoGroupName: "",
      submitType: "add",
      player: null,
      showVideoPlayer: false,
      activeIndex: 0,
      videoList: [],
      dialogVisible: false,
      videoDialogVisible: false,
      list: ["未分组(200)", "商品主图(100)", "商品详情图(600)"],
      title: "添加分组",
      videoName: "",
      url: "",
      fregion: "",
      customerGroupForm: {
        time: "",
        search_start_time: "",
        search_end_time: "",
        per_page: 12,
        current_index: 1,
        search_keyword: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      options: {},
    };
  },
  created() {
    this.getVideoGroupList();
  },
  mounted() {
    this.player = new DPlayer({
      container: document.getElementById("dplayer"),
      autoplay: true,
      heme: "#b7daff", // 风格颜色，例如播放条，音量条的颜色
      loop: false, // 是否自动循环
      lang: "zh-cn", // 语言，'en', 'zh-cn', 'zh-tw'
      // screenshot: true, // 是否允许截图（按钮），点击可以自动将截图下载到本地
      hotkey: true, // 是否支持热键，调节音量，播放，暂停等
      preload: "auto", // 自动预加载
      screenshot: true,
      volume: 0.7, // 初始化音量
      playbackSpeed: [0.5, 0.75, 1, 1.25, 1.5, 2, 3],
      video: {
        url: "",
      },
    });
    this.getVideoGroupList();
  },
  methods: {
    showUploadDialog() {
      this.uploadDialog = true;
    },
    closeUploadDialog () {
      this.uploadDialog = false
      this.getVideoGroupList()
    },
    beforeUpload(file) {
      this.$message.info("稍等片刻，正在上传...");
      if (file.name.split(".")[1].toLowerCase() != "mp4") {
        this.$message.error("文件格式错误~");
        return false; // 终止上传
      }
      this.formData = new FormData();
      this.formData.append("file", file);
      this.formData.append("name", file.name);
      this.formData.append("group_uuid", this.group_uuid);
    },
    // 上传图片
    httpRequest(data) {
      // const isImg = data.file.size / 1024 / 1024 < 2;
      // if (!isImg) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      //   return false
      // }
      // 封装FormData对象
      // 调用后端接口
      this.$api.materialLibrary
        .addVideo(this.formData)
        .then((res) => {
          this.$message.success("上传成功！");
          this.getVideoGroupList();
          this.getVideoList();
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    changeGroupUuid(v) {
      this.editGroup_uuid = v;
    },
    showVideo(item) {
      this.showVideoPlayer = true;
      this.player.play();
      this.player.video.src = item.url;
    },
    // 点击更换组
    changeMenu(item, index) {
      console.log(item);
      this.group_uuid = item.uuid;
      this.activeIndex = index;
      this.getVideoList();
    },
    // 获取分组列表
    getVideoGroupList() {
      this.$api.materialLibrary.getVideoGroupList().then((res) => {
        this.list = res;
        console.log(res)
        this.getVideoList(res[0].uuid)
      });
    },
    // 添加分组
    addGroup() {
      this.dialogVisible = true;
      this.submitType = "add";
      this.title = "添加分组";
      this.videoGroupName = "";
    },
    // 编辑分组
    editGroup(item) {
      this.dialogVisible = true;
      this.title = "编辑分组";
      this.submitType = "edit";
      this.name = item.name;
      this.uuid = item.uuid;
      this.videoGroupName = item.name;
    },
    // 编辑视频信息
    showVideoDialog(item) {
      this.videoDialogVisible = true;
      this.videoName = item.name;
      this.name = item.name;
      this.uuid = item.uuid;
      this.url = item.url;
      this.editGroup_uuid = item.group_uuid;
    },

    // 关闭视频
    closeVideo() {
      this.player.pause();
      this.showVideoPlayer = false;
    },
    // 编辑视频信息
    submitVideoInfo() {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          name: this.videoName,
          group_uuid: this.editGroup_uuid,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Movie/${this.uuid}`,
      }).then((res) => {
        //执行成功后代码处理
        this.videoDialogVisible = false;
        this.getVideoList();
        this.$message.success("编辑成功！");
      });
    },
    // 删除视频
    delVideo(item) {
      this.uuid = item.uuid;
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              "Content-Type": "text/html;charset=UTF-8",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://time.api.boyalife.cn/v1/cms/Movie/${this.uuid}`,
          }).then((res) => {
            //执行成功后代码处理
            this.getVideoList();
            this.$message.success("删除成功！");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    // 提交
    submitAddGroup() {
      if (this.submitType === "add") {
        this.$api.materialLibrary
          .addVideoGroup({ name: this.videoGroupName })
          .then((res) => {
            this.$message.success("创建成功！");
            this.dialogVisible = false;
            this.getVideoGroupList();
          })
          .catch((err) => {
            this.$message.error(err);
          });
      } else if (this.submitType === "edit") {
        this.axios({
          method: "put", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: { name: this.videoGroupName },
          url: ` https://time.api.boyalife.cn/v1/cms/MovieGroup/${this.uuid}`,
        }).then((res) => {
          //执行成功后代码处理
          this.dialogVisible = false;
          this.getVideoGroupList();
          this.$message.success("编辑成功！");
        });
      }
    },
    // 删除分组
    delGroup(item) {
      this.name = item.name;
      this.uuid = item.uuid;
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: { name: this.videoGroupName },
            url: ` https://time.api.boyalife.cn/v1/cms/MovieGroup/${this.uuid}`,
          }).then((res) => {
            //执行成功后代码处理
            this.dialogVisible = false;
            this.getVideoGroupList();
            this.$message.success("删除成功！");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    // 获取视频列表
    getVideoList(group_uuid) {
      if (group_uuid) this.group_uuid = group_uuid
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.customerGroupForm,
        url: ` https://time.api.boyalife.cn/v1/cms/Movie?group_uuid=${this.group_uuid}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}&page_size=${this.customerGroupForm.per_page}&page_index=${this.customerGroupForm.current_index}`,
      }).then((response) => {
        this.videoList = response.data.result;
        //你是点击分组刷新列表是吧   不是   是上传完图片之后
        // this.getGroupList()
      });
    },
    handleCurrentChange(v) {
      this.customerGroupForm.current_index = v;
    },
    handleSizeChange(v) {
      this.customerGroupForm.per_page = v;
    },
    // 清空查询参数
    clearForm() {
      this.customerGroupForm = {
        time: "",
        search_start_time: "",
        search_end_time: "",
        search_keyword: "",
        per_page: 12,
        current_index: 1,
      };
    },
  },
  beforeDestroy() {
    this.player.destroy();
  },
};
</script>

<style scoped>
.menu {
  width: 20%;
  min-width: 200px;
}
.menu .btn {
  display: none;
}
.menu dl dd {
  cursor: pointer;
  padding: 10px 5px;
}
.menu dl dd:hover,
.menu dl dd.current {
  background: #f6f9fe;
  color: #2561ef;
}
.menu dl dd .el-button {
  padding: 0;
}
.menu dl dd >>> .el-button span {
  font-size: 12px;
}
.menu dl dd:hover .btn {
  display: block;
}
.menu dl dd:hover > div > div:nth-child(1) {
  width: 70%;
}
.image {
  width: 80%;
}
.el-icon-circle-plus-outline {
  color: #2561ef;
  font-size: 16px;
  cursor: pointer;
}
.el-icon-video-camera-solid {
  color: #ffc71c;
  font-size: 18px;
  margin-right: 10px;
  margin-top: 2px;
}
.el-row .el-col {
  margin-bottom: 20px;
}
.el-row .el-image {
  width: 100%;
  display: block;
}
.el-row .info {
  border: 1px solid rgb(220, 220, 220);
  border-top: none;
  margin: 0 1px;
}
.el-row .info .top {
  padding: 10px;
  border-bottom: 1px solid rgb(220, 220, 220);
}
.el-row .info .top h4 {
  color: #7da1f6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-row .info .bottom >>> .el-button span {
  font-size: 12px !important;
}
.layout {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 8;
  left: 0;
  top: 0;
}
.layout .el-icon-circle-close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 10;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
#dplayer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
}
>>> .dplayer-video-wrap {
  width: 80%;
  height: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
>>> .dplayer-mask {
  background: rgba(0, 0, 0, 1);
  display: block;
}
>>> .dplayer-controller,
.dplayer-controller-mask {
  z-index: 3;
}
.img {
  position: relative;
  cursor: pointer;
}
.img .l {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
}
.img:hover .l {
  background: rgba(0, 0, 0, 0.6);
}
.img:hover .el-icon-video-play {
  color: #c5c5c5;
}
.el-icon-video-play {
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  transition: all 0.2s;
}
.bbox {
  position: relative;
}
.bbox .upload-demo {
  position: absolute;
  right: 0;
  top: -74px;
}
</style>
