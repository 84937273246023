<template>
  <div class="addStore">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>门店</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/storeList'">店铺列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{ pageType }}店铺信息</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="info boxLayout mg-t-20">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="140px"
        :rules="rules"
      >
        <el-form-item label="店铺名称" prop="name">
          <el-input v-model="basicInfoForm.name"></el-input>
        </el-form-item>
        <el-form-item label="店铺地址" prop="name">
          <p class="address" @click="selectMapControl">
            <span class="font-12 color-999" v-if="basicInfoForm.address == ''"
              >点击启用地图控件</span
            >
            <span v-else
              >{{ basicInfoForm.address
              }}<span class="font-12 color-999 mg-l-20"
                >(点击可修改)</span
              ></span
            >
          </p>
        </el-form-item>
        <el-form-item label="店铺背景图" prop="shop_background">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest"
          >
          <img
              v-if="basicInfoForm.shop_background !== ''"
              :src="basicInfoForm.shop_background"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseBackground">从素材库选择</el-button>
          <p class="font-12 color-999">支持1～10张，建议尺寸：750*1624像素</p>
        </el-form-item>
        <el-form-item label="店铺介绍图" prop="shop_introduction">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest2"
          >
          <img
              v-if="basicInfoForm.shop_introduction !== ''"
              :src="basicInfoForm.shop_introduction"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseIntroduction">从素材库选择</el-button>
          <p class="font-12 color-666">支持1～10张，建议尺寸：750*1624像素</p>
        </el-form-item>
        <el-divider></el-divider>
        <h3 class="title-t mg-b-30">店长信息</h3>
        <el-form-item label="店长企微" prop="manager_contact">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest3"
          >
          <img
              v-if="basicInfoForm.manager_contact !== ''"
              :src="basicInfoForm.manager_contact"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="choosecontact">从素材库选择</el-button>
          <p class="font-12 color-999">建议尺寸：750*750像素</p>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button type="primary" size="mini" @click="sumbitForm('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <!-- <MapContainer class="map" v-show="dialogVisible" @confirmDialog="confirmDialog" @closeDialog="closeDialog"/> -->
    <div class="boxLayout layouts" :class="{ toTop: dialogVisible }">
      <div class="bj"></div>
      <div class="box">
        <h3 class="title-t flex space-between">
          <p>选择地址</p>
        </h3>
        <el-form :model="form" ref="mapForm">
          <div class="flex mg-b-10 align-center">
            <h4
              class="mg-r-10 text-right"
              style="width: 100px; margin-right: 10px"
            >
              省市区查询
            </h4>
            <div class="input-item mg-r-10">
              <!-- <div class="input-item-prepend"><span class="input-item-text">省市区</span></div> -->
              <el-select
                value-key="area_id"
                clearable
                v-model="form.provinceValue"
                placeholder="请选择"
                @change="search($event)"
                @clear="clearProvince"
              >
                <el-option
                  placeholder="请选择"
                  clearable
                  v-for="item in form.provinceoptions"
                  :key="item.area_id"
                  :label="item.area_name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="input-item mg-r-10">
              <el-select
                value-key="area_id"
                clearable
                v-model="form.cityValue"
                placeholder="请选择"
                @change="search($event)"
                @clear="clearCity"
              >
                <el-option
                  placeholder="请选择"
                  clearable
                  v-for="item in form.cityOptions"
                  :key="item.area_id"
                  :label="item.area_name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="input-item mg-r-10">
              <el-select
                value-key="area_id"
                clearable
                v-model="form.districtValue"
                placeholder="请选择"
                @change="search($event)"
                @clear="clearDis"
              >
                <el-option
                  placeholder="请选择"
                  clearable
                  v-for="item in form.districtOptions"
                  :key="item.area_id"
                  :label="item.area_name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <p
              class="color-999 font-12"
              style="width: 200px; color: red"
              v-if="Object.keys(this.form.districtValue).length <= 0"
            >
              请先选择省市区
            </p>
          </div>
          <div
            class="flex align-center"
            v-if="Object.keys(this.form.districtValue).length > 0"
          >
            <p style="width: 100px; margin-right: 10px" class="text-right">
              详细地址查询
            </p>
            <el-input
              v-model="form.keyword"
              clearable
              class="ipt"
              placeholder="请输入详细地址"
              @input="kewordSearch"
            ></el-input>
          </div>
          <!-- <p style="width: 100px;margin-right: 10px; text-right" class="text-right">经纬度查询</p> -->
          <el-form-item
            label="经纬度"
            prop="latitude"
            label-width="110px"
            v-if="Object.keys(this.form.districtValue).length > 0"
          >
            <div class="flex align-center mg-t-10">
              <el-input
                v-model="form.longitude"
                clearable
                class="mg-r-10"
                placeholder="请输入经度"
                @input="numSearch"
              ></el-input>
              <el-input
                v-model="form.latitude"
                clearable
                placeholder="请输入纬度"
                @input="numSearch"
              ></el-input>
            </div>
          </el-form-item>
          <div
            class="flex align-center mg-t-10"
            v-if="Object.keys(this.form.districtValue).length > 0"
          >
            <p
              style="width: 100px;margin-right: 10px; text-right"
              class="text-right"
            >
              地址:
            </p>
            <p>{{ form.address !== "" ? form.address : "暂无" }}</p>
          </div>
        </el-form>
        <div class="map mg-t-10">
          <div
            class="layout"
            v-if="Object.keys(this.form.districtValue).length == 0"
          ></div>
          <div id="container"></div>
          <div id="panel"></div>
        </div>
        <div class="btns text-center mg-t-20 mg-b-20">
          <el-button @click="closeDialog">取消</el-button>
          <el-button type="primary" @click="confirmDialog('mapForm')"
            >确定</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogImageVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog
      :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog"
      />
    </el-dialog>
  </div>
</template>
<script>
import imageDialog from "../../../components/imageDialog.vue";
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  // 安全密钥
  securityJsCode: "5cc2994d6e5654e9bd6fbc0c50c5af86",
};
export default {
  name: "addStore",
  components: {
    imageDialog,
  },

  data() {
    return {
      imgType: "",
      pageType: "",
      options2: [],
      currentLevel: "",
      dialogImageVisible: false,
      dialogVisible: false,
      isShow: "",
      getUrlType: "",
      //地图
      placeSearch: "",
      districtSearch: "",
      form: {
        address: "",
        longitude: "",
        latitude: "",
        visible: false,
        provinceValue: {},
        provinceoptions: [],
        cityValue: {},
        cityOptions: [],
        districtValue: {},
        districtOptions: [],
        keyword: "",
        searchWord: "",
      },
      rules: {
        name: [{ required: true, message: "请输入经纬度", trigger: "blur" }],
        address: [{ required: true, message: "请输入经纬度", trigger: "blur" }],
        shop_background: [
          { required: true, message: "请上传店铺背景图", trigger: "blur" },
        ],
        shop_introduction: [
          { required: true, message: "请上传店铺介绍图", trigger: "blur" },
        ],
        manager_contact: [
          { required: true, message: "请上传企微", trigger: "blur" },
        ],
      },
      basicInfoForm: {
        address: "",
        name: "",
        shop_background: "",
        shop_introduction: "",
        manager_contact: "",
      },
      options: [
        {
          label: "一级分类A",
          options: [
            {
              value: "Shanghai",
              label: "二级分类A",
            },
            {
              value: "Beijing",
              label: "二级分类B",
            },
          ],
        },
        {
          label: "一级分类B",
          options: [
            {
              value: "Chengdu",
              label: "二级分类C",
            },
          ],
        },
      ],
      tableData: [
        {
          num: 1,
          nickName: "限时优惠",
        },
        {
          num: 2,
          nickName: "少女的激战与温馨的日常",
        },
        {
          num: 3,
          nickName: "让人失去理智的死循环",
        },
      ],
      gridData: [
        {
          name: "少女的激战与温馨的日常",
        },
        {
          name: "让人失去理智的死循环",
        },
      ],
    };
  },
  created() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      let params = this.$route.query;
      for (let i in params) {
        this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
      }
    } else {
      this.pageType = "新增";
    }
    this.initMap();
  },
  methods: {
    closeImageDialog () {
      this.dialogVisible = false
    },
     // 上传图片
     httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.shop_background =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // 上传图片
    httpRequest2() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.shop_introduction =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // 上传图片
    httpRequest3() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.manager_contact =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    selectMapControl() {
      this.getProvince(-1);
      if (this.pageType == "编辑") {
        for (let i in this.basicInfoForm) {
          this.form[i] = JSON.parse(JSON.stringify(this.basicInfoForm[i]));
        }
        this.form.province !== ""
          ? (this.form.provinceValue = JSON.parse(this.form.province))
          : "";
        this.form.city !== ""
          ? (this.form.cityValue = JSON.parse(this.form.city))
          : "";
        this.form.area !== ""
          ? (this.form.districtValue = JSON.parse(this.form.area))
          : "";
        this.getProvince(-1);
        this.getCity(this.form.province_uuid);
        this.getArea(this.form.city_uuid);
        this.placeSearch.search(this.form.address, function (status, result) {
          // this.form.latitude = result.poiList.
        });
      } else {
        this.getProvince("-1");
      }
      this.dialogVisible = true;
    },
    getPcd(address) {
      // 获取省
      let provinceIndex = address.lastIndexOf("省");
      let provinceStr = address.substring(0, provinceIndex);
    },
    // 确认选择地址
    confirmDialog(form) {
      if (this.form.districtValue == "") {
        this.$message.error("省市区必选");
        return false;
      }
      if (
        this.form.latitude !== "" &&
        this.form.longitude !== "" &&
        this.form.address !== ""
      ) {
        this.dialogVisible = false;
        for (let i in this.form) {
          this.basicInfoForm[i] = JSON.parse(JSON.stringify(this.form[i]));
        }
        this.basicInfoForm.province_uuid =
          this.basicInfoForm.provinceValue.area_id + "";
        this.basicInfoForm.city_uuid =
          this.basicInfoForm.cityValue.area_id + "";
        this.basicInfoForm.area_uuid =
          this.basicInfoForm.districtValue.area_id + "";
        delete this.basicInfoForm.provinceoptions;
        delete this.basicInfoForm.cityOptions;
        delete this.basicInfoForm.districtOptions;
        delete this.basicInfoForm.keyword;
        delete this.basicInfoForm.provinceValue;
        delete this.basicInfoForm.cityValue;
        delete this.basicInfoForm.districtValue;
      } else {
        this.$message.error("请选择地图或者手动输入经纬度");
      }
    },
    clearProvince() {
      this.form.provinceValue = "";
      this.form.cityValue = "";
      this.form.districtValue = "";
    },
    clearCity() {
      this.form.cityValue = "";
      this.form.districtValue = "";
    },
    clearDis() {
      this.form.districtValue = "";
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    closed() {
      this.dialogImageVisible = false;
    },
    // 选择店铺背景图
    chooseBackground() {
      this.imgType = "chooseThumbnail_image";
      this.dialogImageVisible = true;
      this.getUrlType = "shop_background";
    },
    // 选择店铺介绍图
    chooseIntroduction() {
      this.imgType = "chooseThumbnail_image";
      this.dialogImageVisible = true;
      this.getUrlType = "shop_introduction";
    },
    // 选择企微
    choosecontact() {
      this.imgType = "chooseThumbnail_image";
      this.dialogImageVisible = true;
      this.getUrlType = "manager_contact";
    },
    confirmImageDialog(item) {
      if (this.getUrlType == "shop_background") {
        this.basicInfoForm.shop_background = item[0].url;
      } else if (this.getUrlType == "shop_introduction") {
        this.basicInfoForm.shop_introduction = item[0].url;
      } else {
        this.basicInfoForm.manager_contact = item[0].url;
      }
      this.dialogImageVisible = false;
    },
    // 提交表单
    sumbitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (Object.keys(this.$route.query).length !== 0) {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/Shop/${this.basicInfoForm.uuid}`,
            })
              .then((res) => {
                this.$message.success("编辑成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/Shop`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
    initMap() {
      let that = this;
      AMapLoader.load({
        key: "	a60d52419a53d04eb46d0248b5ee5f14", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.Geocoder",
          "AMap.AutoComplete",
          "AMap.DistrictSearch",
          "AMap.PlaceSearch",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("container", {
            //设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            zoom: 10, //初始化地图级别
            center: [116.395672, 39.906042], //初始化地图中心点位置
          });
          //点击获取经纬度;
          this.map.on("click", (e) => {
            // 获取经纬度
            this.form.longitude = e.lnglat.lng;
            this.form.latitude = e.lnglat.lat;
            // 清除点
            this.removeMarker();
            // 标记点
            this.setMapMarker();
            this.toGeoCoder();
            this.getPcd();
          });
          this.geoCoder = new AMap.Geocoder({
            radius: 1000, //范围，默认：500
          });
          // 关键字搜索
          this.placeSearch = new AMap.PlaceSearch({
            pageSize: 5, // 单页显示结果条数
            pageIndex: 1, // 页码
            city: "010", // 兴趣点城市
            citylimit: false, //是否强制限制在设置的城市内搜索
            map: this.map, // 展现结果的地图实例
            panel: "panel", // 结果列表将在此容器中进行展示。
            autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          });

          AMap.Event.addListener(
            this.placeSearch,
            "selectChanged",
            function (data) {
              that.form.latitude = data.selected.data.location.lat;
              that.form.longitude = data.selected.data.location.lng;
              that.form.address =
                data.selected.data.pname +
                data.selected.data.cityname +
                data.selected.data.name +
                data.selected.data.address;
            }
          );
        })
        .catch((e) => {});
    },
    kewordSearch() {
      this.form.latitude = "";
      this.form.longitude = "";
      this.form.address = "";

      let str =
        this.form.provinceValue.area_name +
        this.form.cityValue.area_name +
        this.form.districtValue.area_name +
        this.form.keyword;

      this.placeSearch.search(str, function (status, result) {});
    },
    numSearch() {
      this.toGeoCoder();
      this.setMapMarker();
      this.$forceUpdate();
    },
    // 标记点
    setMapMarker() {
      // 自动适应显示想显示的范围区域
      this.map.setFitView();
      this.marker = new AMap.Marker({
        map: this.map,
        position: [this.form.longitude, this.form.latitude],
      });
      this.map.setFitView();
      this.map.add(this.marker);
    },
    // 清除点
    removeMarker() {
      if (this.marker) {
        this.map.remove(this.marker);
      }
    },
    toGeoCoder() {
      let lnglat = [this.form.longitude, this.form.latitude];
      this.geoCoder.getAddress(lnglat, (status, result) => {
        if (status === "complete" && result.regeocode) {
          this.form.address = result.regeocode.formattedAddress;
          this.getPcd();
        }
      });
    },

    search(obj) {
      this.form.latitude = "";
      this.form.longitude = "";
      this.form.address = "";
      this.currentLevel = obj.level;
      if (obj.level == 1) {
        this.form.cityValue = "";
        this.form.districtValue = "";
        this.getCity(obj.area_id);
      }
      if (obj.level == 2) {
        this.form.districtValue = "";
        this.getArea(obj.area_id);
      }
      this.placeSearch.search(
        obj.area_name + this.form.keyword,
        function (status, result) {
          console.log(status);
          console.log(result);
          // this.form.latitude = result.poiList.
        }
      );
    },
    clearForm() {
      this.form = {};
    },
    getProvince(parent_id) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Province?parent_id=${parent_id}`,
      })
        .then((res) => {
          this.form.provinceoptions = JSON.parse(
            JSON.stringify(res.data.result)
          );
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getCity(parent_id) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/City?parent_id=${parent_id}`,
      })
        .then((res) => {
          this.form.cityOptions = JSON.parse(JSON.stringify(res.data.result));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getArea(parent_id) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Area?parent_id=${parent_id}`,
      })
        .then((res) => {
          this.form.districtOptions = JSON.parse(
            JSON.stringify(res.data.result)
          );
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
  },
};
</script>
<style scoped>
.addStore .map {
  position: relative;
  z-index: -3;
}

.addStore .map .layout {
  width: 100%;
  height: 100%;
  z-index: 5;
  position: absolute;
  left: 0;
  top: 0;
}

.addStore {
  margin-bottom: 80px;
}

.addStore .el-form {
  width: 800px;
}

.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 16px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.address {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  cursor: pointer;
}

#container {
  padding: 0px;
  margin: 0px 20px;
  height: 50vh;
}

.addStore .box .el-form {
  width: 100% !important;
}

#panel {
  width: 300px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

>>> .el-input {
  width: 200px;
}

>>> .el-input.ipt {
  width: 500px;
}

.bj {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 7;
}

.box {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  width: 80vw;
  max-height: 670px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
}

.boxLayout.layouts {
  position: relative;
  z-index: -100;
  visibility: hidden;
}

.boxLayout.layouts.toTop {
  z-index: 20;
  visibility: visible;
}

.layout {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 8;
  left: 0;
  top: 0;
}

.layout .bg {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 7;
}

.layout .el-icon-circle-close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 10;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

.matreial {
  width: 1050px;
  height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
  background: #fff;
  padding: 30px;
  border-radius: 4px;
}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
.upload-demo{
  border: 1px dashed #409eff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}
</style>
