<template>
  <div class="withdrawalReview" style="background: #f0f2f5">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>财务</el-breadcrumb-item>
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>提现审核</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <div class="title">
        <el-form
          :inline="true"
          :model="customerGroupForm"
          class="demo-form-inline"
        >
          <el-form-item label="提现状态">
            <el-select
              v-model="customerGroupForm.search_status"
              placeholder="请选择"
            >
              <el-option label="待处理" :value="1"></el-option>
              <el-option label="已处理" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="申请日期">
            <el-date-picker
              v-model="customerGroupForm.dataTime"
              type="daterange"
              align="right"
              unlink-panels
              @change="getTimes"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="关键字" class="keyword">
            <el-input
              placeholder="请输入内容"
              v-model="customerGroupForm.search_keyword"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="btn mg-t-10">
          <el-button type="primary" size="medium" @click="getList"
            >查询</el-button
          >
          <el-button size="medium" @click="clear">清空</el-button>
          <el-button size="medium" @click="exportExcel">导出</el-button>
        </div>
        <el-table
          class="mg-t-20"
          ref="multipleTable"
          :data="tableData.data"
          border
          tooltip-effect="dark"
          style="width: 100%"
        >
          <!-- <el-table-column
            type="selection"
            :align="'center'"
            >
          </el-table-column> -->
          <el-table-column
            label="交易时间"
            prop="update_time"
            :align="'center'"
            width="160"
          >
          </el-table-column>
          <el-table-column
            prop="user"
            label="申请人账号"
            :align="'center'"
            width="170"
          >
            <template slot-scope="scope">
              <router-link :to="'/customerInfo?uuid=' + scope.row.user.uuid">
                <div style="margin: 0 auto; width: 150px">
                  <div class="flex align-center">
                    <el-image
                      fit="cover"
                      :src="scope.row.user.avatar"
                      v-if="
                        scope.row.user !== null &&
                        scope.row.user.avatar !== null
                      "
                    >
                    </el-image>
                    <div class="text-left" v-if="scope.row.user !== null">
                      <p>{{ scope.row.user.nick_name }}</p>
                      <p>{{ scope.row.user.mobile }}</p>
                    </div>
                  </div>
                </div>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="提现方式" :align="'center'" prop="status">
            <template slot-scope="scope"> 手动提现 </template>
          </el-table-column>
          <el-table-column label="提现渠道" :align="'center'" prop="status">
            <template slot-scope="scope"> 微信零钱 </template>
          </el-table-column>
          <el-table-column
            prop="money"
            label="提现金额（元）"
            :align="'center'"
            width="150"
            sortable
          >
            <template slot-scope="scope">
              <span>¥{{ scope.row.money }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="phone"
            label="提现状态"
            :align="'center'"
            width="120"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">待处理</span>
              <span v-else>已处理</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="order_sn"
            label="交易单号"
            :align="'center'"
            width="200"
          >
          </el-table-column>

          <el-table-column label="操作" :align="'center'" fixed="right">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.status == 1"
                @click="pass(scope.row)"
                type="text"
                size="small"
                >确认打款</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <pagination
          :total="tableData.total"
          :per_page="tableData.per_page"
          :current_page="tableData.current_page"
          @handleCurrentChange="handleCurrentChange"
          @handleSizeChange="handleSizeChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "withdrawalReview",
  components: {
    pagination,
  },
  data() {
    return {
      customerGroupForm: {
        search_keyword: "",
        search_end_time: "",
        search_start_time: "",
        search_status: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    if (this.$route.query.search_status) {
      this.customerGroupForm.search_status = Number(
        this.$route.query.search_status
      );
    }
    this.getList();
  },
  methods: {
    // 导出
    exportExcel() {
      this.axios({
        method: "post", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Withdraw/export?search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_status=${this.customerGroupForm.search_status}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}`,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let currentTime = new Date();
          let year = currentTime.getFullYear();
          let month = currentTime.getMonth() + 1;
          let day = currentTime.getDate();
          let formattedDate = `${year}/${month
            .toString()
            .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;

          link.download = `交易流水记录 - ${formattedDate}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Withdraw?search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_status=${this.customerGroupForm.search_status}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    pass(row) {
      let html = `
        <h5 class="mg-l-30 mg-b-10">姓名: ${row.user.real_name}</h5>
        <h5 class="mg-l-30 mg-b-10">身份证号码：${row.user.idcard}</h5>
        <h5 class="mg-l-30 mg-b-10">提现金额: ${row.pay_money}元</h5>
        <h5 class="mg-l-30 mg-b-10">提现到：微信零钱</h5>
      `;
      this.$confirm(html, "确认打款", {
        confirmButtonText: "确认提交",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.axios({
          method: "put", //请求方法
          headers: {
            "Content-Type": "text/html;charset=UTF-8",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/Withdraw/confirm/${row.uuid}`,
        })
          .then((res) => {
            this.$message.success("操作成功！");
            this.getList();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
