<template>
  <div class="addInformationClassification">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/informationClassification'"
        >资讯分类列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ pageType }}资讯分类</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="info boxLayout mg-t-20">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="140px"
        :rules="rules"
      >
        <el-form-item label="分类名称" prop="category_name">
          <el-input v-model="basicInfoForm.category_name"></el-input>
        </el-form-item>
        <el-form-item label="是否启用" placeholder="请输入">
          <el-switch
            v-model="basicInfoForm.status"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="话题缩略图">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest"
          >
          <img
              v-if="basicInfoForm.thumbnail_image != ''"
              :src="basicInfoForm.thumbnail_image"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseThumbnail">从素材库选择</el-button>
        
          <p class="font-12 color-999">建议尺寸：800*800像素</p>
        </el-form-item>
        <el-form-item label="分类封面图">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest2"
          >
          <img
              v-if="basicInfoForm.cover_image != ''"
              :src="basicInfoForm.cover_image"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseCover_image">从素材库选择</el-button>
          
          <p class="font-12 color-999">建议尺寸：750*600像素</p>
        </el-form-item>
        <el-form-item label="设置热门分类" prop="name" placeholder="请输入">
          <el-switch
            v-model="basicInfoForm.is_hot"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button type="primary" size="mini" @click="sumbitForm('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog  :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog"/>
      
    </el-dialog>
  </div>
</template>
<script>
import imageDialog from "../../../components/imageDialog.vue";
export default {
  name: "addInformationClassification",
  components: {
    imageDialog,
  },
  created() {
    if (this.$route.query) {
      let params = this.$route.query;
      for (let i in params) {
        this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
      }
    }
  },
  data() {
    return {
      imgType: "chooseThumbnail_image",
      dialogVisible: false,
      basicInfoForm: {
        thumbnail_image: "",
        cover_image: "",
        category_name: null,
        status: 2,
        is_hot: 2,
        fid: 0,
      },
      formData: '',
      rules: {
        category_name: [
          { required: true, message: "分类名称必填", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.getInfo();
      this.pageType = "编辑";
    } else {
      this.pageType = "新增";
    }
  },
  methods: {
    closeImageDialog () {
      this.dialogVisible = false
    },
     // 上传图片
     httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.thumbnail_image =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    httpRequest2() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.cover_image =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/InformationCategory/${this.$route.query.uuid}`,
      }).then((res) => {
        console.log(res);
        let params = res.data.result;
        for (let i in params) {
          this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
        }
        this.basicInfoForm.status = Number(this.basicInfoForm.status);
        this.basicInfoForm.is_hot = Number(this.basicInfoForm.is_hot);
      });
    },
    // 选择缩略图
    chooseThumbnail() {
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible = true;
    },
    chooseCover_image() {
      this.imgType = "chooseCover_image";
      this.dialogVisible = true;
    },
    // 选择封面
    confirmImageDialog(item) {
      if (this.imgType === "chooseThumbnail_image") {
        this.basicInfoForm.thumbnail_image = item[0].url;
      } else {
        this.basicInfoForm.cover_image = item[0].url;
      }
      this.dialogVisible = false;
    },
    // 提交表单
    sumbitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (Object.keys(this.$route.query).length !== 0) {
            delete this.basicInfoForm.create_time;
            delete this.basicInfoForm.delete_time;
            delete this.basicInfoForm.update_time;
            delete this.basicInfoForm.uuid;

            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/InformationCategory/${this.$route.query.uuid}`,
            })
              .then((res) => {
                if (res.data.result == true) {
                  this.$message.success("编辑成功！");
                  this.$router.go(-1);
                }
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/InformationCategory`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.addInformationClassification {
  margin-bottom: 80px;
}
.addInformationClassification .el-form {
  width: 800px;
}
.avatar-uploader {
  position: relative;
}
.avatar-uploader .el-icon-delete {
  font-size: 20px;
  position: absolute;
  right: -40px;
  bottom: 0px;
}
.avatar-uploader-icon {
  font-size: 16px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
.upload-demo{
  border: 1px dashed #409eff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}
</style>
