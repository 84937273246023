<template>
  <div class="dialog-container" v-show="visible">
    <div class="bg-layout"></div>
    <div class="dialog">
      <h2>选择商品</h2>
      <div class="search-container flex space-between mg-t-20">
        <el-form
          :inline="true"
          :model="customerGroupForm"
          class="demo-form-inline"
        >
          <el-form-item label="" class="keyword">
            <div class="flex">
              <el-input
                placeholder="请输入人群名称"
                v-model="customerGroupForm.search_keyword"
                clearable
              >
              </el-input>
              <el-button
                class="mg-l-10"
                type="primary"
                size="medium"
                @click="getList"
                >查询</el-button
              >
            </div>
          </el-form-item>
        </el-form>
        <div class="btn mg-t-10"></div>
      </div>
      <h3 class="mg-l-10 mg-b-10">
        已选 <span style="color: orange">{{ selectNum }} </span>个群体
      </h3>
      <el-table
        :data="tableData.data"
        style="width: 100%"
        ref="multipleTable"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        row-key="getRowKeys"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="name"
          label="优惠券名称"
          width="220"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="status" label="状态" width="85">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.status == 1"
              >可领用</span
            >
            <span v-else>禁用</span>
          </template>
        </el-table-column>
        <el-table-column prop="desc" label="优惠券内容"> </el-table-column>
        <el-table-column prop="desc" label="适用范围" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.goods.length == 0">全部商品</span>
            <div v-else>
              <span v-for="(item, index) in scope.row.goods" :key="item.uuid"
                >{{ index + 1 }} : {{ item.name }} &nbsp;&nbsp;
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="有效期">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.validity == 1"
              >永久有效</span
            >
            <span style="color: #2ed477" v-if="scope.row.validity == 2"
              >领取后{{ scope.row.validity_day }}天有效</span
            >
            <span style="color: #2ed477" v-if="scope.row.validity == 3"
              >{{ scope.row.validity_start_date }} -
              {{ scope.row.validity_end_date }}期间有效</span
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
      <div class="dialog-footer mg-t-20 text-right">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "./pagination.vue";
export default {
  name: "selectCoupon",
  components: {
    pagination,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    coupons: [],
    releaseType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      selectNum: 0,
      selectRow: [],
      customerGroupForm: {
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  watch: {
    dialogVisible(n) {
      this.visible = n;
    },
    coupons(n) {
      if (n.length > 0) {
        if (this.coupons && this.coupons.length > 0) {
          this.selectRows = this.coupons;
          let uuids = this.selectRows.map((i) => i.uuid);
          this.tableData.data.forEach((row) => {
            if (uuids.includes(row.uuid)) {
              this.$nextTick(() => {
                this.$refs.multipleTable.toggleRowSelection(row, true);
              });
            }
          });
        }
      }
    },
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Coupon?search_keyword=${this.customerGroupForm.search_keyword}&search_status=1&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&release_type=${this.releaseType}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    handleClose() {
      this.visible = false;
      this.$emit("closeDialog", this.visible);
    },
    confirm() {
      this.$emit("confirmCoupon", this.selectRows);
    },
    handleSelectionChange(rows) {
      this.selectNum = rows.length;
      this.selectRows = rows;
    },
    getRowKeys(row) {
      return row.case_id;
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
