<template>
  <div class="newMemberRewards">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>推广</el-breadcrumb-item>
      <el-breadcrumb-item>推广设置</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" :model="form" label-width="130px" :rules="rules">
      <div class="boxLayout">
        <h3 class="title-t mg-b-20">基础信息</h3>
        <el-form-item label="开通推广权限条件" prop="is_real_name">
          <el-checkbox
            label="实名认证"
            v-model="form.is_real_name"
          ></el-checkbox>
          <br />
        </el-form-item>
        <el-form-item :prop="form.is_share == true ? 'share_num' : ''">
          <el-checkbox
            label="邀请好友数量 ≥"
            v-model="form.is_share"
            class="mg-b-10"
          ></el-checkbox>
          <el-input v-model="form.share_num" class="ipt" placeholder="请输入"
            ><template slot="prepend">位</template></el-input
          ><br />
        </el-form-item>
        <el-form-item :prop="form.is_register == true ? 'register_day' : ''">
          <el-checkbox
            label="注册天数 ≥"
            v-model="form.is_register"
            class="mg-b-10"
          ></el-checkbox>
          <el-input v-model="form.register_day" class="ipt" placeholder="请输入"
            ><template slot="prepend">天</template></el-input
          ><br />
        </el-form-item>
        <el-form-item
          :prop="form.is_consumption == true ? 'consumption_money' : ''"
        >
          <el-checkbox
            label="累计消费金额 ≥"
            v-model="form.is_consumption"
            class="mg-b-10"
          ></el-checkbox>
          <el-input
            v-model="form.consumption_money"
            class="ipt"
            placeholder="请输入"
            ><template slot="prepend">天</template></el-input
          ><br />
        </el-form-item>
        <el-form-item label="自动审核" prop="is_auto_audit">
          <el-switch
            v-model="form.is_auto_audit"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
          <p class="font-12 color-999"></p>
        </el-form-item>
        <!-- <el-form-item label="说明" prop="explain" >
            <el-input v-model="form.explain" type="textarea" resize="none" rows="6"></el-input>
          </el-form-item> -->
        <!-- <el-form-item label="推广提成比例" prop="royalty_rate">
            <el-input v-model="form.royalty_rate" class="ipt" placeholder="请输入"><template slot="prepend">%</template></el-input><br>
          </el-form-item> -->
        <el-form-item label="最低提现金额" prop="min_withdraw">
          <el-input v-model="form.min_withdraw" class="ipt" placeholder="请输入"
            ><template slot="prepend">元</template></el-input
          ><br />
        </el-form-item>
        <el-form-item label="提现费率" prop="withdraw_rate">
          <el-input
            v-model="form.withdraw_rate"
            class="ipt"
            placeholder="请输入"
            ><template slot="prepend">%</template></el-input
          ><br />
        </el-form-item>
      </div>

      <div class="boxLayout">
        <h3 class="title-t mg-t-20 mg-b-20">关系设置</h3>
        <el-form-item label="推广员绑客模式" prop="permanent_lock">
          <el-radio-group v-model="form.permanent_lock">
            <el-radio :label="1">永久锁客</el-radio>
            <el-radio :label="2">绑定客户关系</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="relieve_day" v-if="form.permanent_lock == 2">
          <span
            ><el-input
              v-model="form.relieve_day"
              class="ipt mg-l-10 mg-r-10"
              placeholder="请输入"
              ><template slot="prepend">天</template></el-input
            >后，解除客户关系</span
          >
          <p class="font-12 color-999 mg-t-10">
            客户再次点击推广员的推广链接/扫码时，有效期将会重新倒计时
          </p>
        </el-form-item>
      </div>
    </el-form>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submitForm('form')"
        >保存</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      form: {
        is_real_name: false,
        is_share: false,
        share_num: "",
        is_register: false,
        register_day: "",
        is_consumption: false,
        consumption_money: "",
        is_auto_audit: 1,
        explain: "",
        royalty_rate: "",
        min_withdraw: "",
        withdraw_rate: "",
        permanent_lock: 1,
        is_relieve: "",
        relieve_day: "",
      },
      rules: {
        share_num: { required: true, message: "不能为空", trigger: "blur" },
        register_day: { required: true, message: "不能为空", trigger: "blur" },
        consumption_money: {
          required: true,
          message: "不能为空",
          trigger: "blur",
        },
        relieve_day: { required: true, message: "不能为空", trigger: "blur" },
      },
    };
  },
  created() {
    this.axios({
      method: "get", //请求方法
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": this.storage.get("__token"),
        Authorization: `Bearer ${this.storage.get("__token")}`,
      },
      url: ` https://time.api.boyalife.cn/v1/cms/ExtendSetting/1`,
    })
      .then((res) => {
        for (let i in res.data.result) {
          this.form[i] = res.data.result[i];
        }
        this.form.is_auto_audit = Number(this.form.is_auto_audit);
        this.form.is_real_name = this.form.is_real_name == 1 ? true : false;
        this.form.is_share = this.form.is_share == 1 ? true : false;
        this.form.is_register = this.form.is_register == 1 ? true : false;
        this.form.is_consumption = this.form.is_consumption == 1 ? true : false;
        this.form.permanent_lock = Number(this.form.permanent_lock);
      })
      .catch((err) => {
        this.$message.error(err.response.data.error.message);
      });
  },
  methods: {
    submitForm(form) {
      this.form.is_real_name = this.form.is_real_name == true ? 1 : 2;
      this.form.is_share = this.form.is_share == true ? 1 : 2;
      this.form.is_register = this.form.is_register == true ? 1 : 2;
      this.form.is_consumption = this.form.is_consumption == true ? 1 : 2;
      this.form.is_relieve = this.form.is_relieve == 1 ? 2 : 1;
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.form,
            url: ` https://time.api.boyalife.cn/v1/cms/ExtendSetting/1`,
          })
            .then((res) => {
              this.$message.success("保存成功！");
              this.form.is_real_name =
                this.form.is_real_name == 1 ? true : false;
              this.form.is_share = this.form.is_share == 1 ? true : false;
              this.form.is_register = this.form.is_register == 1 ? true : false;
              this.form.is_consumption =
                this.form.is_consumption == 1 ? true : false;
              this.form.permanent_lock =
                this.form.permanent_lock == 0 ? 1 : this.form.permanent_lock;
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.newMemberRewards {
  margin-bottom: 80px;
}
>>> .el-form {
}
>>> .el-form .ipt {
  width: 200px;
}
>>> .el-checkbox {
  margin-right: 10px !important;
}
</style>
