<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>分享配置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <h3 class="title-t mg-b-30">分享配置</h3>
      <el-form
        :model="basicInfoForm"
        label-width="95px"
        ref="form"
        :rules="rules"
      >
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>通用分享设置</span>
            <p class="font-12 color-999">修改小程序页面分享设置</p>
          </div>
          <el-form-item label="分享名称" prop="common_name">
            <el-input v-model="basicInfoForm.common_name"></el-input>
          </el-form-item>
          <el-form-item label="分享描述" prop="common_desc">
            <el-input v-model="basicInfoForm.common_desc"></el-input>
          </el-form-item>
          <el-form-item label="分享封面">
            <el-upload
              ref="upload"
              class="upload-demo"
              action="#"
              :accept="'jpg,jpeg,png'"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              list-type="picture-card"
              :http-request="httpRequest"
            >
            <img
                v-if="basicInfoForm.common_cover != ''"
                :src="basicInfoForm.common_cover"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
            </el-upload>
            <el-button  type="text"  @click="chooseBanner('1')">从素材库选择</el-button>
            <p class="font-12 color-999">
              *
              请按xxx*xxx尺寸上传png/jpg格式且小于2m的图片/若不上传则默认分享当前页面截图
            </p>
          </el-form-item>
        </el-card>
        <el-card class="box-card mg-t-20" shadow="never">
          <div slot="header" class="clearfix">
            <span>商城分享设置</span>
            <p class="font-12 color-999">修改小程序页面分享设置</p>
          </div>
          <el-form-item label="分享名称" prop="shop_name">
            <el-input v-model="basicInfoForm.shop_name"></el-input>
          </el-form-item>
          <el-form-item label="分享描述" prop="shop_desc">
            <el-input v-model="basicInfoForm.shop_desc"></el-input>
          </el-form-item>
          <el-form-item label="分享封面" prop="poster">
            <el-upload
              ref="upload"
              class="upload-demo"
              action="#"
              :accept="'jpg,jpeg,png'"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              list-type="picture-card"
              :http-request="httpRequest2"
            >
            <img
                v-if="basicInfoForm.shop_cover != ''"
                :src="basicInfoForm.shop_cover"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
            </el-upload>
            <el-button  type="text"  @click="chooseBanner('2')">从素材库选择</el-button>
            <p class="font-12 color-999">
              *
              请按xxx*xxx尺寸上传png/jpg格式且小于2m的图片/若不上传则默认分享当前页面截图
            </p>
          </el-form-item>
        </el-card>
        <el-card class="box-card mg-t-20 mg-b-30" shadow="never">
          <div slot="header" class="clearfix">
            <span>邀请好友分享设置</span>
            <p class="font-12 color-999">修改小程序页面分享设置</p>
          </div>
          <el-form-item label="分享名称" prop="share_name">
            <el-input v-model="basicInfoForm.share_name"></el-input>
          </el-form-item>
          <el-form-item label="分享描述" prop="share_desc">
            <el-input v-model="basicInfoForm.share_desc"></el-input>
          </el-form-item>
          <el-form-item label="分享封面" prop="poster">
            <el-upload
              ref="upload"
              class="upload-demo"
              action="#"
              :accept="'jpg,jpeg,png'"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              list-type="picture-card"
              :http-request="httpRequest3"
            >
            <img
                v-if="basicInfoForm.share_cover != ''"
                :src="basicInfoForm.share_cover"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
            </el-upload>
            <el-button  type="text"  @click="chooseBanner('3')">从素材库选择</el-button>
            <p class="font-12 color-999">
              *
              请按xxx*xxx尺寸上传png/jpg格式且小于2m的图片/若不上传则默认分享当前页面截图
            </p>
          </el-form-item>
      
        </el-card>
        <el-card class="box-card mg-t-20 mg-b-30" shadow="never">
          <div slot="header" class="clearfix">
            <span>项目商城分享设置</span>
            <p class="font-12 color-999">修改小程序页面分享设置</p>
          </div>
          <el-form-item label="分享名称" prop="item_name">
            <el-input v-model="basicInfoForm.item_name"></el-input>
          </el-form-item>
          <el-form-item label="分享描述" prop="item_desc">
            <el-input v-model="basicInfoForm.item_desc"></el-input>
          </el-form-item>
          <el-form-item label="分享封面" prop="poster">
            <el-upload
              ref="upload"
              class="upload-demo"
              action="#"
              :accept="'jpg,jpeg,png'"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              list-type="picture-card"
              :http-request="httpRequest4"
            >
            <img
                v-if="basicInfoForm.item_cover != ''"
                :src="basicInfoForm.item_cover"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
            </el-upload>
            <el-button  type="text"  @click="chooseBanner('4')">从素材库选择</el-button>
            <p class="font-12 color-999">
              *
              请按xxx*xxx尺寸上传png/jpg格式且小于2m的图片/若不上传则默认分享当前页面截图
            </p>
          </el-form-item>
        </el-card>
        <el-card class="box-card mg-t-20 mg-b-30" shadow="never">
          <div slot="header" class="clearfix">
            <span>我的细胞分享设置</span>
            <p class="font-12 color-999">修改小程序页面分享设置</p>
          </div>
          <el-form-item label="分享名称" prop="cell_name">
            <el-input v-model="basicInfoForm.cell_name"></el-input>
          </el-form-item>
          <el-form-item label="分享描述" prop="cell_desc">
            <el-input v-model="basicInfoForm.cell_desc"></el-input>
          </el-form-item>
          <el-form-item label="分享封面">
            <el-upload
              ref="upload"
              class="upload-demo"
              action="#"
              :accept="'jpg,jpeg,png'"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              list-type="picture-card"
              :http-request="httpRequest5"
            >
            <img
                v-if="basicInfoForm.cell_cover != ''"
                :src="basicInfoForm.cell_cover"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
            </el-upload>
            <el-button  type="text"  @click="chooseBanner('5')">从素材库选择</el-button>
            <p class="font-12 color-999">
              *
              请按xxx*xxx尺寸上传png/jpg格式且小于2m的图片/若不上传则默认分享当前页面截图
            </p>
          </el-form-item>
        </el-card>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog" />
    </el-dialog>
  </div>
</template>
<script>
import imageDialog from '../../../components/imageDialog.vue';
export default {
  name: "addMessageConfig",
  components: { imageDialog },
  data() {
    return {
      dialogVisible: false,
      imgType: "",
      type: "",
      basicInfoForm: {
        common_name: "",
        common_desc: "",
        common_cover: "",
        shop_name: "",
        shop_desc: "",
        shop_cover: "",
        share_name: "",
        share_desc: "",
        share_cover: "",
        item_name: "",
        item_desc: "",
        item_cover: "",
        cell_name: "",
        cell_desc: "",
        cell_cover: "",
      },
      rules: {
        common_name: { required: true, message: "请输入", trigger: "blur" },
        common_desc: { required: true, message: "请输入", trigger: "blur" },
        shop_name: { required: true, message: "请输入", trigger: "blur" },
        shop_desc: { required: true, message: "请输入", trigger: "blur" },
        share_name: { required: true, message: "请输入", trigger: "blur" },
        share_desc: { required: true, message: "请输入", trigger: "blur" },
        item_name: { required: true, message: "请输入", trigger: "blur" },
        item_desc: { required: true, message: "请输入", trigger: "blur" },
        cell_name: { required: true, message: "请输入", trigger: "blur" },
        cell_desc: { required: true, message: "请输入", trigger: "blur" },
      },
      formData: null
    };
  },
  mounted() {
    this.axios({
      method: "get", //请求方法
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": this.storage.get("__token"),
        Authorization: `Bearer ${this.storage.get("__token")}`,
      },
      url: ` https://time.api.boyalife.cn/v1/cms/ShareSetting/1`,
    })
      .then((res) => {
        let d = res.data.result;
        for (let i in d) {
          this.basicInfoForm[i] = d[i];
        }
      })
      .catch((err) => {
        this.$message.error(err.response.data.error.message);
      });
  },
  methods: {
    closeImageDialog () {
      this.dialogVisible = false
    },
     // 上传图片
     httpRequest() {
      this.$api.file
      .uploadFile(this.formData)
      .then((res) => {
        this.basicInfoForm.common_cover =  res[0].url;
      })
      .catch((err) => {
        this.$message.error(err);
      });
    },
    httpRequest2() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.shop_cover =  res[0].url;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    httpRequest3() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.share_cover =  res[0].url;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    httpRequest4() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.item_cover =  res[0].url;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    httpRequest5() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.cell_cover =  res[0].url;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    beforeAvatarUpload(file) {
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    // 选择封面
    confirmImageDialog(item) {
      if (this.type == '1') {
        this.basicInfoForm.common_cover = item[0].url;
      } else if (this.type == '2') {
        this.basicInfoForm.shop_cover = item[0].url;
      } else if (this.type == '3') {
        this.basicInfoForm.share_cover = item[0].url;
      } else if (this.type == '4') {
        this.basicInfoForm.item_cover = item[0].url;
      }else{
        this.basicInfoForm.cell_cover = item[0].url;
      }
      this.dialogVisible = false;
    },
    chooseBanner(n) {
      this.type = n;
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible = true;
    },
    // 选择封面
    selectImg(item) {
      if (this.type == 1) {
        this.basicInfoForm.common_cover = item.url;
      } else if (this.type == 2) {
        this.basicInfoForm.shop_cover = item.url;
      } else if (this.type == 3) {
        this.basicInfoForm.share_cover = item.url;
      } else if (this.type == 4) {
        this.basicInfoForm.item_cover = item.url;
      } else {
        this.basicInfoForm.cell_cover = item.url;
      }
      this.dialogVisible = false;
    },
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.basicInfoForm,
            url: ` https://time.api.boyalife.cn/v1/cms/ShareSetting/1`,
          })
            .then((res) => {
              this.$message.success("保存成功！");
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
>>> .el-form-item {
  width: 600px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar-uploader {
  position: relative;
}
.avatar-uploader .el-icon-delete {
  font-size: 20px;
  position: absolute;
  right: -40px;
  bottom: 0px;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
</style>
