<template>
  <div class="addInformation">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容</el-breadcrumb-item>
      <el-breadcrumb-item>资讯</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/informationList'">资讯列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{ pageType }}资讯</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="info boxLayout mg-t-20">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item label="封面图片" prop="cover_image">
          <span slot="label"><span style="color: red">*</span>封面图片</span>
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest"
          >
          <img
              v-if="basicInfoForm.cover_image.length != 0"
              :src="basicInfoForm.cover_image"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseBanner">从素材库选择</el-button>
          <p class="font-12 color-999">建议尺寸：800*800像素，</p>
          <!-- <el-button
            v-if="basicInfoForm.cover_image.length < 1"
            class="avatar-uploader"
            @click="chooseBanner"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-button>
          <div class="cover_image_list flex" v-if="basicInfoForm.cover_image.length > 0">
            <div class="img" v-for="(item,index) in basicInfoForm.cover_image" :key="index">
              <i class="el-icon-delete" @click.stop="deleteImg(index)"></i>
              <el-image :src="item" fit="cover"></el-image>
            </div>
          </div> -->
          <!-- <p class="font-12 color-999">建议尺寸：800*800像素，拖拽图片可以调整顺序，最多上传15张</p> -->
        </el-form-item>
        <el-form-item label="渠道头像">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest2"
          >
          <img
              v-if="basicInfoForm.source_image !== ''"
              :src="basicInfoForm.source_image"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseAvater">从素材库选择</el-button>
          <p class="font-12 color-999">非必填，建议尺寸：800*800像素</p>
        </el-form-item>
        <el-form-item label="来源渠道">
          <el-input v-model="basicInfoForm.source_text"></el-input>
        </el-form-item>
        <el-form-item label="资讯标题" prop="information_title">
          <el-input v-model="basicInfoForm.information_title"></el-input>
        </el-form-item>
        <el-form-item label="资讯分类" prop="information_category">
          <el-cascader
            v-model="value3"
            :show-all-levels="false"
            :emitPath="false"
            @change="getCheckedNodes"
            :options="options"
            :props="props"
            filterable
            collapse-tags
          ></el-cascader>
          <!-- <el-select v-model="basicInfoForm.information_category" placeholder="请选择" v-if="options.length>0">
            <el-option-group
              v-for="group in options"
              :key="group.uuid"
              :label="group.category_name"
              :value="group.uuid">
              <el-option
                v-for="item in group.children"
                :key="item.uuid"
                :label="item.category_name"
                :value="item.uuid">
              </el-option>
            </el-option-group>
          </el-select> -->
        </el-form-item>
        <el-form-item label="添加标签">
          <el-tag v-for="tag in tags" :key="tag.uuid" :type="tag.type">
            {{ tag.name }}
          </el-tag>
          <span
            style="color: cornflowerblue; cursor: pointer"
            @click="labelDialogVisible = true"
          >
            <span v-if="tags.length > 0">+ 编辑标签</span>
            <span v-else>+ 添加标签</span>
          </span>
        </el-form-item>
        <el-form-item label="投放人群">
          <el-select
            multiple
            collapse-tags
            v-model="basicInfoForm.group_uuid"
            placeholder="请选择"
          >
            <el-option
              v-for="(jtem, i) in userGroupList"
              :key="i"
              :label="jtem"
              :value="i"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="基础关注量">
          <el-input v-model="basicInfoForm.read_base_num"></el-input>
        </el-form-item>
        <el-form-item label="创作时间">
          <el-date-picker
            v-model="basicInfoForm.publish_time"
            type="datetime"
            placeholder="选择日期时间"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="权重">
          <el-input v-model="basicInfoForm.show_order" type="number"></el-input>
        </el-form-item>
        <el-form-item
          label="上架时间"
          :prop="basicInfoForm.shelf_type == 2 ? 'shelf_time' : ''"
        >
          <el-radio v-model="basicInfoForm.shelf_type" :label="1"
            >立即上架</el-radio
          >
          <el-radio v-model="basicInfoForm.shelf_type" :label="2"
            >定时上架</el-radio
          >
          <el-date-picker
            v-if="basicInfoForm.shelf_type === 2"
            v-model="basicInfoForm.shelf_time"
            type="datetime"
            placeholder="选择日期时间"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <div class="info boxLayout mg-t-20">
      <div class="flex space-between align-center">
        <h3 class="title-t">推广项目</h3>
        <el-button type="primary" size="medium" @click="dialogProjectVisible = true">添加项目</el-button>
      </div>
     <div v-if="projectList.length > 0">
      <el-table
        ref="multipleTable"
        :data="projectList"
        style="width: 100%"
        tooltip-effect="dark"
        class="mg-t-20"
        border
      >
        <el-table-column
          label="项目描述"
          prop="nickName"
        >
          <template slot-scope="scope">
            <div class="nickAndAvatar">
              <!-- <el-image
                v-if="scope.row.image && scope.row.image.length > 0 "
                :src="scope.row.image[0].image"
              ></el-image>
              <el-image
                v-if="scope.row.cover"
                :src="scope.row.cover"
              ></el-image> -->
              <div style="width: 200px">
                <el-tooltip :content="scope.row.name" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.name }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="projectList.splice(scope.$index, 1)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
     </div>
    </div>
    <div class="info boxLayout mg-t-20">
      <div class="flex space-between align-center">
        <h3 class="title-t">推广商品</h3>
        <el-button type="primary" size="medium" @click="selectCommdityDialogVisible = true">添加商品</el-button>
      </div>
     <div>
      <el-table
        ref="multipleTable"
        :data="commodityList"
        style="width: 100%"
        tooltip-effect="dark"
        class="mg-t-20"
        border
      >
        <el-table-column
          label="项目描述"
          prop="nickName"
        >
          <template slot-scope="scope">
            <div class="nickAndAvatar">
              <!-- <el-image
                v-if="typeof(scope.row.image) == 'object' && scope.row.image.length > 0"
                :src="scope.row.image[0].image"
              ></el-image>
              <el-image
                v-else
                :src="scope.row.image"
              ></el-image> -->
              <div style="width: 200px">
                <el-tooltip :content="scope.row.name" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.name }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="commodityList.splice(scope.$index, 1)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
     </div>
    </div>
    <div class="info boxLayout mg-t-20">
      <h3 class="title-t mg-b-30">资讯详情</h3>
      <wangEditorTool ref="editors" :content="basicInfoForm.desc" />
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submitForm">保存</el-button>
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog
      :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog"
      />
  
    </el-dialog>
    <el-dialog
      title="选择标签"
      :visible.sync="labelDialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <markLabel
        @closeDialog="closeDialog"
        :tags="tags"
        :type="'addCustomer'"
      />
    </el-dialog>

    <SelectProject
      :dialogVisible="dialogProjectVisible"
      @confirmProject="confirmProject"
      :user="tags"
      :project="projectList"
      @closeDialog="closeProjectDialog"
    />
    <selectCommdity
      :dialogVisible="selectCommdityDialogVisible"
      @confirmCommidity="confirmCommidity"
      :goods="commodityList"
      @closeDialog="closeCommodityDialog"
    />
  </div>
</template>
<script>
import markLabel from "../../../components/markLabel.vue";
import SelectProject from "../../../components/selectProject.vue";
import SelectCommdity from "../../../components/selectCommdity.vue";
import imageDialog from "../../../components/imageDialog.vue";

import wangEditorTool from "../../../components/wangEditorTool.vue";
export default {
  name: "addInformation",
  components: { imageDialog, markLabel, wangEditorTool, SelectProject ,SelectCommdity},
  data() {
    var validateImgList = (rule, value, callback) => {
      if (value.length < 1) {
        callback(new Error("请至少上传一张封面图"));
      } else {
        callback();
      }
    };
    return {
      pageType: "新增",
      dialogProjectVisible: false,
      selectCommdityDialogVisible: false,
      commodityList: [],
      projectList: [],
      imgType: "",
      labelDialogVisible: false,
      tags: [],
      active: 0,
      dialogVisible: false,
      uuid: "",
      options: [],
      userGroupList: [],
      props: {
        label: "category_name",
        value: "uuid",
        children: "children",
        checkStrictly: true,
      },
      value2: "",
      value3: "",
      basicInfoForm: {
        information_title: "",
        read_base_num: "",
        source_text: "",
        cover_image: [],
        source_image: "",
        shelf_type: 1,
        information_category: "",
        shelf_time: "",
        show_order: "",
        label_uuid: [],
        desc: "",
        publish_time: "",
        goods: [],
        item: []
      },
      rules: {
        cover_image: [{ validator: validateImgList, trigger: "blur" }],
        information_title: [
          { required: true, message: "请输入标题", trigger: "blur" },
        ],
        information_category: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
        shelf_time: [
          { required: true, message: "请选择时间", trigger: "change" },
        ],
        desc: [{ required: true, message: "请填写资讯详情", trigger: "blur" }],
      },
      pickerOptions: {
        disabledDate: (time) => {
          // 禁用今天之前的日期选择
          return time.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000;
        },
      },
      formData: ''
    };
  },
  created() {
    this.getInformationCategoryList();
    this.getUserGroup();
   
  },
  mounted () {
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      let info = this.$route.query
      // console.log(this.$route.query)
      
      if (info.uuid) {
        this.getInfo()
      } else {
      this.basicInfoForm.information_title = info.title
      this.basicInfoForm.cover_image = []
      this.basicInfoForm.cover_image.push(info.cover_image)
      this.basicInfoForm.information_title = info.title
      this.$refs.editors.html = info.content
      console.log( info.content)
      console.log(this.$refs.editors.html)

      this.basicInfoForm.desc = this.$refs.editors.html
      }
    } else {
      this.pageType = "新增";
    }
  },
  methods: {
    closeImageDialog () {
      this.dialogVisible = false
    },
     // 上传图片
     httpRequest() {
      this.basicInfoForm.cover_image = []
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.cover_image.push(res[0].url);
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    httpRequest2() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.source_image =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    confirmProject (v) {
      console.log(v)
      this.projectList = v
      this.dialogProjectVisible = false
    },  
    confirmCommidity (v) {
      this.commodityList = v
      this.selectCommdityDialogVisible = false
    },
    closeProjectDialog (v) {
      this.dialogProjectVisible = v
    },
    closeCommodityDialog (v) {
      this.selectCommdityDialogVisible = v
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Information/${this.$route.query.uuid}`,
      }).then((res) => {
        console.log(res);
        let params = res.data.result;
        for (let i in params) {
          this.basicInfoForm[i] = params[i];
        }
        this.value3 = this.basicInfoForm.information_category;
        this.tags = this.basicInfoForm.label;
        this.basicInfoForm.group_uuid = this.basicInfoForm.group.map(
          (item) => item.uuid
        );
        this.basicInfoForm.shelf_type = Number(this.basicInfoForm.shelf_type);
        if (this.basicInfoForm.shelf_type == 1) {
          this.basicInfoForm.shelf_time = "";
        }
        if (this.basicInfoForm.publish_time.slice(0, 1) == 0) {
          this.basicInfoForm.publish_time = "";
        }
        this.projectList = params.items
        this.commodityList = params.goods
        console.log(this.basicInfoForm.publish_time);
        this.basicInfoForm.cover_image =
          this.basicInfoForm.cover_image.split(",");
        console.log(this.basicInfoForm.cover_image);
      });
    },
    getCheckedNodes(v) {
      if (v.length > 1) {
        this.basicInfoForm.information_category = v[1];
      } else {
        this.basicInfoForm.information_category = v[0];
      }
    },
    closeDialog(v) {
      this.tags = JSON.parse(JSON.stringify(v));
      this.labelDialogVisible = false;
    },
    // 资讯分类
    // 资讯分类
    getInformationCategoryList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/InformationCategorySelect`,
      })
        .then((res) => {
          this.options = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getUserGroup() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserGroupSelect`,
      })
        .then((res) => {
          this.userGroupList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 选择图片
    chooseBanner() {
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible = true;
    },
    // 选择头像
    chooseAvater() {
      this.imgType = "chooseCover_image";
      this.dialogVisible = true;
    },

    // 选择多个封面
    confirmImageDialog(item) {
      if (this.imgType === "chooseThumbnail_image") {
        this.basicInfoForm.cover_image = [];
        this.basicInfoForm.cover_image.push(item[0].url);
      } else if (this.imgType === "chooseCover_image") {
        this.basicInfoForm.source_image = item[0].url;
      }
      this.dialogVisible = false;
    },
    // 富文本插入图片
    insertImg(url) {
      this.dialogVisible = false;
      // this.editor.cmd.do('insertHTML', `<h1>就哈哈哈哈哈</h1>`)
      this.editor.focus();
      this.editor.dangerouslyInsertHtml(`<img src=${url} />`);
    },
    // 删除图片
    deleteImg(index) {
      this.basicInfoForm.cover_image.splice(index, 1);
    },
    submitForm() {
      
      if (this.tags.length > 0) {
        this.basicInfoForm.label_uuid = this.tags.map((item) => {
          return item.uuid;
        });
      }
      if (this.projectList.length > 0) {
        this.basicInfoForm.item = this.projectList.map(item => item.uuid)
      } else {
        this.basicInfoForm.item = []
      }
      if (this.commodityList.length > 0) {
        this.basicInfoForm.goods = this.commodityList.map(item => item.uuid)
      } else {
        this.basicInfoForm.goods = []
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.basicInfoForm.desc = this.$refs.editors.html;
          if (
            this.basicInfoForm.desc == "<p><br></p>" ||
            this.basicInfoForm.desc == ""
          ) {
            this.$message.warning("请填写资讯详情");
            return false;
          }
          if (Object.keys(this.$route.query).length == 0 || !this.$route.query.uuid) {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: `https://time.api.boyalife.cn/v1/cms/Information`,
            }).then((res) => {
              this.$message.success("保存成功！");
              this.$router.go(-1);
            });
          } else {
            delete this.basicInfoForm.create_time
            delete this.basicInfoForm.update_time

            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: `https://time.api.boyalife.cn/v1/cms/Information/${this.$route.query.uuid}`,
            }).then((res) => {
              this.$message.success("保存成功！");
              this.$router.go(-1);
            });
          }
            
        } else {
          return false;
        }
      });
      {
      }
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.addInformation {
  margin-bottom: 80px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 16px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar-uploader {
  position: relative;
}
.avatar-uploader .el-icon-delete {
  font-size: 20px;
  position: absolute;
  right: -40px;
  bottom: 0px;
}
.cover_image_list {
  flex-wrap: wrap;
  margin-top: 20px;
}
.cover_image_list .img {
  position: relative;
  border: 1px solid #dcdcdc;
  width: 220px;
  height: 220px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.cover_image_list .img .el-icon-delete {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  z-index: 2;
  cursor: pointer;
}
.cover_image_list .img .el-image {
  width: 100%;
  height: 100%;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
>>> .el-form .el-input {
  width: 400px;
}
.insertImg {
  width: 52px;
  height: 40px;
  position: absolute;
  right: 159px;
  top: 0;
  z-index: 2;
  cursor: pointer;
}
.insertVideo {
  width: 52px;
  height: 40px;
  position: absolute;
  right: 107px;
  top: 0;
  z-index: 2;
  cursor: pointer;
}
.el-tag {
  margin-right: 10px;
  margin-bottom: 5px;
}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
.upload-demo{
  border: 1px dashed #409eff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}
::v-deep .v-modal {
  display: none!important;
}
</style>
