<template>
  <!-- 添加客户 -->
  <div class="addMemberBenefits">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>客户</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/customerList' }"
        >全部客户</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ pageType }}会员权益</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicInfo">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="权益名称" prop="name">
          <el-input v-model="basicInfoForm.name"></el-input>
        </el-form-item>
        <el-form-item label="权益描述" prop="tips">
          <el-input v-model="basicInfoForm.tips"></el-input>
          <p class="font-12 color-999">权益描述只用于后台展示</p>
        </el-form-item>
        <el-form-item label="权益图标">
          <span slot="label"><i style="color: red">*</i> 权益图标</span>
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest"
          >
          <img
              v-if="basicInfoForm.image !== ''"
              :src="basicInfoForm.image"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseBanner">从素材库选择</el-button>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-switch
            v-model="basicInfoForm.status"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="关联权益(单选)">
          <span slot="label"><i style="color: red">*</i> 关联权益(单选)</span>
          <el-radio-group
            v-model="basicInfoForm.option_uuid"
            @change="getRadioValue"
          >
            <el-radio :label="1">包邮</el-radio>
            <el-radio :label="2">虚拟权益</el-radio><br />
            <div class="flex">
              <div class="flex align-center mg-t-10">
                <el-radio :label="3">商城返积分</el-radio>
                <el-input v-model="value3">
                  <template slot="prepend">%</template>
                </el-input>
              </div>
              <div class="flex align-center mg-t-10">
                <el-radio :label="4">干细胞存储返积分</el-radio>
                <el-input v-model="value4">
                  <template slot="prepend">%</template>
                </el-input>
              </div>
              <div class="flex align-center mg-t-10">
                <el-radio :label="5">干细胞应用返积分</el-radio>
                <el-input v-model="value5">
                  <template slot="prepend">%</template>
                </el-input>
              </div>
              <div class="flex align-center mg-t-10">
                <el-radio :label="6">商城折扣</el-radio>
                <el-input v-model="value6">
                  <template slot="prepend">折</template>
                </el-input>
              </div>
              <div class="flex align-center mg-t-10">
                <el-radio :label="7">干细胞存储折扣</el-radio>
                <el-input v-model="value7">
                  <template slot="prepend">折</template>
                </el-input>
              </div>
              <div class="flex align-center mg-t-10">
                <el-radio :label="8">干细胞应用折扣</el-radio>
                <el-input v-model="value8">
                  <template slot="prepend">折</template>
                </el-input>
              </div>

              <div class="flex align-center mg-t-10">
                <el-radio :label="12">优惠券（多选）</el-radio>
                <el-button
                  type="text"
                  v-if="basicInfoForm.option_uuid == 12"
                  @click="selectCouponDialogVisible = true"
                  >+ 选择优惠券</el-button
                >
              </div>
            </div>
          </el-radio-group>

          <!-- <el-radio-group v-model="basicInfoForm.option_uuid" @change="getRadioValue">
            <el-radio :label="1">包邮</el-radio>
            <el-radio :label="2">虚拟权益</el-radio>
            <el-radio :label="3">返积分</el-radio>
            <el-radio :label="4">打折扣</el-radio>
            <el-radio :label="12">优惠券（多选）</el-radio>

            <div class="flex" >
              <div class="flex align-center mg-t-10" v-if="basicInfoForm.option_uuid == 3">
                <span>商城返积分</span>
                <el-input v-model="value3">
                  <template slot="prepend">%</template>
                </el-input>
              </div>
              <div class="flex align-center mg-t-10" v-if="basicInfoForm.option_uuid == 3">
                <span>干细胞存储返积分</span>
                <el-input v-model="value4">
                  <template slot="prepend">%</template>
                </el-input>
              </div>

              <div class="flex align-center mg-t-10" v-if="basicInfoForm.option_uuid == 3">
                <span>干细胞应用返积分</span>
                <el-input v-model="value5">
                  <template slot="prepend">%</template>
                </el-input>
              </div>

              <div class="flex align-center mg-t-10" v-if="basicInfoForm.option_uuid == 4">
                <span>商城折扣</span>
                <el-input v-model="value6">
                  <template slot="prepend">折</template>
                </el-input>
              </div>
              <div class="flex align-center mg-t-10" v-if="basicInfoForm.option_uuid == 4">
                <span :label="7">干细胞存储折扣</span>
                <el-input v-model="value7">
                  <template slot="prepend">折</template>
                </el-input>
              </div>
              <div class="flex align-center mg-t-10" v-if="basicInfoForm.option_uuid == 4">
                <span :label="8">干细胞应用折扣</span>
                <el-input v-model="value8">
                  <template slot="prepend">折</template>
                </el-input>
              </div>

              <div class="flex align-center mg-t-10">
                <el-button type="text" v-if="basicInfoForm.option_uuid == 12" @click="selectCouponDialogVisible = true">+ 选择优惠券</el-button>
              </div>
            </div>
            -->
          <el-table :data="couponList" border v-if="couponList.length > 0">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column
              property="name"
              label="优惠券名称"
              width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              property="status"
              label="状态"
              width="200"
              align="center"
            >
              <template slot-scope="scope">
                <span style="color: #2ed477" v-if="scope.row.status == 1"
                  >可领用</span
                >
                <span v-else>禁用</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="couponList.splice(scope.$index, 1)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="权益说明" prop="phone">
          <wangEditorTool ref="editors" :content="basicInfoForm.desc" />
        </el-form-item>
      </el-form>
    </div>

    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog" />

    </el-dialog>
    <selectCoupon
      :coupons="couponList"
      :releaseType="'6'"
      :dialogVisible="selectCouponDialogVisible"
      @confirmCoupon="confirmCoupon"
      @closeDialog="closeDialog"
    />
  </div>
</template>
<script>
import wangEditorTool from "../../../components/wangEditorTool.vue";
import MaterialLibrary from "../../../components/materialLibraryDialog/index";
import selectCoupon from "../../../components/selectCoupon.vue";
import imageDialog from "../../../components/imageDialog.vue";

export default {
  name: "addMemberBenefits",
  components: {
    wangEditorTool,
    imageDialog,
    selectCoupon,
  },
  data() {
    return {
      pageType: "",
      imgType: "",
      couponList: [],
      isShow: false,
      dialogVisible: false,
      selectCouponDialogVisible: false,
      dialogTableVisible: false,
      imageUrl: "",
      radio: "",
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      value6: "",
      value7: "",
      value8: "",
      basicInfoForm: {
        name: "",
        image: "",
        tips: "",
        desc: "",
        option_uuid: "",
        value: "",
        status: 1,
        coupon: [],
      },
      input1: "",
      rules: {
        name: { required: true, message: "请输入", trigger: "blur" },
        type: { required: true, message: "请输入", trigger: "change" },
      },
    };
  },
  created() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.getInfo();

      this.pageType = "编辑";
    } else {
      this.pageType = "新增";
    }
  },
  methods: {
    closeImageDialog () {
      this.dialogVisible = false
    },
     // 上传图片
     httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.image =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserRight/${this.$route.query.uuid}`,
      })
        .then((res) => {
          let params = res.data.result;
          for (let i in params) {
            this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
          }
          this.radio = Number(this.basicInfoForm.option_uuid);
          this["value" + this.radio] = this.basicInfoForm.value
            ? this.basicInfoForm.value
            : "";
          this.basicInfoForm.option_uuid = Number(
            this.basicInfoForm.option_uuid
          );
          this.basicInfoForm.status = Number(this.basicInfoForm.status);
          this.couponList = this.basicInfoForm.coupon;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    confirmCoupon(row) {
      this.couponList = row;
      this.selectCouponDialogVisible = false;
      this.basicInfoForm.coupon = row.map((item) => item.uuid);
    },
    closeDialog() {
      this.selectCouponDialogVisible = false;
    },
    getRadioValue(v) {
      console.log(v);
      // if (v !== 1 && v !==2 && v ) {
      this.radio = v;
      // } else {
      //   this.radio = ''
      // }
    },
    // 选择banner
    chooseBanner() {
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible = true;
    },
    // 选择封面
    confirmImageDialog(item) {
      this.basicInfoForm.image = item[0].url;
      this.dialogVisible = false;
    },
    submit(form) {
      this.basicInfoForm.desc = this.$refs.editors.html;
      this.basicInfoForm.value = this["value" + this.radio];
      delete this.basicInfoForm.option;
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.basicInfoForm.image == "") {
            this.$message.error("请上传权益图标");
            return false;
          }
          if (this.basicInfoForm.option_uuid == "") {
            this.$message.warning("请选择权益");
            return false;
          }
          if (
            this.basicInfoForm.option_uuid == 12 &&
            this.basicInfoForm.coupon.length == 0
          ) {
            this.$message.warning("请选择优惠券");
            return false;
          }
          if (Object.keys(this.$route.query).length !== 0) {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/UserRight/${this.$route.query.uuid}`,
            })
              .then((res) => {
                this.$message.success("编辑成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/UserRight`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.addMemberBenefits {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-radio-group {
  width: 100%;
}
.el-radio-group span {
  display: inline-block;
  margin-right: 10px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form .el-input-group > .el-input__inner {
  width: 60px;
}
.el-form .el-input-group {
  width: 200px;
}
.el-form .flex {
  flex-wrap: wrap;
}
.el-form .flex .flex {
  width: 33%;
  flex-wrap: nowrap;
}

>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
.upload-demo{
  border: 1px dashed #409eff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}
</style>
