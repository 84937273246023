import { render, staticRenderFns } from "./addVirtualGoods.vue?vue&type=template&id=290ad598&scoped=true"
import script from "./addVirtualGoods.vue?vue&type=script&lang=js"
export * from "./addVirtualGoods.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./addVirtualGoods.vue?vue&type=style&index=1&id=290ad598&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "290ad598",
  null
  
)

export default component.exports