<template>
  <!-- 工作台 -->
  <div style="background: #f0f2f5">
    <div class="shopData" v-if="dataInfo !== null">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="grid-content bg-blue">
            <div class="number mg-b-20">
              {{ dataInfo.order_paid_today || 0 }}
            </div>
            <div class="text">
              <p class="t font-14">今日支付订单数</p>
              <p class="font-12">
                昨日全天 {{ dataInfo.order_paid_yesterday || 0 }}
              </p>
              <p class="font-12">
                较昨日
                <span v-if="dataInfo.order_paid_updown_yesterday == 1">+ </span>
                <span v-else>- </span>

                {{ dataInfo.order_paid_compare_yesterday }}%
                <i
                  class="el-icon-top"
                  v-if="dataInfo.order_paid_updown_yesterday == 1"
                ></i>
                <i v-else class="el-icon-bottom"></i>
              </p>
            </div>
            <span class="icon">
              <i class="el-icon-s-order color-blue"></i>
            </span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-pink">
            <div class="number mg-b-20">{{ dataInfo.payout_today || 0 }}</div>
            <div class="text">
              <p class="t font-14">今日支付人数</p>
              <p class="font-12">
                昨日全天 {{ dataInfo.payout_yesterday || 0 }}
              </p>
              <p class="font-12">
                较昨日
                <span v-if="dataInfo.payout_updown_yesterday == 1">+ </span>
                <span v-else>- </span>
                {{ dataInfo.payout_compare_yesterday }}%
                <i
                  class="el-icon-top"
                  v-if="dataInfo.payout_updown_yesterday == 1"
                ></i>
                <i v-else class="el-icon-bottom"></i>
              </p>
            </div>
            <span class="icon">
              <i class="el-icon-s-custom color-pink"></i>
            </span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-green">
            <div class="number mg-b-20">
              {{ dataInfo.money_paid_today || 0 }}
            </div>
            <div class="text">
              <p class="t font-14">今日支付金额（元）</p>
              <p class="font-12">
                昨日全天 {{ dataInfo.money_paid_yesterday || 0 }}
              </p>
              <p class="font-12">
                较昨日
                <span v-if="dataInfo.money_paid_updown_yesterday == 1">+ </span>
                <span v-else>- </span>
                {{ dataInfo.money_paid_compare_yesterday }}%
                <i
                  class="el-icon-top"
                  v-if="dataInfo.money_paid_updown_yesterday == 1"
                ></i>
                <i v-else class="el-icon-bottom"></i>
              </p>
            </div>
            <span class="icon">
              <i class="el-icon-s-finance color-green"></i>
            </span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-yellow">
            <div class="number mg-b-20">{{ dataInfo.visitors_today || 0 }}</div>
            <div class="text">
              <p class="t font-14">今日访客量</p>
              <p class="font-12">
                昨日全天 {{ dataInfo.visitors_yesterday || 0 }}
              </p>
              <p class="font-12">
                较昨日
                <span v-if="dataInfo.visitors_updown_yesterday == 1">+ </span>
                <span v-else>- </span>
                {{ dataInfo.visitors_compare_yesterday }}%
                <i
                  class="el-icon-top"
                  v-if="dataInfo.visitors_updown_yesterday == 1"
                ></i>
                <i v-else class="el-icon-bottom"></i>
              </p>
            </div>
            <span class="icon">
              <i class="el-icon-s-custom color-yellow"></i>
            </span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-blue">
            <div class="number mg-b-20">
              {{ dataInfo.customer_price_today || 0 }}
            </div>
            <div class="text">
              <p class="t font-14">今日客单价</p>
              <p class="font-12">
                昨日全天 {{ dataInfo.customer_price_yesterday || 0 }}
              </p>
              <p class="font-12">
                较昨日
                <span v-if="dataInfo.customer_price_updown_yesterday == 1"
                  >+
                </span>
                <span v-else>- </span>
                {{ dataInfo.customer_price_compare_yesterday }}%
                <i
                  class="el-icon-top"
                  v-if="dataInfo.customer_price_updown_yesterday == 1"
                ></i>
                <i v-else class="el-icon-bottom"></i>
              </p>
            </div>
            <span class="icon">
              <i class="el-icon-s-order color-blue"></i>
            </span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-pink">
            <div class="number mg-b-20">
              {{ dataInfo.payment_conversion_today || 0 }}
            </div>
            <div class="text">
              <p class="t font-14">今日访问-支付转化率</p>
              <p class="font-12">
                昨日全天 {{ dataInfo.payment_conversion_yesterday || 0 }}
              </p>
              <p class="font-12">
                较昨日
                <span v-if="dataInfo.payment_conversion_updown_yesterday == 1"
                  >+
                </span>
                <span v-else>- </span>
                {{ dataInfo.payment_conversion_compare_yesterday }}%
                <i
                  class="el-icon-top"
                  v-if="dataInfo.payment_conversion_updown_yesterday == 1"
                ></i>
                <i v-else class="el-icon-bottom"></i>
              </p>
            </div>
            <span class="icon">
              <i class="el-icon-s-custom color-pink"></i>
            </span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-green">
            <div class="number mg-b-20">
              {{ dataInfo.member_transaction_today || 0 }}
            </div>
            <div class="text">
              <p class="t font-14">会员成交金额（元）</p>
              <p class="font-12">
                昨日全天 {{ dataInfo.member_transaction_yesterday || 0 }}
              </p>
              <p class="font-12">
                较昨日
                <span v-if="dataInfo.member_transaction_updown_yesterday == 1"
                  >+
                </span>
                <span v-else>- </span>
                {{ dataInfo.member_transaction_compare_yesterday }}%
                <i
                  class="el-icon-top"
                  v-if="dataInfo.member_transaction_updown_yesterday == 1"
                ></i>
                <i v-else class="el-icon-bottom"></i>
              </p>
            </div>
            <span class="icon">
              <i class="el-icon-s-finance color-green"></i>
            </span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-yellow">
            <div class="number mg-b-20">{{ dataInfo.views_today || 0 }}</div>
            <div class="text">
              <p class="t font-14">今日浏览量</p>
              <p class="font-12">
                昨日全天 {{ dataInfo.views_yesterday || 0 }}
              </p>
              <p class="font-12">
                较昨日
                <span v-if="dataInfo.views_updown_yesterday == 1">+ </span>
                <span v-else>- </span>
                {{ dataInfo.visitors_compare_yesterday }}%
                <i
                  class="el-icon-top"
                  v-if="dataInfo.views_updown_yesterday == 1"
                ></i>
                <i v-else class="el-icon-bottom"></i>
              </p>
            </div>
            <span class="icon">
              <i class="el-icon-s-custom color-yellow"></i>
            </span>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 商城订单 项目订单 快捷操作 会员数据概况 -->
    <div class="topSquare" v-if="dataInfo !== null">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="grid-content left">
            <div class="shopOrder bg-white">
              <h3 class="title-t">商城订单</h3>
              <el-row :gutter="20">
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <router-link
                      :to="{ path: '/commodityOrder?search_status=1' }"
                    >
                      <p class="color-666 text-center">待付款订单</p>
                      <p class="font-26 text-center">
                        {{ dataInfo.to_be_paid || 0 }}
                      </p>
                    </router-link>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <router-link
                      :to="{ path: '/commodityOrder?search_status=2' }"
                    >
                      <p class="color-999 text-center">待发货订单</p>
                      <p class="font-26 text-center">
                        {{ dataInfo.to_be_sent || 0 }}
                      </p>
                    </router-link>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <router-link
                      :to="{ path: '/commodityOrder?search_status=4' }"
                    >
                      <p class="color-999 text-center">待处理售后订单</p>
                      <p class="font-26 text-center">
                        {{ dataInfo.to_be_processed || 0 }}
                      </p>
                    </router-link>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <router-link
                      :to="{ path: '/withdrawalReview?search_status=1' }"
                    >
                      <p class="color-999 text-center">提现申请</p>
                      <p class="font-26 text-center">
                        {{ dataInfo.withdrawal_application || 0 }}
                      </p>
                    </router-link>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="shopOrder mg-t-20 bg-white">
              <h3 class="title-t">项目订单</h3>
              <el-row :gutter="20">
                <el-col :span="6">
                  <div class="grid-content">
                    <router-link
                      :to="{ path: '/projectOrder?search_status=1' }"
                    >
                      <p class="color-999 text-center">待处理预约订单</p>
                      <p class="font-26 text-center">
                        {{ dataInfo.appointment }}
                      </p>
                    </router-link>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <router-link :to="{ path: '/cellList?search_status=2' }">
                      <p class="color-999 text-center">待签约订单</p>
                      <p class="font-26 text-center">{{ dataInfo.sign }}</p>
                    </router-link>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <router-link :to="{ path: '/cellList?search_status=4' }">
                      <p class="color-999 text-center">待缴费订单</p>
                      <p class="font-26 text-center">{{ dataInfo.fees }}</p>
                    </router-link>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <router-link
                      :to="{ path: '/invoicingList?search_status=1' }"
                    >
                      <p class="color-999 text-center">开票申请</p>
                      <p class="font-26 text-center">
                        {{ dataInfo.billing_application }}
                      </p>
                    </router-link>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="shopOrder mg-t-20 bg-white">
              <h3 class="title-t">快捷操作</h3>
              <el-row :gutter="20">
                <el-col :span="5">
                  <div class="grid-content">
                    <router-link to="/projectList">
                      <p class="text-center icon">
                        <i class="el-icon-folder-add"></i>
                      </p>
                      <p class="color-666 text-center">项目管理</p>
                    </router-link>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="grid-content bg-purple">
                    <router-link to="/physicalCommodityList">
                      <p class="text-center icon">
                        <i class="el-icon-folder-opened"></i>
                      </p>
                      <p class="color-666 text-center">商品库</p>
                    </router-link>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="grid-content bg-purple">
                    <router-link to="/customerList">
                      <p class="text-center icon">
                        <i class="el-icon-s-custom"></i>
                      </p>
                      <p class="color-666 text-center">客户管理</p>
                    </router-link>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="grid-content bg-purple">
                    <router-link to="/informationList">
                      <p class="text-center icon">
                        <i class="el-icon-s-promotion"></i>
                      </p>
                      <p class="color-666 text-center">资讯内容管理</p>
                    </router-link>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="grid-content bg-purple">
                    <router-link to="/commodityOrder">
                      <p class="text-center icon">
                        <i class="el-icon-s-order"></i>
                      </p>
                      <p class="color-666 text-center">全部订单</p>
                    </router-link>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content right bg-white" style="padding: 20px">
            <h3 class="title-t">会员数据概况</h3>
            <div class="labelLineData mg-t-20">
              <p class="color-999">
                新会员增加人数：<span class="color-333 font-30">{{
                  dataInfo.new_member
                }}</span>
                <i class="font-12"
                  >较昨日
                  <span v-if="dataInfo.new_member_updown_yesterday == 1"
                    >+</span
                  >
                  <span v-else>-</span>
                  {{ dataInfo.new_member_compare_yesterday }}%</i
                >
              </p>
              <p class="color-999">
                支付会员增加人数：<span class="color-333 font-30">{{
                  dataInfo.paid_member
                }}</span>
                <i class="font-12"
                  >较昨日
                  <span v-if="dataInfo.paid_member_updown_yesterday == 1"
                    >+</span
                  >
                  <span v-else>-</span>
                  {{ dataInfo.paid_member_compare_yesterday }}%</i
                >
              </p>
            </div>
            <div class="myChartBox" style="width: 100%; height: 400px">
              <div class="totalNum color-333 font-30 text-center font-bold">
                {{ dataInfo.total_menber }}
                <p class="color-999 font-12">支付人数</p>
              </div>
              <div
                id="myChart"
                ref="myChart"
                style="width: 100%; height: 100%"
              ></div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="bottomSquare mg-t-20" v-if="dataInfo !== null">
      <h3 class="title-t">交易概况</h3>
      <div
        id="transactionOverview"
        ref="myChart"
        style="width: 100%; height: 400px"
      ></div>
    </div>
  </div>
</template>
<script>
//引入echarts文件
import * as echarts from "echarts";

export default {
  name: "stage",
  data() {
    return {
      dataInfo: null,
      dates: [],
      paid_money: [],
      payer: [],
      payment: [],
      views: [],
      customer: [],
    };
  },
  created() {
    this.getData();
    console.log(this.$route);
  },
  mounted() {
    setTimeout(() => {
      this.init();
    }, 500);
  },
  methods: {
    init() {
      let that = this;
      // 会员数据概况
      let dom = document.getElementById("myChart");
      let myChart = echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false,
      });
      let option;
      let datas = [
        { name: `老会员`, value: this.dataInfo.super_menber },
        { name: `新会员`, value: this.dataInfo.normal_menber },
      ];
      option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "5%",
          left: "center",
        },
        series: [
          {
            type: "pie",
            radius: [70, 100],
            top: top + "%",
            height: "100%",
            left: "center",
            width: 600,
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1,
            },

            label: {
              alignTo: "edge",
              formatter: "{b}: {@2012} ({d}%) \n\n",
              minMargin: 0,
              edgeDistance: 20,
              lineHeight: 10,
              textStyle: {
                fontSize: 14,
              },
            },
            labelLine: {
              length: 15,
              length2: 0,
              maxSurfaceAngle: 120,
            },
            labelLayout: function (params) {
              const isLeft = params.labelRect.x < myChart.getWidth() / 2;
              const points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points,
              };
            },
            data: datas,
          },
        ],
      };
      if (option && typeof option === "object") {
        myChart.setOption(option);
      }
      window.addEventListener("resize", myChart.resize);
      // 交易概况
      let chartDom = document.getElementById("transactionOverview");
      let transactionOverview = echarts.init(chartDom);
      let transactionOverviewOption = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["支付金额", "支付人数", "支付订单数", "访问量", "客单价"],
          bottom: "5%",
          selectedMode: "single",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "15%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: [
            "2023-03-01",
            "2023-03-01",
            "2023-03-01",
            "2023-03-01",
            "2023-03-01",
            "2023-03-01",
            "2023-03-01",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "支付金额",
            type: "line",
            data: that.paid_money,
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16,
              },
            },
            type: "line",
          },
          {
            name: "支付人数",
            type: "line",
            data: that.payer,
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16,
              },
            },
          },
          {
            name: "支付订单数",
            type: "line",
            data: that.views,
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16,
              },
            },
          },
          {
            name: "访问量",
            type: "line",
            data: that.customer,
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16,
              },
            },
          },
          {
            name: "客单价",
            type: "line",
            data: that.payment,
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16,
              },
            },
          },
        ],
      };

      transactionOverviewOption &&
        transactionOverview.setOption(transactionOverviewOption);
    },
    getData() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Workbench`,
      })
        .then((res) => {
          this.dataInfo = res.data.result;
          this.paid_money = [];
          this.payer = [];
          this.payment = [];
          this.views = [];
          this.customer = [];
          this.dataInfo.table_data.paid_money.map((item) => {
            this.paid_money.push(item.number);
          });
          this.dataInfo.table_data.payer.map((item) => {
            this.payer.push(item.number);
          });
          this.dataInfo.table_data.payment.map((item) => {
            this.payment.push(item.number);
          });
          this.dataInfo.table_data.views.map((item) => {
            this.views.push(item.number);
          });
          this.dataInfo.table_data.customer.map((item) => {
            this.customer.push(item.number);
          });
          console.log(this.customer);
        })
        .catch((err) => {
          console.log(err);
          // this.$message.error(err.response.data.error.message)
        });
    },
  },
};
</script>
<style>
.shopData .number {
  color: #fff;
  font-weight: bold;
  font-size: 36px;
}
.shopData p {
  color: #fff;
}
.shopData .t {
  font-weight: bold;
}
.shopData .icon {
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shopData .icon i {
  font-size: 30px;
}

.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
.bg-blue {
  background: #2561ef;
}
.color-blue {
  color: #2561ef;
}
.bg-pink {
  background: #ff6262;
}
.color-pink {
  color: #ff6262;
}
.bg-green {
  background: #28d094;
}
.color-green {
  color: #28d094;
}
.bg-yellow {
  background: #ffc71c;
}
.color-yellow {
  color: #ffc71c;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
}
.topSquare > .el-row {
  height: 536px;
}
.topSquare .el-col-12 {
  height: 100%;
}
.topSquare > .el-row > .el-col > .grid-content {
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  margin-bottom: 0;
}
.shopOrder {
  padding: 23px 20px;
  border-radius: 8px;
}
.shopOrder .grid-content {
  padding: 0;
  padding-top: 20px;
  margin-bottom: 0;
}
.el-col-5 {
  width: 20% !important;
}
.shopOrder .grid-content .icon {
  width: 50px;
  height: 50px;
  background: #e6f1ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 15px;
}
.shopOrder .grid-content i {
  color: #2561ef;
  font-size: 24px;
}
.myChartBox {
  position: relative;
}
.myChartBox .totalNum {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.labelLineData {
  display: flex;
  justify-content: space-between;
}
.labelLineData p {
  position: relative;
}
.labelLineData p i {
  position: absolute;
  right: 0;
  bottom: -20px;
  color: #63a103;
}
.bottomSquare {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.bottomSquare h3 {
  margin-bottom: 30px;
}
</style>
