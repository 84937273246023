<template>
  <!-- 添加客户 -->
  <div class="invitateIsPolite">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item>邀请有礼</el-breadcrumb-item>
      <el-breadcrumb-item>邀请设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicInfo">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form ref="form" :model="basicInfoForm" label-width="150px">
        <el-form-item label="" prop="name" style="margin-left: -84px">
          <div class="flex align-center mg-t-10">
            <p>
              每邀请一名新用户可获得<el-input
                v-model="basicInfoForm.per_life"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
            </p>
            <p>
              每日最多可获得<el-input
                v-model="basicInfoForm.max_life"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">次</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <h2 class="color-333 mg-b-20" style="margin-left: 66px">
          阶段奖励设置
        </h2>
        <el-form-item label="阶段奖励一" prop="stage_number1">
          <div class="flex align-center">
            <p>
              每累积邀请<el-input
                v-model="basicInfoForm.stage_number1"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">人</template></el-input
              >
            </p>
            <p>
              可额外获得<el-input
                v-model="basicInfoForm.stage_life1"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="阶段奖励二" prop="stage_number2">
          <div class="flex align-center">
            <p>
              每累积邀请<el-input
                v-model="basicInfoForm.stage_number2"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">人</template></el-input
              >
            </p>
            <p>
              可额外获得<el-input
                v-model="basicInfoForm.stage_life2"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="阶段奖励三" prop="stage_number3">
          <div class="flex align-center">
            <p>
              每累积邀请<el-input
                v-model="basicInfoForm.stage_number3"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">人</template></el-input
              >
            </p>
            <p>
              可额外获得<el-input
                v-model="basicInfoForm.stage_life3"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="阶段奖励四" prop="stage_number4">
          <div class="flex align-center">
            <p>
              每累积邀请<el-input
                v-model="basicInfoForm.stage_number4"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">人</template></el-input
              >
            </p>
            <p>
              可额外获得<el-input
                v-model="basicInfoForm.stage_life4"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="阶段奖励五" prop="stage_number5">
          <div class="flex align-center">
            <p>
              每累积邀请<el-input
                v-model="basicInfoForm.stage_number5"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">人</template></el-input
              >
            </p>
            <p>
              可额外获得<el-input
                v-model="basicInfoForm.stage_life5"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="阶段奖励六" prop="stage_number6">
          <div class="flex align-center">
            <p>
              每累积邀请<el-input
                v-model="basicInfoForm.stage_number6"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">人</template></el-input
              >
            </p>
            <p>
              可额外获得<el-input
                v-model="basicInfoForm.stage_life6"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="是否开启阶段奖励">
          <el-switch
            v-model="basicInfoForm.status"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit">保存</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "invitateIsPolite",
  data() {
    return {
      imageUrl: "",
      isShow: false,
      basicInfoForm: {
        per_life: "",
        max_life: "",
        stage_number1: "",
        stage_number2: "",
        stage_number3: "",
        stage_number4: "",
        stage_number5: "",
        stage_number6: "",
        stage_life1: "",
        stage_life2: "",
        stage_life3: "",
        stage_life4: "",
        stage_life5: "",
        stage_life6: "",
        status: 1,
      },
    };
  },
  created() {
    this.axios({
      method: "get", //请求方法
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": this.storage.get("__token"),
        Authorization: `Bearer ${this.storage.get("__token")}`,
      },
      url: ` https://time.api.boyalife.cn/v1/cms/Share/1`,
    })
      .then((res) => {
        let a = res.data.result;
        for (let i in a) {
          this.basicInfoForm[i] = Number(a[i]);
        }
      })
      .catch((err) => {
        this.$message.error(err.response.data.error.message);
      });
  },
  methods: {
    submit() {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.basicInfoForm,
        url: ` https://time.api.boyalife.cn/v1/cms/Share/1`,
      })
        .then((res) => {
          this.$message.success("保存成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
  },
};
</script>
<style scoped>
.invitateIsPolite {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form {
  width: 100%;
}
>>> .ipt {
  width: 200px;
  margin: 0 10px;
}
>>> .el-checkbox {
  margin-right: 0;
}
p {
  line-height: 40px;
}
</style>
