<template>
  <div class="addRole">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/rolesManagement'">角色管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加角色信息</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="info boxLayout mg-t-20">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form ref="form" :model="basicInfoForm" label-width="140px">
        <el-form-item
          label="角色名称"
          prop="title"
          :rules="{ required: true, message: '请填写', trigger: 'blur' }"
        >
          <el-input v-model="basicInfoForm.title"></el-input>
        </el-form-item>
        <el-form-item label="菜单权限">
          <el-checkbox
            v-model="menuExpand"
            @change="handleCheckedTreeExpand($event, 'menu')"
            >展开/折叠</el-checkbox
          >
          <el-checkbox
            v-model="menuNodeAll"
            @change="handleCheckedTreeNodeAll($event, 'menu')"
            >全选/全不选</el-checkbox
          >
          <el-checkbox
            :disabled="true"
            v-model="basicInfoForm.menuCheckStrictly"
            @change="handleCheckedTreeConnect($event, 'menu')"
            >父子联动</el-checkbox
          >
          <el-tree
            class="tree-border"
            :data="menuOptions"
            show-checkbox
            ref="menu"
            node-key="id"
            :check-strictly="!basicInfoForm.menuCheckStrictly"
            empty-text="加载中，请稍候"
            :props="defaultProps"
          ></el-tree>
        </el-form-item>
        <!-- <el-form-item label="权限" prop="menusIds"  :rules="{required: true,message: '请选择',trigger: 'change'}">
          <el-select value-key="id" multiple v-model="basicInfoForm.menusIds" placeholder="请选择" v-if="tableData.length > 0">
            <el-option-group
              v-for="group in tableData"
              :key="group.id"
              :label="group.menu_name">
              <el-option
                v-for="item in group.children"
                :key="item.id"
                :label="item.menu_name"
                :value="item">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button type="primary" size="mini" @click="submit('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "addAttribute",
  data() {
    return {
      menuOptions: [], // 所有的菜单数组，
      menusIds: [], // 已选的菜单id数组
      menuExpand: false,
      menuNodeAll: false,
      basicInfoForm: {
        menuCheckStrictly: false,
        deptCheckStrictly: false,
        title: "",
        rules_url: [],
        rules: [],
        rules_name: [],
      },
      defaultProps: {
        children: "children",
        label: "menu_name",
      },
    };
  },
  created() {
    this.getMenu();
  },
  mounted() {},
  methods: {
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/AdminAuthGroup/${this.$route.query.uuid}`,
      })
        .then((res) => {
          this.basicInfoForm = res.data.result;
          this.basicInfoForm.deptCheckStrictly = false;
          this.basicInfoForm.menuCheckStrictly = true;
          let op = [...new Set(res.data.result.group.rules)];
          console.log(op);
          // this.open = true;
          this.$nextTick(() => {
            op.forEach((v) => {
              this.$nextTick(() => {
                console.log(v);
                this.$refs.menu.setChecked(Number(v), true, false);
                // this.menuExpand = true
              });
            });
          });
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 树权限（展开/折叠）
    handleCheckedTreeExpand(value, type) {
      if (type == "menu") {
        let treeList = this.menuOptions;
        for (let i = 0; i < treeList.length; i++) {
          this.$refs.menu.store.nodesMap[treeList[i].id].expanded = value;
        }
      } else if (type == "dept") {
        let treeList = this.deptOptions;
        for (let i = 0; i < treeList.length; i++) {
          this.$refs.dept.store.nodesMap[treeList[i].id].expanded = value;
        }
      }
    },
    // 所有菜单节点数据
    getMenuAllCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.menu.getCheckedKeys();
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },
    // 树权限（全选/全不选）
    handleCheckedTreeNodeAll(value, type) {
      if (type == "menu") {
        this.$refs.menu.setCheckedNodes(value ? this.menuOptions : []);
      } else if (type == "dept") {
        this.$refs.dept.setCheckedNodes(value ? this.deptOptions : []);
      }
    },
    // 树权限（父子联动）
    handleCheckedTreeConnect(value, type) {
      // if (type == 'menu') {
      //   this.basicInfoForm.menuCheckStrictly = value ? true: false;
      // } else if (type == 'dept') {
      //   this.basicInfoForm.deptCheckStrictly = value ? true: false;
      // }
    },
    getMenu() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/AdminAuthMenu`,
      })
        .then((res) => {
          this.menuOptions = res.data.result;
          console.log(this.menuOptions);
          this.menuOptions.map((item) => {
            if (item.children.length == 0) {
              item.children[0] = {
                menu_name: item.menu_name,
                menu_url: item.menu_url,
                id: item.id,
              };
            }
          });
          if (this.$route.query.uuid) {
            this.getInfo();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let op = [...new Set(this.getMenuAllCheckedKeys())];

          this.basicInfoForm.rules_name = [];
          this.basicInfoForm.rules_url = [];
          this.basicInfoForm.rules = op;
          delete this.basicInfoForm.admin_auth_group_update_time;
          delete this.basicInfoForm.create_time;
          delete this.basicInfoForm.deptCheckStrictly;
          delete this.basicInfoForm.menuCheckStrictly;
          delete this.basicInfoForm.admin_auth_group_update_time;
          delete this.basicInfoForm.admin_auth_group_update_time;
          delete this.basicInfoForm.admin_auth_group_update_time;

          if (!this.$route.query.uuid) {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/AdminAuthGroup`,
            })
              .then((res) => {
                this.$message.success("保存成功!");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/AdminAuthGroup/${this.$route.query.uuid}`,
            })
              .then((res) => {
                this.$message.success("保存成功!");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.addRole {
  margin-bottom: 80px;
}
.el-checkbox + .el-checkbox {
  margin-left: 0px;
}
.el-checkbox {
  margin-right: 25px;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #c79f5e !important;
  border-color: #c79f5e !important;
}
.el-checkbox__inner:hover {
  border-color: #c79f5e !important;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #c79f5e !important;
}
.el-checkbox + .el-checkbox {
  margin-left: 0px;
}
.el-checkbox {
  margin-right: 25px;
}
.second {
  width: 92%;
  margin: 0 auto;
  height: 46px;
  background: #f1f6f9;
  box-sizing: border-box;
  line-height: 46px;
  padding: 0 20px;
  font-size: 16px;
}
.Levelthree {
  width: 92%;
  line-height: 40px;
  margin: 0 auto;
}
>>> .el-cascader-panel .el-cascader-menu:last-child {
  display: none !important;
}
</style>
