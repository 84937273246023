<template>
  <div class="dialog-container" v-show="visible">
    <div class="bg-layout"></div>
    <div class="dialog">
      <div class="search-container flex space-between mg-t-20" ref="div">
        <el-form
          :inline="true"
          :model="customerGroupForm"
          class="demo-form-inline"
        >
          <el-form-item label="" class="search_keyword">
            <el-input
              placeholder="请输入人群名称"
              v-model="customerGroupForm.search_keyword"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="btn mg-t-10">
          <el-button type="primary" size="medium" @click="getList"
            >查询</el-button
          >
          <el-button size="medium" @click="clear">清空</el-button>
        </div>
      </div>
      <h3 class="mg-l-10 mg-b-10">
        已选 <span style="color: orange">{{ selectNum }} </span>个群体
      </h3>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        style="width: 100%"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        row-key="getRowKeys"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="name" label="人群名称"> </el-table-column>
        <el-table-column prop="desc" label="人群描述"> </el-table-column>
        <el-table-column prop="user_number" label="客户数量"> </el-table-column>
        <el-table-column prop="update_time" width="150" label="更新时间">
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
      <div class="dialog-footer text-right mg-t-20">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "./pagination.vue";
export default {
  name: "",
  components: {
    pagination,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    user: [],
  },

  data() {
    return {
      visible: false,
      selectNum: 0,
      selectRow: [],
      customerGroupForm: {
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  watch: {
    dialogVisible(n) {
      this.visible = n;
    },
    user(n) {
      if (n.length > 0) {
        this.getList();
      }
    },
  },
  created() {
    this.getList();
  },
  methods: {
    clear() {
      this.customerGroupForm.search_keyword = "";
      this.getList();
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserGroup?page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
          if (this.user && this.user.length > 0) {
            this.selectRows = this.user;
            let uuids = this.selectRows.map((i) => i.uuid);
            this.tableData.data.forEach((row) => {
              if (uuids.includes(row.uuid)) {
                this.$nextTick(() => {
                  this.$refs.multipleTable.toggleRowSelection(row, true);
                });
              }
            });
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    cancel() {
      this.visible = false;
      this.$emit("closeDialog", this.visible);
    },
    confirm() {
      this.$emit("confirmPeople", this.selectRows);
    },
    handleSelectionChange(rows) {
      this.selectNum = rows.length;
      this.selectRows = rows;
    },
    getRowKeys(row) {
      return row.case_id;
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
