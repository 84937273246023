<template>
  <div class="memberAnalysis">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>秒杀数据</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <div class="overview ">
        <div class="title flex space-between align-center">
          <h3 class="title-t">秒杀数据概览</h3>
          <div class="flex">
            <el-form
              :inline="true"
              :model="customerGroupForm"
              class="demo-form-inline"
            >
              <el-form-item label="选择时间">
                <el-date-picker
                  class="mg-l-20"
                  v-model="dated"
                  type="daterange"
                  align="right"
                  unlink-panels
                  @change="getTimes"
                  :clearable="false"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy/MM/dd"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="flex mg-t-20" v-if="Object.keys(dataOverview).length > 0">
          <div class="box pad-20 mg-r-10">
            <h4 class="color-999 mg-b-10">
              总商品数
              <el-tooltip
                class="item"
                effect="dark"
                content="参与拼团的商品数量"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </h4>
            <p class="font-24 mg-b-10">{{ dataOverview.goods_num || 0 }}</p>
            <div class="flex color-999 space-between">
              <p>环比</p>
              <p>{{ dataOverview.goods_number_scale || 0 }}%</p>
            </div>
          </div>
          <div class="box pad-20 mg-r-10">
            <h4 class="color-999 mg-b-10">
              动销商品数
              <el-tooltip
                class="item"
                effect="dark"
                content="所选时间内，被购买的商品总数"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </h4>
            <p class="font-24 mg-b-10">{{ dataOverview.dx_goods_number || 0 }}</p>
            <div class="flex color-999 space-between">
              <p>环比</p>
              <p>{{ dataOverview.dx_goods_scale || 0 }}%</p>
            </div>
          </div>
          <div class="box pad-20 mg-r-10">
            <h4 class="color-999 mg-b-10">
              商品浏览量
              <el-tooltip
                class="item"
                effect="dark"
                content="所选时间内，被查看的商品总数"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </h4>
            <p class="font-24 mg-b-10">{{ dataOverview.visitor_number || 0 }}</p>
            <div class="flex color-999 space-between">
              <p>环比</p>
              <p>{{ dataOverview.visitor_number_scale || 0 }}%</p>
            </div>
          </div>
          <div class="box pad-20 mg-r-10">
            <h4 class="color-999 mg-b-10">
              总成交单数
              <el-tooltip
                class="item"
                effect="dark"
                content="所选时间内，秒杀订单的数量"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </h4>
            <p class="font-24 mg-b-10">{{ dataOverview.order_number || 0 }}</p>
            <div class="flex color-999 space-between">
              <p>环比</p>
              <p>{{ dataOverview.order_number_scale || 0 }}%</p>
            </div>
          </div>
          <div class="box pad-20 mg-r-10">
            <h4 class="color-999 mg-b-10">
              成交金额
              <el-tooltip
                class="item"
                effect="dark"
                content="所选时间内，总成交额"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </h4>
            <p class="font-24 mg-b-10">{{ dataOverview.order_amount || 0 }}</p>
            <div class="flex color-999 space-between">
              <p>环比</p>
              <p>{{ dataOverview.order_amount_scale || 0 }}%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex dataTop mg-t-50 space-between">
        <div class="top">
          <div class="title flex space-between align-center">
            <h3 class="title-t">商品销售额排行</h3>
            <div>
              <el-tag v-for="(item,index) in tags" :key="item +( index +2)" :effect="current1 == index ? 'dark' : 'plain'" class="mg-l-10" @click="table1Click(index)">{{item}}</el-tag>
            </div>
          </div>
          <el-table
            class="mg-t-20"
            :data="tableData"
            style="width: 100%"
            border
          >
            <el-table-column
              prop="top"
              label="排名"
              align="center"
              type="index"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              show-overflow-tooltip
              label="商品名称"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="pay_amount" label="销售额(元)" align="center">
            </el-table-column>

            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="$router.push('/productReport')"
                  type="text"
                  size="small"
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="top">
          <div class="title flex space-between align-center">
            <h3 class="title-t">商品销量排行</h3>
            <div>
              <el-tag v-for="(item,index) in tags" :key="item +( index +2)" :effect="current2 == index ? 'dark' : 'plain'" class="mg-l-10"  @click="table2Click(index)">{{item}}</el-tag>
            </div>
          </div>
          <el-table
            class="mg-t-20"
            :data="visitorTableData"
            style="width: 100%"
            border
          >
            <el-table-column
              prop="top"
              label="排名"
              align="center"
              type="index"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              show-overflow-tooltip
              label="商品名称"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="pay_amount" label="销量(件)" align="center">
            </el-table-column>

            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="$router.push('/productReport')"
                  type="text"
                  size="small"
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="top">
          <div class="title">
            <h3 class="title-t">本月销售情况</h3>
            <div class="myChartBox" style="width: 100%; height: 300px">
              <div
                class="totalNum color-333 font-30 text-center font-bold"
                v-if="Object.keys(count).length > 0"
              >
                {{ count.sale_total }}
                <p class="color-999 font-12">销售总数</p>
              </div>
              <div id="ageBox" ref="ageox" style="width: 100%; height: 100%"></div>
            </div>
          </div>
        </div>
      </div>
      <h3 class="title-t">活动订单</h3>
      <el-table
            class="mg-t-20"
            :data="activityData.data"
            style="width: 100%"
            border
          >
            <el-table-column
              prop="top"
              label="订单号"
              align="center"
              type="index"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="mobile"
              show-overflow-tooltip
              label="用户手机"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="name" label="姓名" align="center">
            </el-table-column>
            <el-table-column prop="user_level" label="会员等级" align="center">
            </el-table-column>
            <el-table-column prop="create_time" label="下单时间" align="center">
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center">
            </el-table-column>
            <el-table-column prop="price" label="支付金额" align="center">
            </el-table-column>
            <el-table-column prop="goods[0].name" label="商品" align="center" :show-overflow-tooltip="true">
            </el-table-column>
          </el-table>
          <pagination
            :total="tableData.total"
            :per_page="tableData.per_page"
            :current_page="tableData.current_page"
            @handleCurrentChange="handleCurrentChange"
            @handleSizeChange="handleSizeChange"
          />
    </div>
    
    
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";

let currentTime = new Date();
let year = currentTime.getFullYear();
let month = currentTime.getMonth() + 1;
let day = currentTime.getDate();
let formattedDate = `${year}/${month
  .toString()
  .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;

const start = new Date();
start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
let timed = start.toLocaleDateString();
import * as echarts from "echarts";
export default {
  name: "",
  components: {pagination},

  data() {
    return {
      current1: 0,
      current2: 0,
      count: {},
      tags: ['今日', '本周', '本月'],
      tableData: [],
      visitorTableData: [],
      dated: [],
      dataOverview: {},
      activityData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      search_start_time: '',
      search_end_time: '',
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  created () {
    this.getOverview()
    this.getPayAmountRank()
    this.getVisitorRank()
    this.getCount()
    this.getActivityData()
  },
  methods: {
    // 销售情况
    getCount() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: `https://time.api.boyalife.cn/v1/cms/Miaosha/count?uuid=${this.$route.query.uuid}`
      })
        .then(res => {
          this.count = res.data.result;
          this.getCountData();
        })
        .catch(err => {
          this.$message.success(err);
        });
    },
    getCountData() {
      // 会员数据概况
      let _that = this;
      let dom = document.getElementById("ageBox");
      let myChart = echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false
      });
      let option;
      let datas = [
        { name: `销售总数`, value: _that.count.sale_total },
        { name: `退货总数`, value: _that.count.refund_total },
      ];
      option = {
        tooltip: {
          trigger: "item"
        },
        legend: {
          bottom: "5%",
          left: "center"
        },
        series: [
          {
            type: "pie",
            radius: [70, 100],
            top: top + "%",
            height: "100%",
            left: "center",
            width: "100%",
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1
            },

            label: {
              alignTo: "edge",
              formatter: "{b}: {@2012} ({d}%) \n\n",
              minMargin: 0,
              edgeDistance: 20,
              lineHeight: 10,
              textStyle: {
                fontSize: 14
              }
            },
            labelLine: {
              length: 15,
              length2: 0,
              maxSurfaceAngle: 120
            },
            labelLayout: function(params) {
              const isLeft = params.labelRect.x < myChart.getWidth() / 2;
              const points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points
              };
            },
            data: datas
          }
        ]
      };
      if (option && typeof option === "object") {
        myChart.setOption(option);
      }
      window.addEventListener("resize", myChart.resize);
    },
    table1Click (i) {
      this.current1 = i
      this.getPayAmountRank()
    },
    table2Click (i) {
      this.current2 = i
      this.getVisitorRank()
    },
    getTimes(v) {
      this.search_start_time = v[0] + " 00:00:00";
      this.search_end_time = v[1] + " 23:59:59";
    },
     // 获取活动订单
     getActivityData() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: `https://time.api.boyalife.cn/v1/cms/Miaosha/orders?page_size=${this.activityData.per_page}&page_index=${this.activityData.current_page}`
      })
        .then(res => {
          this.activityData = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取销售额数据
    getPayAmountRank() {
      let type = this.current1 == 0 ? '今日' : this.current1 == 1 ? '本周' : '本月'
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: `https://time.api.boyalife.cn/v1/cms/Miaosha/sale_amount?uuid=${this.$route.query.uuid}&type=${type}` 
      })
        .then(res => {
          this.tableData = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取销量数据
    getVisitorRank() {
      let type = this.current2 == 0 ? '今日' : this.current2 == 1 ? '本周' : '本月'

      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: `https://time.api.boyalife.cn/v1/cms/Miaosha/sale_num?uuid=${this.$route.query.uuid}&type=${type}`
      })
        .then(res => {
          this.visitorTableData = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getOverview() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        // url: `https://time.api.boyalife.cn/v1/cms/Data/member`,
        url: `https://time.api.boyalife.cn/v1/cms/Miaosha/summary?uuid=${this.$route.query.uuid}&startTime=${this.search_start_time}&endTime=${this.search_end_time}`
      }).then(res => {
        this.dataOverview = res.data.result
      });
    },
    
    handleSizeChange(v) {
      this.activityData.per_page = v;
      this.getActivityData();
    },
    handleCurrentChange(v) {
      this.activityData.current_page = v;
      this.getActivityData();
    },
  }
};
</script>
<style scoped>
.overview .box {
  padding: 20px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  min-width: 175px;
}
::v-deep .el-tag {
  cursor: pointer;
}
.myChartBox {
  position: relative;
}
.myChartBox .totalNum {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.top {
  width: 30%;
}
</style>
