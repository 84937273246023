<template>
  <div>
    <el-form ref="form" :model="basicForm" :rules="rules">
      <div class="stepThird" style="width: 100%">
        <el-button
          type="primary"
          icon="el-icon-circle-plus"
          class="addSpecificaType"
          @click="_addSpecifoca"
          >添加规格类型</el-button
        >
        <div
          v-for="(item, index) in basicForm.templateData"
          :key="`item${index}`"
          class="itemTag"
        >
          <div class="outerBorder">
            <div class="inputMar">
              <el-select
                v-model="item.spec"
                filterable
                placeholder="请选择"
                @change="selectSpecificate($event)"
                @focus="getIndex(index)"
                value-key="name"
              >
                <el-option
                  v-for="jtem in specificateOptions"
                  :key="jtem.name"
                  :label="jtem.name"
                  :value="jtem"
                >
                </el-option>
              </el-select>
              <el-button
                class="mg-l-20"
                size="mini"
                type="text"
                @click="_deleteTemplateData(index)"
                >删除规格</el-button
              >
              <!-- <el-input v-model="item.name" placeholder="请输入规格名称，如尺寸;" style="width:300px" /> -->
              <!-- <el-button type="primary" @click="_addSizeName">添加</el-button> -->
            </div>
            <div v-show="item.spec">
              <el-tag
                v-for="tag in item.dynamicTags"
                :key="`${tag}index`"
                closable
                :disable-transitions="false"
                @close="handleClose(tag, item)"
                >{{ tag }}</el-tag
              >
              <el-input
                v-if="item.tagInputVisible"
                :ref="'saveTagInput' + index"
                v-model="item.tagInputValue"
                v-focus="item.focusState"
                class="input-new-tag"
                size="small"
                @keyup.enter.native="handleInputConfirm(item, index)"
                @blur="handleInputConfirm(item, index)"
              />
              <el-button
                v-else
                class="button-new-tag"
                size="small"
                @click="_addshowInput(item, index)"
                >+ 添加标签</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <el-button
        v-show="basicForm.templateData.length > 0"
        type="primary"
        class="saveBtn mg-b-20"
        @click="confirm"
        >确定</el-button
      >
      <div v-show="isShow" class="stepFour">
        <div
          class="stepFour__name"
          :style="{ width: templateNameList.length * 162 + 'px' }"
        >
          <div
            v-for="(nameItem, nameIndex) in templateNameList"
            :key="`nameItem${nameIndex}`"
            class="item"
            style="text-align: center"
          >
            {{ nameItem }}
          </div>
        </div>
        <div
          class="stepFour__box"
          :style="{ width: templateNameList.length * 162 + 'px' }"
        >
          <div
            v-for="(item, index) in basicForm.newTemplateData"
            :key="`item${index}`"
            class="stepFour__box__panel"
          >
            <div
              v-for="(value, childIndex) in item"
              :key="`childItem2${childIndex}`"
              class="item"
            >
              <span v-show="typeof value == 'string'">{{ value }}</span>
              <!-- <el-form-item
                  label-width="0"
                  :prop="'newTemplateData.'+unSureAttribute[childIndex - basicForm.templateData.length]"
                  :rules="newTemplateDataRule[unSureAttribute[childIndex - basicForm.templateData.length]]"
                >
                  <el-input
                    style="width: 160px"
                    v-show="(typeof value == 'object')"
                    v-model="value[unSureAttribute[childIndex - basicForm.templateData.length]]"
                    placeholder="输入"
                  />
                </el-form-item> -->
              <el-form-item
                label-width="0"
                :prop="
                  'newTemplateData.' +
                  unSureAttribute[childIndex - basicForm.templateData.length]
                "
                :rules="
                  newTemplateDataRule[
                    unSureAttribute[childIndex - basicForm.templateData.length]
                  ]
                "
              >
                <el-input
                  style="width: 160px"
                  v-show="typeof value == 'object'"
                  v-model="
                    value[
                      unSureAttribute[
                        childIndex - basicForm.templateData.length
                      ]
                    ]
                  "
                  placeholder="输入"
                />
              </el-form-item>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    attributes: [],
    newTemplateDatas: [],
  },
  data() {
    return {
      isShow: false,
      indexNum: "",
      basicForm: {
        templateData: [],
        newTemplateData: [],
        attribute: [],
      },
      newTemplateDataRule: {
        retail_price: {
          required: true,
          message: " 请填写零售价",
          trigger: " blur",
        },
        stock: { required: true, message: " 请填写库存", trigger: " blur" },
      },
      rules: {},
      tabelData: [],
      tabel: [],
      // 后端返回的规格
      attributeList: [],
      unSureAttribute: [],
      specificateOptions: [],
      //步骤
      shopStepActive: 3,
      //每一个sku规格的数据
      templateData: [],
      //  每一个sku规格里面拟定的数据如：颜色里面的[红色，蓝色，黄色]这些
      dynamicTags: [],
      // 这个是第四步步骤里的for循环的颜色的模板名称，如[颜色，尺寸，库存，价格]
      templateNameList: [],
      // 这个是第四步步骤里的for循环的数据，如[红色，S，11个，56元]
    };
  },
  created() {
    this.getAttribute();
    this.getList();
  },
  methods: {
    validateForm(callback) {
      console.log("no");

      this.$refs["form"].validate((valid) => {
        console.log(valid);
        if (valid) {
          console.log("ok");
        } else {
          console.log("no");

          return false;
        }
      });
    },
    // 获取属性
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ItemAttribute`,
      })
        .then((res) => {
          let d = res.data.result.data;
          d.map((item) => {
            if (item.values.includes(",")) {
              item.values = item.values.split(",");
            } else {
              item.values = [item.values];
            }
          });
          this.specificateOptions = d;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    inputChange() {
      this.$forceUpdate();
    },
    getAttribute() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/AttributeHeader`,
      })
        .then((res) => {
          let d = res.data.result;
          let k = [];
          this.attributeList = d;
          k = d.map((item) => item.key);

          this.unSureAttribute = k.slice();
          this.unSureAttribute = Object.assign({}, this.unSureAttribute);
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // 添加规格类型
    _addSpecifoca() {
      this.basicForm.templateData.push({
        name: "",
        dynamicTags: [],
        tagInputVisible: false,
        tagInputValue: "",
        focusState: false,
      });
    },
    getIndex(i) {
      this.indexNum = i;
    },
    // 选择规格
    selectSpecificate(e) {
      this.specificateOptions.map((item) => {
        if (item.name == e.name) {
          this.basicForm.templateData[this.indexNum].dynamicTags = item.values;
        }
      });
      console.log(this.basicForm.templateData);
    },
    // 删除商品规格
    _deleteTemplateData(index) {
      this.basicForm.templateData.splice(index, 1);
    },
    // 删除单个标签
    handleClose(tag, item) {
      console.log(this.basicForm.templateData);
      item.dynamicTags.splice(item.dynamicTags.indexOf(tag), 1);
    },
    // 按下enter或者失去焦点的时候触发Input里面的值到<el-tag>
    handleInputConfirm(item, index) {
      console.log(this.basicForm.templateData);
      console.log(index);

      const tagInputValue = item.tagInputValue;
      if (tagInputValue) {
        if (item.dynamicTags.indexOf(tagInputValue) === -1) {
          item.dynamicTags.push(tagInputValue);
        } else {
          this.$message.warning("请勿重复输入");
        }
      }
      item.tagInputVisible = false;
      item.tagInputValue = "";
    },
    // 点击变成输入框
    _addshowInput(item, index) {
      item.tagInputVisible = true;
      this.$nextTick(() => {
        item.focusState = true;
        //   this.$refs.saveTagInput[index].$refs.input.focus()
      });
    },
    // 多数组求笛卡儿积function cartesianProduct(array){
    recursionData(array) {
      return array.reduce(
        function (a, b) {
          return a
            .map(function (x) {
              return b.map(function (y) {
                return x.concat(y);
              });
            })
            .reduce(function (a, b) {
              return a.concat(b);
            }, []);
        },
        [[]]
      );
    },
    // 下一步步骤
    confirm() {
      this.isShow = true;
      let that = this;
      this.basicForm.newTemplateData = [];
      this.templateNameList = [];
      let array = [];
      this.basicForm.templateData.forEach((element) => {
        this.templateNameList.push(element.spec.name);
        array.push(element.dynamicTags);
        let obj = {
          key: element.spec.name,
          value: element.spec.values,
        };
        this.basicForm.attribute.push(obj);
      });
      let k = JSON.parse(JSON.stringify(this.attributeList));
      k.map((item) => {
        this.templateNameList.push(item.header);
      });
      this.basicForm.newTemplateData = this.recursionData(array);
      this.basicForm.newTemplateData.filter((item) => {
        for (let i in k) {
          item.push({
            [k[i].key]: "",
          });
        }

        return item;
      });

      this.$emit("getAttributeData", this.basicForm);
    },
    // 上一步
    _previousStep() {
      this.shopStepActive--;
    },
    // 保存添加的模板
    _saveTemplate() {
      // var newVal = []
      // const sku = this.basicForm.newTemplateData.reduce((c, item) => {
      //   newVal = []
      //   for (let k = 0; k < item.length - 2; k++) {
      //     newVal.push({
      //       keyName: [...this.templateNameList][k],
      //       keyVal: item[k]
      //     })
      //   }
      //   return c.concat({
      //     price: item[item.length - 1].price,
      //     stockNum: item[item.length - 2].stockNum,
      //     item_id: [...newVal]
      //   })
      // }, [])
    },
  },
};
</script>

<style scoped>
.stepThird .itemTag .outerBorder {
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
}
.stepThird .addSpecificaType {
  margin-bottom: 10px;
}
.stepThird .itemTag {
  margin-bottom: 20px;
}
.stepThird .itemTag .outerBorder .close {
  position: absolute;
  right: 0;
  top: 0;
}
.stepThird .itemTag .outerBorder .close i {
  font-size: 20px;
  cursor: pointer;
}
.stepThird .itemTag .outerBorder .inputMar {
  margin-bottom: 10px;
}
.stepFour {
  width: 100%;
  margin: auto;
  overflow: auto;
  background: #fff;
}
.stepFour__box__panel {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
}
.stepFour__box__panel .item {
  flex: 1;
  font-size: 14px;
  border-right: 1px solid #eee;
  box-sizing: border-box;
  padding: 20px 0;
  width: 160px;
}
.stepFour__box__panel .item .el-input {
}
.stepFour__name {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #eee;
  align-items: center;
}
.stepFour__name .item {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  width: 160px;
  padding: 0 5px;
  box-sizing: border-box;
}
.stepFour__box__panel .item > span,
.stepFour__box__panel .item .el-input {
  width: 160px;
  text-align: center;
  display: inline-block;
  padding: 0 5px;
  box-sizing: border-box;
}

.rigEnd {
  display: flex;
  justify-content: flex-end;
}
.rigEnd .previousBtn {
  color: #333;
  margin-right: 20px;
}
.rigEnd .saveBtn {
  width: 200px;
  height: 46px;
}
.input-new-tag {
  width: 90px;
  vertical-align: baseline;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.el-tag {
  margin-right: 10px;
  margin-bottom: 5px;
}
</style>
