<template>
  <!-- 资产明细 -->
  <div class="particulars">
    <div class="container">
      <h3 class="title-t mb20">资产明细</h3>
      <el-row :gutter="20" class="mb20">
        <el-col :span="8">
          <el-card>
            <p class="card-tit">样本名称</p>
            <p class="card-con">胎盘间充质干细胞</p>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card>
            <p class="card-tit">细胞存储天数</p>
            <p class="card-con">365</p>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card>
            <p class="card-tit">综合分析</p>
            <p class="card-con">良好</p>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="mb20">
        <el-col :span="8">
          <el-card>
            <h3 class="card-titile mb20">样本动态</h3>
            <el-steps :active="1" align-center>
              <el-step title="合同签约" icon="el-icon-circle-check"></el-step>
              <el-step title="缴纳费用" icon="el-icon-info"></el-step>
              <el-step title="样本采集" icon="el-icon-question"></el-step>
            </el-steps>
            <p class="current-step mb20">
              当前进度：缴纳费用
            </p>
            <el-descriptions>
              <el-descriptions-item label="订单编号" :span="2">20001391231231</el-descriptions-item>
              <el-descriptions-item label="套餐类型" :span="2">博雅盒子2号</el-descriptions-item>
              <el-descriptions-item label="合同编号" :span="2">20001391231231</el-descriptions-item>
              <el-descriptions-item label="签约日期" :span="2">20201120</el-descriptions-item>
            </el-descriptions>
          </el-card>
        </el-col>
        <el-col :span="16">
          <el-row :gutter="20" class="mb20">
            <el-col :span="12">
              <el-card>
                <p class="card-tit">细胞计数（个）</p>
                <p class="card-con">10×10</p>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card>
                <p class="card-tit">细胞活率</p>
                <p class="card-con">99%</p>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="mb20">
            <el-col :span="24">
              <el-card>
                <h3 class="card-titile">更多信息</h3>
                <el-table class="mg-t-20" :data="tebData" style="width: 100%">
                  <el-table-column prop="q" label="我的细胞存储位置" align="center">
                  </el-table-column>
                  <el-table-column prop="w" label="运输温度" align="center">
                  </el-table-column>
                  <el-table-column prop="e" label="母血检测" align="center">
                  </el-table-column>
                  <el-table-column prop="r" label="罐内温度" align="center">
                  </el-table-column>
                  <el-table-column prop="t" label="样本检测" align="center">
                  </el-table-column>
                </el-table>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'particulars',
  data() {
    return {
      uuid: this.$route.query.uuid,
      tebData: [
        { id: 0, q: '博雅北京库 第20号液氮罐', w: '正常/10～15摄氏度', e: '正常/无感染', r: '正常/10～15摄氏度', t: '正常/无感染' }
      ]
    }
  },

}
</script>
<style scoped>
.particulars {
  background: #f2f2f2;
}

.container {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}

.mb20 {
  margin-bottom: 20px;
}

.card-tit {
  text-align: center;
  color: #909399;
}

.card-con {
  text-align: center;
  font-size: 18px;
}

.card-titile::before {
  content: '';
  display: inline-block;
  width: 2px;
  height: 20px;
  vertical-align: middle;
  background-color: #909399;
  margin-right: 10px;
}

.current-step {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px dashed #909399;
}
</style>