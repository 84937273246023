import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const localModulesFiles = require.context('./modules', true, /\.module\.js$/)
const localModule = localModulesFiles.keys().reduce((modules, modulePath) => {
    const value = localModulesFiles(modulePath)
    const moduleName = modulePath.replace(/^\.\/(.*)\.module\.js$/, '$1')
    modules[moduleName] = value.default
    return modules
}, {})

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        ...localModule
    }
})
