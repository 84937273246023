<template>
  <!-- 我的细胞存储位置 -->
  <div class="storageLocation">
    <el-table class="mg-t-20" :data="tableData.data" border style="width: 100%">
      <el-table-column prop="name" label="成员" align="center" width="280">
      </el-table-column>
      <el-table-column
        prop="desc"
        label="实验室名称"
        align="center"
        width="280"
      >
      </el-table-column>
      <el-table-column prop="update_time" label="存储位置" align="center">
      </el-table-column>
    </el-table>
    <pagination
      :total="tableData.total"
      :per_page="tableData.per_page"
      :current_page="tableData.current_page"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
    />
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";

export default {
  name: "storageLocation",
  data() {
    return {
      customerGroupForm: {
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  components: {
    pagination,
  },
  props: ["uuid"],
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserGroup?page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped></style>
