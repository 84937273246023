<template>
  <!-- 添加客户 -->
  <div class="addReturnAddress">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>订单</el-breadcrumb-item>
      <el-breadcrumb-item>配送服务</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/returnAddress' }"
        >退货地址</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ pageType }}地址</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicInfo">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="联系人" prop="name">
          <el-input
            v-model="basicInfoForm.name"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="mobile">
          <el-input
            v-model="basicInfoForm.mobile"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="所在地区" prop="area_uuid">
          <el-cascader
            ref="chooseArea"
            :options="options"
            v-model="region"
            @change="getRegionValue"
            :props="props"
            collapse-tags
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input
            v-model="basicInfoForm.address"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否启用" prop="status" placeholder="请输入">
          <el-switch
            v-model="basicInfoForm.status"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="关联商品" prop="is_goods">
          <el-switch
            v-model="basicInfoForm.is_goods"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
          <el-button
            v-if="basicInfoForm.is_goods == 1"
            type="text"
            @click="selectCommdityDialogVisible = true"
            >+ 选择关联商品</el-button
          >
          <el-table
            :data="commidityList"
            border
            v-if="commidityList.length > 0"
          >
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="name" label="商品名"></el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <selectCommdity
      :goods="basicInfoForm.goods"
      :dialogVisible="selectCommdityDialogVisible"
      @confirmCommidity="confirmCommidity"
      @closeDialog="closeDialog"
    />
  </div>
</template>
<script>
import selectCommdity from "../../../components/selectCommdity.vue";
export default {
  name: "addReturnAddress",
  components: {
    selectCommdity,
  },
  data() {
    return {
      region: [],
      options: [],
      props: {
        label: "area_name",
        value: "area_id",
      },
      selectCommdityDialogVisible: false,
      basicInfoForm: {
        goods: [],
        is_goods: 2,
        name: "",
        mobile: "",
        province_uuid: "",
        city_uuid: "",
        area_uuid: "",
        address: "",
      },
      commidityList: [],
      rules: {
        name: { required: true, message: "请输入昵称", trigger: "blur" },
        mobile: { required: true, message: "请输入手机号", trigger: "blur" },
        area_uuid: { required: true, message: "请选择地址", trigger: "change" },
        address: { required: true, message: "请输入昵称", trigger: "blur" },
      },
    };
  },
  created() {
    this.getRegion();
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ReturnAddress/${this.$route.query.uuid}`,
      })
        .then((res) => {
          console.log(res);
          let params = res.data.result;
          for (let i in params) {
            this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
          }
          this.region[0] = this.basicInfoForm.province_uuid;
          this.region[1] = this.basicInfoForm.city_uuid;
          this.region[2] = this.basicInfoForm.area_uuid;
          this.basicInfoForm.status = Number(this.basicInfoForm.status);
          this.commidityList = JSON.parse(
            JSON.stringify(this.basicInfoForm.goods)
          );
          delete this.basicInfoForm.create_time;
          delete this.basicInfoForm.delete_time;
          delete this.basicInfoForm.update_time;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    } else {
      this.pageType = "新增";
    }
  },
  methods: {
    getRegionValue(v) {
      this.basicInfoForm.province_uuid = v[0];
      this.basicInfoForm.city_uuid = v[1];
      this.basicInfoForm.area_uuid = v[2];
    },
    getRegion() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Region`,
      })
        .then((res) => {
          this.options = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    confirmCommidity(v) {
      this.selectCommdityDialogVisible = false;
      this.commidityList = v;
      this.basicInfoForm.goods = v;
    },
    closeDialog() {
      this.selectCommdityDialogVisible = false;
    },
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.basicInfoForm.shelf_type == 1) {
            this.basicInfoForm.shelf_time = "";
          }
          if (this.basicInfoForm.is_goods == 1) {
            if (this.basicInfoForm.goods.length < 1) {
              this.$message.error("请选择商品！");
              return false;
            } else {
              this.basicInfoForm.goods = this.basicInfoForm.goods.map(
                (item) => item.uuid
              );
            }
          }
          if (Object.keys(this.$route.query).length !== 0) {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/ReturnAddress/${this.basicInfoForm.uuid}`,
            })
              .then((res) => {
                this.$message.success("编辑成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.success(err);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/ReturnAddress`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.success(err);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.addReturnAddress {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form {
  width: 400px;
}
</style>
