<template>
  <div class="productOverview">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>数据</el-breadcrumb-item>
      <el-breadcrumb-item>商品概览</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container boxLayout mg-b-20">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="统计时间">
          <el-date-picker
            class="mg-l-20"
            v-model="date1"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            :clearable="false"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <div class="overview boxLayout">
      <div v-if="Object.keys(productOverview).length > 0">
        <h3 class="title-t mg-b-20">商品数据概览</h3>
        <div class="flex">
          <div class="box pad-20 mg-r-10">
            <h4 class="color-999 mg-b-10">
              在架商品数
              <el-tooltip
                class="item"
                effect="dark"
                content="上架中的商品总数"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </h4>
            <p class="font-24 mg-b-10">{{ productOverview.goods_up_number }}</p>
            <div class="flex color-999 space-between">
              <p>环比</p>
              <p>{{ productOverview.goods_up_number }}</p>
            </div>
          </div>
          <div class="box pad-20 mg-r-10">
            <h4 class="color-999 mg-b-10">
              商品总数
              <el-tooltip
                class="item"
                effect="dark"
                content="包括上架中和已上架的商品"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </h4>
            <p class="font-24 mg-b-10">{{ productOverview.goods_number }}</p>
            <div class="flex color-999 space-between">
              <p>环比</p>
              <p>{{ productOverview.goods_number_scale }}</p>
            </div>
          </div>
          <div class="box pad-20 mg-r-10">
            <h4 class="color-999 mg-b-10">
              销售库存
              <el-tooltip
                class="item"
                effect="dark"
                content="所有SKU总数"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </h4>
            <p class="font-24 mg-b-10">
              {{ productOverview.goods_sales_number }}
            </p>
            <div class="flex color-999 space-between">
              <p>环比</p>
              <p>{{ productOverview.goods_sales_number_scale }}</p>
            </div>
          </div>
          <div class="box pad-20 mg-r-10">
            <h4 class="color-999 mg-b-10">
              动销商品数
              <el-tooltip
                class="item"
                effect="dark"
                content="所选时间内,被购买的商品总数"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </h4>
            <p class="font-24 mg-b-10">
              {{ productOverview.goods_pay_number }}
            </p>
            <div class="flex color-999 space-between">
              <p>环比</p>
              <p>{{ productOverview.goods_pay_number_scale }}</p>
            </div>
          </div>
          <div class="box pad-20 mg-r-10">
            <h4 class="color-999 mg-b-10">
              商品浏览量
              <el-tooltip
                class="item"
                effect="dark"
                content="所选时间内,被查看的商品总数"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </h4>
            <p class="font-24 mg-b-10">{{ productOverview.visitor_number }}</p>
            <div class="flex color-999 space-between">
              <p>环比</p>
              <p>{{ productOverview.visitor_number_scale }}</p>
            </div>
          </div>
        </div>
      </div>

      <h3 class="title-t mg-b-20 mg-t-20 flex space-between">
        <p>指标趋势</p>
        <el-button>导出</el-button>
      </h3>
      <div
        id="transactionOverview"
        ref="myChart"
        style="width: 100%; height: 600px"
      ></div>
      <div class="flex dataTop mg-t-50 space-between">
        <div class="top">
          <div class="title flex space-between align-center">
            <h3 class="title-t">支付金额TOP</h3>
            <div>
              <span>商品分类</span>
              <el-select
                class="mg-l-10 mg-r-10"
                v-model="payAmountUuid"
                placeholder="请选择"
                @change="getPayAmountRank"
                clearable
              >
                <el-option-group
                  v-for="group in categoryOptions"
                  :key="group.uuid"
                  :label="group.name"
                  :value="group.uuid"
                >
                  <el-option
                    v-for="item in group.children"
                    :key="item.uuid"
                    :label="item.name"
                    :value="item.uuid"
                  >
                  </el-option>
                </el-option-group>
              </el-select>

              <el-button @click="goodsExport('pay_amount')">导出</el-button>
            </div>
          </div>
          <el-table
            class="mg-t-20"
            :data="tableData"
            style="width: 100%"
            border
          >
            <el-table-column
              prop="top"
              label="排名"
              align="center"
              type="index"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              show-overflow-tooltip
              label="商品名称"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="pay_amount" label="支付金额" align="center">
            </el-table-column>

            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="$router.push('/productReport')"
                  type="text"
                  size="small"
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="top">
          <div class="title flex space-between align-center">
            <h3 class="title-t">访问量TOP</h3>
            <div>
              <span>商品分类</span>
              <el-select
                class="mg-l-10 mg-r-10"
                v-model="visitorUuid"
                placeholder="请选择"
                @change="getVisitorRank"
                clearable
              >
                <el-option-group
                  v-for="group in categoryOptions"
                  :key="group.uuid"
                  :label="group.name"
                  :value="group.uuid"
                >
                  <el-option
                    v-for="item in group.children"
                    :key="item.uuid"
                    :label="item.name"
                    :value="item.uuid"
                  >
                  </el-option>
                </el-option-group>
              </el-select>

              <el-button @click="goodsExport('visitor_number')">导出</el-button>
            </div>
          </div>
          <el-table
            class="mg-t-20"
            :data="visitorTableData"
            style="width: 100%"
            border
          >
            <el-table-column
              prop="top"
              label="排名"
              align="center"
              type="index"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              show-overflow-tooltip
              label="商品名称"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="pay_amount" label="支付金额" align="center">
            </el-table-column>

            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="$router.push('/productReport')"
                  type="text"
                  size="small"
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="flex dataTop mg-t-50 space-between">
        <div class="top">
          <div class="title flex space-between align-center">
            <h3 class="title-t">加购数量TOP</h3>
            <div>
              <span>商品分类</span>
              <el-select
                class="mg-l-10 mg-r-10"
                v-model="cartUuid"
                placeholder="请选择"
                @change="getCartRank"
                clearable
              >
                <el-option-group
                  v-for="group in categoryOptions"
                  :key="group.uuid"
                  :label="group.name"
                  :value="group.uuid"
                >
                  <el-option
                    v-for="item in group.children"
                    :key="item.uuid"
                    :label="item.name"
                    :value="item.uuid"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
              <el-button @click="goodsExport('goods_cart_number')"
                >导出</el-button
              >
            </div>
          </div>
          <el-table
            class="mg-t-20"
            :data="cartTableData"
            style="width: 100%"
            border
          >
            <el-table-column
              prop="top"
              label="排名"
              align="center"
              type="index"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              show-overflow-tooltip
              label="商品名称"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="pay_amount" label="支付金额" align="center">
            </el-table-column>

            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="$router.push('/productReport')"
                  type="text"
                  size="small"
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="top">
          <div class="title flex space-between align-center">
            <h3 class="title-t">销售数量TOP</h3>
            <div>
              <span>商品分类</span>
              <el-select
                class="mg-l-10 mg-r-10"
                v-model="salesUuid"
                placeholder="请选择"
                @change="getSalesRank"
                clearable
              >
                <el-option-group
                  v-for="group in categoryOptions"
                  :key="group.uuid"
                  :label="group.name"
                  :value="group.uuid"
                >
                  <el-option
                    v-for="item in group.children"
                    :key="item.uuid"
                    :label="item.name"
                    :value="item.uuid"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
              <el-button @click="goodsExport('goods_sales_number')"
                >导出</el-button
              >
            </div>
          </div>
          <el-table
            class="mg-t-20"
            :data="salesTableData"
            style="width: 100%"
            border
          >
            <el-table-column
              prop="top"
              label="排名"
              align="center"
              type="index"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              show-overflow-tooltip
              label="商品名称"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="pay_amount" label="支付金额" align="center">
            </el-table-column>

            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="$router.push('/productReport')"
                  type="text"
                  size="small"
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
let currentTime = new Date();
let year = currentTime.getFullYear();
let month = currentTime.getMonth() + 1;
let day = currentTime.getDate();
let formattedDate = `${year}/${month
  .toString()
  .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;

const start = new Date();
start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
let timed = start.toLocaleDateString();
export default {
  name: "",
  data() {
    return {
      payAmountUuid: "",
      visitorUuid: "",
      cartUuid: "",
      salesUuid: "",

      customerGroupForm: {
        search_start_time: timed,
        search_end_time: formattedDate
      },
      date1: [timed, formattedDate],
      value: "",
      productOverview: {},
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      categoryOptions: [],
      options: [
        {
          label: "一级分类A",
          options: [
            {
              value: "Shanghai",
              label: "二级分类A"
            },
            {
              value: "Beijing",
              label: "二级分类B"
            }
          ]
        },
        {
          label: "一级分类B",
          options: [
            {
              value: "Chengdu",
              label: "二级分类C"
            }
          ]
        }
      ],
      type: [
        "goods_up_number",
        "goods_number",
        "goods_sales_number",
        "goods_pay_number",
        "visitor_number",
        "visitor_user",
        "goods_cart_number",
        "goods_order_number"
      ],
      tableData: [],
      visitorTableData: [],
      cartTableData: [],
      salesTableData: [],

      arr: [
        {
          name: "在架商品数",
          value: "goods_up_number"
        },
        {
          name: "在架商品数",
          value: "goods_up_number"
        },
        {
          name: "商品总数",
          value: "goods_number"
        },
        {
          name: "销售库存数",
          value: "goods_sales_number"
        },
        {
          name: "动销商品数",
          value: "goods_pay_number"
        },
        {
          name: "商品浏览量",
          value: "visitor_number"
        },
        {
          name: "商品访客量",
          value: "visitor_user"
        },
        {
          name: "加购商品数",
          value: "goods_cart_number"
        },
        {
          name: "下单商品数",
          value: "goods_order_number"
        }
      ],
      dateArray: [],
      numberArray: [],
      goodsUpNumber: [],
      goodsUpDate: [],
      goodsCarNumber: [],
      goodsCarDate: [],
      goodsNumber: [],
      goodsDate: [],
      goodsOrderNumber: [],
      goodsOrderDate: [],
      goodsPayNumber: [],
      goodsPayDate: [],
      goodsSalesNumber: [],
      goodsSalesDate: [],
      visitorNumber: [],
      visitorDate: [],
      visitorUserNumber: [],
      visitorUserDate: []
    };
  },
  created() {
    this.getProductData();
    this.goodsTransactionProfilePolyline();
    this.getPayAmountRank();
    this.getVisitorRank();
    this.getCartRank();
    this.getSalesRank();
    this.getCategoryData();
  },
  mounted() {},
  methods: {
    initProfilePolyline() {
      let _that = this;
      // 交易概况
      let chartDom = document.getElementById("transactionOverview");
      let transactionOverview = echarts.init(chartDom);
      let transactionOverviewOption = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: [
            "在架商品数",
            "商品总数",
            "销售库存数",
            "动销商品数",
            "商品浏览量",
            "商品访客量",
            "加购商品数",
            "下单商品数",
            "支付商品数"
          ],
          top: "0",
          selectedMode: "multiple"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "0",
          height: "500px",
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: _that.goodsUpDate
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            name: "在架商品数",
            smooth: true,
            type: "line",
            data: _that.goodsUpNumber,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16
              }
            },
            type: "line"
          },
          {
            name: "商品总数",
            smooth: true,
            type: "line",
            data: _that.goodsNumber,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16
              }
            }
          },
          {
            name: "销售库存数",
            smooth: true,
            type: "line",
            data: _that.goodsSalesNumber,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16
              }
            }
          },
          {
            name: "动销商品数",
            smooth: true,
            type: "line",
            data: _that.goodsPayNumber,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16
              }
            }
          },
          {
            name: "商品浏览量",
            smooth: true,
            type: "line",
            data: _that.visitorNumber,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16
              }
            }
          },
          {
            name: "商品访客量",
            smooth: true,
            type: "line",
            data: _that.visitorUserNumber,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16
              }
            }
          },
          {
            name: "加购商品数",
            smooth: true,
            type: "line",
            data: _that.goodsCarNumber,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16
              }
            }
          },
          {
            name: "下单商品数",
            smooth: true,
            type: "line",
            data: _that.goodsSalesNumber,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16
              }
            }
          }
        ]
      };
      let that = this;

      transactionOverviewOption &&
        transactionOverview.setOption(transactionOverviewOption);
      transactionOverview.on("legendselectchanged", function(params) {
        console.log(params);
        that.type = [];
        let count = 0;
        for (let i in params.selected) {
          count++;
          if (params.selected[i]) {
            that.type.push(that.arr[count].value);
          }
        }
        console.log(that.type);

        // switch (name) {
        //   case '支付金额':
        //     that.type = 'pay_amount';
        //     break;
        //   case '支付人数':
        //     that.type = 'pay_user';

        //     break;
        //   case '支付订单数':
        //     that.type = 'pay_number';
        //     break;
        //     case '访问量':
        //     that.type = 'visitor_number';
        //     break;
        //     case '客单价':
        //     that.type = 'avg_pay_user_amount';
        //     break;
        //     case '访问-下单转化率':
        //     that.type = 'avg_visitor_order';
        //     break;
        //     case '下单-支付转化率':
        //     that.type = 'avg_order_pay';
        //     break;
        //     case '访问-支付转化率':
        //     that.type = 'goods_cart_number';
        //     break;
        //     default:
        //     that.type = 'pay_amount'
        // }
        that.goodsTransactionProfilePolyline();
      });
    },
    // 获取商品分类
    getCategoryData() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsCategoryTree`
      })
        .then(res => {
          this.categoryOptions = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取排行榜数据
    getPayAmountRank() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/goodsRank?start_time=${this.customerGroupForm.search_start_time}&end_time=${this.customerGroupForm.search_end_time}&sort=pay_amount&keys[]=pay_amount&category_uuid=${this.payAmountUuid}`
      })
        .then(res => {
          this.tableData = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取排行榜数据
    getVisitorRank() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/goodsRank?start_time=${this.customerGroupForm.search_start_time}&end_time=${this.customerGroupForm.search_end_time}&sort=visitor_number&keys[]=visitor_number&category_uuid=${this.visitorUuid}`
      })
        .then(res => {
          this.visitorTableData = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取排行榜数据单排序
    getCartRank() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/goodsRank?start_time=${this.customerGroupForm.search_start_time}&end_time=${this.customerGroupForm.search_end_time}&sort=goods_cart_number&keys[]=goods_cart_number&category_uuid=${this.cartUuid}`
      })
        .then(res => {
          this.cartTableData = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取排行榜数据
    getSalesRank() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/goodsRank?start_time=${this.customerGroupForm.search_start_time}&end_time=${this.customerGroupForm.search_end_time}&sort=goods_sales_number&keys[]=goods_sales_number&category_uuid=${this.salesUuid}`
      })
        .then(res => {
          this.salesTableData = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 商品概览
    getProductData() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/goodsTransactionProfile?start_time=${this.customerGroupForm.search_start_time}&end_time=${this.customerGroupForm.search_end_time}`
      })
        .then(res => {
          console.log(res);
          this.productOverview = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 商品指数趋势
    goodsTransactionProfilePolyline() {
      let str = "";
      for (let i in this.type) {
        console.log(str);
        str += "type[]=" + this.type[i] + "&";
      }
      console.log(str);
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/goodsTransactionProfilePolyline?start_time=${this.customerGroupForm.search_start_time}&end_time=${this.customerGroupForm.search_end_time}&${str}`
      })
        .then(res => {
          // this.productOverview = res.data.result
          let params = res.data.result;
          this.goodsUpNumber = [];
          this.goodsUpDate = [];
          this.goodsCarNumber = [];
          this.goodsCarDate = [];
          this.goodsNumber = [];
          this.goodsDate = [];
          this.goodsOrderNumber = [];
          this.goodsOrderDate = [];
          this.goodsPayNumber = [];
          this.goodsPayDate = [];
          this.goodsSalesNumber = [];
          this.goodsSalesDate = [];
          this.visitorNumber = [];
          this.visitorDate = [];
          this.visitorUserNumber = [];
          this.visitorUserDate = [];
          for (let i in params) {
            params[i].map(item => {
              if (i == "goods_up_number") {
                this.goodsUpNumber.push(item.number);
                this.goodsUpDate.push(item.date);
              } else if (i == "goods_cart_number") {
                this.goodsCarNumber.push(item.number);
                this.goodsCarDate.push(item.date);
              } else if (i == "goods_number") {
                this.goodsNumber.push(item.number);
                this.goodsDate.push(item.date);
              } else if (i == "goods_order_number") {
                this.goodsOrderNumber.push(item.number);
                this.goodsOrderDate.push(item.date);
              } else if (i == "goods_pay_number") {
                this.goodsPayNumber.push(item.number);
                this.goodsPayDate.push(item.date);
              } else if (i == "goods_sales_number") {
                this.goodsSalesNumber.push(item.number);
                this.goodsSalesDate.push(item.date);
              } else if (i == "visitor_number") {
                this.visitorNumber.push(item.number);
                this.visitorDate.push(item.date);
              } else {
                this.visitorUserNumber.push(item.number);
                this.visitorUserDate.push(item.date);
              }
            });
            this.initProfilePolyline();
          }
          console.log(this.goodsDate);
          this.dateArray = [];
          this.numberArray = [];
          // params.map(item => {
          //   this.dateArray.push(item.date)
          //   this.numberArray.push(item.number)
          // })
          console.log(res);
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
      this.getProductData();
    },
    // 导出类目分析导出
    goodsExport(type) {
      let str = "";
      if (type == "pay_amount") {
        str = "支付金额Top导出数据.xlsx";
      } else if (type == "visitor_number") {
        str = "访问量Top导出数据.xlsx";
      } else if (type == "goods_cart_number") {
        str = "加购数量Top导出数据.xlsx";
      } else if (type == "goods_sales_number") {
        str = "销售数量Top导出数据.xlsx";
      }
      this.axios({
        method: "get", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/goodsRankExport?start_time=${this.customerGroupForm.search_start_time}&end_time=${this.customerGroupForm.search_end_time}&sort=${type}&keys[]=${type}`
      })
        .then(response => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = str;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    }
  }
};
</script>
<style scoped>
>>> .el-form-item {
  margin-bottom: 0;
}
.overview .box {
  padding: 20px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  min-width: 175px;
}
.dataTop .top {
  width: 45%;
}
</style>
