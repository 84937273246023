<template>
  <div class="activityList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item>满减/满赠列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="活动名称" class="search_keyword">
          <el-input
            placeholder="请输入"
            v-model="customerGroupForm.search_keyword"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="活动状态">
          <el-select v-model="customerGroupForm.status" placeholder="请选择">
            <el-option label="未开始" :value="0"></el-option>
            <el-option label="进行中" :value="1"></el-option>
            <el-option label="已结束" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上线状态">
          <el-select
            v-model="customerGroupForm.search_status"
            placeholder="请选择"
          >
            <el-option label="上线" :value="0"></el-option>
            <el-option label="下线" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动时间">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getActivityList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="text-right mg-b-20">
        <el-button type="primary" @click="$router.push('/addFullReduction')"
          >新增满减/满赠</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column label="ID" :align="'center'" prop="uuid">
        </el-table-column>
        <el-table-column prop="name" label="活动名称" :align="'center'">
        </el-table-column>
        <el-table-column prop="apply_number" label="活动时间" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.start_time }} - {{ scope.row.end_time }}
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_num"
          label="商品数"
          :align="'center'"
          width="150"
        >
        </el-table-column>

        <el-table-column prop="a_status" label="活动状态" :align="'center'">
          <template slot-scope="scope">
            <span v-if="scope.row.a_status == 0">未开始</span>
            <span style="color: #2ed477" v-else-if="scope.row.a_status == 1"
              >进行中</span
            >
            <span style="color: red" v-else>已结束</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="上线状态" :align="'center'">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">上线</span>
            <span style="color: red" v-else>下线</span>
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          :align="'center'"
          fixed="right"
          width="200"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="$router.push('/addFullReduction?uuid='+ scope.row.uuid)"
              >编辑</el-button
            >
            <el-button type="text" @click="deleted(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import rangeInput from "../../../components/rangeInput.vue";
import pagination from "../../../components/pagination.vue";
export default {
  name: "fullReductionActivity",
  components: { rangeInput, pagination },
  data() {
    return {
      customerGroupForm: {
        search_start_time: "",
        search_end_time: "",
        search_status: "",
        search_keyword: "",
        status: "",
        search_order: ""
      },
      tabs: ["全部", "未开始", " 进行中", "已结束"],
      currentClass: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      activityCategoryList: [],

      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0
      }
    };
  },
  created() {
    this.getActivityList();
    this.getActivityCategorySelectList();
  },
  methods: {
    // 置顶
    toTop(row) {
      let isTop = row.is_top == 1 ? 2 : 1;

      if (row.is_top == 2 && row.is_hot == 1) {
        this.$confirm("选择活动置顶, 则取消热门推荐", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.setHot(2, row.uuid);
          this.setTop(isTop, row.uuid);
        });
      } else {
        this.setTop(isTop, row.uuid);
      }
    },
    sortChange(column) {
      if (column.order == "descending") {
        this.customerGroupForm.search_order = "create_time DESC";
      } else {
        this.customerGroupForm.search_order = "create_time ASC";
      }
      // this.customerGroupForm.search_key = column.prop
      this.getActivityList();
    },
    toHot(row) {
      let isHot = row.is_hot == 1 ? 2 : 1;
      if (row.is_top == 1 && row.is_hot == 2) {
        this.$confirm("选择热门推荐, 则取消活动置顶", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.setTop(2, row.uuid);
          this.setHot(isHot, row.uuid);
        });
      } else {
        this.setHot(isHot, row.uuid);
      }
    },

    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getActivityList();
    },


    // 获取列表
    getActivityList() {
      let data = {};
      let queryStry = `search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}&search_status=${this.customerGroupForm.search_status}&status=${this.customerGroupForm.status}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}&search_order=${this.customerGroupForm.search_order}`;
      this.getData("get", data, queryStry, "?", this.tableData);
      this.currentClass =
        this.customerGroupForm.search_status == ""
          ? 0
          : this.customerGroupForm.search_status;
    },
    // 接口
    getData(methods, data, queryStry, mark, tableData, msg = "") {
      this.axios({
        method: methods, //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        data,
        url: ` https://time.api.boyalife.cn/v1/cms/Manjian${mark}${queryStry}`
      })
        .then(res => {
          console.log(res);
          if (msg !== "") {
            this.$message.success(msg);
          }
          if (tableData !== null) {
            this.tableData = res.data.result;
          }
          if (methods == "post" || methods == "put" || methods === "delete") {
            this.getActivityList();
          }
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 删除BANNER
    deleted(row) {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true
      })
        .then(() => {
          this.getData("delete", {}, `${row.uuid}`, "/", null, "删除成功！");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除"
          });
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getActivityList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getActivityList();
    }
  }
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.activityList .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
.dataTable {
  position: relative;
}
.dataTable .tabs {
  position: absolute;
  top: 15px;
  left: 20px;
}
.dataTable .tabs p {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.dataTable .tabs p:after {
  content: "";
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.dataTable .tabs p.on:after {
  background: #409eff;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
.nickAndAvatar .el-image {
  border-radius: 0;
}
</style>
