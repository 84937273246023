<template>
  <el-dialog
    :title="title"
    :visible.sync="dailogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleClose"
    :destroy-on-close="true"
    :lock-scroll="false"
    width="30%">
    <slot></slot>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="handleClose" v-if="showCancelBtn">{{ cancelBtnText }}</el-button>
      <el-button size="mini" type="primary" @click="confirm" v-if="showConfirmBtn">{{ confirmBtnText }}</el-button>
    </span>
    </el-dialog>
</template>
<script>
export default {
  name: 'checkInfoDialog',
  props: {
    title: {
      type: String,
      default: '提示'
    },
    visible: {
      type: Boolean,
      default: false,
      required: true
    },
    showCancelBtn: {
      type: Boolean,
      default: false,
    },
    showConfirmBtn: {
      type: Boolean,
      default: false,
    },
    cancelBtnText: {
      type: String,
      default: '取消'
    },
    confirmBtnText: {
      type: String,
      default: '确定'
    }
  },
  watch: {
    visible (n) {
      this.dailogVisible = n
    }
  },
  data () {
    return {
      dailogVisible: false
    }
  },
  methods: {
    handleClose () {
      this.dailogVisible = false
      this.$emit('closeDialog', this.dailogVisible)
    },
    confirm () {
      this.$emit('confirmDialog')
      
    }
  }
}
</script>
<style></style>