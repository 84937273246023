<template>
  <div class="addInformation">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>联系我们</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="info boxLayout mg-t-20">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="客服名称" prop="service_name">
          <el-input v-model="basicInfoForm.service_name"></el-input>
        </el-form-item>
        <el-form-item label="客服热线" prop="service_tel">
          <el-input v-model="basicInfoForm.service_tel"></el-input>
        </el-form-item>
        <el-form-item label="客服二维码" >
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest"
          >
          <img
              v-if="basicInfoForm.service_qrcode != ''"
              :src="basicInfoForm.service_qrcode"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseBanner('1')">从素材库选择</el-button>
          <p class="font-12 color-999">建议尺寸：800*800像素</p>
        </el-form-item>
        <div class="info mg-t-50">
          <h3 class="title-t mg-b-30 mg-t-50">其他设置</h3>
          <el-form-item label="公众号名称" prop="official_name">
            <el-input v-model="basicInfoForm.official_name"></el-input>
          </el-form-item>
          <el-form-item label="公众号简介" prop="official_desc">
            <el-input v-model="basicInfoForm.official_desc"></el-input>
          </el-form-item>
          <el-form-item label="公众号二维码">
            <el-upload
              ref="upload"
              class="upload-demo"
              action="#"
              :accept="'jpg,jpeg,png'"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              list-type="picture-card"
              :http-request="httpRequest2"
            >
            <img
                v-if="basicInfoForm.official_qrcode != ''"
                :src="basicInfoForm.official_qrcode"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
            </el-upload>
            <el-button  type="text"  @click="chooseBanner('2')">从素材库选择</el-button>
            <p class="font-12 color-999">非必填，建议尺寸：800*800像素</p>
          </el-form-item>
          <el-form-item class="mg-t-50" label="群聊名称">
            <el-input v-model="basicInfoForm.chat_name"></el-input>
          </el-form-item>
          <el-form-item label="群聊简介">
            <el-input v-model="basicInfoForm.chat_desc"></el-input>
          </el-form-item>
          <el-form-item label="群聊二维码">
            <el-upload
              ref="upload"
              class="upload-demo"
              action="#"
              :accept="'jpg,jpeg,png'"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              list-type="picture-card"
              :http-request="httpRequest3"
            >
            <img
                v-if="basicInfoForm.chat_qrcode != ''"
                :src="basicInfoForm.chat_qrcode"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
            </el-upload>
            <el-button  type="text"  @click="chooseBanner('3')">从素材库选择</el-button>
            <p class="font-12 color-999">非必填，建议尺寸：800*800像素</p>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialogVue :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog" />
      
    </el-dialog>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submitForm">保存</el-button>
      <!-- <el-button size="mini" @click="$router.go(-1)">返回</el-button> -->
    </div>
  </div>
</template>
<script>
import imageDialogVue from "../../../components/imageDialog.vue";
export default {
  name: "addInformation",
  components: { imageDialogVue },
  data() {
    var validateImgList = (rule, value, callback) => {
      if (value.length < 1) {
        callback(new Error("请至少上传一张封面图"));
      } else {
        callback();
      }
    };
    return {
      pageType: "新增",
      imgType: "",
      labelDialogVisible: false,
      tags: [],
      active: 0,
      dialogVisible: false,
      uuid: "",
      options: [],
      props: {
        label: "category_name",
        value: "uuid",
        children: "children",
      },
      selectType: "",
      value2: "",
      value3: "",
      basicInfoForm: {
        service_name: "",
        service_tel: "",
        service_qrcode: "",
        official_name: "",
        official_desc: "",
        official_qrcode: "",
        chat_name: "",
        chat_desc: "",
        chat_qrcode: "",
      },
      formData: null,
      rules: {
        service_name: [
          { required: true, message: "请输入标题", trigger: "blur" },
        ],
        service_tel: [
          { required: true, message: "请填写简介详情", trigger: "blur" },
        ],
        official_name: [
          { required: true, message: "请填写公众号名称", trigger: "blur" },
        ],
        official_desc: [
          { required: true, message: "请填写简介详情", trigger: "blur" },
        ],
        service_qrcode: { validator: validateImgList, trigger: "blur" },
        official_qrcode: { validator: validateImgList, trigger: "blur" },
        chat_name: [
          { required: true, message: "请填写群聊名称", trigger: "blur" },
        ],
        chat_desc: [
          { required: true, message: "请填写简介详情", trigger: "blur" },
        ],
        chat_qrcode: { validator: validateImgList, trigger: "blur" },
      },
    };
  },
  created() {
    this.getInfo();
  },

  methods: {
    // 选择封面
    confirmImageDialog(item) {
      if (this.selectType == '1') {
        this.basicInfoForm.service_qrcode = item[0].url;
      } else if (this.selectType == '2'){
        this.basicInfoForm.official_qrcode = item[0].url;
      } else {
        this.basicInfoForm.chat_qrcode = item[0].url;
      }
      this.dialogVisible = false;
    },
    closeImageDialog () {
      this.dialogVisible = false
    },
     // 上传图片
     httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.service_qrcode =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    httpRequest2() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.official_qrcode =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    httpRequest3() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.chat_qrcode =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    // 选择图片
    chooseBanner(n) {
      this.selectType = n;
      this.dialogVisible = true;
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Contact`,
      }).then((res) => {
        let params = res.data.result;
        for (let i in params) {
          this.basicInfoForm[i] = params[i];
        }
      });
    },
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.axios({
            method: "post", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.basicInfoForm,
            url: ` https://time.api.boyalife.cn/v1/cms/Contact`,
          }).then((res) => {
            this.$message.success("保存成功！");
          });
        }
      });
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.addInformation {
  margin-bottom: 80px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar-uploader {
  position: relative;
}
.avatar-uploader .el-icon-delete {
  font-size: 20px;
  position: absolute;
  right: -40px;
  bottom: 0px;
}
.service_qrcode_list {
  flex-wrap: wrap;
  margin-top: 20px;
}
.service_qrcode_list .img {
  position: relative;
  border: 1px solid #dcdcdc;
  width: 220px;
  height: 220px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.service_qrcode_list .img .el-icon-delete {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  z-index: 2;
  cursor: pointer;
}
.service_qrcode_list .img .el-image {
  width: 100%;
  height: 100%;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
>>> .el-form .el-input {
  width: 400px;
}
.insertImg {
  width: 52px;
  height: 40px;
  position: absolute;
  right: 159px;
  top: 0;
  z-index: 2;
  cursor: pointer;
}
.insertVideo {
  width: 52px;
  height: 40px;
  position: absolute;
  right: 107px;
  top: 0;
  z-index: 2;
  cursor: pointer;
}
.el-tag {
  margin-right: 10px;
  margin-bottom: 5px;
}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
</style>
