<template>
  <div class="addGroup">
    <div class="stepList boxLayout">
      <el-steps
        :space="'50%'"
        :active="active"
        finish-status="success"
        align-center
      >
        <el-step
          title="基本信息"
          description="请填写分组的基础信息，方便管理会员分组"
        ></el-step>
        <el-step
          title="分组规则"
          description="设置分组规则，360度会员透视"
        ></el-step>
      </el-steps>
    </div>
    <el-form
      ref="form"
      :model="basicInfoForm"
      label-width="120px"
      :rules="rules"
    >
      <div class="basicInfo boxLayout mg-t-20" v-if="active === 0">
        <h3 class="title-t mg-b-30">基础信息</h3>
        <el-form-item
          label="分组名称"
          prop="name"
          :rules="{
            required: true,
            message: '分组名称不能为空。',
            trigger: 'blur',
          }"
        >
          <el-input
            v-model="basicInfoForm.name"
            placeholder="用于企业内部展示的分组名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="分组描述" prop="desc">
          <el-input
            type="textarea"
            v-model="basicInfoForm.desc"
            placeholder="请输入分组描述，分组描述仅用于内部展示"
          ></el-input>
        </el-form-item>
        <el-form-item label="更新方式" prop="update_type">
          <el-radio v-model="basicInfoForm.update_type" :label="1" border
            >手动</el-radio
          >
          <el-radio v-model="basicInfoForm.update_type" :label="2" border
            >例行</el-radio
          >
          <div
            class="font-12 color-999"
            style="width: 500px"
            v-if="basicInfoForm.update_type == 2"
          >
            系统自动每
            <el-input-number
              class="day"
              v-model="basicInfoForm.update_interval"
              :min="1"
            ></el-input-number
            >天更新一次。更新时间为凌晨00:00:00
          </div>
        </el-form-item>
        <el-form-item label="创建方式">
          <el-tag>标签创建</el-tag>
        </el-form-item>
      </div>
      <div class="boxLayout mg-t-20" v-else>
        <h3 class="title-t mg-b-30">分组规则</h3>
        <el-form-item label="选择标签">
          <div class="label" id="myLabelBox">
            <div class="search">
              <el-input
                v-model="keyword"
                @input="getList"
                placeholder="请输入关键词搜索"
                ><el-button slot="append" icon="el-icon-search"></el-button
              ></el-input>
            </div>
            <div
              :data="index"
              class="d_jump"
              v-for="(item, index) in labelData"
              :key="item.id"
            >
              <h4 v-if="item.label.length > 0">{{ item.name }}</h4>
              <div class="flex">
                <el-checkbox-group v-model="newLabelData">
                  <el-checkbox
                    :key="jtem.uuid"
                    :label="jtem.uuid"
                    border
                    size="medium"
                    v-for="(jtem, Jndex) in item.label"
                    @change="chooseTag(index, Jndex, jtem)"
                    >{{ jtem.name }}</el-checkbox
                  >
                </el-checkbox-group>
                <!-- <span :class="{'on': States[index]==Jndex}" v-for="(jtem,Jndex) in item.children" :key="jtem.value" @click="chooseTag(index,Jndex,jtem)">{{jtem.label}}</span> -->
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-tag
            :key="tag.uuid"
            v-for="(tag, index) in dynamicTags"
            closable
            :disable-transitions="false"
            @close="handleClose(index)"
          >
            {{ tag.name }}
          </el-tag>
        </el-form-item>
        <el-form-item label="标签规则" prop="label_rule">
          <el-radio v-model="basicInfoForm.label_rule" :label="1" border
            >满足任意一个标签</el-radio
          >
          <el-radio v-model="basicInfoForm.label_rule" :label="2" border
            >同时满足所有标签</el-radio
          >
        </el-form-item>
      </div>
    </el-form>

    <div class="operateBtn">
      <el-button
        type="primary"
        size="mini"
        v-if="active === 1"
        @click="active = 0"
        >上一步</el-button
      >
      <el-button type="primary" v-if="active === 0" size="mini" @click="toNext"
        >下一步</el-button
      >
      <el-button size="mini" v-if="active === 1" @click="submit('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      active: 0,
      state: "",
      timeout: null,
      keyword: "",
      showDay: false,
      basicInfoForm: {
        update_type: 1,
        name: "",
        update_interval: "",
        label_rule: 1,
        label_uuid: [],
      },
      newLabelData: [],
      labelData: [],
      dynamicTags: [],
      rules: {
        name: { required: true, message: "请输入", trgger: "blur" },
      },
    };
  },
  created() {
    this.getList();
    if (Object.keys(this.$route.query).length !== 0) {
      this.getInfo();
    }
  },
  methods: {
    getInfo() {
      //  https://time.api.boyalife.cn/v1/cms/UserGroup/{$uuid}
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserGroup/${this.$route.query.uuid}`,
      })
        .then((res) => {
          let params = res.data.result;
          delete params.admin;
          delete params.admin_uuid;
          delete params.create_time;
          delete params.delete_time;
          delete params.update_time;
          delete params.uuid;
          delete params.user_number;
          for (let i in params) {
            this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
          }
          this.basicInfoForm.update_type = Number(
            this.basicInfoForm.update_type
          );
          this.basicInfoForm.label_rule = Number(this.basicInfoForm.label_rule);

          this.dynamicTags = JSON.parse(
            JSON.stringify(this.basicInfoForm.label)
          );
          this.newLabelData = this.dynamicTags.map((item) => {
            return item.uuid;
          });
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserLabelGroupTree?search_keyword=${this.keyword}`,
      })
        .then((res) => {
          this.labelData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    toNext() {
      if (this.active == 0) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.active++;
          } else {
            return false;
          }
        });
      }
    },
    submit() {
      delete this.basicInfoForm.admin;
      delete this.basicInfoForm.create_time;
      delete this.basicInfoForm.admin_uuid;
      delete this.basicInfoForm.delete_time;
      delete this.basicInfoForm.label;
      delete this.basicInfoForm.update_time;
      delete this.basicInfoForm.uuid;

      if (this.dynamicTags.length > 0) {
        this.basicInfoForm.label_uuid = this.dynamicTags.map((item) => {
          return item.uuid;
        });
      } else {
        this.$message.warning("请至少选择一个标签");
        return false;
      }
      let methods = "";
      let uuid = "";
      if (Object.keys(this.$route.query).length !== 0) {
        methods = "put";
        uuid = "/" + this.$route.query.uuid;
      } else {
        methods = "post";
        uuid = "";
      }

      this.axios({
        method: methods, //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.basicInfoForm,
        url: ` https://time.api.boyalife.cn/v1/cms/UserGroup${uuid}`,
      })
        .then((res) => {
          this.$message.success("保存成功！");
          this.$router.go(-1);
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    querySearchAsync(queryString, cb) {
      var labelData = this.labelData;
      var results = queryString
        ? labelData.filter(this.createStateFilter(queryString))
        : labelData;
      console.log(cb);

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 3000 * Math.random());
    },
    createStateFilter(queryString) {
      return (state) => {
        return (
          state.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      console.log(item);
    },
    handleChange(value) {
      console.log(value);
    },
    chooseTag(index, Jndex, jtem) {
      if (this.newLabelData.indexOf(jtem.uuid) !== -1) {
        this.dynamicTags.push(this.labelData[index].label[Jndex]);
      } else {
        this.dynamicTags = this.dynamicTags.filter(
          (item) => item.uuid !== jtem.uuid
        );
      }
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      this.newLabelData.splice(this.newLabelData.indexOf(tag), 1);
    },
  },
};
</script>
<style scoped>
.addGroup {
  margin-bottom: 80px;
}
.el-form {
}
.el-form .day {
  width: 120px;
  margin: 20px 10px;
}
>>> .el-checkbox.is-bordered.el-checkbox--medium .el-checkbox__inner {
  display: none;
}
#myLabelBox {
  width: 750px;
  height: 400px;
  overflow: auto;
  position: relative;
  border: 1px solid rgb(233, 233, 233);
}
.d_jump {
  padding: 0 20px;
}
.search {
  padding: 20px;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
}
>>> .el-checkBox {
  margin-right: 0;
}
#myLabelBox > div {
  margin-bottom: 20px;
}
#myLabelBox .flex {
  flex-wrap: wrap;
}
#myLabelBox h4 {
  font-size: 12px;
  font-weight: bold;
}
#myLabelBox .flex span {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid rgb(233, 233, 233);
  margin-right: 15px;
  margin-bottom: 5px;
  font-size: 12px;
  cursor: pointer;
}
.el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}
>>> .el-checkbox {
  margin-right: 10px;
}
>>> .el-checkbox,
>>> .el-button + .el-button,
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}
.operateBtn >>> .el-button + .el-button {
  margin-left: 10px;
}
</style>
