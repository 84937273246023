<template>
  <div class="usualAskQuestion" :loading="load">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>常见问题</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="问题分类">
          <el-select
            v-model="customerGroupForm.search_category"
            placeholder="请选择活动区域"
          >
            <el-option
              v-for="(item, i) in problemCategory"
              :key="i"
              :value="i"
              :label="item"
              >{{ item }}</el-option
            >
          </el-select>
        </el-form-item>
        <el-form-item label="发布日期">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入关键字"
            v-model="customerGroupForm.search_keyword"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getCommonProblem"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
      </div>
    </div>
    <div class="boxLayout mg-t-20">
      <div class="text-right mg-b-20">
        <el-button type="primary" @click="addAsk('add')">添加问题</el-button>
        <el-upload
          class="upload-demo"
          action="#"
          accept=".xlsx"
          :show-file-list="false"
          :http-request="httpRequest"
          :before-upload="beforeUpload"
        >
          <el-button type="primary">导入</el-button>
        </el-upload>
        <a
          href="https://time.api.boyalife.cn/excel/%E5%B8%B8%E8%A7%81%E9%97%AE%E9%A2%98%E5%AF%BC%E5%85%A5.xlsx"
          style="color: #409eff; font-size: 12px"
          class="mg-l-20"
          >下载导入模板</a
        >
      </div>
      <el-table
        class="mg-t-20"
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          label="问题分类"
          prop="category.name"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column label="问题" prop="ask" :align="'center'">
        </el-table-column>
        <el-table-column label="回答内容" prop="answer" :align="'center'">
        </el-table-column>
        <el-table-column label="发布时间" prop="update_time" :align="'center'">
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          width="150"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              @click="addAsk('edit', scope.row)"
              type="text"
              size="small"
              >编辑</el-button
            >
            <el-button @click="deleted(scope.row)" type="text" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog title="添加常见问题" :visible.sync="dialogVisible">
      <el-form
        :model="form"
        label-width="90px"
        :rules="rules"
        class="mg-t-20"
        ref="form"
      >
        <el-form-item label="问题分类" prop="category_uuid">
          <el-select v-model="form.category_uuid" placeholder="请选择">
            <el-option
              v-for="(item, i) in problemCategory"
              :key="i"
              :value="i"
              :label="item"
              >{{ item }}</el-option
            >
          </el-select>
        </el-form-item>
        <el-form-item label="问题" prop="ask">
          <el-input v-model="form.ask" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="回答" prop="answer">
          <el-input
            type="textarea"
            rows="5"
            resize="none"
            v-model="form.answer"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
import fileDownload from "js-file-download";

export default {
  name: "usualAskQuestion",
  components: {
    pagination,
  },
  data() {
    return {
      load: false,
      editType: "",
      dialogVisible: false,
      problemCategory: [],
      uuid: "",
      customerGroupForm: {
        search_category: "",
        search_keyword: "",
        search_end_time: "",
        search_start_time: "",
      },
      form: {
        category_uuid: Number,
        answer: "",
        ask: "",
      },
      rules: {
        category_uuid: {
          required: true,
          message: "请选择问题类型",
          trigger: "blur",
        },
        answer: { required: true, message: "请输入答案", trigger: "blur" },
        ask: { required: true, message: "请输入问题", trigger: "blur" },
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getCommonProblem();
    this.getCommonProblemCategory();
  },
  methods: {
    beforeUpload(file) {
      console.log(file);
      this.load = true;
      this.$message.info("稍等片刻，正在上传...");
      if (file.name.split(".")[1].toLowerCase() != "xlsx") {
        this.$message.error("文件格式错误~");
        return false; // 终止上传
      }
      this.formData = new FormData();
      this.formData.append("file", file);
    },
    // 上传图片
    httpRequest(data) {
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.formData,
        url: ` https://time.api.boyalife.cn/v1/cms/CommonProblem/import`,
      })
        .then((res) => {
          this.$message.success("保存成功！");
          this.getCommonProblem();
          this.load = false;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 常见问题分类
    getCommonProblemCategory(data) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/CommonProblemCategorySelect`,
      })
        .then((res) => {
          this.problemCategory = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getCommonProblem();
    },
    addAsk(str, row) {
      this.dialogVisible = true;
      if (str == "add") {
        this.editType = "add";
        this.form = {
          category_uuid: "",
          answer: "",
          ask: "",
        };
      } else {
        this.editType = "edit";
        this.uuid = row.uuid;
        this.form = {
          category_uuid: String(row.category_uuid),
          answer: row.answer,
          ask: row.ask,
        };
      }
    },
    getData(methods, data, queryStry, mark, tableData, msg = "") {
      this.axios({
        method: methods, //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data,
        url: ` https://time.api.boyalife.cn/v1/cms/CommonProblem${mark}${queryStry}`,
      })
        .then((res) => {
          this.dialogVisible = false;
          if (msg !== "") {
            this.$message.success(msg);
          }
          if (tableData !== null) {
            this.tableData = res.data.result;
          }
          if (methods === "delete" || methods === "post" || methods === "put") {
            this.getCommonProblem();
          } else {
            for (let i in this.form) {
              this.form[i] = "";
            }
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 列表
    getCommonProblem() {
      let data = {};
      let queryStry = `search_category=${this.customerGroupForm.search_category}&search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}`;
      this.getData("get", data, queryStry, "?", this.tableData);
    },
    // 提交表单
    submitForm() {
      if (this.editType == "edit") {
        let data = { ...this.form };
        this.getData(
          "put",
          data,
          `${this.uuid}`,
          "/",
          this.tableData,
          "编辑成功！"
        );
      } else {
        let data = { ...this.form };
        this.getData("post", data, "", "", this.tableData, "新增成功！");
      }
    },
    deleted(row) {
      this.$confirm("信息删除后无法恢复，确定要删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.getData("delete", {}, `${row.uuid}`, "/", {}, "删除成功！");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    closeDialog(v) {
      this.dialogVisible = v;
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getCommonProblem();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getCommonProblem();
    },
  },
};
</script>
<style scoped>
>>> .el-table .el-button {
  width: 40px;
}
.upload-demo {
  display: inline-block;
  margin-left: 10px;
}
</style>
