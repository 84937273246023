<template>
  <div class="physicalCommodityList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item >商品</el-breadcrumb-item>
      <el-breadcrumb-item >商品管理</el-breadcrumb-item>
      <el-breadcrumb-item >素材库</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <materialLibrary />
    </div>
  </div>
</template>
<script>
import materialLibrary from '../../../components/materialLibrary/index.vue';
export default {
  name: '',
  components: {
    materialLibrary
  },
  data () {
    return {

    }
  },
  methods: {}
}
</script>
<style></style>