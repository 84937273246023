<template>
  <div class="flowAnalysis" style="background: #f0f2f5;">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item >数据</el-breadcrumb-item>
      <el-breadcrumb-item >客户分析</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 商城订单 项目订单 快捷操作 会员数据概况 -->
    <div class="topSquare  boxLayout">
      <h3 class="title-t mg-b-30">客户概况</h3>
      <div class="analysis flex space-around">
        <div class="text-center">
          <h6 class="font-12 color-999">浏览量</h6>
          <p class="font-30">14300</p>
          <p class="font-12">昨日 <span style="color:#2561EF;">1000</span></p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">访客数</h6>
          <p class="font-30">14300</p>
          <p class="font-12">昨日 <span style="color:#2561EF;">1000</span></p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">跳出率 </h6>
          <p class="font-30">53.45%</p>
          <p class="font-12">昨日 <span style="color:#2561EF;">45%</span></p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">平均访问时长</h6>
          <p class="font-30"> 01:08:09</p>
          <p class="font-12">昨日 <span style="color:#2561EF;">02:08:01</span></p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">订单转化率</h6>
          <p class="font-30">4.40%</p>
          <p class="font-12">昨日 <span style="color:#2561EF;">4.40%</span></p>
        </div>
      </div>
      <div class="title flex space-between align-center mg-t-50">
        <h3 class="title-t">流量趋势</h3>
        <div class="flex">
          <el-form :inline="true" :model="customerGroupForm" class="demo-form-inline">
            <el-form-item label="选择时间">
              <el-date-picker
                v-model="customerGroupForm.registerDate"
                type="datetimerange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-form>
          <el-button>导出</el-button>  
        </div>
      </div>
      <div class="tansData mg-t-30">
        <div id="customerOverview" ref="customerOverview"  style="width: 100%; height: 350px;"></div>
      </div>
    </div>
    <div class="boxLayout mg-t-20">
        <div class="title flex space-between align-center">
          <h3 class="title-t">新访客/老访客占比</h3>
          <div class="flex">
            <el-form :inline="true" :model="customerGroupForm" class="demo-form-inline">
              <el-form-item label="选择时间">
                <el-date-picker
                  v-model="customerGroupForm.registerDate"
                  type="datetimerange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
            </el-form>
            <el-button>导出</el-button>  
          </div>
        </div>
        <div class="myChartBox" style="width: 100%; height: 400px">
          <div class="totalNum color-333 font-30 text-center font-bold" >
            14000
            <p class="color-999 font-12">访客数</p>
          </div>
          <div id="myChart" ref="myChart"  style="width: 100%; height: 100%;"></div>
        </div>
    </div>
    <div class="flex dataTop boxLayout mg-t-50 space-between">
        <div class="top">
          <div>
            <h3 class="title-t mg-b-20">文章访问量TOP</h3>
            <div class=" flex space-between align-center">
              <el-form :inline="true" :model="customerGroupForm" class="demo-form-inline">
              <el-form-item label="选择时间">
                <el-date-picker
                  v-model="customerGroupForm.registerDate"
                  type="datetimerange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
            </el-form>
               <el-button>导出</el-button>
            </div>
          </div>
          <el-table
            class="mg-t-20"
            :data="tableData"
            style="width: 100%"
            border

            >
            <el-table-column
              prop="top"
              label="排名"
              align="center"
              type="index"
              width="60"
              >
            </el-table-column>
            <el-table-column
              prop="name"
              show-overflow-tooltip
              label="文章标题"
              align="center"
              >
            </el-table-column>
            <el-table-column
              prop="money"
              label="访客数"
              align="center"
              >
            </el-table-column>
            <el-table-column
              prop="money"
              label="访问-支付转化率"
              align="center"
              >
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              >
              <template slot-scope="scope">
                <el-button
                  @click="$router.push('/productInfo')"
                  type="text"
                  size="small">
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="top">
          <div class="title">
            <h3 class="title-t mg-b-20">早报访问量TOP</h3>
            <div class="flex space-between align-center">
              <el-form :inline="true" :model="customerGroupForm" class="demo-form-inline">
              <el-form-item label="选择时间">
                <el-date-picker
                  v-model="customerGroupForm.registerDate"
                  type="datetimerange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
            </el-form>
               <el-button>导出</el-button>
            </div>
          </div>
          <el-table
            class="mg-t-20"
            :data="tableData"
            style="width: 100%"
            border
            >
            <el-table-column
              prop="top"
              label="排名"
              align="center"
              type="index"
              width="60"
              >
            </el-table-column>
            <el-table-column
              prop="name"
              show-overflow-tooltip
              label="早报标题"
              align="center"
              >
            </el-table-column>
            <el-table-column
              prop="money"
              label="访客数"
              align="center"
              >
            </el-table-column>
            <el-table-column
              prop="money"
              label="访问-支付转化率"
              align="center"
              >
            </el-table-column>
            <el-table-column
              align="center"
              label="操作"
              >
              <template slot-scope="scope">
                <el-button
                  @click="$router.push('/productInfo')"
                  type="text"
                  size="small">
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
  </div>
</template>
<script>
//引入echarts文件
import * as echarts from 'echarts'
export default {
  name: 'flowAnalysis',
  data () {
    return {
      customerGroupForm: {},
      checkList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime())
              start.setTime(start.getTime() - start.getHours()* 60 * 60 * 1000 - start.getMinutes() * 60 * 1000 - start.getSeconds() * 1000 - start.getMilliseconds());
              picker.$emit('pick', [start, end]);
            }
          },  
          {
            text: '昨天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1- start.getHours()* 60 * 60 * 1000 - start.getMinutes() * 60 * 1000 - start.getSeconds() * 1000 - start.getMilliseconds());
              picker.$emit('pick', [start, end]);
            }
          },  
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7- start.getHours()* 60 * 60 * 1000 - start.getMinutes() * 60 * 1000 - start.getSeconds() * 1000 - start.getMilliseconds());
              picker.$emit('pick', [start, end]);
            }
          }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30- start.getHours()* 60 * 60 * 1000 - start.getMinutes() * 60 * 1000 - start.getSeconds() * 1000 - start.getMilliseconds());
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      tableData: [{
        id: 9999999,
        avatar: 'https://axure-file.lanhuapp.com/06b9f5b8-0544-41f9-bee3-1322615ce006__b91b040875a6a0217563d85598fdfc7e.png',
        nickName: '一级分类/二级分类',
        phone: '2322',
        level: '7099',
        label: '1222',
        times: '1',
        money: '电子数码-相机',
        hasMoney: '2222',
        integral: '显示',
        identity: '2022/08/29 11:20:33',
        status: '正常',
        time: '2022-5-23 16:00:00',
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        id: 3,
        avatar: 'https://axure-file.lanhuapp.com/06b9f5b8-0544-41f9-bee3-1322615ce006__b91b040875a6a0217563d85598fdfc7e.png',
        nickName: '一级分类/二级分类',
        phone: '2322',
        level: '7099',
        label: '1222',
        times: '1',
        money: '电子数码-相机',
        hasMoney: '2222',
        integral: '显示',
        identity: '2022/08/29 11:20:33',
        status: '正常',
        time: '2022-5-23 16:00:00',
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        id: 2,
        avatar: 'https://axure-file.lanhuapp.com/06b9f5b8-0544-41f9-bee3-1322615ce006__b91b040875a6a0217563d85598fdfc7e.png',
        nickName: '一级分类/二级分类',
        phone: '2322',
        level: '7099',
        label: '1222',
        times: '1',
        money: '电子数码-相机',
        hasMoney: '2222',
        integral: '显示',
        identity: '2022/08/29 11:20:33',
        status: '正常',
        time: '2022-5-23 16:00:00',
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, ],
    }
  },
  mounted () {
    this.getCustomerOverview()
    this.getCustomerData()
    this.getSexData()
    this.getAgeData()
    this.getSpendPower()
    this.getTransactionOverview()
  },
  methods: {
    getCustomerOverview () {
      // 交易数据
      let chartDom = document.getElementById('customerOverview');
      let transactionOverview = echarts.init(chartDom);
      let transactionOverviewOption = {
        tooltip: {
          trigger: 'axis'
        },
          legend: {
            data: ['浏览量',  '访客数', '跳出率', '平均访问时长', '订单转化率'],
            bottom: '0',
            selectedMode:'single'
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '40px',
            height: "300px",
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['2023-03-01', '2023-03-01', '2023-03-01', '2023-03-01', '2023-03-01', '2023-03-01', '2023-03-01']
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '浏览量',
              type: 'line',
              data: [120, 132, 101, 134, 90, 230, 210],
              label: {
                show: false,
                position: 'top',
                textStyle: {
                  color: 'gray',
                  fontSize: 16
                }
              },
            },
            {
              name: '访客数',
              type: 'line',
              data: [220, 182, 191, 234, 290, 330, 310],
              label: {
                show: false,
                position: 'top',
                textStyle: {
                  color: 'gray',
                  fontSize: 16
                }
              }
            },
            {
              name: '跳出率',
              type: 'line',
              data: [150, 232, 201, 154, 190, 330, 410],
              label: {
                show: false,
                position: 'top',
                textStyle: {
                  color: 'gray',
                  fontSize: 16
                }
              }
            },
            {
              name: '平均访问时长',
              type: 'line',
              data: [320, 332, 301, 334, 390, 330, 320],
              label: {
                show: false,
                position: 'top',
                textStyle: {
                  color: 'gray',
                  fontSize: 16
                }
              }
            },
            {
              name: '订单转化率',
              type: 'line',
              data: [320, 332, 301, 334, 390, 330, 320],
              label: {
                show: false,
                position: 'top',
                textStyle: {
                  color: 'gray',
                  fontSize: 16
                }
              }
            },
          ]
      };

      transactionOverviewOption && transactionOverview.setOption(transactionOverviewOption);
    },
    getCustomerData () {
      // 会员数据概况
      let dom = document.getElementById('myChart');
      let myChart = echarts.init(dom, null, {
        renderer: 'canvas',
        useDirtyRect: false
      });
      let option;
      let datas = [
        { name: `新访客`, value: 4800 },
        { name: `老访客`, value: 11220 },
      ];
      option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          bottom: '5%',
          left: 'center'
        },
        series: [{
            type: 'pie',
            radius: [70, 100],
            top: top + '%',
            height: '100%',
            left: 'center',
            width: 600,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1,
              
            },
            
            label: {
              alignTo: 'edge',
              formatter: '{b}: {@2012} ({d}%) \n\n',
              minMargin: 0,
              edgeDistance: 20,
              lineHeight: 10,
              textStyle:{
                fontSize: 14
              },
            
            },
            labelLine: {
              length: 15,
              length2: 0,
              maxSurfaceAngle: 120
            },
            labelLayout: function (params) {
              const isLeft = params.labelRect.x < myChart.getWidth() / 2;
              const points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points
              };
            },
            data: datas
          }
        ]
      };
      if (option && typeof option === 'object') {
        myChart.setOption(option);
      }
      window.addEventListener('resize', myChart.resize);
    },

    getSpendPower () {
      // 交易概况
      let chartDom = document.getElementById('spendPower');
      let spendPower = echarts.init(chartDom);
      let spendPowerOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {
          bottom: 0
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: ['100以下', '100-500', '500-1000', '1000-1500', '1500-2000', '2000-2500', '2500-3000',  '3000以上']
        },
        yAxis: {
          type: 'value'
          
        },
        series: [
          {
            name: '新客户',
            type: 'bar',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [320, 302, 301, 334, 390, 330, 320, 309]
          },
          {
            name: '老客户',
            type: 'bar',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [120, 132, 101, 134, 90, 230, 210, 309]
          },
        ]
      }

      spendPowerOption && spendPower.setOption(spendPowerOption);
    },
    getTransactionOverview () {
      let chartDom = document.getElementById('transactionOverview');
      let transactionOverview = echarts.init(chartDom);
      let transactionOverviewOption = {
        tooltip: {
          trigger: 'axis'
        },
          legend: {
            bottom: 0
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            height: "400px",
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['2023-03-01', '2023-03-01', '2023-03-01', '2023-03-01', '2023-03-01', '2023-03-01', '2023-03-01']
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '新成交客户数',
              smooth: true,
              type: 'line',
              data: [120, 132, 101, 134, 90, 230, 210],
              label: {
                show: false,
                position: 'top',
                textStyle: {
                  color: 'gray',
                  fontSize: 16
                }
              },
              type: 'line'
            },
            {
              name: '老成交客户数',
              smooth: true,
              type: 'line',
              data: [220, 182, 191, 234, 290, 330, 310],
              label: {
                show: false,
                position: 'top',
                textStyle: {
                  color: 'gray',
                  fontSize: 16
                }
              }
            },
          ]
      };

      transactionOverviewOption && transactionOverview.setOption(transactionOverviewOption);
    }
  }
}
</script>
<style scoped>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
    margin-bottom: 0;
  }
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
}
.topSquare > .el-row  {
  height: 536px;
}
.topSquare .el-col-12 {
  height: 100%;
}
.topSquare > .el-row > .el-col > .grid-content {
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  margin-bottom: 0;
}

.el-col-5 {
  width: 20%!important;
}

.myChartBox {
  position: relative;
}
.myChartBox .totalNum {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.el-form-item {
  margin-bottom: 0;
}
.dataTop .top {
  width: 48%;
}
</style>