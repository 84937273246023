<template>
  <div class="brandList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>门店</el-breadcrumb-item>
      <el-breadcrumb-item>门店管理</el-breadcrumb-item>
      <el-breadcrumb-item>品牌列表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="dataTable">
      <div class="flex space-between">
        <el-form
          :inline="true"
          :model="customerGroupForm"
          class="demo-form-inline"
        >
          <el-form-item class="keyword">
            <el-input
              placeholder="请输入要搜索的内容"
              clearable
              v-model="customerGroupForm.search_keyword"
              @input="getBrandList"
            >
              <i
                slot="append"
                class="el-icon-search"
                style="cursor: pointer"
              ></i>
            </el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-button type="primary" @click="editBrand('add')">新建</el-button>
        </div>
      </div>

      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <!-- <el-table-column
          type="selection"
          width="55">
        </el-table-column> -->
        <el-table-column
          label="序号"
          :align="'center'"
          type="index"
          width="55px"
        >
        </el-table-column>
        <el-table-column
          label="品牌名称"
          :align="'center'"
          prop="name"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="update_time" label="更新时间" :align="'center'">
        </el-table-column>
        <el-table-column
          prop="supplier_number"
          label="供应商数量"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          fixed="right"
          width="250"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="editBrand('edit', scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="deleteBrand(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog
      :title="title"
      :visible.sync="checkInfoDialogVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="form" label-width="80px" ref="addForm" :rules="rules">
        <el-form-item label="品牌名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelEdit">取 消</el-button>
        <el-button type="primary" @click="submit('addForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
import checkInfoDialog from "../../../components/checkInfoDialog.vue";
export default {
  name: "brandList",
  components: {
    checkInfoDialog,
    pagination,
  },
  data() {
    return {
      title: "编辑品牌",
      editType: "",
      uuid: "",
      customerGroupForm: {
        search_keyword: "",
      },
      form: {
        name: "",
      },
      rules: {
        name: [{ required: true, message: "品牌名不能为空", trigger: "blur" }],
      },
      checkInfoDialogVisible: false,
      labelOptions: [
        {
          label: "启用",
          value: "ok",
        },
        {
          label: "禁用",
          value: "no",
        },
      ],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getBrandList();
  },
  methods: {
    closeDialog(v) {
      this.checkInfoDialogVisible = v;
    },
    cancelEdit() {
      this.checkInfoDialogVisible = false;
      this.form.name = "";
    },
    editBrand(v, row) {
      this.editType = v;
      this.checkInfoDialogVisible = true;
      this.title = v === "add" ? "新增品牌" : "编辑品牌";
      if (row !== null) {
        this.uuid = row.uuid;
        this.form.name = row.name;
      }
    },
    getData(methods, data, queryStry, mark, tableData, msg = "") {
      this.axios({
        method: methods, //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data,
        url: ` https://time.api.boyalife.cn/v1/cms/Brand${mark}${queryStry}`,
      })
        .then((res) => {
          console.log(res);
          if (msg !== "") {
            this.$message.success(msg);
          }
          if (methods == "post" || methods == "put" || methods === "delete") {
            this.getBrandList();
          }
          if (tableData !== null) {
            this.tableData = res.data.result;
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 列表
    getBrandList() {
      let data = {};
      let queryStry = `search_keyword=${this.customerGroupForm.search_keyword}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}`;
      this.getData("get", data, queryStry, "?", this.tableData);
    },
    // 新增or编辑
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.editType === "add") {
            let data = {
              name: this.form.name,
            };
            this.getData("post", data, "", "", this.tableData, "新增成功！");
            this.checkInfoDialogVisible = false;
          } else {
            let data = {
              name: this.form.name,
            };
            let queryStry = `${this.uuid}`;
            this.getData(
              "put",
              data,
              queryStry,
              "/",
              this.tableData,
              "编辑成功！"
            );
            this.checkInfoDialogVisible = false;
          }

          this.form.name = "";
        } else {
          return false;
        }
      });
    },
    deleteBrand(row) {
      this.$confirm("确定删除该品牌吗?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let queryStry = `${row.uuid}`;
        this.getData(
          "delete",
          { name: row.name },
          queryStry,
          "/",
          this.tableData,
          "删除成功！"
        );
      });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getBrandList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getBrandList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.brandList .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
.dataTable {
  position: relative;
  margin-top: 0;
}
.dataTable .tabs {
  position: absolute;
  top: 35px;
  left: 20px;
}
.dataTable .tabs p {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.dataTable .tabs p:after {
  content: "";
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.dataTable .tabs p.on:after {
  background: #409eff;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
</style>
