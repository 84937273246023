<template>
  <div class="projectOrderInfo">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>订单</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/commodityOrder'">订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="stepList boxLayout" v-if="Object.keys(orderInfo).length > 0">
      <div class="top">
        <template v-if="active === 0 && orderInfo.status == 1">
          <h2 class="color-333">预约已提交，等待处理</h2>
          <p class="font-12 mg-t-10 mg-b-20">
            如买家未在<span style="color: rgb(234, 79, 79)">7天</span
            >内处理，预约将按自动关闭。
          </p>
        </template>
        <template v-if="active === 1 && orderInfo.status == 2">
          <h2 class="color-333 mg-b-20">订单已处理</h2>
          <!-- <p class="font-12 mg-t-10 mg-b-20">买家已确认收货，交易已完成。</p> -->
        </template>
        <template v-if="active === 2 && orderInfo.status == 3">
          <h2 class="color-333 mg-b-20">交易关闭</h2>
          <!-- <p class="font-12 mg-t-10 mg-b-20">支付超时自动关闭。 </p> -->
        </template>
        <el-steps
          :space="'50%'"
          :active="active"
          simple
          finish-status="success"
          align-center
        >
          <el-step title="提交预约"></el-step>
          <el-step title="已处理"></el-step>
          <el-step title="关闭预约"></el-step>
        </el-steps>
        <div class="flex mg-t-20">
          <p>买家备注：</p>
          <p v-if="orderInfo.itemorder.buyer_remark != ''">
            {{ orderInfo.itemorder.buyer_remark }}
          </p>
          <p v-else>暂无</p>
        </div>
        <div class="flex remark">
          <p>卖家备注：</p>
          <p v-if="orderInfo.itemorder.seller_remark != null">
            {{ orderInfo.itemorder.seller_remark }}
          </p>
          <el-button
            type="text"
            class="font-14"
            @click="addRemark"
            v-if="!remark"
            >{{
              orderInfo.itemorder.seller_remark == null ||
              orderInfo.itemorder.seller_remark == ""
                ? "添加备注"
                : "编辑备注"
            }}</el-button
          >
        </div>
      </div>
    </div>
    <div
      class="info boxLayout mg-t-20"
      v-if="Object.keys(orderInfo).length > 0"
    >
      <h3 class="title-t mg-b-30">基础信息</h3>
      <div class="flex">
        <div class="mg-r-50 mg-l-20">
          <h4 class="mg-b-10 font-16">买家信息</h4>
          <p>客户昵称: {{ orderInfo.name }}</p>
          <p>联系电话: {{ orderInfo.mobile }}</p>
        </div>
        <div class="mg-r-50 mg-l-20">
          <h4 class="mg-b-10 font-16">个人预约信息</h4>
          <p>姓名: {{ orderInfo.name }}</p>
          <p>性别：{{ orderInfo.sex == 1 ? "男" : "女" }}</p>
          <p>年龄：{{ orderInfo.age }}</p>
          <p>生日：{{ orderInfo.birthday }}</p>
          <p>职业：{{ orderInfo.job }}</p>
          <p v-if="orderInfo.city != null">
            城市：{{ orderInfo.city.area_name }}
          </p>
        </div>
        <div
          class="mg-r-50 mg-l-20"
          v-if="
            orderInfo.itemorder.user != null &&
            orderInfo.itemorder.user.manager_uuid != '' &&
            orderInfo.itemorder.user.manager_uuid != null
          "
        >
          <h4 class="mg-b-10 font-16">推广信息</h4>
          <p>
            推广人：{{ orderInfo.itemorder.user.manager_uuid }}
            <el-button
              type="text"
              @click="
                $router.push(
                  `/promoterInfo?uuid=${orderInfo.itemorder.user.manager_uuid}`
                )
              "
              >推广员详情</el-button
            >
          </p>
        </div>
        <div class="mg-r-50 mg-l-20">
          <h4 class="mg-b-10 font-16">订单信息</h4>
          <p>
            订单编号: {{ orderInfo.itemorder.order_sn }}
            <el-button type="text" @click="onCopy">复制</el-button>
          </p>
          <p>
            下单账号: {{ orderInfo.name }}
            <el-button
              type="text"
              @click="
                $router.push(
                  `/customerInfo?uuid=${orderInfo.itemorder.user_uuid}`
                )
              "
              >客户详情</el-button
            >
          </p>
          <p>下单时间: {{ orderInfo.itemorder.create_time }}</p>
          <p v-if="orderInfo.itemorder.status == 1">订单状态: 待处理</p>
          <template v-else>
            <p>
              订单状态:
              <span v-if="orderInfo.status == 1">待处理</span>
              <span v-if="orderInfo.status == 2">已处理</span>
              <span v-if="orderInfo.status == 3">已关闭</span>
            </p>
            <p>付款时间: {{ orderInfo.itemorder.pay_time }}</p>
            <p>付款金额: ￥{{ orderInfo.itemorder.item_price }}</p>
            <p>支付方式: 微信支付</p>
          </template>
        </div>
      </div>
    </div>

    <div class="contractInfo boxLayout mg-t-20">
      <h3 class="title-t mg-b-20">商品</h3>
      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="项目信息" :align="'center'" width="260">
          <template slot-scope="scope">
            <div class="nickAndAvatar align-center">
              <el-image
                v-if="scope.row.item_image != ''"
                :src="scope.row.item_image"
              ></el-image>
              <div style="width: 200px">
                <el-tooltip :content="scope.row.item_name" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.item_name }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="item_price" label="单价" :align="'center'">
          <template slot-scope="scope"> ￥{{ scope.row.item_price }} </template>
        </el-table-column>
        <el-table-column prop="item_number" label="数量" :align="'center'">
        </el-table-column>

        <el-table-column prop="itemorder" label="优惠" :align="'center'">
          <template slot-scope="scope">
            {{
              Number(scope.row.item_price) * Number(scope.row.item_number) -
              Number(scope.row.price)
            }}
            <!-- ￥{{ scope.row.label }} -->
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right mg-t-20" v-if="Object.keys(orderInfo).length > 0">
        <p style="width: 200px; display: inline-block" class="text-left">
          商品总价：￥{{ orderInfo.itemorder.item_price }}
        </p>
        <br />
        <p style="width: 200px; display: inline-block" class="text-left">
          优惠：￥{{
            Number(orderInfo.itemorder.item_price) *
              Number(orderInfo.itemorder.item_number) -
            Number(orderInfo.itemorder.price)
          }}
        </p>
        <br />
        <p
          style="width: 200px; display: inline-block"
          class="font-18 text-left"
        >
          应付款：<span style="color: tomato"
            >￥{{ orderInfo.itemorder.price }}</span
          >
        </p>
      </div>
    </div>
    <div class="operateBtn">
      <el-button
        size="mini"
        type="primary"
        v-if="active === 0 && orderInfo.status == 1"
        @click="showDialog('new')"
        >创建档案</el-button
      >
      <el-button
        size="mini"
        v-if="active === 0 && orderInfo.status != 3"
        @click="closeDialog = true"
        >关闭预约</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
      <!-- <el-button size="mini" v-if="active !== 0" @click="active --">返回上一状态</el-button> -->
      <!-- <el-button size="mini" v-if="active !== 3" @click="active ++">预览下一状态</el-button> -->
    </div>
    <el-dialog title="关闭预约" :visible.sync="closeDialog" width="30%">
      <el-form ref="iform" :model="form" label-width="100px" :rules="rules2">
        <el-form-item label="关闭原因" prop="close_cause">
          <el-select v-model="form.close_cause" placeholder="请选择">
            <el-option label="客户取消" value="客户取消"></el-option>
            <el-option label="联系不上" value="联系不上"></el-option>
            <el-option label="价格过高" value="价格过高"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理人" prop="close_user">
          <el-input v-model="form.close_user"></el-input>
        </el-form-item>
        <el-form-item label="补充说明">
          <el-input
            type="textarea"
            resize="none"
            rows="5"
            v-model="form.close_ext"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeDialog = false">关闭</el-button>
        <el-button size="mini" type="primary" @click="closeItemAppoint('iform')"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import checkInfoDialog from "../../../components/checkInfoDialog.vue";
export default {
  name: "projectOrderInfo",
  components: {
    checkInfoDialog,
  },
  data() {
    return {
      active: 0,
      orderId: "",
      checkList: [],
      checkInfoDialogVisible: false,
      closeDialog: false,
      dialogType: "",
      confirmBtnText: "确定",
      title: "编辑合同信息",
      remark: "",
      orderInfo: {},
      infoForm: {
        checked: true,
      },
      form: {
        close_cause: "",
        close_user: "",
        close_ext: "",
      },
      rules2: {
        close_cause: { required: true, message: "请选择原因", blur: "change" },
        close_user: { required: true, message: "请填写", blur: "blur" },
      },
      tableData: [],

      activities: [],
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ItemAppoint/${this.$route.query.uuid}`,
      }).then((res) => {
        this.orderInfo = res.data.result;
        this.tableData = [this.orderInfo.itemorder];
        this.active = Number(this.orderInfo.status) - 1;
      });
    },
    showDialog(type) {
      this.$prompt("合同编号:", "创建档案信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPlaceholder: "请输入合同编号",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "请输入合同编号",
      }).then(({ value }) => {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            contract_code: value,
            item_order_uuid: this.orderInfo.order_uuid,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/ItemOrder/sync`,
        }).then(() => {
          this.$message.success("操作成功!");
        });
      });
    },
    // 关闭预约
    closeItemAppoint(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "text/html;charset=UTF-8",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.form,
            url: ` https://time.api.boyalife.cn/v1/cms/ItemAppoint/close/${this.$route.query.uuid}`,
          })
            .then((res) => {
              this.$message.success("操作成功");
              this.getInfo();
              this.closeDialog = false;
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
    onCopy() {
      this.$copyText("3333").then(
        (e) => {
          this.$message.success("复制成功!");
        },
        (e) => {
          this.$message.success("复制失败!");
        }
      );
    },
    //  添加备注
    addRemark() {
      this.$prompt("备注信息", "备注", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPlaceholder: "请输入",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "请输入备注",
      })
        .then(({ value }) => {
          this.axios({
            method: "post", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: {
              remark: value,
              uuid: this.orderInfo.itemorder.uuid,
            },
            url: ` https://time.api.boyalife.cn/v1/cms/ItemAppoint/remark`,
          })
            .then((res) => {
              this.$message.success("操作成功");
              this.getInfo();
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
  },
};
</script>
<style scoped>
.projectOrderInfo {
  margin-bottom: 80px;
}
.top .remark .el-button {
  padding: 0 10px;
}
>>> .el-dialog {
  width: 800px !important;
}
>>> .el-step__title.is-process {
  color: #409eff;
}
>>> .el-step.is-simple .el-step__icon {
  border-color: #409eff;
}
>>> .el-step__head.is-wait .el-step__icon {
  border-color: #c0c4cc;
}
>>> .el-step__head.is-success .el-step__icon {
  border-color: #67c23a;
}
>>> .el-button--text {
  padding: 5px 0px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
>>> .el-timeline-item {
  margin-left: 200px;
  padding-top: 20px;
}
>>> .el-timeline-item__timestamp.is-bottom {
  width: 120px;
  position: absolute;
  right: 100%;
  bottom: 3px;
}
>>> .el-timeline-item__node--large {
  width: 25px;
  height: 25px;
  left: -7px;
}
>>> .el-timeline-item__node--large .el-timeline-item__icon {
  font-size: 17px;
}
.logisticsInfo {
  max-height: 400px;
  overflow: auto;
}
</style>
