<template>
  <div class="addCoupon">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/couponList' }"
        >优惠券列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ pageType }}优惠券</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <h3 class="title-t mg-b-20">基础信息</h3>
      <el-form :model="form" label-width="150px" ref="form" :rules="rules">
        <el-form-item label="优惠券名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item
          label="优惠券门槛"
          :prop="form.is_condition == 1 ? 'condition_money' : (form.is_condition == 3 ? 'condition_num': '')"
        >
          <el-radio-group v-model="form.is_condition" @input="changeCondition">
            <div class="flex align-center mg-b-20">
              <el-radio v-model="form.is_condition" :label="1">现金门槛</el-radio>
              订单满<el-input
                v-model="form.condition_money"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">元</template></el-input
              >
              <br />
            </div>
            <div class="flex align-center mg-b-20">
              <el-radio v-model="form.is_condition" :label="3">数量门槛</el-radio>
              订单满<el-input
                v-model="form.condition_num"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">件</template></el-input
              >
              <br />
            </div>
            <el-radio v-model="form.is_condition" :label="2">无门槛</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="优惠券可用数量"
          prop="usable"
          v-if="form.release_type != 2"
        >
          <el-input v-model="form.usable"></el-input>
        </el-form-item>
        <el-form-item label="每人最多可领用数量" prop="get">
          <el-input v-model="form.get"></el-input>
        </el-form-item>
        <el-form-item label="指定商品可用">
          <el-checkbox v-model="special"></el-checkbox>
          <el-button
            @click="selectCommdityDialogVisible = true"
            class="mg-l-20"
            :disabled="!special"
            type="text"
          >
            + 选择商品</el-button
          >
        </el-form-item>
        <el-form-item label="选择的商品" v-if="commidityList.length > 0">
          <el-table :data="commidityList" border>
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="name" label="商品名"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="commidityList.splice(scope.$index, 1)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="指定客户可用">
          <el-checkbox v-model="special2"></el-checkbox>
          <el-button
            class="mg-l-20"
            @click="selectCustomerDialogVisible = true"
            :disabled="!special2"
            type="text"
          >
            + 选择客户</el-button
          >
        </el-form-item>
        <el-form-item label="选择的客户" v-if="customerList.length > 0">
          <el-table :data="customerList" border>
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="nick_name" label="用户名"></el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item
          label="有效期"
          :prop="
            form.validity == 2
              ? 'validity_day'
              : form.validity == 3
              ? 'validity_end_date'
              : ''
          "
        >
          <el-radio-group v-model="form.validity" @input="selectValidity">
            <div class="flex align-center">
              <el-radio :label="1">永久有效</el-radio> <br />
            </div>
            <div class="flex align-center mg-t-20 mg-b-20">
              <el-radio :label="2">领取后</el-radio>
              <el-input
                v-if="form.validity == 2"
                v-model="form.validity_day"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >有效
            </div>
            <div class="flex align-center">
              <el-radio :label="3">固定时间</el-radio>
              <el-date-picker
                v-if="form.validity == 3"
                v-model="dataTime"
                type="daterange"
                align="right"
                unlink-panels
                @change="getTimes"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="优惠券类型" prop="type">
          <el-radio-group v-model="form.type">
            <div class="flex align-center">
              <el-radio :label="1">现金券</el-radio>
              可抵扣<el-input
                v-model="form.type_money"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">元</template></el-input
              >
            </div>
            <div class="flex align-center mg-t-20 mg-b-20">
              <el-radio :label="2">折扣券</el-radio>
              可享受<el-input
                v-model="form.type_discount"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">折</template></el-input
              >
            </div>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="发放方式">
          <el-radio-group v-model="form.release_type">
            <!-- <el-radio :label="1">新用户优惠券</el-radio> -->
            <el-radio :label="2">全局发放</el-radio>
            <!-- <el-radio :label="3">老会员优惠券</el-radio> -->
            <el-radio :label="4">领券大厅</el-radio>
            <el-radio :label="5">口令兑换</el-radio>
            <el-radio :label="6">营销活动</el-radio>
            <el-radio :label="7">人群发放</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="输入口令" v-if="form.release_type == 5">
          <el-input v-model="form.word"></el-input>
        </el-form-item>
        <el-form-item label="针对人群" v-if="form.release_type == 7">
          <el-tag
            closable
            @close="closeTags(i)"
            v-for="(tag, i) in tags"
            :key="tag.uuid"
            :type="tag.type"
          >
            {{ tag.name }}
          </el-tag>
          <span
            style="color: cornflowerblue; cursor: pointer"
            @click="selectPeopleDialogVisible = true"
          >
            <span v-if="tags.length > 0">+ 编辑人群</span>
            <span v-else>+ 添加人群</span>
          </span>
        </el-form-item>
        <el-form-item label="叠加设置">
          <el-radio-group v-model="form.overlay_type">
            <el-radio :label="1">仅原价购买可使用</el-radio>
            <el-radio :label="2">优惠券可叠加使用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="转赠设置">
          <el-radio-group v-model="form.give_type">
            <el-radio :label="1">不可转赠</el-radio>
            <el-radio :label="2">用户可转赠给好友使用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分享设置">
          <el-radio-group v-model="form.share_type">
            <el-radio :label="1">不可分享</el-radio>
            <el-radio :label="2">用户可分享给好友领取</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="优惠券描述">
          <el-input
            v-model="form.desc"
            type="textarea"
            resize="none"
            rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item label="优惠券备注">
          <el-input
            v-model="form.remark"
            type="textarea"
            resize="none"
            rows="5"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <!-- <el-dialog title="选择优惠券" :visible.sync="dialogTableVisible" :close-on-click-modal="false">
      <el-table :data="tableData" border>
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column property="name" label="优惠券名称" width="150" align="center"></el-table-column>
        <el-table-column property="status" label="状态" width="200" align="center">
          <template  slot-scope="scope">
            <span style="color:#2ED477">{{ scope.row.status }}</span>
          </template>
        </el-table-column>
        <el-table-column property="content" label="优惠券内容" align="center">
          <template  slot-scope="scope">
            <p>￥{{ scope.row.money }}</p>
            <p>满{{ scope.row.bigMoney }}元可用</p>
          </template>
        </el-table-column>
        <el-table-column property="range" label="适用范围" align="center"></el-table-column>
        <el-table-column property="date" label="有效期" align="center"></el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogTableVisible = false">确 定</el-button>
      </span>
    </el-dialog> -->

    <selectPeople
      :dialogVisible="selectPeopleDialogVisible"
      @confirmPeople="confirmPeople"
      :user="tags"
      @closeDialog="closeTagDialog"
    />
    <selectCustomer
      :dialogVisible="selectCustomerDialogVisible"
      @confirmCustomer="confirmCustomer"
      @cancel="closeSelectCustomer"
      :customer="customerList"
    />
    <selectCommdity
      :dialogVisible="selectCommdityDialogVisible"
      @confirmCommidity="confirmCommidity"
      @closeDialog="closeDialog"
      :goods="commidityList"
    />
  </div>
</template>
<script>
import selectPeople from "../../../components/selectPeople.vue";
import selectCustomer from "../../../components/selectCustomer.vue";
import selectCommdity from "../../../components/selectCommdity.vue";
import pagination from "../../../components/pagination.vue";
export default {
  name: "addCoupon",
  components: { selectPeople, selectCustomer, selectCommdity, pagination },
  data() {
    return {
      pageType: "",
      labelDialogVisible: false,
      selectPeopleDialogVisible: false,
      selectCustomerDialogVisible: false,
      selectCommdityDialogVisible: false,
      dialogTableVisible: false,
      dataTime: "",
      special: false,
      special2: false,

      form: {
        name: "",
        is_condition: 2,
        condition_money: "",
        usable: "",
        get: "",
        validity: 1,
        validity_day: "",
        validity_start_date: "",
        validity_end_date: "",
        type: 1,
        type_money: "",
        type_discount: "",
        release_type: 2,
        word: "",
        overlay_type: 1,
        give_type: 1,
        share_type: 1,
        desc: "",
        remark: "",
        goods: "",
        user_group: "",
        user: "",
        wordgroup: "",
        condition_num: "",
      },
      rules: {
        name: { required: true, message: "请输入", trigger: "blur" },
        condition_money: { required: true, message: "请输入", trigger: "blur" },
        condition_num: { required: true, message: "请输入", trigger: "blur" },
        usable: { required: true, message: "请输入", trigger: "blur" },
        get: { required: true, message: "请输入", trigger: "blur" },
        validity_day: { required: true, message: "请输入", trigger: "blur" },
        validity_end_date: {
          required: true,
          message: "请选择",
          trigger: "change",
        },
      },
      commidityList: [],
      customerList: [],
      tags: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: [],
    };
  },
  created() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      this.getInfo();
    } else {
      this.pageType = "新增";
    }
  },
  methods: {
    changeCondition (v) {
      console.log(v)
      if (v == 1) {
        this.form.condition_num = ''
      } else if (v == 3) {
        this.form.condition_money = ''
      } else {
        this.form.condition_num = ''
        this.form.condition_money = ''
      }
    },
    closeTags(i) {
      this.tags.splice(i, 1);
    },
    selectValidity(v) {
      if (v == 2) {
        this.form.validity_start_date = "";
        this.form.validity_end_date = "";
        this.dataTime = "";
      }
      if (v == 3) {
        this.form.validity_day = "";
      }
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.form,
        url: ` https://time.api.boyalife.cn/v1/cms/Coupon/${this.$route.query.uuid}`,
      })
        .then((res) => {
          let params = res.data.result;
          for (let i in params) {
            this.form[i] = JSON.parse(JSON.stringify(params[i]));
          }
          if (this.form.validity == 1) {
            this.dataTime = [];
            this.form.validity_start_date = "";
            this.form.validity_end_date = "";
            this.form.validity_day = "";
          }
          if (this.form.validity == 3) {
            this.form.validity_day = "";
            this.dataTime = [
              this.form.validity_start_date,
              this.form.validity_end_date,
            ];
          }
          if (this.form.validity == 2) {
            this.dataTime = [];
            this.form.validity_start_date = "";
            this.form.validity_end_date = "";
          }

          this.form.is_condition = Number(this.form.is_condition);
          this.form.validity = Number(this.form.validity);
          this.form.type = Number(this.form.type);
          this.form.release_type = Number(this.form.release_type);
          this.form.overlay_type = Number(this.form.overlay_type);
          this.form.give_type = Number(this.form.give_type);
          this.form.share_type = Number(this.form.share_type);
          this.form.type_money = Number(this.form.type_money);
          if (this.form.goods.length > 0) {
            this.special = true;
            this.commidityList = this.form.goods;
          }
          if (this.form.user.length > 0) {
            this.special2 = true;
            this.customerList = this.form.user;
          }
          if (this.form.group.length > 0) {
            this.tags = this.form.group;
          }
          console.log(this.special);

          // this.form.group.length > 0 ?this.form.group_uuid = JSON.parse(this.form.group).map(item => item.uuid) : []
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 选择时间
    getTimes(v) {
      this.form.validity_start_date = v[0];
      this.form.validity_end_date = v[1];
    },
    showPeopleDialog() {
      this.selectPeopleDialogVisible = true;
      console.log(this.selectPeopleDialogVisible);
    },
    confirmPeople(v) {
      console.log(v);
      this.selectPeopleDialogVisible = false;
      this.tags = v;
    },
    closeTagDialog() {
      this.selectPeopleDialogVisible = false;
    },
    closeSelectCustomer(v) {
      this.selectCustomerDialogVisible = v;
    },
    confirmCustomer(v) {
      this.selectCustomerDialogVisible = false;
      this.customerList = v;
    },
    confirmCommidity(v) {
      this.selectCommdityDialogVisible = false;
      this.commidityList = v;
    },
    closeDialog(v) {
      this.selectCommdityDialogVisible = v;
    },
    // handleSizeChange (v) {
    //   this.tableData.per_page = v
    //   this.getCouponList()
    // },
    // handleCurrentChange (v) {
    //   this.tableData.current_page = v
    //   this.getCouponList()
    // },
    submit(formName) {
      if (this.commidityList.length > 0) {
        this.form.goods = this.commidityList.map((item) => item.uuid);
      }
      if (this.customerList.length > 0) {
        this.form.user = this.customerList.map((item) => item.uuid);
      }
      if (this.tags.length > 0) {
        if (this.form.release_type == 5) {
          this.form.wordgroup = this.tags.map((item) => item.uuid);
        } else {
          this.form.user_group = this.tags.map((item) => item.uuid);
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.is_condition == 1 && this.form.condition_money == "") {
            this.$message.warning("有门槛，订单价格内容必填");
            return false;
          }
          if (this.form.is_condition == 3 && this.form.condition_num == "") {
            this.$message.warning("有门槛，订单数量内容必填");
            return false;
          }
          if (this.form.validity == 2 && this.form.validity_day == "") {
            this.$message.warning("请填写领取后多少天有效");
            return false;
          }
          if (this.form.validity == 3 && this.form.validity_end_date == "") {
            this.$message.warning("请选择有效期的固定时间");
            return false;
          }
          if (this.form.type == 1 && this.form.type_money == "") {
            this.$message.warning("优惠券类型为现金券，请填写可抵扣多少元");
            return false;
          }
          if (this.form.type == 2 && this.form.type_discount == "") {
            this.$message.warning("优惠券类型为折扣券，请填写可享受多少折扣");
            return false;
          }

          if (Object.keys(this.$route.query).length > 0) {
            delete this.form.create_time;
            delete this.form.delete_time;
            delete this.form.update_time;
            delete this.form.uuid;

            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.form,
              url: ` https://time.api.boyalife.cn/v1/cms/Coupon/${this.$route.query.uuid}`,
            })
              .then((res) => {
                this.$message.success("保存成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.form,
              url: ` https://time.api.boyalife.cn/v1/cms/Coupon`,
            })
              .then((res) => {
                this.$message.success("保存成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.addCoupon {
  margin-bottom: 80px;
}
>>> .ipt {
  width: 200px;
  margin: 0 10px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-tag {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>
