<template>
  <div class="afterSaleManageInfo">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>订单</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/commodityOrder'">订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="stepList boxLayout" v-if="Object.keys(info).length > 0">
      <div class="top">
        <template v-if="info.status == 1">
          <h2 class="color-333">买家申请退款，等待卖家处理</h2>
          <p class="font-12 mg-t-10 mg-b-20">
            如卖家未在<span style="color: rgb(234, 79, 79)"
              >6天1小时13分04秒</span
            >内处理，将自动退款给买家。
          </p>
        </template>
        <template v-if="info.status == 2 && info.type == 1">
          <h2 class="color-333 mg-b-20">商家同意退款，售后完成</h2>
          <p class="font-12 mg-t-10">退款金额：{{ info.money }}</p>
          <p class="font-12 mg-t-10 mg-b-20">退款至微信零钱</p>
        </template>
        <template
          v-if="
            info.status == 2 &&
            info.type == 2 &&
            info.delivery_status == '待发货' &&
            info.after_status == 1
          "
        >
          <h2 class="color-333 mg-b-20">商家同意退货退款，等待买家发货</h2>
          <!-- <p class="font-12 mg-t-10">退款金额：{{info.money}}</p> -->
          <!-- <p class="font-12 mg-t-10 mg-b-20">退款至微信零钱</p> -->
        </template>
        <template
          v-if="
            info.status == 2 &&
            info.type == 2 &&
            info.delivery_status != '待发货' &&
            info.after_status == 2
          "
        >
          <h2 class="color-333 mg-b-20">买家已退货，等待卖家处理</h2>
          <!-- <p class="font-12 mg-t-10">退款金额：{{info.money}}</p> -->
          <!-- <p class="font-12 mg-t-10 mg-b-20">退款至微信零钱</p> -->
        </template>
        <template
          v-if="
            info.status == 2 &&
            info.type == 2 &&
            info.delivery_status != '待发货' &&
            info.after_status == 3
          "
        >
          <h2 class="color-333 mg-b-20">卖家已确认收货，等待退款金额到账</h2>
          <!-- <p class="font-12 mg-t-10">退款金额：{{info.money}}</p> -->
          <!-- <p class="font-12 mg-t-10 mg-b-20">退款至微信零钱</p> -->
        </template>
        <template
          v-if="
            info.status == 2 &&
            info.type == 2 &&
            info.delivery_status != '待发货' &&
            info.after_status == 4
          "
        >
          <h2 class="color-333 mg-b-20">退款已到账，售后完成。</h2>
          <p class="font-12 mg-t-10">退款金额：{{ info.money }}</p>
          <p class="font-12 mg-t-10 mg-b-20">退款至微信零钱</p>
        </template>
        <template v-if="info.status == 0">
          <h2 class="color-333 mg-b-20">买家已取消，售后关闭</h2>
        </template>
        <div class="remark">
          <div class="flex align-center" style="width: 100%">
            <p>备注：</p>
            <el-tooltip
              class="item"
              effect="dark"
              v-if="remark"
              :content="remark"
              placement="top"
            >
              <p class="p">{{ remark }}</p>
            </el-tooltip>
            <el-button
              type="text"
              class="font-14"
              @click="addRemark"
              v-if="!remark"
              >添加备注</el-button
            >

            <el-button type="text" class="font-14" @click="addRemark" v-else
              >编辑</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="info boxLayout mg-t-20" v-if="Object.keys(info).length > 0">
      <h3 class="title-t mg-b-30">售后信息</h3>
      <div class="flex msg">
        <div class="mg-r-50 mg-l-20">
          <h4 class="mg-b-10 font-16">申请信息</h4>
          <p>售后类型: {{ info.type == 1 ? "仅退款" : "退货" }}</p>
          <p>
            售后编号: {{ info.refund_order }}
            <el-button type="text" @click="onCopy(info.refund_order)"
              >复制</el-button
            >
          </p>
          <p>退款金额: {{ info.money }}</p>
          <p>退款原因：{{ info.reason_content }}</p>
          <p>退款说明：{{ info.mini_remark }}</p>
        </div>
        <div class="mg-r-50 mg-l-20">
          <h4 class="mg-b-10 font-16">客户信息</h4>
          <p>
            申请人: {{ info.user_name }}
            <el-button
              type="text"
              @click="$router.push('/customerInfo?uuid=' + info.user_uuid)"
              >客户详情</el-button
            >
          </p>
          <p>联系电话: {{ info.mobile }}</p>
          <p>申请时间：{{ info.create_time }}</p>
        </div>
        <div class="mg-r-50 mg-l-20">
          <h4 class="mg-b-10 font-16">订单信息</h4>
          <p>
            订单编号: {{ info.orderInfo.order_sn }}
            <el-button type="text" @click="onCopy(info.orderInfo.order_sn)"
              >复制</el-button
            >
          </p>
          <p>
            实付款：￥{{ info.money }}
            <!-- <span v-if="active === 1">（已退款：{{ info.money }}）</span> -->
          </p>
          <p v-if="info.delivery_status != '待发货' && info.delivery != null">
            物流状态：{{ info.delivery_status }}
            <el-button type="text" @click="checkDelivery">查看物流</el-button>
          </p>
        </div>
      </div>
    </div>

    <div class="contractInfo boxLayout mg-t-20">
      <h3 class="title-t mg-b-20">商品</h3>
      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="商品信息" :align="'center'" width="260">
          <template slot-scope="scope">
            <div class="nickAndAvatar align-center">
              <el-image :src="scope.row.image"></el-image>
              <div style="width: 200px">
                <el-tooltip :content="scope.row.name" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.name }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="retail_price" label="单价" :align="'center'">
          <template slot-scope="scope">
            ￥{{ scope.row.retail_price }}
          </template>
        </el-table-column>
        <el-table-column prop="number" label="数量" :align="'center'">
        </el-table-column>
        <el-table-column prop="price_real" label="实付款" :align="'center'">
          <template slot-scope="scope"> ￥{{ scope.row.price_real }} </template>
        </el-table-column>
        <el-table-column prop="status" label="发货状态" :align="'center'">
        </el-table-column>
        <el-table-column prop="number" label="退货数量" :align="'center'">
        </el-table-column>
      </el-table>
    </div>
    <!-- 协商信息 -->
    <div class="logisticsInfo mg-t-20 boxLayout">
      <h3 class="title-t mb-b-20">协商信息</h3>
      <el-timeline :reverse="true">
        <el-timeline-item
          v-for="(activity, index) in activities"
          :key="index"
          :timestamp="activity.timestamp"
        >
          <p class="font-16 color-333">{{ activity.status }}</p>
          <p class="font-12 color-666" v-html="activity.content"></p>
        </el-timeline-item>
      </el-timeline>
    </div>
    <div class="operateBtn">
      <el-button
        size="mini"
        type="danger"
        v-if="
          info.status == 2 &&
          info.type == 2 &&
          info.delivery_status != '待发货' &&
          info.after_status == 2
        "
        @click="confirmReceived"
        >确认收货</el-button
      >
      <el-button
        size="mini"
        type="primary"
        v-if="
          info.status == 2 &&
          info.type == 2 &&
          info.delivery_status != '待发货' &&
          info.after_status == 2
        "
        @click="showDialog('refuse')"
        >关闭售后</el-button
      >
      <el-button
        size="mini"
        type="primary"
        v-if="info.status == 1"
        @click="showDialog('agree')"
        >同意退款</el-button
      >
      <el-button
        v-if="info.status == 1"
        size="mini"
        type="danger"
        @click="showDialog('refuse')"
        >拒绝退款</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog title="提示" :visible.sync="checkInfoDialogVisible" width="30%">
      <div class="mg-l-40">
        <el-form
          :model="infoForm"
          v-if="dialogType === 'agree' || dialogType === 'refuse'"
          :rules="rules"
          ref="infoForm"
        >
          <template
            v-if="
              (dialogType === 'agree' && info.type == 1) ||
              (info.status == 2 && info.type == 2)
            "
          >
            <el-form-item label="售后方式">
              <p style="line-height: 40px">
                {{ info.type == 1 ? "仅退款" : "退货退款" }}
              </p>
            </el-form-item>
            <el-form-item label="退款金额">
              <p style="color: tomato; line-height: 40px">¥{{ info.money }}</p>
            </el-form-item>
            <el-form-item label="退款方式">
              <p style="line-height: 40px">原支付渠道退回</p>
            </el-form-item>
          </template>
          <template v-if="dialogType === 'agree' && info.type == 2">
            <p style="margin-top: 20px; text-align: center">
              买家申请退货退款,是否同意?
            </p>
          </template>
          <template v-if="dialogType === 'refuse'">
            <el-form-item
              label="拒绝退款原因"
              prop="reject_reason"
              class="mg-t-20"
            >
              <el-select v-model="infoForm.reject_reason" placeholder="请选择">
                <el-option
                  v-for="(jtem, i) in reason"
                  :key="i"
                  :label="jtem"
                  :value="jtem"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="拒绝退款说明">
              <el-input
                type="textarea"
                v-model="infoForm.reject_remark"
                resize="none"
                rows="6"
              ></el-input>
            </el-form-item>
          </template>
        </el-form>
        <!-- 物流信息 -->
        <div class="logisticsInfo" v-if="dialogType === 'logistics'">
          <el-timeline :reverse="false">
            <el-timeline-item
              v-for="(activity, index) in activities2"
              :key="index"
              :icon="index === 0 ? 'el-icon-truck' : 'el-icon-more'"
              :color="index === 0 ? '#0bbd87' : '#d3d1d1'"
              :size="index === 0 ? 'large' : 'normal'"
              :timestamp="activity.timestamp"
            >
              <p class="font-12 color-333">{{ activity.status }}</p>
              <p class="font-12 color-666">{{ activity.content }}</p>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="checkInfoDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('infoForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import checkInfoDialog from "../../../components/checkInfoDialog.vue";
export default {
  name: "afterSaleManageInfo",
  components: {
    checkInfoDialog,
  },
  data() {
    return {
      active: 0,
      checkList: [],
      checkInfoDialogVisible: false,
      dialogType: "",
      confirmBtnText: "确定",
      remark: "",
      title: "编辑合同信息",
      reason: [],
      infoForm: {
        reject_reason: "",
        reject_remark: "",
      },
      rules: {
        reject_reason: { required: true, message: "请选择", trigger: "change" },
        reject_remark: { required: true, message: "请填写", trigger: "blur" },
      },
      info: {},
      tableData: [],
      gridData: [],
      activities: [],
      activities2: [],
    };
  },
  created() {
    this.getInfo();
    this.getReason();
  },
  methods: {
    confirmReceived() {
      this.$confirm("确认收货，货款将退回到客户账户?", "确认收货提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios({
          method: "get", //请求方法
          headers: {
            "Content-Type": "text/html;charset=UTF-8",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/After/received/uuid/${this.$route.query.uuid}`,
        })
          .then((res) => {
            this.$message.success("操作成功！");
            this.getInfo();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/After/${this.$route.query.uuid}`,
      })
        .then((res) => {
          this.info = res.data.result;
          this.tableData = res.data.result.goods;
          this.remark = this.info.admin_remark;
          this.active = Number(this.info.status) - 1;
          this.activities = [];
          this.info.log.map((item, index) => {
            let obj = {};
            let html = "";
            if (item.status == 1) {
              html = `
              <p>售后类型: ${this.info.type == 1 ? "仅退款" : "退货退款"}</p>
              <p>售后编号: ${this.info.refund_order}</p>
              <p>退款金额: ¥${this.info.money}</p>
            `;
            } else {
              html = "";
            }
            obj.status = item.status_name;
            obj.timestamp = item.create_time;
            obj.content = html;
            this.activities.push(obj);
          });
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getReason() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/After/reason`,
      })
        .then((res) => {
          this.reason = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    submitForm(formName) {
      if (this.dialogType == "agree") {
        this.axios({
          method: "get", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/After/agree?uuid=${this.$route.query.uuid}`,
        })
          .then((res) => {
            this.$message.success("操作成功！");
            this.checkInfoDialogVisible = false;
            this.getInfo();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      } else if (this.dialogType == "refuse") {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.axios({
              method: "get", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              url: ` https://time.api.boyalife.cn/v1/cms/After/reject?uuid=${this.$route.query.uuid}&reject_reason=${this.infoForm.reject_reason}&reject_remark=${this.infoForm.reject_remark}`,
            })
              .then((res) => {
                this.$message.success("操作成功！");
                this.checkInfoDialogVisible = false;
                this.getInfo();
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            return false;
          }
        });
      }
    },
    // 查看物流
    checkDelivery() {
      this.showDialog("logistics");

      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/After/delivery/uuid/${this.$route.query.uuid}`,
      })
        .then((res) => {
          this.activities2 = [];
          res.data.result.detail.data.map((item, index) => {
            let obj = {};

            obj.status = item.context;
            obj.timestamp = item.time;
            this.activities2.push(obj);
          });
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    showDialog(type) {
      this.dialogType = type;
      this.checkInfoDialogVisible = true;
      if (type === "agree") {
        this.confirmBtnText = "确定退款";
        this.title = "同意退款";
      } else if (type === "refuse") {
        this.confirmBtnText = "确定";
        this.title = "拒绝退款";
      } else {
        this.confirmBtnText = "确定";
        this.title = "物流信息";
      }
    },
    closeDialog(v) {
      this.checkInfoDialogVisible = v;
    },
    onCopy(v) {
      this.$copyText(v).then(
        (e) => {
          this.$message.success("复制成功!");
        },
        (e) => {
          this.$message.success("复制失败!");
        }
      );
    },
    //  添加备注
    addRemark() {
      const msg = this.remark !== "" ? "编辑" : "添加";
      this.$prompt("备注信息", msg + "备注", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPlaceholder: this.remark,
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "请输入备注",
      }).then(({ value }) => {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            uuids: this.$route.query.uuid,
            remark: value,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/After/remark`,
        })
          .then((res) => {
            this.$message.success("操作成功！");
            this.remark = value;
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
  },
};
</script>
<style scoped>
.afterSaleManageInfo {
  margin-bottom: 80px;
}
.top .remark .el-button {
  padding: 0 10px;
}
>>> .el-dialog {
  width: 800px !important;
}
>>> .el-step__title.is-process {
  color: #409eff;
}
>>> .el-step.is-simple .el-step__icon {
  border-color: #409eff;
}
>>> .el-step__head.is-wait .el-step__icon {
  border-color: #c0c4cc;
}
>>> .el-step__head.is-success .el-step__icon {
  border-color: #67c23a;
}
>>> .el-button--text {
  padding: 5px 0px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 22px;
  max-height: 22px;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}
>>> .el-timeline-item {
  margin-left: 200px;
  padding-top: 20px;
}
>>> .el-timeline-item__timestamp.is-bottom {
  width: 140px;
  position: absolute;
  right: 100%;
  top: -5px;
}
>>> .el-timeline-item__node--large {
  width: 25px;
  height: 25px;
  left: -7px;
}
>>> .el-timeline-item__node--large .el-timeline-item__icon {
  font-size: 17px;
}
.logisticsInfo {
  max-height: 400px;
  overflow: auto;
}
.flex.msg p {
  line-height: 35px;
}
</style>
