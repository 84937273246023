<template>
  <!-- 添加客户 -->
  <div class="integralRule">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>客户</el-breadcrumb-item>
      <el-breadcrumb-item>客户设置</el-breadcrumb-item>
      <el-breadcrumb-item>积分规则</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" :model="basicInfoForm" label-width="150px">
      <div class="boxLayout">
        <h3 class="title-t mg-b-30">基础信息</h3>
        <el-form-item label="积分名称" prop="score_name">
          <el-input v-model="basicInfoForm.score_name"></el-input>
        </el-form-item>
      </div>
      <div class="boxLayout mg-t-20">
        <h3 class="title-t mg-b-30">获取规则</h3>
        <el-form-item label="商城消费">
          <div class="flex align-center mg-b-10">
            <el-radio v-model="basicInfoForm.is_consumption_score" :label="1"
              >每消费</el-radio
            >
            <p>
              <el-input
                v-model="basicInfoForm.consumption_number"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">笔</template></el-input
              >
            </p>
            <p>
              获取<el-input
                v-model="basicInfoForm.consumption_number_score"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">分</template></el-input
              >
            </p>
          </div>
          <div class="flex align-center">
            <el-radio v-model="basicInfoForm.is_consumption_score" :label="2"
              >每消费</el-radio
            >
            <p>
              <el-input
                v-model="basicInfoForm.consumption_price"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">元</template></el-input
              >
            </p>
            <p>
              获取<el-input
                v-model="basicInfoForm.consumption_price_score"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">分</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="项目签约">
          <div class="flex align-center mg-b-10">
            <el-radio v-model="basicInfoForm.is_contract_score" :label="1"
              >每签约</el-radio
            >
            <p>
              <el-input
                v-model="basicInfoForm.contract_number"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">笔</template></el-input
              >
            </p>
            <p>
              获取<el-input
                v-model="basicInfoForm.contract_number_score"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">分</template></el-input
              >
            </p>
          </div>
          <div class="flex align-center">
            <el-radio v-model="basicInfoForm.is_contract_score" :label="2"
              >每缴费</el-radio
            >
            <p>
              <el-input
                v-model="basicInfoForm.contract_price"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">元</template></el-input
              >
            </p>
            <p>
              获取<el-input
                v-model="basicInfoForm.contract_price_score"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">分</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="参与活动">
          <div class="flex align-center mg-b-10">
            <el-radio v-model="basicInfoForm.is_activity_score" :label="1"
              >每参与</el-radio
            >
            <p>
              <el-input
                v-model="basicInfoForm.activity_number"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">次</template></el-input
              >
            </p>
            <p>
              获取<el-input
                v-model="basicInfoForm.activity_number_score"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">分</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="评价商品">
          <div class="flex align-center mg-b-10">
            <el-radio v-model="basicInfoForm.is_comment_score" :label="1"
              >每评价</el-radio
            >
            <p>
              <el-input
                v-model="basicInfoForm.comment_number"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">次</template></el-input
              >
            </p>
            <p>
              获取<el-input
                v-model="basicInfoForm.comment_number_score"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">分</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="积分有效期">
          <div class="flex align-center mg-b-10">
            <el-radio v-model="basicInfoForm.score_validity" :label="1"
              >永久有效</el-radio
            >
          </div>
          <div class="flex align-center">
            <el-radio v-model="basicInfoForm.score_validity" :label="2"
              >获取</el-radio
            >
            <p v-if="basicInfoForm.score_validity == 2">
              <el-input
                v-model="basicInfoForm.score_validity_day"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >
              有效
            </p>
          </div>
        </el-form-item>
        <el-form-item label="获取限制">
          <span>
            <el-switch
              :active-value="1"
              :inactive-value="2"
              class="mg-r-10"
              v-model="basicInfoForm.status"
              active-color="#13ce66"
              inactive-color="gray"
              @change="changeSwitch"
            >
            </el-switch>
            是否开启获得限制
          </span>
          <div
            class="flex align-center mg-b-10"
            v-if="basicInfoForm.status == 1"
          >
            <el-radio v-model="isMax" :label="1">每天最多获取</el-radio>
            <p>
              <el-input
                type="number"
                v-model="basicInfoForm.max_score"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">分</template></el-input
              >
            </p>
          </div>
        </el-form-item>
      </div>
      <div class="boxLayout mg-t-20">
        <h3 class="title-t mg-b-20">抵扣规则</h3>
        <el-form-item label="抵扣比例">
          <el-input
            v-model="basicInfoForm.deduction_rate"
            class="ipt"
            placeholder="请输入"
            ><template slot="prepend">分</template></el-input
          >抵扣一元
        </el-form-item>
        <!-- <el-form-item label="抵扣限制">
            <div class="flex align-center mg-b-10">
              <el-radio  v-model="isMin" :label="1">最多抵扣</el-radio>
              <p>
                <el-input v-model="basicInfoForm.max_deduction" class="ipt" placeholder="请输入"><template slot="prepend">分</template></el-input>
              </p>
            </div>
            <div class="flex align-center mg-b-10">
              <el-radio  v-model="isMin" :label="1">最低抵扣</el-radio>
              <p>
                <el-input v-model="basicInfoForm.min_deduction" class="ipt" placeholder="请输入"><template slot="prepend">分</template></el-input>
              </p>
            </div>
        </el-form-item>
        <el-form-item label="商品抵扣限制">
            <div class="flex align-center mg-b-10">
              <el-radio  v-model="basicInfoForm.goods_deduction" :label="1">全部商品</el-radio>
            </div>
            <div class="flex align-center mg-b-20">
              <el-radio v-model="basicInfoForm.goods_deduction" :label="2">部分商品可抵扣</el-radio>
              <el-button size="mini" type="text" :disabled="basicInfoForm.goods_deduction != 2 " @click="selectCommdityDialogVisible = true">+ 选择商品</el-button>
            </div>
            <el-table :data="commidityList" border v-if="commidityList.length > 0">
              <el-table-column
                type="index"
                label="序号"
                width="55">
              </el-table-column>
              <el-table-column prop="name" label="商品名"></el-table-column>
            </el-table>
        </el-form-item> -->
      </div>
    </el-form>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
    </div>
    <!-- <selectCommdity :goods="basicInfoForm.goods" :dialogVisible="selectCommdityDialogVisible" @confirmCommidity="confirmCommidity" @closeDialog="closeDialog"/> -->
  </div>
</template>
<script>
// import selectCommdity from '../../../components/selectCommdity.vue'

export default {
  name: "integralRule",
  components: {
    // selectCommdity
  },
  data() {
    return {
      selectCommdityDialogVisible: false,
      commidityList: [],
      imageUrl: "",
      isShow: false,
      isMax: 1,
      isMin: 1,
      basicInfoForm: {
        score_name: "",
        consumption_number: "",
        consumption_number_score: "",
        consumption_price: "",
        consumption_price_score: "",
        is_consumption_score: 1,
        contract_number: "",
        contract_number_score: "",
        contract_price: "",
        contract_price_score: "",
        is_contract_score: 1,
        activity_number: "",
        activity_number_score: "",
        is_activity_score: 1,
        comment_number: "",
        comment_number_score: "",
        is_comment_score: 1,
        score_validity: 1,
        score_validity_day: "",
        min_score: "",
        max_score: "",
        deduction_rate: "",
        min_deduction: "",
        max_deduction: "",
        goods_deduction: 1,
        goods_uuids: "",
        status: "",
      },
    };
  },
  created() {
    this.axios({
      method: "get", //请求方法
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": this.storage.get("__token"),
        Authorization: `Bearer ${this.storage.get("__token")}`,
      },
      url: ` https://time.api.boyalife.cn/v1/cms/ScoreRule/1`,
    })
      .then((res) => {
        console.log(res);
        for (let i in res.data.result) {
          this.basicInfoForm[i] = res.data.result[i];
        }
        this.commidityList = this.basicInfoForm.goods;
        this.basicInfoForm.is_consumption_score = Number(
          this.basicInfoForm.is_consumption_score
        );
        this.basicInfoForm.is_contract_score = Number(
          this.basicInfoForm.is_contract_score
        );
        this.basicInfoForm.is_activity_score = Number(
          this.basicInfoForm.is_activity_score
        );
        this.basicInfoForm.is_comment_score = Number(
          this.basicInfoForm.is_comment_score
        );
        this.basicInfoForm.score_validity = Number(
          this.basicInfoForm.score_validity
        );
        if (this.basicInfoForm.max_score != -1) {
          this.basicInfoForm.status = 1;
        } else {
          this.basicInfoForm.max_score = "";
        }
        console.log(this.basicInfoForm.is_consumption_score);
      })
      .catch((err) => {
        this.$message.error(err.response.data.error.message);
      });
  },
  methods: {
    changeSwitch() {
      if (this.basicInfoForm.status == 2) {
        this.basicInfoForm.max_score = "";
      }
    },
    confirmCommidity(v) {
      this.selectCommdityDialogVisible = false;
      this.commidityList = v;
    },
    closeDialog(v) {
      this.selectCommdityDialogVisible = v;
    },
    submit(form) {
      if (this.commidityList.length > 0) {
        this.basicInfoForm.goods_uuids = this.commidityList.map(
          (item) => item.uuid
        );
      }
      if (this.basicInfoForm.status == 2) {
        this.basicInfoForm.max_score = -1;
      }
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.basicInfoForm,
            url: ` https://time.api.boyalife.cn/v1/cms/ScoreRule/1`,
          })
            .then((res) => {
              this.$message.success("保存成功！");
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.integralRule {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form {
  width: 100%;
}
>>> .ipt {
  width: 200px;
  margin: 0 10px;
}
>>> .el-checkbox {
  margin-right: 0;
}
p {
  line-height: 40px;
}
</style>
