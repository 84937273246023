<template>
  <!-- 样本动态弹窗 -->
  <div class="dynamicTime">
    <el-timeline>
      <el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon" :type="activity.type"
        :color="activity.color" :size="activity.size">
        {{ activity.content + ' ' + activity.timestamp }}
      </el-timeline-item>
    </el-timeline>
  </div>
</template>
<script>
export default {
  name: 'dynamicTime',
  data() {
    return {
      activities: [{
        content: '合同已签订',
        timestamp: '2018-04-12 20:46',
        color: '#E6A23C'
      }, {
        content: '样本到达实验室',
        timestamp: '2018-04-03 20:46',
        color: '#E6A23C'
      }, {
        content: '常规检测',
        timestamp: '2018-04-03 20:46',
        color: '#E6A23C'
      }, {
        content: '病毒检测',
        timestamp: '2018-04-03 20:46',
        color: '#E6A23C'
      }, {
        content: '样本成功存储',
        timestamp: '2018-04-03 20:46',
        color: '#E6A23C',
        icon: 'el-icon-check'
      }]
    }
  },
  props: ['uuid']
}
</script>
<style scoped>
.dynamicTime {
  padding: 20px;
}
</style>