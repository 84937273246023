<template>
  <div class="memberAnalysis">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>数据</el-breadcrumb-item>
      <el-breadcrumb-item>会员分析</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="overview boxLayout">
      <div class="title flex space-between align-center">
        <h3 class="title-t">会员数据概览</h3>
        <div class="flex">
          <el-form
            :inline="true"
            :model="customerGroupForm"
            class="demo-form-inline"
          >
            <el-form-item label="选择时间">
              <el-date-picker
                class="mg-l-20"
                v-model="dated"
                type="daterange"
                align="right"
                unlink-panels
                @change="getTimes"
                :clearable="false"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="flex mg-t-20">
        <div
          class="box pad-20 mg-r-10"
          v-if="Object.keys(customerOverview).length > 0"
        >
          <h4 class="color-999 mg-b-10">
            累计会员数
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">{{ customerOverview.all_user || 0 }}</p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ customerOverview.all_user_scale || 0 }}%</p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            新增会员数
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">{{ customerOverview.add_user || 0 }}</p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ customerOverview.add_user_scale || 0 }}%</p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            支付会员数
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">{{ customerOverview.pay_user || 0 }}</p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ customerOverview.pay_user_scale || 0 }}%</p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            储值会员数
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">{{ customerOverview.stored_user || 0 }}</p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ customerOverview.stored_user_scale || 0 }}%</p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            领券会员数
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">{{ customerOverview.coupon_user || 0 }}</p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ customerOverview.coupon_user_scale || 0 }}%</p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10 mg-t-20">
          <h4 class="color-999 mg-b-10">
            会员支付金额
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">{{ customerOverview.pay_amount || 0 }}</p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ customerOverview.pay_amount_scale || 0 }}%</p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10 mg-t-20">
          <h4 class="color-999 mg-b-10">
            会员支付订单数
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">
            {{ customerOverview.pay_number_scale || 0 }}
          </p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ customerOverview.all_user || 0 }}%</p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10 mg-t-20">
          <h4 class="color-999 mg-b-10">
            会员客单价
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">
            {{ customerOverview.user_avg_price || 0 }}
          </p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ customerOverview.user_avg_price_scale || 0 }}%</p>
          </div>
        </div>
      </div>
      <div class="flex space-between mg-t-50">
        <div style="width: 46%">
          <h3 class="title-t">会员等级占比</h3>
          <div class="myChartBox" style="width: 100%; height: 400px">
            <div
              id="sexBox"
              ref="sexBox"
              style="width: 100%; height: 100%"
            ></div>
          </div>
        </div>
        <div style="width: 46%">
          <h3 class="title-t">会员等级新增人数占比</h3>
          <div class="myChartBox" style="width: 100%; height: 400px">
            <div
              id="ageBox"
              ref="ageox"
              style="width: 100%; height: 100%"
            ></div>
          </div>
        </div>
      </div>
      <div class="mg-t-20">
        <div class="title flex space-between align-center">
          <h3 class="title-t">会员等级人数趋势</h3>
          <div class="flex">
            <el-form
              :inline="true"
              :model="customerGroupForm"
              class="demo-form-inline"
            >
              <el-form-item label="选择时间">
                <el-date-picker
                  class="mg-l-20"
                  v-model="dated2"
                  type="daterange"
                  align="right"
                  unlink-panels
                  @change="getTimes2"
                  :clearable="false"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy/MM/dd"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div
          id="transactionOverview"
          ref="myChart"
          style="width: 100%; height: 700px"
        ></div>
      </div>
      <div class="mg-t-50">
        <div class="title flex space-between align-center">
          <h3 class="title-t">会员贡献度分析</h3>
          <div class="flex">
            <el-form
              :inline="true"
              :model="customerGroupForm"
              class="demo-form-inline"
            >
              <el-form-item label="选择时间">
                <el-date-picker
                  class="mg-l-20"
                  v-model="dated3"
                  type="daterange"
                  align="right"
                  unlink-panels
                  @change="getTimes3"
                  :clearable="false"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy/MM/dd"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table
          class="mg-t-20"
          ref="multipleTable"
          :data="contributionData"
          border
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column label="会员等级" :align="'center'" prop="label">
          </el-table-column>
          <el-table-column prop="all_user" label="累计会员数" :align="'center'">
          </el-table-column>
          <el-table-column prop="pay_user" label="成交会员数" :align="'center'">
          </el-table-column>
          <el-table-column prop="ratio" label="成交占比" :align="'center'">
            <template slot-scope="scope"> {{ scope.row.ratio }}% </template>
          </el-table-column>
          <el-table-column
            prop="pay_number"
            label="支付订单数"
            :align="'center'"
          >
          </el-table-column>
          <el-table-column prop="pay_amount" label="支付金额" :align="'center'">
          </el-table-column>
          <el-table-column
            prop="pay_ratio"
            label="支付金额占比"
            :align="'center'"
          >
            <template slot-scope="scope"> {{ scope.row.ratio }}% </template>
          </el-table-column>
          <el-table-column prop="avg_price" label="客单价" :align="'center'">
          </el-table-column>
        </el-table>
      </div>
      <div class="mg-t-50">
        <div class="title flex space-between align-center">
          <h3 class="title-t">新会员/老会员成交分析</h3>
          <div class="flex">
            <el-form
              :inline="true"
              :model="customerGroupForm"
              class="demo-form-inline"
            >
              <el-form-item label="选择时间">
                <el-date-picker
                  class="mg-l-20"
                  v-model="dated4"
                  type="daterange"
                  align="right"
                  unlink-panels
                  @change="getTimes4"
                  :clearable="false"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy/MM/dd"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="flex space-between align-center">
          <div style="width: 25%">
            <div class="myChartBox" style="width: 100%; height: 200px">
              <div
                id="customer"
                ref="sexBox"
                style="width: 100%; height: 100%"
              ></div>
            </div>
          </div>
          <div style="width: 75%">
            <el-table
              class="mg-t-20"
              ref="multipleTable"
              :data="analysisData"
              border
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column :align="'center'" prop="label" width="100">
              </el-table-column>
              <el-table-column
                prop="new_pay_user"
                label="成交会员数"
                :align="'center'"
                width="150"
              >
              </el-table-column>
              <el-table-column
                prop="new_pay_amount"
                label="支付金额"
                :align="'center'"
              >
              </el-table-column>
              <el-table-column
                prop="new_pay_ratio"
                label="支付金额占比"
                width="150"
                :align="'center'"
              >
                <template slot-scope="scope">
                  {{ scope.row.new_pay_ratio }}%
                </template>
              </el-table-column>
              <el-table-column
                prop="new_pay_number"
                label="支付订单数"
                :align="'center'"
                width="150"
              >
              </el-table-column>
              <el-table-column
                prop="new_number_ratio"
                label="订单数占比"
                :align="'center'"
                width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.new_number_ratio }}%
                </template>
              </el-table-column>
              <el-table-column
                prop="new_avg_pay_user_amount"
                label="客单价"
                :align="'center'"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="mg-t-50">
        <div class="title flex space-between align-center">
          <h3 class="title-t">新会员/老会员趋势</h3>
          <div class="flex">
            <el-form
              :inline="true"
              :model="customerGroupForm"
              class="demo-form-inline"
            >
              <el-form-item label="选择时间">
                <el-date-picker
                  class="mg-l-20"
                  v-model="dated5"
                  type="daterange"
                  align="right"
                  unlink-panels
                  @change="getTimes5"
                  :clearable="false"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy/MM/dd"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div
          id="spendPower"
          ref="spendPower"
          style="width: 100%; height: 500px"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
let currentTime = new Date();
let year = currentTime.getFullYear();
let month = currentTime.getMonth() + 1;
let day = currentTime.getDate();
let formattedDate = `${year}/${month
  .toString()
  .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;

const start = new Date();
start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
let timed = start.toLocaleDateString();
import * as echarts from "echarts";
export default {
  name: "",
  data() {
    return {
      dated: [timed, formattedDate],
      dated2: [timed, formattedDate],
      dated3: [timed, formattedDate],
      dated4: [timed, formattedDate],
      dated5: [timed, formattedDate],
      customerGroupForm: {
        start_time: timed,
        end_time: formattedDate
      },
      customerGroupForm2: {
        start_time: timed,
        end_time: formattedDate
      },
      customerGroupForm3: {
        start_time: timed,
        end_time: formattedDate
      },
      customerGroupForm4: {
        start_time: timed,
        end_time: formattedDate
      },
      customerGroupForm5: {
        start_time: timed,
        end_time: formattedDate
      },
      customerOverview: {},
      sexData: [],
      ageData: [],
      contributionData: [],
      analysisData: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      tableData: [],
      level1: [],
      level1Date: [],
      level1Number: [],
      level2: [],
      level2Date: [],
      level2Number: [],
      level3: [],
      level3Date: [],
      level3Number: [],
      level4: [],
      level4Date: [],
      level4Number: [],
      level5: [],
      level5Date: [],
      level5Number: [],
      level6: [],
      level6Date: [],
      level6Number: []
    };
  },
  mounted() {
    this.getCustomerOverview();
    this.getMemberNumberTrend();
    this.getSex();
    this.getAge();
    this.getMemberContribution();
    this.getMemberTradeAnalysis();
    this.getMemberLevelTrend();
  },
  methods: {
    getTimes(v) {
      this.customerGroupForm.start_time = v[0];
      this.customerGroupForm.end_time = v[1];
      this.getCustomerOverview();
      this.getSex();
      this.getAge();
    },
    getTimes2(v) {
      this.customerGroupForm2.start_time = v[0];
      this.customerGroupForm2.end_time = v[1];
      this.getMemberLevelTrend();
    },
    getTimes3(v) {
      this.customerGroupForm3.start_time = v[0];
      this.customerGroupForm3.end_time = v[1];
      this.getMemberContribution();
    },
    getTimes4(v) {
      this.customerGroupForm4.start_time = v[0];
      this.customerGroupForm4.end_time = v[1];
      this.getMemberTradeAnalysis();
    },
    getTimes5(v) {
      this.customerGroupForm4.start_time = v[0];
      this.customerGroupForm4.end_time = v[1];
      this.getMemberNumberTrend();
    },
    // 新老会员成交分析
    getMemberTradeAnalysis() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        // url: ` https://time.api.boyalife.cn/v1/cms/Data/member`,
        url: ` https://time.api.boyalife.cn/v1/cms/Data/memberTradeAnalysis?start_time=${this.customerGroupForm.start_time}&end_time=${this.customerGroupForm.end_time}`
      })
        .then(res => {
          let params = res.data.result;
          this.analysisData = [
            {
              label: "新会员",
              new_user_number: params.new_user_number,
              new_pay_user: params.new_pay_user,
              new_pay_amount: params.new_pay_amount,
              new_avg_pay_user_amount: params.new_avg_pay_user_amount,
              new_pay_user_ratio: params.new_pay_user_ratio,
              new_pay_ratio: params.new_pay_ratio,
              new_number_ratio: params.new_number_ratio,
              new_pay_number: params.new_pay_number
            },
            {
              label: "老会员",
              new_user_number: params.old_user_number,
              new_pay_user: params.old_pay_user,
              new_pay_amount: params.old_pay_amount,
              new_avg_pay_user_amount: params.old_avg_pay_user_amount,
              new_pay_user_ratio: params.old_pay_user_ratio,
              new_pay_ratio: params.old_pay_ratio,
              new_number_ratio: params.old_number_ratio,
              new_pay_number: params.old_pay_number
            }
          ];

          this.getCustomerAnalysis();
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 会员贡献度
    getMemberContribution() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        // url: ` https://time.api.boyalife.cn/v1/cms/Data/member`,
        url: ` https://time.api.boyalife.cn/v1/cms/Data/memberContribution?start_time=${this.customerGroupForm.start_time}&end_time=${this.customerGroupForm.end_time}`
      })
        .then(res => {
          this.contributionData = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 会员概览
    getCustomerOverview() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        // url: ` https://time.api.boyalife.cn/v1/cms/Data/member`,
        url: ` https://time.api.boyalife.cn/v1/cms/Data/member?start_time=${this.customerGroupForm.start_time}&end_time=${this.customerGroupForm.end_time}`
      })
        .then(res => {
          this.customerOverview = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 新老会员趋势分析
    getMemberNumberTrend() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        // url: ` https://time.api.boyalife.cn/v1/cms/Data/member`,
        url: ` https://time.api.boyalife.cn/v1/cms/Data/memberNumberTrend?start_time=${this.customerGroupForm5.start_time}&end_time=${this.customerGroupForm5.end_time}`
      })
        .then(res => {
          console.log(res);
          let params = res.data.result;
          this.level1 = [];
          this.level1Date = [];
          this.level1Number = [];
          this.level2 = [];
          this.level2Date = [];
          this.level2Number = [];
          this.level3 = [];
          this.level3Date = [];
          this.level3Number = [];
          this.level4 = [];
          this.level4Date = [];
          this.level4Number = [];
          this.level5 = [];
          this.level5Date = [];
          this.level5Number = [];
          this.level6 = [];
          this.level6Date = [];
          this.level6Number = [];
          // this.level1.push(i)
          params.map(item => {
            this.level1Date.push(item.date);
            this.level1Number.push(item.new);
            this.level2Number.push(item.old);

            // if (i == '白金会员') {
            //   this.level1Number.push(item.number)
            //   this.level1Date.push(item.date)
            // } else if (i == '至尊会员') {
            //   this.level2Number.push(item.number)
            //   this.level2Date.push(item.date)
            // } else if (i == '荣耀等级') {
            //   this.level3Number.push(item.number)
            //   this.level3Date.push(item.date)
            // } else if (i == '钻石会员') {
            //   this.level4Number.push(item.number)
            //   this.level4Date.push(item.date)
            // } else if (i == '青铜会员') {
            //   this.level5Number.push(item.number)
            //   this.level5Date.push(item.date)
            // } else if (i == '黄金会员') {
            //   this.level6Number.push(item.number)
            //   this.level6Number.push(item.date)
            // }
          });
          console.log(this.level1Date);
          this.getSpendPower();
        })
        .catch(err => {
          this.$message.error(err);
        });
    },
    getSex() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        // url: ` https://time.api.boyalife.cn/v1/cms/Data/member`,
        url: ` https://time.api.boyalife.cn/v1/cms/Data/memberLevel?start_time=${this.customerGroupForm.start_time}&end_time=${this.customerGroupForm.end_time}`
      })
        .then(res => {
          this.sexData = [];
          res.data.result.map(item => {
            let obj = {};
            obj.name = item.label;
            obj.value = item.number;
            this.sexData.push(obj);
          });
          this.getSexData();
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getSexData() {
      let dom = document.getElementById("sexBox");
      let myChart = echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false
      });
      let option;
      let datas = this.sexData;
      option = {
        tooltip: {
          trigger: "item",
          formatter: "人数占比<br><br>{b}&nbsp;：{c}人 ， 占比{d}% \n\n"
        },
        legend: {
          bottom: "5%",
          left: "center"
        },
        series: [
          {
            type: "pie",
            radius: [70, 100],
            top: top + "%",
            height: "100%",
            left: "center",
            width: 600,
            avoidLabelOverlap: false,
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1
            },
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: "bold"
              }
            },

            data: datas
          }
        ]
      };
      if (option && typeof option === "object") {
        myChart.setOption(option);
      }
      window.addEventListener("resize", myChart.resize);
    },
    getAge() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        // url: ` https://time.api.boyalife.cn/v1/cms/Data/member`,
        url: ` https://time.api.boyalife.cn/v1/cms/Data/memberLevelAdd?start_time=${this.customerGroupForm.start_time}&end_time=${this.customerGroupForm.end_time}`
      })
        .then(res => {
          this.ageData = [];
          res.data.result.map(item => {
            let obj = {};
            obj.name = item.label;
            obj.value = item.number;
            this.ageData.push(obj);
          });
          this.getAgeData();
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getAgeData() {
      // 会员数据概况
      let dom = document.getElementById("ageBox");
      let myChart = echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false
      });
      let option;
      let datas = this.ageData;
      option = {
        tooltip: {
          trigger: "item",
          formatter: "新增人数占比<br><br>{b}：{c}人，占比{d}%"
        },
        legend: {
          bottom: "5%",
          left: "center"
        },
        series: [
          {
            type: "pie",
            radius: [70, 100],
            top: top + "%",
            height: "100%",
            left: "center",
            width: "100%",
            avoidLabelOverlap: false,
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1
            },
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: "bold"
              }
            },

            data: datas
          }
        ]
      };
      if (option && typeof option === "object") {
        myChart.setOption(option);
      }
      window.addEventListener("resize", myChart.resize);
    },
    // 会员等级趋势
    getMemberLevelTrend() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        // url: ` https://time.api.boyalife.cn/v1/cms/Data/member`,
        url: ` https://time.api.boyalife.cn/v1/cms/Data/memberLevelTrend?start_time=${this.customerGroupForm.start_time}&end_time=${this.customerGroupForm.end_time}`
      })
        .then(res => {
          console.log(res);
          this.getTransactionOverview();
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getTransactionOverview() {
      let chartDom = document.getElementById("transactionOverview");
      let transactionOverview = echarts.init(chartDom);
      let transactionOverviewOption = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          bottom: 0
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "50px",
          height: "450px",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "2023-03-01",
            "2023-03-01",
            "2023-03-01",
            "2023-03-01",
            "2023-03-01",
            "2023-03-01",
            "2023-03-01"
          ]
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            name: "VIP1",
            smooth: true,
            type: "line",
            data: [120, 132, 101, 134, 90, 230, 1000],
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16
              }
            },
            type: "line"
          },
          {
            name: "VIP2",
            smooth: true,
            type: "line",
            data: [220, 182, 191, 234, 290, 330, 10000],
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16
              }
            }
          }
        ]
      };

      transactionOverviewOption &&
        transactionOverview.setOption(transactionOverviewOption);
    },
    // 新老会员分析
    getCustomerAnalysis() {
      let dom = document.getElementById("customer");
      let myChart = echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false
      });
      let option;
      let datas = [
        { name: `新会员`, value: this.analysisData[0].new_user_number },
        { name: `老会员`, value: this.analysisData[1].new_user_number }
      ];
      option = {
        tooltip: {
          trigger: "item",
          formatter:
            "新会员/老会员占比<br><br>{b}&nbsp;：{c}人 ， 占比{d}% \n\n"
        },
        legend: {
          bottom: "0",
          left: "center"
        },
        series: [
          {
            type: "pie",
            radius: [40, 70],
            bottom: 50,
            height: "100%",
            left: "center",
            width: 200,
            avoidLabelOverlap: false,
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1
            },
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 14,
                fontWeight: "bold"
              }
            },

            data: datas
          }
        ]
      };
      if (option && typeof option === "object") {
        myChart.setOption(option);
      }
      window.addEventListener("resize", myChart.resize);
    },
    // 新老会员分析
    getSpendPower() {
      let chartDom = document.getElementById("spendPower");
      let spendPower = echarts.init(chartDom);
      let spendPowerOption = {
        tooltip: {
          trigger: "axis"
          // formatter: function (params) {
          //     return (
          //       `<p class="font-12">
          //         ${params[0].axisValue}<br><br>新会员： ${params[0].data.value}<br>
          //        今日新增：<span style="color: #15d4a3">${params[0].data.newAdd}</span> <br><br>
          //        老会员： ${params[1].data.value} <br>
          //        今日新增：<span style="color: #15d4a3">${params[1].data.newAdd}</span>
          //        <p>
          //       `
          //     )

          // },
        },
        legend: {
          bottom: 0
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          height: "400px",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: this.level1Date
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            name: "新会员",
            smooth: true,
            type: "line",
            data: this.level1Number,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16
              }
            },
            type: "line"
          },
          {
            name: "老会员",
            smooth: true,
            type: "line",
            data: this.level2Number,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16
              }
            }
          }
        ]
      };

      spendPowerOption && spendPower.setOption(spendPowerOption);
    }
  }
};
</script>
<style scoped>
.overview .flex {
  flex-wrap: wrap;
}
.overview .box {
  padding: 20px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  min-width: 175px;
}
::v-deep .el-form-item {
  margin-bottom: 0;
}
</style>
