<template>
  <div class="flex bbox">
    <!-- <el-upload
      class="upload-demo"
      action="#"
      :accept="acceptType"
      :show-file-list="true"
      multiple
      :before-upload="beforeAvatarUpload"
      :auto-upload="false"
      @submit="submitImg"
      list-type="picture-card"
    >
      <el-button type="primary" icon="el-icon-plus">添加</el-button>
      <el-button type="primary" icon="el-icon-plus" @click="httpRequest">上传</el-button>
    </el-upload> -->
    <div class="menu">
      <dl>
        <dt class="flex space-between mg-b-20">
          <span>全部分组</span>
          <i class="el-icon-circle-plus-outline" @click="addGroup"></i>
        </dt>
        <dd
          :class="{ current: activeIndex === index }"
          v-for="(item, index) in list.data"
          :key="index"
          @click="changeMenu(item, index)"
        >
          <div class="flex space-between align-center">
            <div class="flex align-center">
              <i class="el-icon-camera-solid"></i>
              <span>{{ item.name }}({{ item.picture_count }})</span>
            </div>
            <div class="btn">
              <el-button type="text" @click="editGroup(item)">编辑</el-button>
              <el-button type="text" @click="delGroup(item)">删除</el-button>
            </div>
          </div>
        </dd>
      </dl>
      <pagination
        :small="true"
        :layout="'prev, pager, next'"
        :pager-count="5"
        :total="list.total"
        :per_page="list.per_page"
        :current_page="list.current_page"
        @handleCurrentChange="listHandleCurrentChange"
        @handleSizeChange="listHandleSizeChange"
      />
    </div>
    <div class="image mg-l-20">
      <div v-if="activeIndex !== ''">
        <el-form :inline="true" :model="pictureForm" class="demo-form-inline">
          <el-form-item label="创建日期">
            <el-date-picker
              v-model="pictureForm.time"
              type="daterange"
              align="right"
              @change="getTimes"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="关键字" class="keyword">
            <el-input
              placeholder="请输入内容"
              v-model="pictureForm.search_keyword"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn mg-t-10">
          <el-button type="primary" size="medium" @click="getPictureList">查询</el-button>
          <el-button size="medium" @click="clearForm">清空</el-button>
        </div>
      </div>
      <div class="upload-demo">
        <el-button type="primary" icon="el-icon-plus" @click="showUploadDialog"
          >添加图片</el-button
        >
      </div>
      <div
        style="
          height: 60%;
          overflow-y: auto;
          overflow-x: hidden;
          height: calc(100vh - 423px);
        "
      >
        <div v-if="pictureList.data.length > 0">
          <el-row :gutter="20" class="mg-t-20">
            <el-col :span="6" v-for="item in pictureList.data" :key="item.uuid">
              <div style="border: 1px solid #dcdcdc; text-align: center">
                <img
                  class="img"
                  :src="item.url"
                  fit="contain"
                  :preview="'1'"
                  :preview-text="item.name"
                />
                <div class="info">
                  <div class="top" style="text-align: left">
                    <h4 class="font-14">{{ item.name }}</h4>
                    <p class="font-12 color-999">
                      <span v-if="item.width != null"
                        >{{ String(item.file_size).split(".")[0] }}K {{ item.width }}*{{
                          item.height
                        }}</span
                      ><span v-else>无信息</span>
                    </p>
                  </div>
                  <div class="bottom flex space-around align-center">
                    <el-button type="text" @click="showImgDialog(item)">编辑</el-button>
                    <el-divider direction="vertical"></el-divider>
                    <el-button type="text" @click="delPicture(item)">删除</el-button>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <el-empty description="暂无图片"></el-empty>
        </div>
      </div>
      <div class="text-center mg-t-20">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pictureForm.current_page"
          :page-sizes="[12, 24, 36, 48, 60]"
          :page-size="Number(pictureList.per_page)"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pictureList.total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="编辑图片信息"
      :visible.sync="imgDialogVisible"
      :close-on-click-modal="false"
      width="30%"
    >
      <div class="flex align-center mg-t-20">
        <span style="width: 100px">图片名称</span>
        <el-input placeholder="请输入" v-model="imgName"> </el-input>
      </div>
      <div class="flex align-center mg-t-20">
        <span style="width: 80px">选择分组</span>
        <el-select v-model="group_uuid" placeholder="请选择" @change="changeGroupUuid">
          <el-option
            v-for="item in list2"
            :key="item.uuid"
            :label="item.name"
            :value="item.uuid"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="imgDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editPicture">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
      <div class="flex align-center mg-t-20">
        <span style="width: 80px">分组名称</span>
        <el-input placeholder="请输入" v-model="name"> </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitAddGroup(submitType)">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="上传图片"
      :visible.sync="uploadDialog"
      width="70%"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <uploadDialog @closeUploadDialog="closeUploadDialog" />
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../pagination.vue";
import uploadDialog from "../uploadDialog.vue";
export default {
  name: "imageLibrary",
  components: { pagination, uploadDialog },
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeIndex: 0,
      dialogVisible: false,
      imgDialogVisible: false,
      // 图片分组列表
      list: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      // 图片列表
      pictureList: [],
      acceptType: ".jpg,.png,.jpeg,.gif",
      title: "添加分组",
      pictureForm: {
        time: "",
        search_start_time: "",
        search_end_time: "",
        page_size: 12,
        page_index: 1,
        per_page: 12,
        current_page: 1,
        search_keyword: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      formData: "",
      name: "",
      uuid: "",
      // 编辑图片
      imgName: "",
      group_uuid: "",
      img_uuid: "",
      submitType: "",
      uploadImgList: [],
      list2: [],
      uploadDialog: false,
    };
  },
  created() {
    this.$previewRefresh();
    this.getGroupList();
  },
  mounted() {
    console.log(222);
  },
  methods: {
    showUploadDialog() {
      this.uploadDialog = true;
    },
    closeUploadDialog () {
      this.uploadDialog = false
      this.getPictureList()
    },
    // 点击更换组
    changeMenu(item, index) {
      this.group_uuid = item.uuid;
      this.activeIndex = index;
      this.getPictureList();
    },
    // 获取分组列表
    getGroupList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/PictureGroup?page_index=${this.list.current_page}&page_size=${this.list.per_page}`,
      }).then((res) => {
        //执行成功后代码处理
        console.log(res);
        this.list = res.data.result;
        this.getPictureList(this.list.data[0].uuid);
      });
    },
    // 获取不分页分组列表
    getUnGroupList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/PictureGroup?page_index=1&page_size=9999`,
      }).then((res) => {
        //执行成功后代码处理
        this.list2 = res.data.result.data;
      });
    },
    changeGroupUuid(v) {
      this.group_uuid = v;
    },
    // 添加分组
    addGroup() {
      this.name = "";
      this.dialogVisible = true;
      this.title = "添加分组";
      this.submitType = "add";
    },
    // 编辑分组
    editGroup(item) {
      this.submitType = "edit";
      this.dialogVisible = true;
      this.title = "编辑分组";
      this.name = item.name;
      this.uuid = item.uuid;
    },
    // 删除分组
    delGroup(item) {
      this.name = item.name;
      this.uuid = item.uuid;
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            data: {
              name: this.name,
            },
            headers: {
              "Content-Type": "text/html;charset=UTF-8",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://time.api.boyalife.cn/v1/cms/PictureGroup/${this.uuid}`,
          }).then((res) => {
            //执行成功后代码处理
            this.getGroupList();
            this.$message.success("删除成功！");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    // 提交
    submitAddGroup() {
      if (this.submitType === "add") {
        this.$api.materialLibrary
          .addPictureGroup({ name: this.name })
          .then((res) => {
            this.$message.success("创建成功！");
            this.dialogVisible = false;
            this.getGroupList();
          })
          .catch((err) => {
            this.$message.error(err);
          });
      } else if (this.submitType === "edit") {
        this.axios({
          method: "put", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: { name: this.name },
          url: ` https://time.api.boyalife.cn/v1/cms/PictureGroup/${this.uuid}`,
        }).then((res) => {
          //执行成功后代码处理
          this.dialogVisible = false;
          this.getGroupList();
          this.$message.success("编辑成功！");
        });
      }
    },
    // 选择时间
    getTimes(v) {
      this.pictureForm.search_start_time = v[0] + " 00:00:00";
      this.pictureForm.search_end_time = v[1] + " 23:59:59";
    },
    // 获取图片列表
    getPictureList(group_uuid) {
      if (group_uuid) {
        this.group_uuid = group_uuid;
      }
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.pictureForm,
        url: ` https://time.api.boyalife.cn/v1/cms/Picture?group_uuid=${this.group_uuid}&search_start_time=${this.pictureForm.search_start_time}&search_end_time=${this.pictureForm.search_end_time}&search_keyword=${this.pictureForm.search_keyword}&page_size=${this.pictureForm.per_page}&page_index=${this.pictureForm.current_page}`,
      }).then((response) => {
        this.pictureList = response.data.result;
      });
    },
    // 删除图片
    delPicture(item) {
      this.name = item.name;
      this.uuid = item.uuid;
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              "Content-Type": "text/html;charset=UTF-8",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://time.api.boyalife.cn/v1/cms/Picture/${this.uuid}`,
          }).then((res) => {
            //执行成功后代码处理
            this.getGroupList();
            this.getPictureList(this.group_uuid);
            this.$message.success("删除成功！");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    // 点击编辑图片弹窗
    showImgDialog(item) {
      this.getUnGroupList()

      this.imgDialogVisible = true;
      this.group_uuid = item.group_uuid;
      this.imgName = item.name;
      this.img_uuid = item.uuid;
    },
    editPicture() {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          name: this.imgName,
          group_uuid: this.group_uuid,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Picture/${this.img_uuid}`,
      }).then((res) => {
        //执行成功后代码处理
        this.imgDialogVisible = false;
        this.getPictureList();
        this.$message.success("编辑成功！");
      });
    },
    handleSizeChange(val) {
      this.pictureForm.per_page = Number(val);
      this.getPictureList();
    },
    handleCurrentChange(val) {
      this.pictureForm.current_page = Number(val);
      this.getPictureList();
    },
    listHandleSizeChange(val) {
      this.list.per_page = Number(val);
      this.getGroupList();
    },
    listHandleCurrentChange(val) {
      this.list.current_page = Number(val);
      this.getGroupList();
    },
    // 清空查询参数
    clearForm() {
      this.pictureForm = {
        time: "",
        search_start_time: "",
        search_end_time: "",
        search_keyword: "",
        per_page: 12,
        current_index: 1,
      };
    },
  },
};
</script>
<style scoped>
.menu {
  width: 20%;
  min-width: 200px;
  max-height: calc(100vh + 400px);
  overflow: auto;
}
.bbox {
  position: relative;
}
.bbox .upload-demo {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.menu .btn {
  display: none;
}
.menu dl dd {
  cursor: pointer;
  padding: 10px 5px;
}
.menu dl dd:hover,
.menu dl dd.current {
  background: #f6f9fe;
  color: #2561ef;
}
.menu dl dd .el-button {
  padding: 0;
}
.menu dl dd >>> .el-button span {
  font-size: 12px;
}
.menu dl dd:hover .btn {
  display: block;
}
.menu dl dd:hover > div > div:nth-child(1) {
  width: 70%;
}
.image {
  width: 80%;
  height: calc(100% - 100px);
  position: relative;
}
.el-icon-circle-plus-outline {
  color: #2561ef;
  font-size: 16px;
  cursor: pointer;
}
.el-icon-camera-solid {
  color: #ffc71c;
  font-size: 18px;
  margin-right: 10px;
  margin-top: 2px;
}
.el-row .el-col {
  margin-bottom: 20px;
  height: 252px;
}

.el-row .img {
  max-width: 100%;
  display: inline-block;
  height: 150px;
  cursor: pointer;
}
.el-row .info {
  margin: 0 1px;
}
.el-row .info .top {
  padding: 10px;
  border-bottom: 1px solid rgb(220, 220, 220);
}
.el-row .info .top h4 {
  color: #7da1f6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-row .info .bottom >>> .el-button span {
  font-size: 12px !important;
}
</style>
