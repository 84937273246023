<template>
  <div class="addLaboratory">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>实验室</el-breadcrumb-item>
      <el-breadcrumb-item>实验室管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/laboratoryList' }"
        >实验室列表</el-breadcrumb-item
      >
      <el-breadcrumb-item
        >{{ uuid ? "编辑" : "添加" }}实验室</el-breadcrumb-item
      >
    </el-breadcrumb>
    <div class="container">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-row>
        <el-col :span="16">
          <el-form ref="form" :model="labForm" label-width="120px">
            <el-form-item
              label="实验室名称"
              prop="name"
              :rules="{
                required: true,
                message: '实验室名称不能为空。',
                trigger: 'blur',
              }"
            >
              <el-input v-model="labForm.name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item
              label="实验室地址"
              prop="address"
              :rules="{
                required: true,
                message: '请选择实验室地址。',
                trigger: 'blur',
              }"
            >
              <p class="address" @click="selectMapControl">
                <span class="font-12 color-999" v-if="labForm.address == ''"
                  >点击启用地图控件</span
                >
                <span v-else
                  >{{ labForm.address
                  }}<span class="font-12 color-999 mg-l-20"
                    >(点击可修改)</span
                  ></span
                >
              </p>
            </el-form-item>
            <el-form-item
              label="实验室类型"
              prop="type"
              :rules="{
                required: true,
                message: '实验室类型不能为空。',
                trigger: 'blur',
              }"
            >
              <el-select v-model="labForm.type" placeholder="请选择">
                <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.val"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="labForm.type != ''"
              label="城市"
              prop="area_name"
              :rules="{
                required: true,
                message: '城市不能为空。',
                trigger: 'blur',
              }"
            >
              <el-select v-model="labForm.area_name" placeholder="请选择">
                <el-option
                  v-for="item in cityList"
                  :key="item.label"
                  :label="item.value"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="实验室ID">
              <el-input
                v-model="labForm.ext_id"
                placeholder="实验室ID作为同步数据的唯一标识"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="直播推流地址"
            >
              <el-input v-model="labForm.live_src" placeholder="请输入"></el-input>
            </el-form-item>
            <!-- <el-form-item label="开发时间"  
              :rules="{
                required: true,
                message: '开发时间不能为空。',
                trigger: 'blur',
              }">
                  
              <div v-for="(timeRange, index) in timeRanges" :key="index">
                <el-time-picker
                  class="mg-b-10"
                  v-model="timeRange.value"
                  is-range
                  format="H点"
                  value-format="H"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围"
                  :picker-options="{
                    selectableRange: '00:00:00 - 23:59:59'
                  }"
                  @change="handleChange(index, timeRange.value)"
                ></el-time-picker>
                <el-button type="text" class="mg-l-10" @click="delTime(index)" v-if="index != 0">删除</el-button>
              </div>
              <el-button type="text" @click="addTime">+ 新增时间段</el-button>
            </el-form-item> -->
            <el-form-item
              label="是否上线"
              prop="is_online"
              :rules="{
                required: true,
                message: '请选择是否上线',
                trigger: 'blur',
              }">
              <el-select v-model="labForm.is_online" placeholder="请选择">
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="2"></el-option>

              </el-select>
            </el-form-item>
            <!-- <el-form-item label="温度">
              <el-input
                v-model="labForm.temperature"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="湿度">
              <el-input
                v-model="labForm.humidity"
                placeholder="请输入"
              ></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="实验室视频">
              <el-button class="avatar-uploader video" @click="chooseVideo">
                <video v-if="labForm.video_src !== ''" width="178px" controls>
                  <source :src="labForm.video_src" />
                </video>
                <i
                  class="el-icon-delete"
                  v-if="labForm.video_src !== ''"
                  @click.stop="labForm.video_src = ''"
                ></i>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-button>
              <p class="font-12 color-999">建议尺寸：1080P，500m以内</p>
            </el-form-item> -->
            <el-form-item label="实验室封面" prop="image_src">
              <el-upload
                ref="upload"
                class="upload-demo"
                action="#"
                :accept="'jpg,jpeg,png'"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                list-type="picture-card"
                :http-request="httpRequest"
              >
              <img
                  v-if="labForm.image_src != ''"
                  :src="labForm.image_src"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
              </el-upload>
              <el-button  type="text"  @click="chooseBanner">从素材库选择</el-button>
              <p class="font-12 color-999">支持1张, 建议尺寸：750*1624像素</p>
              </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
    </div>
    <el-dialog
      title="选择地址"
      :visible.sync="mapDialog"
      width="80%"
      :close-on-click-modal="false"
    >
      <MapView
        :uuid="uuid"
        :mapForm="mapForm"
        @confirmDialog="confirmMap"
        @closeDialog="mapDialog = false"
      />
    </el-dialog>
    <el-dialog
      :title="getUrlType == 'image' ? '选择封面' : '选择视频'"
      :visible.sync="showMatreial"
      width="80%"
      :close-on-click-modal="false"
    >
      <MaterialLibrary
        :type="imgType"
        @selectImg="selectImg"
        @insertVideoHandler="insertVideoHandler"
      />
    </el-dialog>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog
      :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog"
      />
      </el-dialog>
  </div>
</template>
<script>
import MapView from "./mapView.vue";
import imageDialog from "../../components/imageDialog.vue";
import MaterialLibrary from '../../components/materialLibraryDialog/index.vue'
export default {
  name: "addLaboratory",
  data() {
    return {
      cityList: [{
        label: '北京',
        value: '北京'
      },{
        label: '天津',
        value: '天津'
      },{
        label: '深圳',
        value: '深圳'
      },{
        label: '无锡',
        value: '无锡'
      }],
      uuid: this.$route.query.uuid || "",
      dialogVisible: false,
      startTime: '',
      endTime: '',
      timeRanges: [
        { value: '' } 
      ],
      open_time: [],
      labForm: {
        is_online: '1',
        area_name: '',
        live_src: "",
        name: "", 
        ext_id: "",
        // open_time: [],
        temperature: "",
        humidity: "",
        type: "",
        longitude: "",
        latitude: "",
        province_uuid: "",
        city_uuid: "",
        area_uuid: "",
        address: "",
        // video_src: "",
        image_src: "",
      },
      mapForm: {
        longitude: "",
        latitude: "",
        province: "",
        city: "",
        area: "",
        address: "",
      },
      typeList: [
        { id: "1", val: "样品处理间" },
        { id: "2", val: "样品存储间" },
      ],
      //图片、视频
      imgType: "",
      showMatreial: false,
      getUrlType: "",
      //地址
      mapDialog: false,
    };
  },
  components: { MapView, imageDialog, MaterialLibrary },
  created() {
    console.log(this.labForm.open_time, '2222')
    console.log(this.timeArray)

    if (this.uuid.length > 0) {
      this.getDetail();
    }
  },
  methods: {
    chooseBanner() {
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible = true;
    },
    closeImageDialog () {
      this.dialogVisible = false
    },
     // 选择封面
     confirmImageDialog(item) {
      this.labForm.image_src = item[0].url;
      this.dialogVisible = false;
    },
     // 上传图片
     httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.labForm.image_src =  res[0].url;
          this.dialogVisible = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    beforeAvatarUpload(file) {
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    addTime () {
      this.timeRanges.push({
        value: ''
      })
    },
    delTime (index) {
      console.log(index)
      this.timeRanges.splice(index, 1)
      this.open_time.splice(index, 1)
      this.labForm.open_time =  Object.assign(this.open_time)
    },
    handleChange(index, value) {
      console.log(this.timeRanges);

      this.open_time.push(`${value[0]}-${value[1]}`)
      this.labForm.open_time =  Object.assign(this.open_time)
  },
    getIndex (i) {
      console.log(i)
    },
    //详情
    getDetail() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Lab/${this.uuid}`,
      })
        .then((res) => {
          let that = this
          that.timeRanges = []
          this.labForm = Object.assign(this.labForm, res.data.result);
          this.open_time = this.labForm.open_time
          this.labForm.open_time.map((item,index) => {
            that.timeRanges.push({value: []})
            console.log(item)
            that.timeRanges[index].value = item.split('-')
            console.log(that.timeRanges)

          })
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    //保存
    submit(form) {
      const formData = {
        name: this.labForm.name,
        ext_id: this.labForm.ext_id,
        temperature: this.labForm.temperature,
        open_time: this.labForm.open_time,
        humidity: this.labForm.humidity,
        type: this.labForm.type,
        longitude: this.labForm.longitude,
        latitude: this.labForm.latitude,
        province_uuid: this.labForm.province_uuid,
        city_uuid: this.labForm.city_uuid,
        area_uuid: this.labForm.area_uuid,
        address: this.labForm.address,
        video_src: this.labForm.video_src,
        image_src: this.labForm.image_src,
        live_src: this.labForm.live_src,
        area_name: this.labForm.area_name,
        is_online: this.labForm.is_online
      };
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.uuid.length !== 0) {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: formData,
              url: ` https://time.api.boyalife.cn/v1/cms/Lab/${this.uuid}`,
            })
              .then((res) => {
                this.$message.success("编辑成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.success(err);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: formData,
              url: ` https://time.api.boyalife.cn/v1/cms/Lab`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.success(err);
              });
          }
        } else {
          return false;
        }
      });
    },
    //视频
    chooseVideo() {
      this.imgType = "chooseVideo";
      this.getUrlType = "video";
      this.showMatreial = true;
    },
    insertVideoHandler(url) {
      this.labForm.video_src = url;
      this.showMatreial = false;
    },
    //封面
    chooseImg() {
      if (this.labForm.image_src.length > 0) {
        //仅支持一张
        return false;
      }
      this.imgType = "chooseCover_image";
      this.getUrlType = "image";
      this.showMatreial = true;
    },
    selectImg(item) {
      this.labForm.image_src = item.url;
      this.showMatreial = false;
    },
    //地址
    selectMapControl() {
      this.mapForm.longitude = this.labForm.longitude;
      this.mapForm.latitude = this.labForm.latitude;
      this.mapForm.province = this.labForm.province;
      this.mapForm.city = this.labForm.city;
      this.mapForm.area = this.labForm.area;
      this.mapForm.address = this.labForm.address;
      this.mapDialog = true;
    },
    confirmMap(form) {
      this.labForm.longitude = form.longitude;
      this.labForm.latitude = form.latitude;
      this.labForm.address = form.address;
      this.labForm.province_uuid = form.provinceValue.area_id;
      this.labForm.city_uuid = form.cityValue.area_id;
      this.labForm.area_uuid = form.districtValue.area_id;
      this.mapDialog = false;
    },
  },
};
</script>
<style scoped>
.addLaboratory {
  background-color: #f2f2f2;
}

.container {
  padding: 20px 20px 70px 20px;
  background: #fff;
  border-radius: 8px;
}

.el-select {
  width: 100%;
}

.address {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  cursor: pointer;
}

.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
::v-deep .el-time-spinner .el-time-spinner  .el-scrollbar__wrap:nth-child(2) {
  display: none!important;
}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
</style>
