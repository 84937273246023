<template>
  <div>
    <el-form label-width="120px">
      <el-form-item label="上传至分组">
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.uuid"
            :label="item.name"
            :value="item.uuid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择图片">
        <el-upload
          ref="upload"
          class="upload-demo"
          action="#"
          :accept="acceptType"
          :show-file-list="true"
          multiple
          :before-upload="beforeAvatarUpload"
          list-type="picture-card"
          :http-request="httpRequest"
          v-loading="loading"
        >
          <el-button type="text" size="mini">添加图片</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="操作">
        <el-button type="primary" size="mini" @click="submitUpload">确定</el-button>
        <el-button size="mini" @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      value: "",
      acceptType: ".jpg,.png,.jpeg,.gif",
      loading: false,
      options: [],
      uploadImgList: [],
    };
  },
  created() {
    this.getGroupList();
  },
  methods: {
    // 获取分组列表
    getGroupList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: `https://time.api.boyalife.cn/v1/cms/PictureGroup?page_index=1&page_size=9999`,
      }).then((res) => {
        //执行成功后代码处理
        console.log(res);
        this.options = res.data.result.data;
        this.value = this.options[0].uuid;
      });
    },
    // 上传图片
    httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          console.log(res);
          // this.getPictureList();
          this.uploadImgList.push(res[0]);
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    submitUpload() {
      if (this.uploadImgList.length == 0) {
        this.$message.error("请先添加图片！");
        return false;
      }
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json, text/plain",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          files: this.uploadImgList,
          group_uuid: this.value,
        },
        url: `https://time.api.boyalife.cn/v1/cms/Picture`,
      }).then((res) => {
        //执行成功后代码处理
        this.$message.success('操作成功！')
        this.$refs.upload.clearFiles();
        this.uploadImgList = [];
        this.$emit("closeUploadDialog");
      });
    },
    cancel() {
      this.$refs.upload.clearFiles();
      this.uploadImgList = [];
      this.$emit("closeUploadDialog");
    },
  },
};
</script>
