export default {
   // 资讯列表
   getInformationList:{
    method:'get',
    url:'/v1/cms/Information'
  },
  // 新增资讯
  addInformation:{
    method:'post',
    url:'/v1/cms/Information'
  },
  // 获取资讯分类列表
  getInformationCategoryList:{
    method:'get',
    url:'/v1/cms/InformationCategory'
  },
 
  // 获取早报列表
  getMorningPaperList:{
    method:'get',
    url:'/v1/cms/MorningPaper'
  },

}