<template>
  <div>
    <div class="box" style="border: 1px solid #ccc;position: relative;width: 1067px;">
      <i class="insertImg" @click="insertPic"></i>
      <i class="insertVideo" @click="insertVideo"></i>
      <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editor"
          :defaultConfig="toolbarConfig"
          :mode="mode"
          ref="editorData"
      />
      <Editor
          style="height: 500px; overflow-y: hidden;"
          v-model="html"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="onCreated"
      />
      <div class="layout" v-show="showMatreial">
        <div class="bg"></div>
        <i class="el-icon-circle-close" @click="closed"></i>
        <MaterialLibrary ref="matreial" class="matreial" :type="imgType" @insertImg="insertImg" @insertVideoHandler="insertVideoHandler"/>
      </div>
      <el-dialog
        title="选择图片"
        :visible.sync="dialogImageVisible"
        width="80%"
        :close-on-click-modal="false"
      >
        <imageDialogVue
        @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog"
        />
    
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import MaterialLibrary from './materialLibraryDialog/index';
import imageDialogVue from './imageDialog.vue';
export default {
  name: 'wangEditorTool',
  components: {
     Editor, Toolbar, MaterialLibrary,imageDialogVue
  },
  props: {
    content: ''
  },
  data () {
    return {
      showMatreial: false,
      dialogImageVisible: false,
      dialogVisible: false,
      title: '',
      imgType: '',
      editor: null,
      html: '',
      toolbarConfig: { },
      editorConfig: { 
        placeholder: '请输入内容...' 
      },
      mode: 'default',
    }
  },
created () {
  
},
mounted () {
  if (this.content !== '') {
    this.editor.setHtml(this.content)
  }
},
watch: {
  content: {
    handler (n) {
      this.editor.setHtml(n)
    }
  }
},
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错

      if (this.content !== '') {
        this.editor.setHtml(this.content)
      }

    },
    insertPic () {
      this.dialogImageVisible = true
    },
    insertVideo () {
      this.imgType = 'insert_video'
      this.showMatreial = true
      this.title = '选择视频'
    },
    insertImg (url) {
      this.showMatreial = false
      this.editor.dangerouslyInsertHtml(`<img src=${url.url} />`)
    },
    confirmImageDialog (item) {
      item.map(i => {
        this.editor.focus()
        this.editor.dangerouslyInsertHtml(`<img src=${i.url} />`)
      })
      this.dialogImageVisible = false
    },
    closeImageDialog () {
      this.dialogImageVisible = false
    },
    closeMatreial () {
      this.showMatreial = false
    },
    insertVideoHandler (url) {
      this.showMatreial = false
      const node = { type: 'video',src: url ,  children: [{ text: '' }] }
      Promise.resolve().then(() => {
         Transforms.insertNodes(this.editor, video)
      })
      this.editor.insertNode(node)
      this.editor.focus()
    },
    closed () {
      this.showMatreial = false
    }
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.insertImg {
  width: 52px;
  height: 40px;
  position: absolute;
  right: 18px;
  top: 0;
  z-index: 2;
  cursor: pointer;
}
.insertVideo {
  width: 52px;
  height: 42px;
  position: absolute;
  left: 5px;
  top: 39px;
  z-index: 2;
  cursor: pointer;
}
.layout {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 8;
  left: 0;
  top: 0;
}
.layout .bg  {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.4);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 7;
}
.layout .el-icon-circle-close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 10;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
.matreial  {
  width: 1050px;
  height: 80%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 8;
  background: #fff;
  padding: 30px;
  border-radius: 4px;
}
.matreial .libraryBox {
  height: 100%;
}
.matreial .menu {
  height: 80vh;
}
/* .box >>> video {
  width: 40%;
} */
</style>
