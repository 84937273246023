/**
 * @description  文件上传
 */
 export default {
    uploadFile: {
        method: 'post',
        url: '/v1/cms/Picture/upload'
    },
    uploadVideoFile: {
        method: 'post',
        url: '/v1/app/UploadFile'
    },
    uploadToGroupImg: {
        method: 'post',
        url: '/v1/cms/Picture'
    },
    manyUploadFile: {
        method: 'post',
        url: '/ws/file/uploads'
    },
    //    删除文件
    delFile: {
        method: 'post',
        url: '/ws/file/deleteFile'
    }
}
