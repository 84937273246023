<template>
  <!-- 家庭资产 -->
  <div class="familyAssets">
    <div class="container">
      <h3 class="title-t">细胞资产</h3>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-card>
            <p class="card-tit">家庭成员</p>
            <p class="card-con">8</p>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card>
            <p class="card-tit">细胞资产</p>
            <p class="card-con">9</p>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card>
            <p class="card-tit">life币</p>
            <p class="card-con">10000</p>
          </el-card>
        </el-col>
      </el-row>
      <el-tabs v-model="tabsName">
        <el-tab-pane label="成员细胞资产配置" name="assetAllocation">
          <AssetAllocation :uuid="uuid" />
        </el-tab-pane>
        <el-tab-pane label="我的细胞存储位置" name="storageLocation">
          <StorageLocation :uuid="uuid" />
        </el-tab-pane>
        <el-tab-pane label="成员样本动态" name="sampleDynamics">
          <SampleDynamics :uuid="uuid" />
        </el-tab-pane>
        <el-tab-pane label="家庭细胞资产明细" name="assetDetails">
          <AssetDetails :uuid="uuid" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import AssetAllocation from './assetAllocation.vue'
import StorageLocation from './storageLocation.vue'
import SampleDynamics from './sampleDynamics.vue'
import AssetDetails from './assetDetails.vue'
export default {
  name: 'familyAssets',
  data() {
    return {
      tabsName: 'assetAllocation',
      uuid: this.$route.query.uuid
    }
  },
  components: {
    AssetAllocation,
    StorageLocation,
    SampleDynamics,
    AssetDetails
  }
}
</script>
<style scoped>
.familyAssets {
  background: #f2f2f2;
}

.container {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}

.el-row {
  margin: 20px 0;
}

.card-tit {
  text-align: center;
  color: #909399;
}

.card-con {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}
</style>