export default {
  // 客户群组
  getCustomerGroupList:{
    method:'get',
    url:'/v1/cms/UserLogout'
  },
  // 客户等级
  getCustomerLevelList:{
    method:'get',
    url:'/v1/cms/UserLevel'
  },
  // 注销审核
  getUserLogoutList:{
    method:'get',
    url:'/v1/cms/UserLogout'
  },
  // 管理师列表
  getManagerList:{
    method:'get',
    url:'/v1/cms/Manager'
  },
  // 新增管理师
  addManager:{
    method:'post',
    url:'/v1/cms/Manager'
  }
}