<template>
  <div class="customerAnalysis" style="background: #f0f2f5">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>数据</el-breadcrumb-item>
      <el-breadcrumb-item>客户分析</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 商城订单 项目订单 快捷操作 会员数据概况 -->
    <div class="topSquare boxLayout">
      <h3 class="title-t mg-b-30" v-if="Object.keys(userOverview).length > 0">
        客户概况
      </h3>
      <div
        class="analysis flex space-around"
        v-if="Object.keys(userOverview).length > 0"
      >
        <div class="text-center">
          <h6 class="font-12 color-999">访客数</h6>
          <p class="font-30">{{ userOverview.visitor_number }}</p>
          <p class="font-12">
            今日
            <span style="color: #2561ef">{{
              userOverview.visitor_number_today
            }}</span>
          </p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">客户总数</h6>
          <p class="font-30">{{ userOverview.all_user }}</p>
          <p class="font-12">
            今日
            <span style="color: #2561ef">{{
              userOverview.all_user_today
            }}</span>
          </p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">支付订单数</h6>
          <p class="font-30">{{ userOverview.pay_number }}</p>
          <p class="font-12">
            今日
            <span style="color: #2561ef">{{
              userOverview.pay_number_today
            }}</span>
          </p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">支付订单金额</h6>
          <p class="font-30">
            <span class="font-12">￥</span> {{ userOverview.pay_amount }}
          </p>
          <p class="font-12">
            今日
            <span style="color: #2561ef">{{
              userOverview.pay_amount_today
            }}</span>
          </p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">客单价</h6>
          <p class="font-30">
            <span class="font-12">￥</span
            >{{ userOverview.avg_pay_user_amount }}
          </p>
          <p class="font-12">
            今日
            <span style="color: #2561ef">{{
              userOverview.avg_pay_user_amount_today
            }}</span>
          </p>
        </div>
      </div>
      <div class="title flex space-between align-center mg-t-50">
        <el-form
          :inline="true"
          :model="customerGroupForm"
          class="demo-form-inline"
        >
          <el-form-item label="选择时间">
            <el-date-picker
              class="mg-l-20"
              v-model="value"
              type="daterange"
              align="right"
              unlink-panels
              @change="getTimes"
              :clearable="false"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <el-button>导出</el-button>
      </div>
      <div class="tansData mg-t-30">
        <div
          id="customerOverview"
          ref="customerOverview"
          style="width: 100%; height: 350px"
        ></div>
      </div>
      <div
        class="boxLayout mg-t-20"
        v-if="Object.keys(userNewOldData).length > 0"
      >
        <h3 class="title-t">新客户/老客户占比</h3>
        <div class="myChartBox" style="width: 100%; height: 400px">
          <div class="totalNum color-333 font-30 text-center font-bold">
            {{ userNewOldData.all_number }}
            <p class="color-999 font-12">客户总数</p>
          </div>
          <div
            id="myChart"
            ref="myChart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
    </div>
    <div class="flex space-between mg-t-20">
      <div class="boxLayout boxShadow" style="width: 46%">
        <h3 class="title-t">性别分析</h3>
        <div class="myChartBox" style="width: 100%; height: 400px">
          <div
            class="totalNum color-333 font-30 text-center font-bold"
            v-if="Object.keys(userSex).length > 0"
          >
            {{ userSex.all_number }}
            <p class="color-999 font-12">客户总数</p>
          </div>
          <div id="sexBox" ref="sexBox" style="width: 100%; height: 100%"></div>
        </div>
      </div>
      <div class="boxLayout boxShadow" style="width: 46%">
        <h3 class="title-t">年龄分析</h3>
        <div class="myChartBox" style="width: 100%; height: 400px">
          <div
            class="totalNum color-333 font-30 text-center font-bold"
            v-if="Object.keys(userAge).length > 0"
          >
            {{ userAge.all_number }}
            <p class="color-999 font-12">客户总数</p>
          </div>
          <div id="ageBox" ref="ageox" style="width: 100%; height: 100%"></div>
        </div>
      </div>
    </div>
    <div class="boxLayout mg-t-20">
      <div class="title flex space-between align-center">
        <h3 class="title-t">成交客户分析</h3>
        <div class="flex">
          <el-form
            :inline="true"
            :model="customerGroupForm"
            class="demo-form-inline"
          >
            <el-form-item label="选择时间">
              <el-date-picker
                class="mg-l-20"
                v-model="value2"
                type="daterange"
                align="right"
                unlink-panels
                @change="getTimes2"
                :clearable="false"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <el-button @click="newOldUserAnalysisExport">导出</el-button>
        </div>
      </div>
      <div
        class="flex customerSuccess"
        v-if="Object.keys(newOldUserAnalysis).length > 0"
      >
        <div>
          <div class="title">客户类型</div>
          <div>全部成交客户</div>
          <div>新成交客户</div>
          <div>老成交客户</div>
        </div>
        <div>
          <div class="title">客户数量</div>
          <div>{{ newOldUserAnalysis.user_number }}</div>
          <div>{{ newOldUserAnalysis.new_user_number }}</div>
          <div>{{ newOldUserAnalysis.old_user_number }}</div>
        </div>
        <div>
          <div class="title">客户单价</div>
          <div>{{ newOldUserAnalysis.avg_pay_user_amount }}</div>
          <div>{{ newOldUserAnalysis.new_avg_pay_user_amount }}</div>
          <div>{{ newOldUserAnalysis.old_avg_pay_user_amount }}</div>
        </div>
        <div>
          <div class="title">支付金额</div>
          <div>{{ newOldUserAnalysis.pay_amount }}</div>
          <div>{{ newOldUserAnalysis.new_pay_amount }}</div>
          <div>{{ newOldUserAnalysis.old_pay_amount }}</div>
        </div>
        <div>
          <div class="title">访问-支付转化率</div>
          <div>{{ newOldUserAnalysis.visitor_user }}</div>
          <div>{{ newOldUserAnalysis.new_visitor_user }}</div>
          <div>{{ newOldUserAnalysis.old_visitor_user }}</div>
        </div>
      </div>
    </div>
    <div class="boxLayout mg-t-20">
      <div class="title flex space-between align-center mg-b-20">
        <h3 class="title-t">指标能力趋势</h3>
        <el-button>导出</el-button>
      </div>
      <p class="mg-l-30 mg-b-20">指标选择</p>
      <div
        id="transactionOverview"
        ref="transactionOverview"
        style="width: 100%; height: 600px"
      ></div>
    </div>
    <div class="boxLayout mg-t-20">
      <div class="title flex space-between align-center mg-b-20">
        <h3 class="title-t">消费能力分析</h3>
        <div class="flex">
          <el-form
            :inline="true"
            :model="customerGroupForm"
            class="demo-form-inline"
          >
            <el-form-item label="选择时间">
              <el-date-picker
                class="mg-l-20"
                v-model="value3"
                type="daterange"
                align="right"
                unlink-panels
                @change="getTimes2"
                :clearable="false"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <el-button>导出</el-button>
        </div>
      </div>
      <p class="mg-l-30 mg-b-10">指标选择</p>

      <div
        id="spendPower"
        ref="spendPower"
        style="width: 100%; height: 400px"
      ></div>
    </div>
  </div>
</template>
<script>
//引入echarts文件
import * as echarts from "echarts";
let currentTime = new Date();
let year = currentTime.getFullYear();
let month = currentTime.getMonth() + 1;
let day = currentTime.getDate();
let formattedDate = `${year}/${month
  .toString()
  .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;

const start = new Date();
start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
let timed = start.toLocaleDateString();
export default {
  name: "customerAnalysis",
  data() {
    return {
      value: [timed, formattedDate],
      value2: [timed, formattedDate],
      value3: [timed, formattedDate],

      customerGroupForm: {
        search_start_time: timed,
        search_end_time: formattedDate,
        search_start_time2: timed,
        search_end_time2: formattedDate,
        search_start_time3: timed,
        search_end_time3: formattedDate
      },
      type: ["add_user", "pay_user", "pay_number", "visitor_number"],
      type2: [
        "user_number",
        "pay_amount",
        "avg_pay_user_amount",
        "avg_visitor_pay"
      ],
      type3: [
        "user_number",
        "pay_number",
        "avg_user_pay_number",
        "pay_amount",
        "pay_amount_proportion"
      ],
      user_number: [],
      user_number_date: [],
      pay_amount: [],
      pay_amount_date: [],
      avg_pay_user_amount: [],
      avg_pay_user_amount_date: [],
      avg_visitor_pay: [],
      avg_visitor_pay_date: [],

      user_number2: [],
      pay_number2: [],
      avg_user_pay_number2: [],
      pay_amount2: [],
      pay_amount_proportion2: [],
      user_number_date2: [],
      pay_number_date2: [],
      avg_user_pay_number_date2: [],
      pay_amount_date2: [],
      pay_amount_proportion_date2: [],
      userOverview: {},
      checkList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      tableData: [],
      addUser: [],
      payNumber: [],
      payUser: [],
      visitorNumber: [],
      addUserDate: [],
      payNumberDate: [],
      payUserDate: [],
      visitorDate: [],
      userNewOldData: {},
      userSex: {},
      userAge: {},
      newOldUserAnalysis: {},
      arr: [
        {
          name: "新增客户数量",
          value: "add_user"
        },
        {
          name: "支付人数",
          value: "pay_user"
        },
        {
          name: "支付订单熟",
          value: "pay_number"
        },
        {
          name: "访问量",
          value: "visitor_number"
        }
      ]
    };
  },
  mounted() {
    this.getUserTransactionProfilePolyline();
    this.getUserNewOld();
    this.getSex();
    this.getAge();
    this.getNewOldUserAnalysis();
    this.getUserTransactionProfile();
    this.getNewOldUserIndicatorTrend();
    this.getNewOldUserSpendingPower();

    // this.getZB()
  },
  methods: {
    // 成交分析导出
    newOldUserAnalysisExport() {
      this.axios({
        method: "get", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/newOldUserAnalysisExport?start_time=${this.customerGroupForm.search_start_time2}&end_time=${this.customerGroupForm.search_end_time2}`
      })
        .then(response => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let currentTime = new Date();
          let year = currentTime.getFullYear();
          let month = currentTime.getMonth() + 1;
          let day = currentTime.getDate();
          let formattedDate = `${year}/${month
            .toString()
            .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;
          link.download = "新旧客户成交分析导出.xlsx" + "_" + formattedDate;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 新老客户占比
    getUserNewOld() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },

        url: ` https://time.api.boyalife.cn/v1/cms/Data/userNewOld`
      })
        .then(res => {
          this.userNewOldData = res.data.result;
          let i = setTimeout(() => {
            this.getCustomerData();
            clearTimeout(i);
          }, 500);
        })
        .catch(err => {
          this.$message.success(err);
        });
    },
    // 男女性占比
    getSex() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },

        url: ` https://time.api.boyalife.cn/v1/cms/Data/userSex`
      })
        .then(res => {
          this.userSex = res.data.result;
          this.getSexData();
        })
        .catch(err => {
          this.$message.success(err);
        });
    },
    // 年龄占比
    getAge() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },

        url: ` https://time.api.boyalife.cn/v1/cms/Data/userAge`
      })
        .then(res => {
          this.userAge = res.data.result;
          this.getAgeData();
        })
        .catch(err => {
          this.$message.success(err);
        });
    },
    // 会员数据概览
    getUserTransactionProfile() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/userTransactionProfile?start_time=${this.customerGroupForm.search_start_time}&end_time=${this.customerGroupForm.search_end_time}`
      })
        .then(res => {
          this.userOverview = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 会员数据折线图
    getUserTransactionProfilePolyline() {
      let str = "";
      for (let i in this.type) {
        str += "type[]=" + this.type[i] + "&";
      }
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/userTransactionProfilePolyline?start_time=${this.customerGroupForm.search_start_time}&end_time=${this.customerGroupForm.search_end_time}&${str}`
      })
        .then(res => {
          let params = res.data.result;
          this.addUser = [];
          this.payNumber = [];
          this.payUser = [];
          this.visitorNumber = [];
          this.addUserDate = [];
          this.payNumberDate = [];
          this.payUserDate = [];
          this.visitorDate = [];

          for (let i in params) {
            params[i].map(item => {
              if (i == "add_user") {
                this.addUser.push(item.number);
                this.addUserDate.push(item.date);
              } else if (i == "pay_number") {
                this.payNumber.push(item.number);
                this.payNumberDate.push(item.date);
              } else if (i == "pay_user") {
                this.payUser.push(item.number);
                this.payUserDate.push(item.date);
              } else if (i == "visitor_number") {
                this.visitorNumber.push(item.number);
                this.visitorDate.push(item.date);
              }
            });
          }
          this.initCustomerOverview();
        })
        .catch(err => {
          if (this.type.length == 0) {
            this.$message.error("请至少保留一个");
          }
        });
    },
    initCustomerOverview() {
      // 交易数据
      let tableData = [];
      if (this.addUserDate.length > 0) {
        tableData = this.addUserDate;
      }
      if (this.payNumberDate.length > 0) {
        tableData = this.payNumberDate;
      }
      if (this.payUserDate.length > 0) {
        tableData = this.payNumberDate;
      }
      if (this.visitorDate.length > 0) {
        tableData = this.payNumberDate;
      }

      let _that = this;
      let chartDom = document.getElementById("customerOverview");
      let transactionOverview = echarts.init(chartDom);
      let transactionOverviewOption = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985"
            }
          }
        },
        legend: {
          data: ["新增客户数量", "支付人数", "支付订单数", "访问量"],
          bottom: "0",
          selectedMode: "multiple"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "40px",
          height: "300px",
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: tableData
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            name: "新增客户数量",
            type: "line",
            data: _that.addUser,
            areaStyle: {},
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16
              }
            }
          },
          {
            name: "支付人数",
            type: "line",
            data: _that.payUser,
            areaStyle: {},
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16
              }
            }
          },
          {
            name: "支付订单数",
            type: "line",
            data: _that.payNumber,
            areaStyle: {},
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16
              }
            }
          },
          {
            name: "访问量",
            type: "line",
            data: _that.visitorNumber,
            areaStyle: {},
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16
              }
            }
          }
        ]
      };

      transactionOverviewOption &&
        transactionOverview.setOption(transactionOverviewOption);
      transactionOverview.on("legendselectchanged", function(params) {
        console.log(params);
        _that.type = [];
        let count = 0;
        for (let i in params.selected) {
          count++;
          if (params.selected[i] == true) {
            _that.type.push(_that.arr[count].value);
          }
        }
        _that.getUserTransactionProfilePolyline();
      });
    },
    getCustomerData() {
      let _that = this;
      // 会员数据概况
      let dom = document.getElementById("myChart");
      let myChart = echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false
      });
      let option;
      let datas = [
        { name: `老客户`, value: _that.userNewOldData.old_number },
        { name: `新客户`, value: _that.userNewOldData.new_number }
      ];
      option = {
        tooltip: {
          trigger: "item"
        },
        legend: {
          bottom: "5%",
          left: "center"
        },
        series: [
          {
            type: "pie",
            radius: [70, 100],
            top: top + "%",
            height: "100%",
            left: "center",
            width: 600,
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1
            },

            label: {
              alignTo: "edge",
              formatter: "{b}: {@2012} ({d}%) \n\n",
              minMargin: 0,
              edgeDistance: 20,
              lineHeight: 10,
              textStyle: {
                fontSize: 14
              }
            },
            labelLine: {
              length: 15,
              length2: 0,
              maxSurfaceAngle: 120
            },
            labelLayout: function(params) {
              const isLeft = params.labelRect.x < myChart.getWidth() / 2;
              const points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points
              };
            },
            data: datas
          }
        ]
      };
      if (option && typeof option === "object") {
        myChart.setOption(option);
      }
      window.addEventListener("resize", myChart.resize);
    },
    getSexData() {
      // 会员数据概况
      let _that = this;
      let dom = document.getElementById("sexBox");
      let myChart = echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false
      });
      let option;
      let datas = [
        { name: `男性会员`, value: _that.userSex.man_number },
        { name: `女性会员`, value: _that.userSex.woman_number }
      ];
      option = {
        tooltip: {
          trigger: "item"
        },
        legend: {
          bottom: "5%",
          left: "center"
        },
        series: [
          {
            type: "pie",
            radius: [70, 100],
            top: top + "%",
            height: "100%",
            left: "center",
            width: 600,
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1
            },

            label: {
              alignTo: "edge",
              formatter: "{b}: {@2012} ({d}%) \n\n",
              minMargin: 0,
              edgeDistance: 20,
              lineHeight: 10,
              textStyle: {
                fontSize: 14
              }
            },
            labelLine: {
              length: 15,
              length2: 0,
              maxSurfaceAngle: 120
            },
            labelLayout: function(params) {
              const isLeft = params.labelRect.x < myChart.getWidth() / 2;
              const points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points
              };
            },
            data: datas
          }
        ]
      };
      if (option && typeof option === "object") {
        myChart.setOption(option);
      }
      window.addEventListener("resize", myChart.resize);
    },
    getAgeData() {
      // 会员数据概况
      let _that = this;
      let dom = document.getElementById("ageBox");
      let myChart = echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false
      });
      let option;
      let datas = [
        { name: `20岁以下`, value: _that.userAge.number_0_20 },
        { name: `20-30岁`, value: _that.userAge.number_20_30 },
        { name: `30-40岁`, value: _that.userAge.number_30_40 },
        { name: `40-50岁`, value: _that.userAge.number_40_50 },
        { name: `50岁以上`, value: _that.userAge.number_50 }
      ];
      option = {
        tooltip: {
          trigger: "item"
        },
        legend: {
          bottom: "5%",
          left: "center"
        },
        series: [
          {
            type: "pie",
            radius: [70, 100],
            top: top + "%",
            height: "100%",
            left: "center",
            width: "100%",
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1
            },

            label: {
              alignTo: "edge",
              formatter: "{b}: {@2012} ({d}%) \n\n",
              minMargin: 0,
              edgeDistance: 20,
              lineHeight: 10,
              textStyle: {
                fontSize: 14
              }
            },
            labelLine: {
              length: 15,
              length2: 0,
              maxSurfaceAngle: 120
            },
            labelLayout: function(params) {
              const isLeft = params.labelRect.x < myChart.getWidth() / 2;
              const points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points
              };
            },
            data: datas
          }
        ]
      };
      if (option && typeof option === "object") {
        myChart.setOption(option);
      }
      window.addEventListener("resize", myChart.resize);
    },
    // 新旧成交客户
    getNewOldUserAnalysis() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },

        url: ` https://time.api.boyalife.cn/v1/cms/Data/newOldUserAnalysis?start_time=${this.customerGroupForm.search_start_time2}&end_time=${this.customerGroupForm.search_end_time2}`
      })
        .then(res => {
          console.log(res);
          this.newOldUserAnalysis = res.data.result;
        })
        .catch(err => {
          this.$message.success(err);
        });
    },
    // 指标能力趋势
    getNewOldUserIndicatorTrend() {
      let str = "";
      for (let i in this.type2) {
        str += "type[]=" + this.type2[i] + "&";
      }
      this.user_number = [];
      this.pay_amount = [];
      this.avg_pay_user_amount = [];
      this.avg_visitor_pay = [];
      this.user_number_date = [];
      this.pay_amount_date = [];
      this.avg_pay_user_amount_date = [];
      this.avg_visitor_pay_date = [];
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },

        url: ` https://time.api.boyalife.cn/v1/cms/Data/newOldUserIndicatorTrend?start_time=${this.customerGroupForm.search_start_time2}&end_time=${this.customerGroupForm.search_end_time2}&is_new=1&${str}`
      })
        .then(res => {
          console.log(res);
          let params = res.data.result;
          if (params.user_number != null) {
            params.user_number.map(item => {
              let obj = {};
              obj.value = item.number;
              obj.oldValue = "";
              this.user_number.push(obj);
              this.user_number_date.push(item.date);
            });
          }

          if (params.pay_amount != null) {
            params.pay_amount.map(item => {
              let obj = {};
              obj.value = item.number;
              obj.oldValue = "";
              this.pay_amount.push(obj);
              this.pay_amount_date.push(item.date);
            });
          }
          if (params.avg_pay_user_amount != null) {
            params.avg_pay_user_amount.map(item => {
              let obj = {};
              obj.value = item.number;
              obj.oldValue = "";
              this.avg_pay_user_amount.push(obj);
              this.avg_pay_user_amount_date.push(item.date);
            });
          }
          if (params.avg_visitor_pay != null) {
            params.avg_visitor_pay.map(item => {
              let obj = {};
              obj.value = item.number;
              obj.oldValue = "";
              this.avg_visitor_pay.push(obj);
              this.avg_visitor_pay_date.push(item.date);
            });
          }

          this.getTransactionOverview();
        })
        .catch(err => {
          this.$message.success(err);
        });
    },
    getZB() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },

        url: ` https://time.api.boyalife.cn/v1/cms/Data/newOldUserIndicatorTrend?start_time=${this.customerGroupForm.search_start_time2}&end_time=${this.customerGroupForm.search_end_time2}&is_new=2&${str}`
      })
        .then(res => {
          let params = res.data.result;
          console.log(params.user_number);

          if (params.user_number != null) {
            params.user_number.map((item, index) => {
              this.user_number[index].oldValue = item.number;
            });
          }
          if (params.pay_amount != null) {
            params.pay_amount.map((item, index) => {
              this.pay_amount[index].oldValue = item.number;
            });
          }
          if (params.avg_pay_user_amount != null) {
            params.avg_pay_user_amount.map((item, index) => {
              this.avg_pay_user_amount[index].oldValue = item.number;
            });
          }
          if (params.avg_visitor_pay != null) {
            params.avg_visitor_pay.map((item, index) => {
              this.avg_visitor_pay[index].oldValue = item.number;
            });
          }
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 指标能力趋势柱状图
    getTransactionOverview() {
      let _that = this;
      let totalDate = [];
      if (this.user_number_date.length > 0) {
        totalDate = this.user_number_date;
      }
      if (this.pay_amount_date.length > 0) {
        totalDate = this.pay_amount_date;
      }
      if (this.avg_pay_user_amount_date.length > 0) {
        totalDate = this.avg_pay_user_amount_date;
      }
      if (this.avg_visitor_pay_date.length > 0) {
        totalDate = this.avg_visitor_pay_date;
      }
      let chartDom = document.getElementById("transactionOverview");
      let transactionOverview = echarts.init(chartDom);
      let transactionOverviewOption = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          formatter: function(params) {
            let html = params[0].axisValue;
            params.map(item => {
              html += `<p class="font-12">
                <br>新成交${item.seriesName}： <span style="color: #91cc75;"> ${item["data"].value}</span><br>
                老成交${item.seriesName}：  <span style="color: #5470c6;"> ${item["data"].oldValue} </span><br>
                <p>
              `;
            });
            return html;
          }
        },
        legend: {
          selectedMode: "multiple",
          left: "120px"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            data: totalDate
          }
        ],
        yAxis: [
          {
            type: "value"
          }
        ],
        series: [
          {
            name: "客户数",
            type: "bar",
            smooth: true,
            data: _that.user_number
          },
          {
            name: "客单价",
            type: "bar",
            smooth: true,
            data: _that.pay_amount
          },
          {
            name: "支付金额",
            type: "bar",
            smooth: true,
            data: _that.avg_pay_user_amount
          },
          {
            name: "访问-支付转化率",
            type: "bar",
            smooth: true,
            data: _that.avg_visitor_pay
          }
        ]
      };
      transactionOverviewOption &&
        transactionOverview.setOption(transactionOverviewOption);
    },
    // 消费能力分析
    getNewOldUserSpendingPower() {
      let str = "";
      for (let i in this.type3) {
        str += "type[]=" + this.type3[i] + "&";
      }
      this.user_number2 = [];
      this.pay_number2 = [];
      this.avg_user_pay_number2 = [];
      this.pay_amount2 = [];
      this.pay_amount_proportion2 = [];
      this.user_number_date2 = [];
      this.pay_number_date2 = [];
      this.avg_user_pay_number_date2 = [];
      this.pay_amount_date2 = [];
      this.pay_amount_proportion_date2 = [];
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },

        url: ` https://time.api.boyalife.cn/v1/cms/Data/newOldUserSpendingPower?start_time=${this.customerGroupForm.search_start_time3}&end_time=${this.customerGroupForm.search_end_time3}&is_new=1&${str}`
      })
        .then(res => {
          console.log(res);
          if (res.data.result == null) {
            return false;
          } else {
            let params = res.data.result;
            if (params.user_number != null) {
              params.user_number.map(item => {
                let obj = {};
                obj.value = item.number;
                obj.oldValue = "";
                this.user_number2.push(obj);
                this.user_number_date2.push(item.date);
              });
            }
            console.log(this.user_number2);

            if (params.pay_number2 != null) {
              params.pay_number2.map(item => {
                let obj = {};
                obj.value = item.number;
                obj.oldValue = "";
                this.pay_number22.push(obj);
                this.pay_number2_date2.push(item.date);
              });
            }
            if (params.pay_amount != null) {
              params.pay_amount.map(item => {
                let obj = {};
                obj.value = item.number;
                obj.oldValue = "";
                this.pay_amount2.push(obj);
                this.pay_amount_date2.push(item.date);
              });
            }
            if (params.avg_user_pay_number != null) {
              params.avg_user_pay_number.map(item => {
                let obj = {};
                obj.value = item.number;
                obj.oldValue = "";
                this.avg_user_pay_number2.push(obj);
                this.avg_user_pay_number_date2.push(item.date);
              });
            }
            if (params.pay_amount_proportion != null) {
              params.pay_amount_proportion.map(item => {
                let obj = {};
                obj.value = item.number;
                obj.oldValue = "";
                this.pay_amount_proportion2.push(obj);
                this.pay_amount_proportion_date2.push(item.date);
              });
            }
            this.getSpendPower();
          }
        })
        .catch(err => {
          this.$message.success(err);
        });
      setTimeout(() => {
        this.axios({
          method: "get", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`
          },

          url: ` https://time.api.boyalife.cn/v1/cms/Data/newOldUserIndicatorTrend?start_time=${this.customerGroupForm.search_start_time2}&end_time=${this.customerGroupForm.search_end_time2}&is_new=2&${str}`
        })
          .then(res => {
            let params = res.data.result;
            if (params.user_number != null) {
              params.user_number.map((item, index) => {
                this.user_number2[index].oldValue = item.number;
              });
            }
            if (params.pay_number != null) {
              params.pay_number.map((item, index) => {
                this.pay_number2[index].oldValue = item.number;
              });
            }
            if (params.pay_amount != null) {
              params.pay_amount.map((item, index) => {
                this.pay_amount2[index].oldValue = item.number;
              });
            }
            if (params.avg_user_pay_number != null) {
              params.avg_user_pay_number.map((item, index) => {
                this.avg_user_pay_number2[index].oldValue = item.number;
              });
            }
            if (params.pay_amount_proportion != null) {
              params.pay_amount_proportion.map((item, index) => {
                this.pay_amount_proportion2[index].oldValue = item.number;
              });
            }
          })
          .catch(err => {
            this.$message.success(err);
          });
      }, 500);
    },
    // 消费能力分析趋势柱状图
    getSpendPower() {
      console.log("我在这");

      let _that = this;
      let totalDate = [];
      if (this.user_number_date2.length > 0) {
        totalDate = this.user_number_date2;
      }
      if (this.pay_number_date2.length > 0) {
        totalDate = this.pay_number_date2;
      }
      if (this.pay_amount_date2.length > 0) {
        totalDate = this.pay_amount_date2;
      }
      if (this.avg_user_pay_number_date2.length > 0) {
        totalDate = this.avg_user_pay_number_date2;
      }
      if (this.pay_amount_proportion2.length > 0) {
        totalDate = this.pay_amount_proportion2;
      }
      // 交易概况
      let chartDom = document.getElementById("spendPower");
      let spendPower = echarts.init(chartDom);
      let spendPowerOption = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          formatter: function(params) {
            // console.log(params)
            let html = params[0].axisValue;
            params.map(item => {
              console.log(item, "222");
              html += `<p class="font-12">
                <br>新${item.seriesName}： <span style="color: #91cc75;"> ${item["data"].value}</span><br>
                旧${item.seriesName}：  <span style="color: #5470c6;"> ${item["data"].oldValue} </span><br>
                <p>
              `;
            });
            return html;
          }
        },
        legend: {
          selectedMode: "multiple",
          left: "120px"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            data: totalDate
          }
        ],
        yAxis: [
          {
            type: "value"
          }
        ],
        series: [
          {
            name: "客户数量",
            type: "bar",
            data: _that.user_number2
          },
          {
            name: "支付件数",
            type: "bar",
            data: _that.pay_number2
          },
          {
            name: "人均支付件数",
            type: "bar",
            data: _that.avg_user_pay_number2
          },
          {
            name: "总支付金额",
            type: "bar",
            data: _that.pay_amount2
          },
          {
            name: "支付总额占比",
            type: "bar",
            data: _that.pay_amount_proportion2
          }
        ]
      };
      spendPowerOption && spendPower.setOption(spendPowerOption);
    },
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0];
      this.customerGroupForm.search_end_time = v[1];
      this.getUserTransactionProfilePolyline();
    },
    getTimes2(v) {
      this.customerGroupForm.search_start_time2 = v[0];
      this.customerGroupForm.search_end_time2 = v[1];
      this.getNewOldUserAnalysis();
    },
    getTimes3(v) {
      this.customerGroupForm.search_start_time3 = v[0];
      this.customerGroupForm.search_end_time3 = v[1];
      this.getNewOldUserSpendingPower();
    }
  }
};
</script>
<style scoped>
.shopData .number {
  color: #fff;
  font-weight: bold;
  font-size: 36px;
}
.shopData p {
  color: #fff;
}
.shopData .t {
  font-weight: bold;
}
.shopData .icon {
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shopData .icon i {
  font-size: 30px;
}

.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
.bg-blue {
  background: #2561ef;
}
.color-blue {
  color: #2561ef;
}
.bg-pink {
  background: #ff6262;
}
.color-pink {
  color: #ff6262;
}
.bg-green {
  background: #28d094;
}
.color-green {
  color: #28d094;
}
.bg-yellow {
  background: #ffc71c;
}
.color-yellow {
  color: #ffc71c;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
}
.topSquare > .el-row {
  height: 536px;
}
.topSquare .el-col-12 {
  height: 100%;
}
.topSquare > .el-row > .el-col > .grid-content {
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  margin-bottom: 0;
}
.shopOrder {
  padding: 23px 20px;
  border-radius: 8px;
}
.shopOrder .grid-content {
  padding: 0;
  padding-top: 20px;
  margin-bottom: 0;
}
.el-col-5 {
  width: 20% !important;
}
.shopOrder .grid-content .icon {
  width: 50px;
  height: 50px;
  background: #e6f1ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 15px;
}
.shopOrder .grid-content i {
  color: #2561ef;
  font-size: 24px;
}
.myChartBox {
  position: relative;
}
.myChartBox .totalNum {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.labelLineData {
  display: flex;
  justify-content: space-between;
}
.labelLineData p {
  position: relative;
}
.labelLineData p i {
  position: absolute;
  right: 0;
  bottom: -20px;
  color: #63a103;
}
.el-form-item {
  margin-bottom: 0;
}
p.mg-l-30 {
  margin-bottom: -25px;
}
.customerSuccess > div {
  flex: 1;
  text-align: center;
}
.customerSuccess > div div {
  padding: 20px 0;
  color: #666;
}
.customerSuccess .title {
  background: #f5f5f5;
  color: #333;
}
</style>
