<template>
  <!-- 添加客户 -->
  <div class="integralRule">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>客户</el-breadcrumb-item>
      <el-breadcrumb-item>客户设置</el-breadcrumb-item>
      <el-breadcrumb-item>成长值规则</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" :model="basicInfoForm" label-width="150px">
      <div class="boxLayout">
        <h3 class="title-t mg-b-30">基础规则</h3>
        <el-form-item label="注册" prop="is_register_growth">
          <el-checkbox v-model="basicInfoForm.is_register_growth"
            >获取</el-checkbox
          >
          <el-input
            v-model="basicInfoForm.register_growth"
            class="ipt"
            placeholder="请输入"
            ><template slot="prepend">成长值</template></el-input
          >
        </el-form-item>
        <el-form-item label="登录" prop="is_login_growth">
          <el-checkbox v-model="basicInfoForm.is_login_growth"
            >每天获取</el-checkbox
          >
          <el-input
            v-model="basicInfoForm.login_growth"
            class="ipt"
            placeholder="请输入"
            ><template slot="prepend">成长值</template></el-input
          >
        </el-form-item>

        <el-form-item label="分享商品" prop="is_share_growth">
          <el-checkbox v-model="basicInfoForm.is_share_growth"
            >获取</el-checkbox
          >
          <el-input
            v-model="basicInfoForm.share_growth"
            class="ipt"
            placeholder="请输入"
            ><template slot="prepend">成长值</template></el-input
          >
        </el-form-item>
        <el-form-item label="发布商品评价" prop="is_comment_growth">
          <el-checkbox v-model="basicInfoForm.is_comment_growth"
            >获取</el-checkbox
          >
          <el-input
            v-model="basicInfoForm.comment_growth"
            class="ipt"
            placeholder="请输入"
            ><template slot="prepend">成长值</template></el-input
          >
        </el-form-item>
        <el-form-item label="完成实名认证" prop="is_authentication_growth">
          <el-checkbox v-model="basicInfoForm.is_authentication_growth"
            >获取</el-checkbox
          >
          <el-input
            v-model="basicInfoForm.authentication_growth"
            class="ipt"
            placeholder="请输入"
            ><template slot="prepend">成长值</template></el-input
          >
        </el-form-item>
        <el-form-item label="订单获取规则" prop="growth_validity">
          <div class="flex align-center mg-b-10">
            <el-radio v-model="basicInfoForm.is_consumption_growth" :label="1"
              >每消费</el-radio
            >
            <p>
              <el-input
                v-model="basicInfoForm.consumption_number"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">笔</template></el-input
              >
            </p>
            <p>
              获取<el-input
                v-model="basicInfoForm.consumption_number_growth"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">成长值</template></el-input
              >
            </p>
          </div>
          <div class="flex align-center">
            <el-radio v-model="basicInfoForm.is_consumption_growth" :label="2"
              >每消费</el-radio
            >
            <p>
              <el-input
                v-model="basicInfoForm.consumption_price"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">元</template></el-input
              >
            </p>
            <p>
              获取<el-input
                v-model="basicInfoForm.consumption_price_growth"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">成长值</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="成长值有效期" prop="growth_validity_day">
          <div class="flex align-center mg-b-10">
            <el-radio v-model="basicInfoForm.growth_validity" :label="1"
              >永久有效</el-radio
            >
          </div>
          <div class="flex align-center">
            <el-radio v-model="basicInfoForm.growth_validity" :label="2"
              >获取</el-radio
            >
            <p>
              <el-input
                v-model="basicInfoForm.growth_validity_day"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >
              有效
            </p>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "integralRule",

  data() {
    return {
      selectCommdityDialogVisible: false,
      imageUrl: "",
      isShow: false,
      basicInfoForm: {
        is_register_growth: "",
        register_growth: "",
        is_login_growth: "",
        login_growth: "",
        is_share_growth: false,
        share_growth: "",
        is_comment_growth: "",
        comment_growth: "",
        is_authentication_growth: "",
        authentication_growth: "",
        growth_validity: "",
        growth_validity_day: "",
        consumption_number: "",
        consumption_number_growth: "",
        consumption_price: "",
        consumption_price_growth: "",
        is_consumption_growth: 1,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GrowthRule/1`,
      })
        .then((res) => {
          if (Object.keys(res.data.result).length > 0) {
            for (let i in res.data.result) {
              this.basicInfoForm[i] = res.data.result[i];
            }
            this.basicInfoForm.is_register_growth =
              this.basicInfoForm.is_register_growth == 1 ? true : false;
            this.basicInfoForm.is_login_growth =
              this.basicInfoForm.is_login_growth == 1 ? true : false;
            this.basicInfoForm.is_share_growth =
              this.basicInfoForm.is_share_growth == 1 ? true : false;
            this.basicInfoForm.is_comment_growth =
              this.basicInfoForm.is_comment_growth == 1 ? true : false;
            this.basicInfoForm.is_authentication_growth =
              this.basicInfoForm.is_authentication_growth == 1 ? true : false;
            this.basicInfoForm.is_consumption_growth = Number(
              this.basicInfoForm.is_consumption_growth
            );
            this.basicInfoForm.growth_validity = Number(
              this.basicInfoForm.growth_validity
            );
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    submit(form) {
      this.basicInfoForm.is_register_growth =
        this.basicInfoForm.is_register_growth == true ? 1 : 2;
      this.basicInfoForm.is_login_growth =
        this.basicInfoForm.is_login_growth == true ? 1 : 2;
      this.basicInfoForm.is_share_growth =
        this.basicInfoForm.is_share_growth == true ? 1 : 2;
      this.basicInfoForm.is_comment_growth =
        this.basicInfoForm.is_comment_growth == true ? 1 : 2;
      this.basicInfoForm.is_authentication_growth =
        this.basicInfoForm.is_authentication_growth == true ? 1 : 2;
      this.basicInfoForm.is_consumption_growth =
        this.basicInfoForm.is_consumption_growth == true ? 1 : 2;
      console.log(this.basicInfoForm.is_share_growth);
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.basicInfoForm,
            url: ` https://time.api.boyalife.cn/v1/cms/GrowthRule/1`,
          })
            .then((res) => {
              this.init();
              this.$message.success("保存成功！");
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.integralRule {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form {
  width: 100%;
}
>>> .ipt {
  width: 200px;
  margin: 0 10px;
}
>>> .el-checkbox {
  margin-right: 0;
}
p {
  line-height: 40px;
}
</style>
