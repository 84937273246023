<template>
  <div class="mapView">
    <el-form :model="form" ref="mapForm">
      <div class="flex mg-b-10 align-center">
        <h4 class="mg-r-10 text-right" style="width: 100px; margin-right: 10px">
          省市区查询
        </h4>
        <div class="input-item mg-r-10">
          <!-- <div class="input-item-prepend"><span class="input-item-text">省市区</span></div> -->
          <el-select
            value-key="area_id"
            clearable
            v-model="form.provinceValue"
            placeholder="请选择"
            @change="search($event)"
            @clear="clearProvince"
          >
            <el-option
              placeholder="请选择"
              clearable
              v-for="item in form.provinceoptions"
              :key="item.area_id"
              :label="item.area_name"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="input-item mg-r-10">
          <el-select
            value-key="area_id"
            clearable
            v-model="form.cityValue"
            placeholder="请选择"
            @change="search($event)"
            @clear="clearCity"
          >
            <el-option
              placeholder="请选择"
              clearable
              v-for="item in form.cityOptions"
              :key="item.area_id"
              :label="item.area_name"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="input-item mg-r-10">
          <el-select
            value-key="area_id"
            clearable
            v-model="form.districtValue"
            placeholder="请选择"
            @change="search($event)"
            @clear="clearDis"
          >
            <el-option
              placeholder="请选择"
              clearable
              v-for="item in form.districtOptions"
              :key="item.area_id"
              :label="item.area_name"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <p
          class="color-999 font-12"
          style="width: 200px; color: red"
          v-if="Object.keys(this.form.districtValue).length <= 0"
        >
          请先选择省市区
        </p>
      </div>
      <div
        class="flex align-center"
        v-if="Object.keys(this.form.districtValue).length > 0"
      >
        <p style="width: 100px; margin-right: 10px" class="text-right">
          详细地址查询
        </p>
        <el-input
          v-model="form.keyword"
          clearable
          class="ipt"
          placeholder="请输入详细地址"
          @input="kewordSearch"
        ></el-input>
      </div>
      <!-- <p style="width: 100px;margin-right: 10px; text-right" class="text-right">经纬度查询</p> -->
      <el-form-item
        label="经纬度"
        prop="latitude"
        label-width="110px"
        v-if="Object.keys(this.form.districtValue).length > 0"
      >
        <div class="flex align-center mg-t-10">
          <el-input
            v-model="form.longitude"
            clearable
            class="mg-r-10"
            placeholder="请输入经度"
            @input="numSearch"
          ></el-input>
          <el-input
            v-model="form.latitude"
            clearable
            placeholder="请输入纬度"
            @input="numSearch"
          ></el-input>
        </div>
      </el-form-item>
      <div
        class="flex align-center mg-t-10"
        v-if="Object.keys(this.form.districtValue).length > 0"
      >
        <p
          style="width: 100px;margin-right: 10px; text-right"
          class="text-right"
        >
          地址:
        </p>
        <p>{{ form.address !== "" ? form.address : "暂无" }}</p>
      </div>
    </el-form>
    <div class="map mg-t-10">
      <div
        class="layout"
        v-if="Object.keys(this.form.districtValue).length == 0"
      ></div>
      <div id="container"></div>
      <div id="panel"></div>
    </div>
    <div class="btns text-center mg-t-20 mg-b-20">
      <el-button @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="confirmDialog">确定</el-button>
    </div>
  </div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";

window._AMapSecurityConfig = {
  // 安全密钥
  securityJsCode: "5cc2994d6e5654e9bd6fbc0c50c5af86",
};
export default {
  name: "mapView",
  data() {
    return {
      //地图
      placeSearch: "",
      districtSearch: "",
      form: {
        address: "",
        longitude: "",
        latitude: "",
        provinceValue: {},
        provinceoptions: [],
        cityValue: {},
        cityOptions: [],
        districtValue: {},
        districtOptions: [],
        keyword: "",
        searchWord: "",
      },
    };
  },
  props: ["uuid", "mapForm"],
  created() {
    this.getProvince(-1);
    if (this.uuid.length > 0) {
      this.form.address = this.mapForm.address;
      this.form.longitude = this.mapForm.longitude;
      this.form.latitude = this.mapForm.latitude;
      this.form.provinceValue = this.mapForm.province;
      this.form.cityValue = this.mapForm.city;
      this.form.districtValue = this.mapForm.area;
      this.getCity(this.form.provinceValue.area_id);
      this.getArea(this.form.cityValue.area_id);
    }
  },
  mounted() {
    this.initMap();
    this.placeSearch.search(this.form.address, function (status, result) {
      // this.form.latitude = result.poiList.
    });
  },
  methods: {
    initMap() {
      let that = this;
      AMapLoader.load({
        key: "	a60d52419a53d04eb46d0248b5ee5f14", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.Geocoder",
          "AMap.AutoComplete",
          "AMap.DistrictSearch",
          "AMap.PlaceSearch",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("container", {
            //设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            zoom: 10, //初始化地图级别
            center: [116.395672, 39.906042], //初始化地图中心点位置
          });
          //点击获取经纬度;
          this.map.on("click", (e) => {
            // 获取经纬度
            this.form.longitude = e.lnglat.lng;
            this.form.latitude = e.lnglat.lat;
            // 清除点
            this.removeMarker();
            // 标记点
            this.setMapMarker();
            this.toGeoCoder();
            this.getPcd();
          });
          this.geoCoder = new AMap.Geocoder({
            radius: 1000, //范围，默认：500
          });
          // 关键字搜索
          this.placeSearch = new AMap.PlaceSearch({
            pageSize: 5, // 单页显示结果条数
            pageIndex: 1, // 页码
            city: "010", // 兴趣点城市
            citylimit: false, //是否强制限制在设置的城市内搜索
            map: this.map, // 展现结果的地图实例
            panel: "panel", // 结果列表将在此容器中进行展示。
            autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          });

          AMap.Event.addListener(
            this.placeSearch,
            "selectChanged",
            function (data) {
              that.form.latitude = data.selected.data.location.lat;
              that.form.longitude = data.selected.data.location.lng;
              that.form.address =
                data.selected.data.pname +
                data.selected.data.cityname +
                data.selected.data.name +
                data.selected.data.address;
            }
          );
        })
        .catch((e) => {});
    },
    getPcd(address) {
      // 获取省
      let provinceIndex = address.lastIndexOf("省");
      let provinceStr = address.substring(0, provinceIndex);
    },
    kewordSearch() {
      this.form.latitude = "";
      this.form.longitude = "";
      this.form.address = "";

      let str =
        this.form.provinceValue.area_name +
        this.form.cityValue.area_name +
        this.form.districtValue.area_name +
        this.form.keyword;

      this.placeSearch.search(str, function (status, result) {});
    },
    numSearch() {
      this.toGeoCoder();
      this.setMapMarker();
      this.$forceUpdate();
    },
    // 标记点
    setMapMarker() {
      // 自动适应显示想显示的范围区域
      this.map.setFitView();
      this.marker = new AMap.Marker({
        map: this.map,
        position: [this.form.longitude, this.form.latitude],
      });
      this.map.setFitView();
      this.map.add(this.marker);
    },
    // 清除点
    removeMarker() {
      if (this.marker) {
        this.map.remove(this.marker);
      }
    },
    toGeoCoder() {
      let lnglat = [this.form.longitude, this.form.latitude];
      this.geoCoder.getAddress(lnglat, (status, result) => {
        if (status === "complete" && result.regeocode) {
          this.form.address = result.regeocode.formattedAddress;
          this.getPcd();
        }
      });
    },
    getData(data, level) {
      let subList = data.districtList;
      // 清空下一级别的下拉列表
      if (level === "province") {
        this.cityValue = "";
        this.districtValue = "";
        this.areaValue = "";
        if (subList.length > 0) {
          let contentSub = {};
          for (let i = 0; i < subList.length; i++) {
            contentSub = {
              name: subList[i].name,
              value: subList[i].name,
              center: subList[i].center,
              adcode: subList[i].adcode,
              level: subList[i].level,
            };
            this.cityOptions.push(contentSub);
          }
        }
      } else if (level === "city") {
        this.form.districtValue = "";
        this.form.areaValue = "";
        if (subList.length > 0) {
          let contentSub = {};
          for (let i = 0; i < subList.length; i++) {
            contentSub = {
              name: subList[i].name,
              value: subList[i].name,
              center: subList[i].center,
              adcode: subList[i].adcode,
              level: subList[i].level,
            };
            this.form.districtOptions.push(contentSub);
          }
        }
      } else if (level === "district") {
        this.form.areaValue = "";
        if (subList.length > 0) {
          let contentSub = {};
          for (let i = 0; i < subList.length; i++) {
            contentSub = {
              name: subList[i].name,
              value: subList[i].name,
              center: subList[i].center,
              adcode: subList[i].adcode,
              level: subList[i].level,
            };
            this.form.areaOptions.push(contentSub);
          }
        }
      } else {
        if (subList.length > 0) {
          let contentSub = {};
          for (let i = 0; i < subList.length; i++) {
            contentSub = {
              name: subList[i].name,
              value: subList[i].name,
              center: subList[i].center,
              adcode: subList[i].adcode,
              level: subList[i].level,
            };
            this.form.provinceoptions.push(contentSub);
          }
        }
      }
    },
    getProvince(parent_id) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Province?parent_id=${parent_id}`,
      })
        .then((res) => {
          this.form.provinceoptions = JSON.parse(
            JSON.stringify(res.data.result)
          );
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    getCity(parent_id) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/City?parent_id=${parent_id}`,
      })
        .then((res) => {
          this.form.cityOptions = JSON.parse(JSON.stringify(res.data.result));
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    getArea(parent_id) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Area?parent_id=${parent_id}`,
      })
        .then((res) => {
          this.form.districtOptions = JSON.parse(
            JSON.stringify(res.data.result)
          );
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    search(obj) {
      console.log(obj);
      this.form.latitude = "";
      this.form.longitude = "";
      this.form.address = "";
      this.currentLevel = obj.level;
      if (obj.level == 1) {
        this.form.cityValue = "";
        this.form.districtValue = "";
        this.getCity(obj.area_id);
      }
      if (obj.level == 2) {
        this.form.districtValue = "";
        this.getArea(obj.area_id);
      }
      this.placeSearch.search(
        obj.area_name + this.form.keyword,
        function (status, result) {
          console.log(status);
          console.log(result);
          // this.form.latitude = result.poiList.
        }
      );
    },
    clearForm() {
      console.log(this.form);
      this.form.provinceValue = "";
      this.form.provinceoptions = [];
      this.form.cityValue = "";
      this.form.cityOptions = [];
      this.form.districtValue = "";
      this.form.districtOptions = [];
      this.form.areaValue = "";
      this.form.areaOptions = [];
      this.form.keyword = "";
      this.form.searchWord = "";
      this.form.form.address = "";
      this.form.form.longitude = "";
      this.form.form.latitude = "";
    },
    clearProvince() {
      this.form.provinceValue = "";
      this.form.cityValue = "";
      this.form.districtValue = "";
    },
    clearCity() {
      this.form.cityValue = "";
      this.form.districtValue = "";
    },
    clearDis() {
      this.form.districtValue = "";
    },
    // 确认选择地址
    confirmDialog() {
      if (this.form.districtValue == "") {
        this.$message.error("省市区必选");
        return false;
      }
      if (
        this.form.latitude !== "" &&
        this.form.longitude !== "" &&
        this.form.address !== ""
      ) {
        this.$emit("confirmDialog", this.form);
      } else {
        this.$message.error("请选择地图或者手动输入经纬度");
      }
    },
    closeDialog() {
      this.$emit("closeDialog", false);
    },
  },
};
</script>
<style scoped>
#container {
  padding: 0px;
  margin: 0px 20px;
  height: 300px;
}

.map {
  position: relative;
}

#panel {
  width: 300px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

>>> .el-input {
  width: 200px;
}

>>> .el-input.ipt {
  width: 500px;
}
</style>
