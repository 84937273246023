/**
 * @description 登录信息
 */
import storage from 'store'
import api from '@/api'

const state = {
    // token
    token: storage.get('__token'),
    //账号
    account: storage.get('__account'),
    // 登录角色名
    name: storage.get('__name'),
    // uuID
    uuid: storage.get('__uuid'),
    //手机号码
    mobile: storage.get('__mobile'),
    //是否是管理员
    isAdmin: storage.get('__isAdmin'),
    // 头像
    avatar: storage.get('__avatar'),
    menu: storage.get('__menu')
}
const mutations = {
    /* eslint-disable-next-line */
    SET_TOKEN(state, token) {
        state.token = token
        storage.set('__token', token, new Date().getTime() + 1 * 24 * 60 * 60 * 1000)
    },
     /* eslint-disable-next-line */
     SET_ACCOUNT(state, account) {
        state.account = account
        storage.set('__account', account)
    },
    /* eslint-disable-next-line */
    SET_NAME(state, name) {
        state.name = name
        storage.set('__name', name)
    },
    SET_UUID(state, uuid) {
        state.uuid = uuid
        storage.set('__uuid', uuid)
    },
    /* eslint-disable-next-line */
    SET_MOBILE(state, mobile) {
        state.mobile = mobile
        storage.set('__mobile', mobile)
    },
    /* eslint-disable-next-line */
    SET_IS_ADMIN(state, isAdmin){
        storage.set('__isAdmin', isAdmin)
        state.isAdmin = isAdmin
    },
    /* eslint-disable-next-line */
    SET_AVATAR(state, avatar){
        storage.set('__avatar', avatar)
        state.avatar = avatar
    },
    /* eslint-disable-next-line */
    SET_MENU(state, menu){
        storage.set('__menu', menu)
        state.menu = menu
    }
}
const actions = {
    /**
     * @description 登录
     */
    doLogin({ commit }, options) {
        return api.user.login(options).then(res => {
            //做缓存数据  
            // console.log('获取的用户数据',res)
            commit('SET_TOKEN',res.token)
            commit('SET_ACCOUNT',res.account)
            commit('SET_NAME',res.name)
            commit('SET_UUID',res.uuid)
            commit('SET_MOBILE',res.mobile)
            commit('SET_IS_ADMIN',res.group.is_admin)
            commit('SET_AVATAR',res.avatar)
            commit('SET_MENU',res.menu)


            return true
        })
    },
    /**
     * @description 登出
     */
    doLogout({ commit }) {
        commit('SET_TOKEN','')
        commit('SET_ACCOUNT','')
        commit('SET_NAME','')
        commit('SET_UUID','')
        commit('SET_MOBILE','')
        commit('SET_IS_ADMIN','')
        commit('SET_AVATAR','')
        commit('SET_MENU','')

        storage.clearAll()
        return true
    },
    getToken() {
        return storage.get('__token')
    },
    getMenu() {
        return storage.get('__menu') || []
    }
}
const getters = {
    token: state => state.token,
    account: state => state.account,
    name: state => state.name,
    uuid: state => state.uuid,
    mobile: state => state.mobile,
    isAdmin: state => state.isAdmin,
    avatar: state => state.avatar,
    menu: state => state.menu,
    all: state => ({ ...state }) 
}
export default {
    namespaced: true,
    mutations,
    actions,
    getters,
    state
}
