<template>
  <div class="projectOverview">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>数据</el-breadcrumb-item>
      <el-breadcrumb-item>项目概览</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container boxLayout mg-b-20">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="统计时间">
          <el-date-picker
            class="mg-l-20"
            v-model="value"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            :clearable="false"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <div
      class="overview boxLayout"
      v-if="Object.keys(projectOverview).length > 0"
    >
      <h3 class="title-t mg-b-20">项目数据概览</h3>
      <div class="flex">
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            被访问项目数
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">{{ projectOverview.item_number || 0 }}</p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ projectOverview.item_number_scale || 0 }}</p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            项目总浏览量
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">
            {{ projectOverview.visitor_number || 0 }}
          </p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ projectOverview.visitor_number_scale || 0 }}</p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            项目总访问数
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">{{ projectOverview.visitor_user || 0 }}</p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ projectOverview.visitor_user_scale || 0 }}</p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            项目总咨询量
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">{{ projectOverview.consult_user || 0 }}</p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ projectOverview.consult_user_scale || 0 }}</p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            访问—咨询转化率
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">
            {{ projectOverview.avg_visitor_consult || 0 }}
          </p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>{{ projectOverview.avg_visitor_consult_scale || 0 }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="boxLayout mg-t-20">
      <div class="title flex space-between align-center mg-b-20">
        <h3 class="title-t mg-b-20">项目交易明细</h3>
        <div>
          <el-button @click="projectExport">导出</el-button>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        @sort-change="visitorSortChange"
        style="width: 100%"
      >
        <el-table-column
          label="排名"
          type="index"
          :align="'center'"
          fixed="left"
          width="55"
        >
        </el-table-column>
        <el-table-column
          label="商品描述"
          :align="'center'"
          prop="name"
          width="300"
        >
          <template slot-scope="scope">
            <div class="nickAndAvatar">
              <el-image :src="scope.row.avatar"></el-image>
              <div style="width: 200px">
                <el-tooltip :content="scope.row.name" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.name }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="visitor_number"
          label="浏览量"
          :align="'center'"
          width="150"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="visitor_user"
          label="访问量"
          :align="'center'"
          width="150"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="consult_user"
          label="总咨询量(UV)"
          :align="'center'"
          width="150"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="pay_user"
          label="支付人数"
          :align="'center'"
          width="150"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="pay_number"
          label="支付订单数"
          width="150"
          :align="'center'"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="pay_amount"
          label="支付金额(元)"
          width="150"
          :align="'center'"
          sortable
        >
          <template slot-scope="scope"> ￥{{ scope.row.pay_amount }} </template>
        </el-table-column>
        <el-table-column
          prop="avg_visitor_consult"
          label="访问-咨询转化率(%)"
          width="150"
          :align="'center'"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="avg_consult_user"
          label="咨询-支付转化率(%)"
          width="150"
          :align="'center'"
          sortable
        >
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";

let currentTime = new Date();
let year = currentTime.getFullYear();
let month = currentTime.getMonth() + 1;
let day = currentTime.getDate();
let formattedDate = `${year}/${month
  .toString()
  .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;

const start = new Date();
start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
let timed = start.toLocaleDateString();
export default {
  name: "",
  components: { pagination },
  data() {
    return {
      customerGroupForm: {
        search_start_time: timed,
        search_end_time: formattedDate,
        sort_value: "",
        sort_key: ""
      },
      value: [timed, formattedDate],
      projectOverview: {},
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0
      }
    };
  },
  created() {
    this.getProjectData();
    this.getProjectDetail();
  },
  methods: {
    visitorSortChange(column) {
      console.log(column);
      if (column.order == "descending") {
        this.customerGroupForm.sort_value = "desc";
      } else {
        this.customerGroupForm.sort_value = "asc";
      }
      this.customerGroupForm.sort_key = column.prop;
      this.getProjectDetail();
    },

    // 商品报表导出
    projectExport() {
      this.axios({
        method: "get", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/itemTransactionDetailExport?start_time=${this.customerGroupForm.search_start_time}&end_time=${this.customerGroupForm.search_end_time}&sort_value=${this.customerGroupForm.sort_value}&sort_key=${this.customerGroupForm.sort_key}`
      })
        .then(response => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "项目交易明细.xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 商品概览
    getProjectData() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/itemTransactionProfile?start_time=${this.customerGroupForm.search_start_time}&end_time=${this.customerGroupForm.search_end_time}`
      })
        .then(res => {
          this.projectOverview = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getProjectDetail() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/itemTransactionDetail?start_time=${this.customerGroupForm.search_start_time}&end_time=${this.customerGroupForm.search_end_time}&sort_value=${this.customerGroupForm.sort_value}&sort_key=${this.customerGroupForm.sort_key}`
      })
        .then(res => {
          this.tableData = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0];
      this.customerGroupForm.search_end_time = v[1];
      this.getProjectData();
      this.getProjectDetail();
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getProjectDetail();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getProjectDetail();
    }
  }
};
</script>
<style scoped>
.overview .box {
  padding: 20px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  min-width: 175px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.el-table .el-image {
  border-radius: 0;
}
</style>
