<template>
  <div>
    <el-form label-width="120px">
      <el-form-item label="上传至分组">
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.uuid"
            :label="item.name"
            :value="item.uuid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="添加视频">
        <el-upload
          ref="upload"
          class="upload-demo"
          action="#"
          :accept="acceptType2"
          :show-file-list="false"
          :http-request="httpRequest"
          :before-upload="beforeAvatarUpload"
          v-loading="loading"
        >
         <video v-if="videoSrc != ''" width="178px">
            <source :src="videoSrc" />
          </video>
          <i
            class="el-icon-delete"
            v-if="videoSrc != ''"
            @click.stop="videoSrc != ''"
          ></i>
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <p>仅支持MP4格式，大小不超过50M</p>
      </el-form-item>
      <el-form-item label="添加封面">
        <el-upload
          ref="upload2"
          class="upload-demo"
          action="#"
          :show-file-list="false"
          :accept="acceptType"
          :before-upload="beforeAvatarUpload2"
          :http-request="httpRequest2"
          v-loading="loading"
        >
        <el-image  v-if="cover!=''" :src="cover" />
          <i
            class="el-icon-delete"
            v-if="cover != ''"
            @click.stop="cover = ''"
          ></i>
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <p>仅支持jpg,jpeg,png三种格式格式，大小不超过10M</p>

      </el-form-item>
      <el-form-item label="操作">
        <el-button type="primary" size="mini" @click="submitUpload">确定</el-button>
        <el-button size="mini" @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      value: "",
      video: [],
      cover: '',
      acceptType: ".jpg,.png,.jpeg,.gif",
      acceptType2: ".mp4",
      loading: false,
      options: [],
      videoSrc: '',
      name: '',
      formData: '',
      formData2: '',

    };
  },
  created() {
    this.getGroupList();
    
  },
  methods: {
    getVal (v) {
      this.formData.append("file", file);

    },
    // 获取分组列表
    getGroupList() {
      // 获取分组列表
      this.$api.materialLibrary.getVideoGroupList().then((res) => {
        console.log(res,'video')
        this.options = res;
      });
    },
    // 上传图片
    httpRequest() {
      this.$api.file
        .uploadVideoFile(this.formData)
        .then((res) => {
          console.log(res);
          // this.getPictureList();
          this.videoSrc= res ;
          this.loading = false;
          console.log(typeof this.videoSrc)
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    beforeAvatarUpload(file) {
      this.name = file.name
      let size = file.size / 1024 / 1024 < 50;
      if (!size) {
        this.$message.error("上传视频大小不能超过 50MB!");
        return false
      }
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file", file);
    },
    beforeAvatarUpload2(file) {
      let size = file.size / 1024 / 1024 < 10;
      if (!size) {
        this.$message.error("上传封面图片大小不能超过 10MB!");
        return false
      }
      this.loading = true;
      this.formData2 = new FormData();
      this.formData2.append("file", file);
    },
    httpRequest2() {
      this.$api.file
        .uploadVideoFile(this.formData2)
        .then((res) => {
          this.cover = res;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    submitUpload() {
      if (this.videoSrc == '' ) {
        this.$message.error("请先添加视频！");
        return false;
      }

      this.$api.materialLibrary
        .addVideo({
          name: this.name,
          group_uuip: this.value,
          url: this.videoSrc,
          cover: this.cover
        })
        .then((res) => {
          this.$message.success("操作成功！");
          this.$refs.upload.clearFiles();
          this.$refs.upload2.clearFiles();

          this.videoSrc = '';
          this.cover = '';

          this.$emit("closeUploadDialog");
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    cancel() {
      this.$refs.upload.clearFiles();
      this.$refs.upload2.clearFiles();
      this.videoSrc = '';
      this.cover = '';
      this.$emit("closeUploadDialog");
    },
  },
};
</script>
<style scoped>
.upload-demo{
  border: 1px dashed #409eff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 100px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.upload-demo .el-icon-delete {
  font-size: 20px;
  position: absolute;
  right: -24px;
  bottom: 0px;
}
</style>
