import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views/login/index.vue'
import index from '../views/index.vue'
import Stage from '../views/stage/index.vue'
// 客户
import CustomerList from '../views/customer/customerList/index.vue'
import CustomerInfo from '../views/customer/customerList/customerInfo'
import LoginLog from '../views/customer/loginLog/index.vue'
import AddCustomer from '../views/customer/customerList/addCustomer'
import CustomerGroupList from '../views/customer/customerGroupList/index'
import AddGroup from '../views/customer/customerGroupList/addGroup'
import CustomerLabel from '../views/customer/customerLabel/index'
import AddLabel from '../views/customer/customerLabel/addLabel'
import LabelGroup from '../views/customer/customerLabel/labelGroup'
import MemberLevelList from '../views/customer/memberLevelList/index'
import AddMemberLevel from '../views/customer/memberLevelList/addMemberLevel'
import MemberBenefitsList from '../views/customer/memberBenefitsList/index'
import AddMemberBenefits from '../views/customer/memberBenefitsList/addMemberBenefits'
import RealNameAuthentication from '../views/customer/realNameAuthenticationList/index'
import CancelAudit from '../views/customer/cancelAuditList/index'
//家庭资产
import FamilyAssets from '../views/customer/familyAssets/index.vue'
import AssetsDetailReport from '../views/customer/familyAssets/assetsDetailReport.vue'
import Particulars from '../views/customer/familyAssets/particulars.vue'
// 客户导入
import CustomerImport from '../views/customer/customerImport/index'
// 积分规则
import IntegralRule from '../views/customer/integralRule/index'
// 成长值规则
import GrowthValueRules from '../views/customer/growthValueRules/index'
import CustomerMaterial from '../views/customer/customerMaterial/index'
import AddMaterial from '../views/customer/customerMaterial/addMaterial'
import ManagerMasterList from '../views/customer/managerMasterList/index'
import AddManagerMaster from '../views/customer/managerMasterList/addManagerMaster'
// 内容 资讯列表
import InformationList from '../views/article/informationList/index'
import Information from '../views/article/informationList/information'
import AddInformation from '../views/article/informationList/addInformation'
import InformationData from '../views/article/informationList/informationData'
import RecommondPosisitionSet from '../views/article/recommondPosisitionSet/index'
import AddRecommonPosition from '../views/article/recommondPosisitionSet/addRecommonPosition'


// 内容 早报列表
import MorningPaperList from '../views/article/morningPaperList/index'
import AddMorningPaper from '../views/article/morningPaperList/addMorningPaper'
// 资讯分类
import InformationClassification from '../views/article/informationClassification/index'
import AddInformationClassification from '../views/article/informationClassification/addInformationClassification'

// 敏感词
import SensitiveWordsList from '../views/article/sensitiveWordsList/index'
import WordList from '../views/article/sensitiveWordsList/wordList'
// Banner
import BannerList from '../views/article/bannerList/index'
import AddBanner from '../views/article/bannerList/addBanner'
// 广告
import AdvertisementManageList from '../views/article/advertisementManageList/index'
import AddAdvertise from '../views/article/advertisementManageList/addAdvertise'
// 用户反馈
import UserFeedbackList from '../views/article/userFeedbackList/index'
// 消息公告
import NoticeList from '../views/article/noticeList/index'
import MassMessage from '../views/article/noticeList/massMessage'
import ReadList from '../views/article/noticeList/readList'

// 门店
import StoreList from '../views/salesroom/storeList/index'
import AddStore from '../views/salesroom/storeList/addStore'
import BrandList from '../views/salesroom/brandList/index'
import SupplierList from '../views/salesroom/supplierList/index'
import AddSupplier from '../views/salesroom/supplierList/addSupplier'

//实验室
import LaboratoryList from '../views/laboratory/list.vue' 
import LaboratoryConfig from '../views/laboratory/laboratoryConfig.vue' 
import AddLaboratory from '../views/laboratory/addLaboratory.vue'
import Authentication from '../views/laboratory/authentication.vue'
import AddDatum from '../views/laboratory/addDatum.vue'

// 项目评价
import ProjectList from '../views/project/projectList/index'
import AddProject from '../views/project/projectList/addProject'
import ProjectCagotery from '../views/project/projectCagotery/index'
import ProjectAttributeDatabase from '../views/project/attributeDatabase/index'
import AddProjectAttribute from '../views/project/attributeDatabase/addAttribute'

import ProjectEvaluateList from '../views/project/projectEvaluateList/index'
// 细胞列表
import CellList from '../views/cell/cellList/index'
import CellInfo from '../views/cell/cellList/cellInfo'
// 合同档案
import ContractArchives from '../views/cell/contractArchives/index'

// 缴费记录
import PaymentRecords from '../views/cell/paymentRecords/index'
// 保险记录
import InsuranceRecords from '../views/cell/insuranceRecords/index'
// 理赔记录
import ClaimRecords from '../views/cell/claimRecords/index'
// 样本动态 
import SampleDynamics from '../views/cell/sampleDynamics/index'

// 报告列表
import ReportList from '../views/cell/reportList/index'
// 进度管理
import ProcessManage from '../views/cell/processManage/index'
import AddProjectProcess from '../views/cell/processManage/addProjectProcess'
// 实物商品列表
import PhysicalCommodityList from '../views/commodity/physicalCommodityList/index'
// 新增实物商品
import AddPhysicalCommodity from '../views/commodity/physicalCommodityList/addPhysicalCommodity'
// 虚拟商品分类
import VirtualGoodsList from '../views/commodity/virtualGoodsList/index'
// 新增虚拟商品
import AddVirtualGoods from '../views/commodity/virtualGoodsList/addVirtualGoods'
import PriceChangeApproval from '../views/commodity/priceChangeApproval'
// 实物库存管理
import InventoryManage from '../views/commodity/inventoryManage/index'
// 库存记录
import InventoryRecords from '../views/commodity/inventoryManage/inventoryRecords'
// 虚拟库存
import VirtualInventoryManage from '../views/commodity/virtualInventoryManage/index'
// 添加卡券
import AddCard from '../views/commodity/virtualInventoryManage/addCard.vue'
// 虚拟库存记录
import VirtualInventoryRecords from '../views/commodity/virtualInventoryManage/virtualInventoryRecords'
// 商品分类
import CommodityCategoty from '../views/commodity/commodityCategoty/index'
// 属性库
import CommodityAttributeDatabase from '../views/commodity/commodityAttributeDatabase/index'
import CommodityAddAttribute from '../views/commodity/commodityAttributeDatabase/commodityAddAttribute'
// 素材库
import MaterialLibrary from '../views/commodity/materialLibrary/index'

// 商品评价
import CommodityEvaluate from '../views/commodity/commodityEvaluate/index'
// 商品订单
import CommodityOrder from '../views/order/commodityOrder/index'
// 订单详情 
import OrderInfo from '../views/order/commodityOrder/orderInfo'
// 虚拟商品订单
import VirtualGoodsOrder from '../views/order/virtualGoodsOrder/index'
import VirtualOrderInfo from '../views/order/virtualGoodsOrder/virtualOrderInfo'
// 项目订单
import ProjectOrder from '../views/order/projectOrder/index'
import ProjectOrderInfo from '../views/order/projectOrder/projectOrderInfo'
// 服务订单 ServiceOrderInfo
import ServiceOrder from '../views/order/serviceOrder/index'
import ServiceOrderInfo from '../views/order/serviceOrder/serviceOrderInfo'
// 售后管理 ServiceOrderInfo
import AfterSaleManage from '../views/order/afterSaleManage/index'
import AfterSaleManageInfo from '../views/order/afterSaleManage/afterSaleManageInfo'
// 运费模板
import FreightTemplate from '../views/order/freightTemplate/index'
import AddFreightTemplate from '../views/order/freightTemplate/addFreightTemplate'

//  退货地址管理
import ReturnAddress from '../views/order/returnAddress/index'
import AddReturnAddress from '../views/order/returnAddress/addReturnAddress'
//  售后原因 
import AfterSalesReason from '../views/order/afterSalesReason/index'
//  通用设置 
import GeneralSet from '../views/order/generalSet/index'
// 营销  任务设置 
// 活动列表 activityInfo
import ActivityList from '../views/marketing/activityList/index'
import ActivityInfo from '../views/marketing/activityList/activityInfo'
import ActivityCategory from '../views/marketing/activityList/activityCategory'
// 新增活动
import AddNewActivity from '../views/marketing/activityList/addNewActivity'
// 活动数据
import ActivityData from '../views/marketing/activityList/activityData'
// 签到记录
import AttendanceRecord from '../views/marketing/activityList/attendanceRecord'
// 优惠券
import CouponList from '../views/marketing/couponList/index'
// 添加优惠券
import AddCoupon from '../views/marketing/couponList/addCoupon'
// 优惠券数据
import CouponData from '../views/marketing/couponList/couponData'
// 转赠记录
import TransferRecord from '../views/marketing/couponList/transferRecord'
// 满减/满赠
import FullReductionActivity from '../views/marketing/fullReductionActivity'
import AddFullReduction from '../views/marketing/fullReductionActivity/addFullReduction'
import Seckill from '../views/marketing/seckill'
import AddSeckill from '../views/marketing/seckill/addSeckill.vue'
import SeckillData from '../views/marketing/seckill/seckillData.vue'

import TaskList from '../views/marketing/taskList/index'
// 签到有礼
import SignPolite from '../views/marketing/signPolite/index'
// 推荐设置
import RecommendSet from '../views/marketing/recommendSet/index'

import RecommendSetSet from '../views/marketing/recommendSet/set'


// 邀请有礼
import InvitateIsPolite from '../views/marketing/invitateIsPolite/index'
// 老会员奖励 
import OldMemberRewards from '../views/marketing/oldMemberRewards/index'
import NewMemberRewards from '../views/marketing/newMemberRewards/index'
// 代币记录
import TokensRecord from '../views/marketing/tokensRecord/index'
// 设置兑换比例
import SetExchangeRate from '../views/marketing/tokensRecord/setExchangeRate'
// 积分记录
import IntegralRecord from '../views/marketing/integralRecord/index' 
import BenefitRecord from '../views/marketing/benefitRecord/index' 
import GrowthValueRecord from '../views/marketing/growthValueRecord/index' 

// 储值卡管理  
import StoredValueCardManage from '../views/marketing/storedValueCardManage/index'
// 储值购买记录 
import StoredValuePurchaseRecords from '../views/marketing/storedValuePurchaseRecords/index'
// 储值明细
import DetailsOfStoredValue from '../views/marketing/storedValuePurchaseRecords/detailsOfStoredValue'
// 勋章
import MedalList from '../views/marketing/medalList/index'
import AddMedal from '../views/marketing/medalList/addMedal'
import MedalCategory from '../views/marketing/medalList/medalCategory'

// 推广列表
import PromoterList from '../views/promoter/promoterList/index'
// 推广等级设置
import PromoterLevelSet from '../views/promoter/promoterList/promoterLevelSet'
// 推广员信息
import PromoterInfo from '../views/promoter/promoterList/promoterInfo'
// 推广员数据
import PromoterData from '../views/promoter/promoterList/promoterData'
// 推广员审核
import PromoterExamine from '../views/promoter/promoterExamine/index'
// 推广员订单记录
import PromotionOrderRecords from '../views/promoter/promotionOrderRecords/index'
// 推广设置
import PromotionSet from '../views/promoter/promotionSet/index'


// 数据
// 交易数据
import TransactionData from '../views/data/transactionData/index'
// 商品概况 
import ProductOverview from '../views/data/productOverview/index'
// 商品报表
import ProductReport from '../views/data/productReport/index'
// 类目分析
import CategoryAnalysis from '../views/data/categoryAnalysis/index'
// 项目概括 
import ProjectOverview from '../views/data/projectOverview/index'
// 客户分析 
import CustomerAnalysis from '../views/data/customerAnalysis/index'
// 会员分析
import MemberAnalysis from '../views/data/memberAnalysis/index'
// 流量分析
import FlowAnalysis from '../views/data/flowAnalysis/index'
// 留存分析
import RetentionAnalysis from '../views/data/retentionAnalysis/index'
// 积分分析
import IntegralAnalysis from '../views/data/integralAnalysis/index'
// 推广员分析
import PromoterAnalysis from '../views/data/promoterAnalysis/index'
// 财务
// 交易流水
import TransactionFlow from '../views/finance/transactionFlow/index'
// 提现审核
import WithdrawalReview from '../views/finance/withdrawalReview/index'
// 开票列表
import InvoicingList from '../views/finance/invoicingList/index'

// 设置  
// 账号管理
import AccountManagement from '../views/setting/accountManagement/index'
import AddAccount from '../views/setting/accountManagement/addAccount'
// 角色管理
import RolesManagement from '../views/setting/rolesManagement/index'
import AddRole from '../views/setting/rolesManagement/addRole'
// 部门管理 ProtocolManagement
import DepartmentManagement from '../views/setting/departmentManagement/index'
import AddDepartment from '../views/setting/departmentManagement/addDepartment'
// 协议管理 
import ProtocolManagement from '../views/setting/protocolManagement/index'
import EditProtocol from '../views/setting/protocolManagement/editProtocol'
// 博雅简介  boyaIntroduce   
import BoyaIntroduce from '../views/setting/boyaIntroduce/index'
// 产品与服务
import ProductService from '../views/setting/productService/index'
// 联系我们
import ContactUs from '../views/setting/contactUs/index'

// 短信签名
import SmsSignature from '../views/setting/smsSignature/index'
import SmsConfiguration from '../views/setting/smsSignature/smsConfiguration'
import AddSignature from '../views/setting/smsSignature/addSignature'
// 短信模板
import SmsTemplate from '../views/setting/smsTemplate/index'
// 黑名单
import SmsBlackList from '../views/setting/smsBlackList/index'
// 消息配置
import MessageConfiguration from '../views/setting/messageConfiguration/index'
import AddMessageConfig from '../views/setting/messageConfiguration/addMessageConfig'
// 页面配置
import MiniProgramPageConfig from '../views/setting/miniProgramPageConfig/index'

// 分享配置
import ShareSet from '../views/setting/shareSet/index'
// 常见问题
import UsualAskQuestion from '../views/setting/usualAskQuestion/index'
// 操作日志 OperationLog
import OperationLog from '../views/setting/operationLog/index'
// 修改密码
import ChangePassword from '../views/setting/changePassword/index'
// 通知中心 Notifications
import Notifications from '../views/setting/notifications/index'
// 荣誉与认证  honorsAndCertifications
import HonorsAndCertifications from '../views/setting/honorsAndCertifications/index'
// 新增荣誉  addHonors
import AddHonors from '../views/setting/honorsAndCertifications/addHonors'
// 赠品管理  
import GiftManagement from '../views/marketing/giftManagement/index'
// 新增赠品 giftManagement
import AddGift from '../views/marketing/giftManagement/addGift'

// 菜单配置
import MenuSet from '../views/setting/menuSet/index'
import AddMenu from '../views/setting/menuSet/addMenu'
// 打标签 
import MarkLabel from '../components/markLabel'
Vue.use(Router)
export default new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
      meta: {
        title: '博雅时光机'
      }
    },
    {
      path: '/index',
      name: 'index',
      component: index,
      children: [
        // 工作台
        {
          path: '/markLabel',
          name: 'markLabel',
          component: MarkLabel
        },
        // 工作台
        {
          path: '/stage',
          name: 'stage',
          component: Stage,
          meta: {
            title: '工作台'
          }
        },
        // 客户列表
        {
          path: '/customerList',
          name: 'customerList',
          component: CustomerList,
          meta: {
            title: '客户列表'
          }
        },
        // 客户详情
        {
          path: '/customerInfo',
          name: 'customerInfo',
          component: CustomerInfo,
          meta: {
            title: '客户详情'
          }
        },
        //登录详情
        {
          path: '/loginLog',
          name: 'loginLog',
          component: LoginLog,
          meta: {
            title: '客户详情'
          }
        },
        // 添加客户
        {
          path: '/addCustomer',
          name: 'addCustomer',
          component: AddCustomer,
          meta: {
            title: '编辑客户'
          }
        },
        //家庭资产
        {
          path: '/familyAssets',
          name: 'familyAssets',
          component: FamilyAssets,
          meta: {
            title: '家庭资产'
          }
        },
        //资产明细报告
        {
          path: '/assetsDetailReport',
          name: 'assetsDetailReport',
          component: AssetsDetailReport,
          meta: {
            title: '家庭资产'
          }
        },
        //资产明细
        {
          path: '/particulars',
          name: 'particulars',
          component: Particulars,
          meta: {
            title: '家庭资产'
          }
        },
        // 客户群组列表
        {
          path: '/customerGroupList',
          name: 'customerGroupList',
          component: CustomerGroupList,
          meta: {
            title: '客户群组列表'
          }
        },
        // 添加群组
        {
          path: '/addGroup',
          name: 'addGroup',
          component: AddGroup,
          meta: {
            title: '添加群组'
          }
        },
        // 客户标签列表
        {
          path: '/customerLabel',
          name: 'customerLabel',
          component: CustomerLabel,
          meta: {
            title: '客户标签列表'
          }
        },
        // 添加标签
        {
          path: '/addLabel',
          name: 'addLabel',
          component: AddLabel,
          meta: {
            title: '添加标签'
          }
        },
        // 标签组
        {
          path: '/labelGroup',
          name: 'labelGroup',
          component: LabelGroup,
          meta: {
            title: '标签组'
          }
        },
        // 会员等级列表
        {
          path: '/memberLevelList',
          name: 'memberLevelList',
          component: MemberLevelList,
          meta: {
            title: '会员等级列表'
          }
        },
        // 添加会员等级
        {
          path: '/addMemberLevel',
          name: 'addMemberLevel',
          component: AddMemberLevel,
          meta: {
            title: '添加会员等级'
          }
        },
        // 会员权益列表
        {
          path: '/memberBenefitsList',
          name: 'memberBenefitsList',
          component: MemberBenefitsList,
          meta: {
            title: '会员权益列表'
          }
        },
        // 添加会员权益
        {
          path: '/addMemberBenefits',
          name: 'addMemberBenefits',
          component: AddMemberBenefits,
          meta: {
            title: '添加会员权益'
          }
        },
        // 实名认证
        {
          path: '/realNameAuthentication',
          name: 'realNameAuthentication',
          component: RealNameAuthentication,
          meta: {
            title: '实名认证列表'
          }
        },
        // 注销审核
        {
          path: '/cancelAudit',
          name: 'cancelAudit',
          component: CancelAudit,
          meta: {
            title: '注销审核列表'
          }
        },
        // 客户导入
        {
          path: '/customerImport',
          name: 'customerImport',
          component: CustomerImport,
          meta: {
            title: '客户导入'
          }
        },
        // 积分规则
        {
          path: '/integralRule',
          name: 'integralRule',
          component: IntegralRule,
          meta: {
            title: '积分规则'
          }
        },
        // 成长值规则
        {
          path: '/growthValueRules',
          name: 'growthValueRules',
          component: GrowthValueRules,
          meta: {
            title: '成长值规则'
          }
        },
        // 客户资料
        {
          path: '/customerMaterial',
          name: 'customerMaterial',
          component: CustomerMaterial,
          meta: {
            title: '客户资料'
          }
        },
        // 添加客户资料
        {
          path: '/addMaterial',
          name: 'addMaterial',
          component: AddMaterial,
          meta: {
            title: '添加客户资料'
          }
        },
        // 管理师列表
        {
          path: '/managerMasterList',
          name: 'managerMasterList',
          component: ManagerMasterList,
          meta: {
            title: '管理师列表'
          }
        },
        // 添加管理师
        {
          path: '/addManagerMaster',
          name: 'addManagerMaster',
          component: AddManagerMaster,
          meta: {
            title: '添加管理师'
          }
        },
        // 咨讯列表
        {
          path: '/informationList',
          name: 'informationList',
          component: InformationList,
          meta: {
            title: '咨讯列表'
          }
        },
        ,
        // 添加咨讯
        {
          path: '/addInformation',
          name: 'addInformation',
          component: AddInformation,
          meta: {
            title: '添加咨讯'
          }
        },
        // 咨讯详情
        {
          path: '/information',
          name: 'information',
          component: Information,
          meta: {
            title: '咨讯详情'
          }
        },
        // 咨讯数据
        {
          path: '/informationData',
          name: 'informationData',
          component: InformationData,
          meta: {
            title: '数据分析'
          }
        },
        {
          path: '/recommondPosisitionSet',
          name: 'recommondPosisitionSet',
          component: RecommondPosisitionSet,
          meta: {
            title: '首页推荐位'
          }
        },
        {
          path: '/addRecommonPosition',
          name: 'addRecommonPosition',
          component: AddRecommonPosition,
          meta: {
            title: '添加推荐位'
          }
        },
        // 早报列表
        {
          path: '/morningPaperList',
          name: 'morningPaperList',
          component: MorningPaperList,
          meta: {
            title: '早报列表'
          }
        },
        // 添加早报
        {
          path: '/addMorningPaper',
          name: 'addMorningPaper',
          component: AddMorningPaper,
          meta: {
            title: '添加早报'
          }
        },
        // 资讯分类
        {
          path: '/informationClassification',
          name: 'informationClassification',
          component: InformationClassification,
          meta: {
            title: '资讯分类列表'
          }
        },
        // 添加分类
        {
          path: '/addInformationClassification',
          name: 'addInformationClassification',
          component: AddInformationClassification,
          meta: {
            title: '添加信息分类'
          }
        },
        // 敏感词库 sensitiveWordsList
        {
          path: '/sensitiveWordsList',
          name: 'sensitiveWordsList',
          component: SensitiveWordsList,
          meta: {
            title: '敏感词库列表'
          }
        },
        // 敏感词
        {
          path: '/wordList',
          name: 'wordList',
          component: WordList,
          meta: {
            title: '敏感词列表'
          }
        },
        // banner图
        {
          path: '/bannerList',
          name: 'bannerList',
          component: BannerList,
          meta: {
            title: 'banner列表'
          },
        },
        // 增加banner图
        {
          path: '/addBanner',
          name: 'addBanner',
          component: AddBanner,
          meta: {
            title: '增加banner'
          }
        },
        // 广告列表
        {
          path: '/advertisementManageList',
          name: 'advertisementManageList',
          component: AdvertisementManageList,
          meta: {
            title: '广告列表'
          }
        },
        // 增加广告
        {
          path: '/addAdvertise',
          name: 'addAdvertise',
          component: AddAdvertise,
          meta: {
            title: '增加广告'
          }
        },
        {
          path: '/userFeedbackList',
          name: 'userFeedbackList',
          component: UserFeedbackList,
          meta: {
            title: '用户反馈列表'
          }
        },
        // 消息公告 
        {
          path: '/noticeList',
          name: 'noticeList',
          component: NoticeList,
          meta: {
            title: '消息公告列表'
          }
        },
        // 群发消息
        {
          path: '/massMessage',
          name: 'massMessage',
          component: MassMessage,
          meta: {
            title: '群发消息'
          }
        },
        // 已读名单
        {
          path: '/readList',
          name: 'readList',
          component: ReadList,
          meta: {
            title: '已读名单'
          }
        },
        // 店铺列表
        {
          path: '/storeList',
          name: 'storeList',
          component: StoreList,
          meta: {
            title: '店铺列表'
          }
        },
        // 添加店铺
        {
          path: '/addStore',
          name: 'addStore',
          component: AddStore,
          meta: {
            title: '添加店铺'
          }
        },
        // 品牌列表
        {
          path: '/brandList',
          name: 'brandList',
          component: BrandList,
          meta: {
            title: '品牌列表'
          }
        },
        // 供应商列表
        {
          path: '/supplierList',
          name: 'supplierList',
          component: SupplierList,
          meta: {
            title: '供应商列表'
          }
        },
        // 增加供应商
        {
          path: '/addSupplier',
          name: 'addSupplier',
          component: AddSupplier,
          meta: {
            title: '增加供应商'
          }
        },
        //实验室列表
        {
          path: '/laboratoryList',
          name: 'laboratoryList',
          component: LaboratoryList,
          meta: {
            title: '实验室列表'
          }
        },
        // 实验室配置
        //添加实验室
        {
          path: '/laboratoryConfig',
          name: 'laboratoryConfig',
          component: LaboratoryConfig,
          meta: {
            title: '实验室配置'
          }
        },
        //添加实验室
        {
          path: '/addLaboratory',
          name: 'addLaboratory',
          component: AddLaboratory,
          meta: {
            title: '添加实验室'
          }
        },
        //认证
        {
          path: '/authentication',
          name: 'authentication',
          component: Authentication,
          meta: {
            title: '添加实验室'
          }
        },
        //添加认证资料
        {
          path: '/addDatum',
          name: 'addDatum',
          component: AddDatum,
          meta: {
            title: '添加实验室'
          }
        },
        // 项目列表
        {
          path: '/projectList',
          name: 'projectList',
          component: ProjectList,
          meta: {
            title: '项目列表'
          }
        },
        // 添加项目
        {
          path: '/addProject',
          name: 'addProject',
          component: AddProject,
          meta: {
            title: '添加项目'
          }
        },
        // 项目分类
        {
          path: '/projectCagotery',
          name: 'projectCagotery',
          component: ProjectCagotery,
          meta: {
            title: '项目分类'
          }
        },
        // 属性库
        {
          path: '/addProjectAttribute',
          name: 'addProjectAttribute',
          component: AddProjectAttribute,
          meta: {
            title: '项目属性库'
          }
        },
        // 新增项目属性
        {
          path: '/projectAttributeDatabase',
          name: 'projectAttributeDatabase',
          component: ProjectAttributeDatabase,
          meta: {
            title: '新增项目属性'
          }
        },
        // 项目评价
        {
          path: '/projectEvaluateList',
          name: 'projectEvaluateList',
          component: ProjectEvaluateList,
          meta: {
            title: '项目评价'
          }
        },
        // 细胞列表
        {
          path: '/cellList',
          name: 'cellList',
          component: CellList,
          meta: {
            title: '细胞列表'
          }
        },
        // 细胞详情
        {
          path: '/cellInfo',
          name: 'cellInfo',
          component: CellInfo,
          meta: {
            title: '细胞详情'
          }
        },
        // 合同档案
        {
          path: '/contractArchives',
          name: 'contractArchives',
          component: ContractArchives,
          meta: {
            title: '合同档案'
          }
        },
        // 缴费记录
        {
          path: '/paymentRecords',
          name: 'paymentRecords',
          component: PaymentRecords,
          meta: {
            title: '缴费记录'
          }
        },
        // 保险记录
        {
          path: '/insuranceRecords',
          name: 'insuranceRecords',
          component: InsuranceRecords,
          meta: {
            title: '保险记录'
          }
        },
        // 理赔记录
        {
          path: '/claimRecords',
          name: 'claimRecords',
          component: ClaimRecords,
          meta: {
            title: '理赔记录'
          }
        },
        // 样本动态
        {
          path: '/sampleDynamics',
          name: 'sampleDynamics',
          component: SampleDynamics,
          meta: {
            title: '样本动态'
          }
        },
        // 报告列表
        {
          path: '/reportList',
          name: 'reportList',
          component: ReportList,
          meta: {
            title: '报告列表'
          }
        },
        // 进度管理
        {
          path: '/processManage',
          name: 'processManage',
          component: ProcessManage,
          meta: {
            title: '进度管理'
          }
        },
        // 添加项目进度
        {
          path: '/addProjectProcess',
          name: 'addProjectProcess',
          component: AddProjectProcess,
          meta: {
            title: '添加项目进度'
          }
        },
        // 实物商品列表
        {
          path: '/physicalCommodityList',
          name: 'physicalCommodityList',
          component: PhysicalCommodityList,
          meta: {
            title: '实物商品列表'
          }
        },
        // 增加实物商品
        {
          path: '/addPhysicalCommodity',
          name: 'addPhysicalCommodity',
          component: AddPhysicalCommodity,
          meta: {
            title: '增加实物商品'
          }
        },
        // 虚拟商品列表
        {
          path: '/virtualGoodsList',
          name: 'virtualGoodsList',
          component: VirtualGoodsList,
          meta: {
            title: '虚拟商品列表'
          }
        },
        // 改价审批
        {
          path: '/priceChangeApproval',
          name: 'priceChangeApproval',
          component: PriceChangeApproval,
          meta: {
            title: '改价审批'
          }
        },
        // 增加虚拟商品
        {
          path: '/addVirtualGoods',
          name: 'addVirtualGoods',
          component: AddVirtualGoods,
          meta: {
            title: '增加虚拟商品'
          }
        },
        // 实物库存管理 
        {
          path: '/inventoryManage',
          name: 'inventoryManage',
          component: InventoryManage,
          meta: {
            title: '实物库存管理'
          }
        },
        // 库存记录
        {
          path: '/inventoryRecords',
          name: 'inventoryRecords',
          component: InventoryRecords,
          meta: {
            title: '库存记录'
          }
        },
        // 虚拟库存管理 
        {
          path: '/virtualInventoryManage',
          name: 'virtualInventoryManage',
          component: VirtualInventoryManage,
          meta: {
            title: '虚拟库存管理'
          }
        },
        // 添加卡券
        {
          path: '/addCard',
          name: 'addCard',
          component: AddCard,
          meta: {
            title: '添加卡券'
          }
        },
        // 虚拟库存记录
        {
          path: '/virtualInventoryRecords',
          name: 'virtualInventoryRecords',
          component: VirtualInventoryRecords,
          meta: {
            title: '虚拟库存记录'
          }
        },
        // 商品分类 
        {
          path: '/commodityCategoty',
          name: 'commodityCategoty',
          component: CommodityCategoty,
          meta: {
            title: '商品分类'
          }
        },
        // 属性库 
        {
          path: '/commodityAttributeDatabase',
          name: 'commodityAttributeDatabase',
          component: CommodityAttributeDatabase,
          meta: {
            title: '商品属性库'
          }
        },
        // 素材库 
        {
          path: '/materialLibrary',
          name: 'materialLibrary',
          component: MaterialLibrary,
          meta: {
            title: '素材库'
          }
        },
        // 添加商品属性 
        {
          path: '/commodityAddAttribute',
          name: 'commodityAddAttribute',
          component: CommodityAddAttribute,
          meta: {
            title: '添加商品属性'
          }
        },
        // 商品评价 CommodityCategoty
        {
          path: '/commodityEvaluate',
          name: 'commodityEvaluate',
          component: CommodityEvaluate,
          meta: {
            title: '商品评价列表'
          }
        },
        // 商品订单
        {
          path: '/commodityOrder',
          name: 'commodityOrder',
          component: CommodityOrder,
          meta: {
            title: '商品订单列表'
          }
        },
        // 订单详情
        {
          path: '/orderInfo',
          name: 'orderInfo',
          component: OrderInfo,
          meta: {
            title: '商品订单详情'
          }
        },
        // 虚拟商品订单
        {
          path: '/virtualGoodsOrder',
          name: 'virtualGoodsOrder',
          component: VirtualGoodsOrder,
          meta: {
            title: '虚拟订单列表'
          }
        },
        // 虚拟商品订单详情
        {
          path: '/virtualOrderInfo',
          name: 'virtualOrderInfo',
          component: VirtualOrderInfo,
          meta: {
            title: '虚拟商品订单详情'
          }
        },
        // 项目订单
        {
          path: '/projectOrder',
          name: 'projectOrder',
          component: ProjectOrder,
          meta: {
            title: '项目订单列表'
          }
        },
        // 项目订单订单详情
        {
          path: '/projectOrderInfo',
          name: 'projectOrderInfo',
          component: ProjectOrderInfo,
          meta: {
            title: '项目订单详情'
          }
        },
        // // 服务订单
        {
          path: '/serviceOrder',
          name: 'serviceOrder',
          component: ServiceOrder,
          meta: {
            title: '服务订单'
          }
        },

        // 查看报告 AfterSaleManageInfo
        {
          path: '/serviceOrderInfo',
          name: 'serviceOrderInfo',
          component: ServiceOrderInfo,
          meta: {
            title: '服务订单详情'
          }
        },
        // 售后管理
        {
          path: '/afterSaleManage',
          name: 'afterSaleManage',
          component: AfterSaleManage,
          meta: {
            title: '售后管理列表'
          }
        },
        // 售后管理详情 
        {
          path: '/afterSaleManageInfo',
          name: 'afterSaleManageInfo',
          component: AfterSaleManageInfo,
          meta: {
            title: '售后管理详情'
          }
        },
        // 运费模板
        {
          path: '/freightTemplate',
          name: 'freightTemplate',
          component: FreightTemplate,
          meta: {
            title: '运费模板列表'
          }
        },
        // 增加运费模板
        {
          path: '/addFreightTemplate',
          name: 'addFreightTemplate',
          component: AddFreightTemplate,
          meta: {
            title: '增加运费模板'
          }
        },
        // 退货地址管理FreightTemplate
        {
          path: '/returnAddress',
          name: 'returnAddress',
          component: ReturnAddress,
          meta: {
            title: '退货地址管理'
          }
        },
        // 添加退货地址
        {
          path: '/addReturnAddress',
          name: 'addReturnAddress',
          component: AddReturnAddress,
          meta: {
            title: '添加退货地址'
          }
        },
        // 售后原因
        {
          path: '/afterSalesReason',
          name: 'afterSalesReason',
          component: AfterSalesReason,
          meta: {
            title: '售后原因'
          }
        },
        // 通用设置
        {
          path: '/generalSet',
          name: 'generalSet',
          component: GeneralSet,
          meta: {
            title: '通用设置'
          }
        },
        // 营销
        // 活动列表 
        {
          path: '/activityList',
          name: 'activityList',
          component: ActivityList,
          meta: {
            title: '活动列表'
          }
        },
        {
          path: '/activityInfo',
          name: 'activityInfo',
          component: ActivityInfo,
          meta: {
            title: '活动详情'
          }
        },
        {
          path: '/activityCategory',
          name: 'activityCategory',
          component: ActivityCategory,
          meta: {
            title: '活动分类'
          }
        },
        // 新建活动
        {
          path: '/addNewActivity',
          name: 'addNewActivity',
          component: AddNewActivity,
          meta: {
            title: '新建活动'
          }
        },
        // 活动数据
        {
          path: '/activityData',
          name: 'activityData',
          component: ActivityData,
          meta: {
            title: '活动数据'
          }
        },
        // 签到记录
        {
          path: '/attendanceRecord',
          name: 'attendanceRecord',
          component: AttendanceRecord,
          meta: {
            title: '签到记录'
          }
        },
        // 优惠券列表
        {
          path: '/couponList',
          name: 'couponList',
          component: CouponList,
          meta: {
            title: '优惠券列表'
          }
        },
        // 新增优惠券
        {
          path: '/addCoupon',
          name: 'addCoupon',
          component: AddCoupon,
          meta: {
            title: '新增优惠券'
          }
        },
        // 优惠券数据
        {
          path: '/couponData',
          name: 'couponData',
          component: CouponData,
          meta: {
            title: '优惠券数据'
          }
        },
        // 转赠记录
        {
          path: '/transferRecord',
          name: 'transferRecord',
          component: TransferRecord,
          meta: {
            title: '转赠记录'
          }
        },
        // 任务设置
        {
          path: '/taskList',
          name: 'taskList',
          component: TaskList,
          meta: {
            title: '任务设置'
          }
        },
        // 签到有礼 
        {
          path: '/signPolite',
          name: 'signPolite',
          component: SignPolite,
          meta: {
            title: '签到有礼'
          }
        }, 
        // 推荐设置 
        {
          path: '/recommendSet',
          name: 'recommendSet',
          component: RecommendSet,
          meta: {
            title: '推荐设置'
          }
        },
     
        // 推荐设置 
        {
          path: '/recommendSet/set',
          name: 'recommendSetSet',
          component: RecommendSetSet,
          meta: {
            title: '推荐设置'
          }
        },
        // 邀请有礼 
        {
          path: '/invitateIsPolite',
          name: 'invitateIsPolite',
          component: InvitateIsPolite,
          meta: {
            title: '邀请有礼'
          }
        },
        // 老会员奖励 
        {
          path: '/oldMemberRewards',
          name: 'oldMemberRewards',
          component: OldMemberRewards,
          meta: {
            title: '老会员奖励'
          }
        },
        // 新会员奖励 
        {
          path: '/newMemberRewards',
          name: 'newMemberRewards',
          component: NewMemberRewards,
          meta: {
            title: '新会员奖励'
          }
        },
        // 代币记录 
        {
          path: '/tokensRecord',
          name: 'tokensRecord',
          component: TokensRecord,
          meta: {
            title: '代币记录'
          }
        },
        // 设置兑换比例
        {
          path: '/setExchangeRate',
          name: 'setExchangeRate',
          component: SetExchangeRate,
          meta: {
            title: '设置兑换比例'
          }
        },
        // 积分记录 
        {
          path: '/integralRecord',
          name: 'integralRecord',
          component: IntegralRecord,
          meta: {
            title: '积分记录'
          }
        },
        // 权益金记录 
        {
          path: '/benefitRecord',
          name: 'benefitRecord',
          component: BenefitRecord,
          meta: {
            title: '权益金记录'
          }
        },
        // 成长值记录
        {
          path: '/growthValueRecord',
          name: 'growthValueRecord',
          component: GrowthValueRecord,
          meta: {
            title: '成长值记录'
          }
        },
        // 储值卡管理 
        {
          path: '/storedValueCardManage',
          name: 'storedValueCardManage',
          component: StoredValueCardManage,
          meta: {
            title: '储值卡管理'
          }
        },
        // 储值购买记录 
        {
          path: '/storedValuePurchaseRecords',
          name: 'storedValuePurchaseRecords',
          component: StoredValuePurchaseRecords,
          meta: {
            title: '储值购买记录'
          }
        },
        // 满减 
        {
          path: '/fullReductionActivity',
          name: 'fullReductionActivity',
          component: FullReductionActivity,
          meta: {
            title: '满减活动列表'
          }
        },
        // 满减 
        {
          path: '/addFullReduction',
          name: 'addFullReduction',
          component: AddFullReduction,
          meta: {
            title: '新增满减/满赠活动'
          }
        },
        
        // 满减 
        {
          path: '/seckill',
          name: 'seckill',
          component: Seckill,
          meta: {
            title: '秒杀活动'
          }
        },
        // 秒杀 
        {
          path: '/addSeckill',
          name: 'addSeckill',
          component: AddSeckill,
          meta: {
            title: '新增秒杀活动'
          }
        },
        // 秒杀 
        {
          path: '/seckillData',
          name: 'seckillData',
          component: SeckillData,
          meta: {
            title: '秒杀数据'
          }
        },
        // 储值明细 
        {
          path: '/detailsOfStoredValue',
          name: 'detailsOfStoredValue',
          component: DetailsOfStoredValue,
          meta: {
            title: '储值明细'
          }
        },
        // 交易数据  
        {
          path: '/transactionData',
          name: 'transactionData',
          component: TransactionData,
          meta: {
            title: '交易数据'
          }
        },
        {
          path: '/medalList',
          name: 'medalList',
          component: MedalList,
          meta: {
            title: '勋章列表'
          }
        },
        {
          path: '/addMedal',
          name: 'addMedal',
          component: AddMedal,
          meta: {
            title: '添加勋章'
          }
        },
        {
          path: '/medalCategory',
          name: 'medalCategory',
          component: MedalCategory,
          meta: {
            title: '勋章分类'
          }
        },
        //  赠品列表
        {
          path: '/giftManagement',
          name: 'giftManagement',
          component: GiftManagement,
          meta: {
            title: '赠品列表'
          }
        },
        //  赠品列表
        {
          path: '/addGift',
          name: 'addGift',
          component: AddGift,
          meta: {
            title: '新增赠品'
          }
        },
        // 推广 
        {
          path: '/promoterList',
          name: 'promoterList',
          component: PromoterList,
          meta: {
            title: '推广员列表'
          }
        },
        {
          path: '/promoterLevelSet',
          name: 'promoterLevelSet',
          component: PromoterLevelSet,
          meta: {
            title: '推广等级设置'
          }
        },
        {
          path: '/promoterInfo',
          name: 'promoterInfo',
          component: PromoterInfo,
          meta: {
            title: '推广员详情'
          }
        },
        {
          path: '/promoterData',
          name: 'promoterData',
          component: PromoterData,
          meta: {
            title: '推广列表'
          }
        },
        {
          path: '/promoterExamine',
          name: 'promoterExamine',
          component: PromoterExamine,
          meta: {
            title: '推广员审核'
          }
        },
        {
          path: '/promotionOrderRecords',
          name: 'promotionOrderRecords',
          component: PromotionOrderRecords,
          meta: {
            title: '推广订单记录'
          }
        },

        {
          path: '/promotionSet',
          name: 'promotionSet',
          component: PromotionSet,
          meta: {
            title: '推广设置'
          }
        },
        // 商品概况
        {
          path: '/productOverview',
          name: 'productOverview',
          component: ProductOverview,
          meta: {
            title: '商品概况'
          }
        },
        // 商品报表CategoryAnalysis
        {
          path: '/productReport',
          name: 'productReport',
          component: ProductReport,
          meta: {
            title: '商品报表'
          }
        },
        // 类目分析 
        {
          path: '/categoryAnalysis',
          name: 'categoryAnalysis',
          component: CategoryAnalysis,
          meta: {
            title: '类目分析'
          }
        },
        // 项目概括 
        {
          path: '/projectOverview',
          name: 'projectOverview',
          component: ProjectOverview,
          meta: {
            title: '项目概括'
          }
        },
        // 客户分析 
        {
          path: '/customerAnalysis',
          name: 'customerAnalysis',
          component: CustomerAnalysis,
          meta: {
            title: '客户分析'
          }
        },
        // 会员分析 
        {
          path: '/memberAnalysis',
          name: 'memberAnalysis',
          component: MemberAnalysis,
          meta: {
            title: '会员分析'
          }
        },
        // 流量分析 
        {
          path: '/flowAnalysis',
          name: 'flowAnalysis',
          component: FlowAnalysis,
          meta: {
            title: '流量分析'
          }
        },
        // 留存分析 
        {
          path: '/retentionAnalysis',
          name: 'retentionAnalysis',
          component: RetentionAnalysis,
          meta: {
            title: '留存分析'
          }
        },
        // 积分分析 
        {
          path: '/integralAnalysis',
          name: 'integralAnalysis',
          component: IntegralAnalysis,
          meta: {
            title: '积分分析'
          }
        },
        // 推广员分析 
        {
          path: '/promoterAnalysis',
          name: 'promoterAnalysis',
          component: PromoterAnalysis,
          meta: {
            title: '推广员分析'
          }
        },
        // 交易流水  
        {
          path: '/transactionFlow',
          name: 'transactionFlow',
          component: TransactionFlow,
          meta: {
            title: '交易流水'
          }
        },
        // 提现审核 
        {
          path: '/withdrawalReview',
          name: 'withdrawalReview',
          component: WithdrawalReview,
          meta: {
            title: '提现审核'
          }
        },
        // 开票列表 
        {
          path: '/invoicingList',
          name: 'invoicingList',
          component: InvoicingList,
          meta: {
            title: '开票列表'
          }
        },
        // 账号管理 
        {
          path: '/accountManagement',
          name: 'accountManagement',
          component: AccountManagement,
          meta: {
            title: '账号管理'
          }
        },
        // 添加账号 
        {
          path: '/addAccount',
          name: 'addAccount',
          component: AddAccount,
          meta: {
            title: '编辑账号'
          }
        },
        // 角色管理 
        {
          path: '/rolesManagement',
          name: 'rolesManagement',
          component: RolesManagement,
          meta: {
            title: '角色列表'
          }
        },
        // 添加账号 
        {
          path: '/addRole',
          name: 'addRole',
          component: AddRole,
          meta: {
            title: '编辑角色'
          }
        },
        // 部门管理 
        {
          path: '/departmentManagement',
          name: 'departmentManagement',
          component: DepartmentManagement,
          meta: {
            title: '部门列表'
          }
        },
        // 添加部门 
        {
          path: '/addDepartment',
          name: 'addDepartment',
          component: AddDepartment,
          meta: {
            title: '添加部门'
          }
        },
        // 协议管理 
        {
          path: '/protocolManagement',
          name: 'protocolManagement',
          component: ProtocolManagement,
          meta: {
            title: '协议管理'
          }
        },
        // 添加协议 
        {
          path: '/editProtocol',
          name: 'editProtocol',
          component: EditProtocol,
          meta: {
            title: '添加协议'
          } 
        },
        // 博雅简介 
        {
          path: '/boyaIntroduce',
          name: 'boyaIntroduce',
          component: BoyaIntroduce,
          meta: {
            title: '博雅简介'
          } 
        },
         // 产品与服务 
         {
          path: '/productService',
          name: 'productService',
          component: ProductService,
          meta: {
            title: '产品与服务'
          } 
        },
        // 联系我们 
        {
          path: '/contactUs',
          name: 'contactUs',
          component: ContactUs,
          meta: {
            title: '联系我们'
          } 
        },
        // 短信签名 
        {
          path: '/smsSignature',
          name: 'smsSignature',
          component: SmsSignature,
          meta: {
            title: '短信签名'
          }
        },
        // 短信配置
        {
          path: '/smsConfiguration',
          name: 'smsConfiguration',
          component: SmsConfiguration,
          meta: {
            title: '短信配置'
          }
        },
        // 新增签名 
        {
          path: '/addSignature',
          name: 'addSignature',
          component: AddSignature,
          meta: {
            title: '新增签名'
          }
        },
        // 短信模板
        {
          path: '/smsTemplate',
          name: 'smsTemplate',
          component: SmsTemplate,
          meta: {
            title: '短信模板'
          }
        },
        // 短信黑名单 
        {
          path: '/smsBlackList',
          name: 'smsBlackList',
          component: SmsBlackList,
          meta: {
            title: '短信黑名单'
          }
        },
        // 消息配置 
        {
          path: '/messageConfiguration',
          name: 'messageConfiguration',
          component: MessageConfiguration,
          meta: {
            title: '消息配置'
          }
        },
        // 编辑消息 
        {
          path: '/addMessageConfig',
          name: 'addMessageConfig',
          component: AddMessageConfig,
          meta: {
            title: '编辑消息'
          }
        },
        // 页面配置 
        {
          path: '/miniProgramPageConfig',
          name: 'miniProgramPageConfig',
          component: MiniProgramPageConfig,
          meta: {
            title: '页面配置'
          }
        },
        // 分享设置 
        {
          path: '/shareSet',
          name: 'shareSet',
          component: ShareSet,
          meta: {
            title: '分享设置'
          }
        },
        // 常见问题 
        {
          path: '/usualAskQuestion',
          name: 'usualAskQuestion',
          component: UsualAskQuestion,
          meta: {
            title: '常见问题'
          }
        },
        // 操作日志 
        {
          path: '/operationLog',
          name: 'operationLog',
          component: OperationLog,
          meta: {
            title: '操作日志'
          }
        },
        // 修改密码 
        {
          path: '/changePassword',
          name: 'changePassword',
          component: ChangePassword,
          meta: {
            title: '修改密码'
          }
        },
        // 通知中心 
        {
          path: '/notifications',
          name: 'notifications',
          component: Notifications,
          meta: {
            title: '通知中心'
          }
        },
        // 荣誉认证
        {
          path: '/honorsAndCertifications',
          name: 'honorsAndCertifications',
          component: HonorsAndCertifications,
          meta: {
            title: '荣誉与认证'
          }
        },
        {
          path: '/addHonors',
          name: 'addHonors',
          component: AddHonors,
          meta: {
            title: '新增荣誉与认证'
          }
        },
        // 菜单设置 
        {
          path: '/menuSet',
          name: 'menuSet',
          component: MenuSet,
          meta: {
            title: '菜单设置'
          }
        },
        // 新建菜单
        {
          path: '/addMenu',
          name: 'addMenu',
          component: AddMenu,
          meta: {
            title: '新建菜单'
          }
        }
      ]

    },
  ]
})
