<template>
  <div class="projectList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>项目</el-breadcrumb-item>
      <el-breadcrumb-item>项目列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="项目分类">
          <el-select
            v-model="customerGroupForm.search_type"
            placeholder="请选择"
          >
            <el-option-group
              v-for="group in options"
              :key="group.uuid"
              :label="group.name"
            >
              <el-option
                v-for="item in group.children"
                :key="item.uuid"
                :label="item.name"
                :value="item.uuid"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="价格区间">
          <rangeInput
            @input="rangeNumber"
            ref="ipt1"
            :placeholderMin="'最低金额'"
            :placeholderMax="'最高金额'"
          />
        </el-form-item>
        <el-form-item label="发布时间">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getItemList"
          >查询</el-button
        >
        <el-button size="medium" @click="clearForm">清空</el-button>
        <el-button size="medium">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="flex space-between">
        <div class="tabs flex">
          <p
            v-for="(item, index) in tabs"
            :key="index"
            @click="addClass(index)"
            :class="{ on: currentClass === index }"
          >
            {{ item }}
          </p>
        </div>
        <div class="text-right">
          <el-button type="primary" @click="$router.push('/addProject')"
            >添加项目</el-button
          >
          <el-button @click="batchUpOrDown(1)" :disabled="selectNum == 0"
            >上架</el-button
          >
          <el-button @click="batchUpOrDown(2)" :disabled="selectNum == 0"
            >下架</el-button
          >
          <el-button
            @click="dialogFormVisible = true"
            :disabled="selectNum == 0"
            >修改分类</el-button
          >
          <el-button @click="batchDel" :disabled="selectNum == 0"
            >删除</el-button
          >
        </div>
      </div>

      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          label="商品描述"
          :align="'center'"
          prop="nickName"
          width="300"
        >
          <template slot-scope="scope">
            <div class="nickAndAvatar">
              <el-image
                v-if="scope.row.image.length > 0"
                :src="scope.row.image[0].image"
              ></el-image>
              <div style="width: 200px">
                <el-tooltip :content="scope.row.name" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.name }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="项目编码" :align="'center'">
        </el-table-column>
        <el-table-column
          prop="boya_tc"
          label="所属套餐"
          show-overflow-tooltip
          :align="'center'"
        >
        </el-table-column>
        <el-table-column prop="price" label="价格" :align="'center'" sortable>
          <template slot-scope="scope"> ￥{{ scope.row.price }} </template>
        </el-table-column>
        <el-table-column
          prop="sales_number"
          label="销量"
          :align="'center'"
          sortable
        >
        </el-table-column>
        <el-table-column prop="category.name" label="分类" :align="'center'">
        </el-table-column>
        <el-table-column prop="status" label="状态" :align="'center'">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.status == 1"
              >出售中</span
            >
            <span v-if="scope.row.status == 3">已售完</span>
            <span v-if="scope.row.status == 2">已下架</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          :align="'center'"
          width="160"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          fixed="right"
          width="150"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="toEdit(scope.row)">编辑</el-button>
            <el-button
              type="text"
              v-if="scope.row.is_recommended == 2"
              @click="recommond(scope.row)"
              >推荐</el-button
            >
            <el-button type="text" v-else @click="recommond(scope.row)"
              >取消推荐</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.status == 1"
              @click="upOrDown(scope.row)"
              >下架</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.status == 2"
              @click="upOrDown(scope.row)"
              >上架</el-button
            >
            <el-button type="text" @click="deleteItem(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog
      title="修改分类"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form label-width="80px" class="mg-t-20">
        <el-form-item label="类别名称">
          <el-select v-model="category_uuid" placeholder="请选择">
            <el-option-group
              v-for="group in options"
              :key="group.uuid"
              :label="group.name"
            >
              <el-option
                v-for="item in group.children"
                :key="item.uuid"
                :label="item.name"
                :value="item.uuid"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="batchCategory">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
import rangeInput from "../../../components/rangeInput.vue";
export default {
  name: "projectList",
  components: { rangeInput, pagination },
  data() {
    return {
      status: "",
      dialogFormVisible: false,
      customerGroupForm: {
        search_start_time: "",
        search_end_time: "",
        search_keyword: "",
        search_type: "",
        search_start_price: "",
        search_end_price: ""
      },
      number1: [],
      options: [],
      category_uuid: "",
      selectNum: 0,
      uuids: [],
      tabs: [],
      currentClass: 0,
      countData: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0
      }
    };
  },
  created() {
    this.getItemList();
    this.getProjectCategory();
    this.getCount();
  },
  methods: {
    clearForm() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.$refs["ipt1"].val.min = "";
      this.$refs["ipt1"].val.max = "";
      this.getItemList();
    },
    handleSelectionChange(row) {
      this.selectNum = row.length;
      this.uuids = row.map(item => {
        return item.uuid;
      });
    },
    addClass(index) {
      if (index == 0) {
        this.status = "";
      } else {
        this.status = index;
      }
      this.currentClass = index;
      this.getItemList();
    },
    rangeNumber(val) {
      console.log(val);
      this.customerGroupForm.search_start_price = val.min;
      this.customerGroupForm.search_end_price = val.max;
    },
    recommond(row) {
      let data = {
        is_recommended: row.is_recommended == 1 ? 2 : 1
      };
      this.getData(
        "put",
        data,
        `${row.uuid}`,
        "/recommended/",
        null,
        "操作成功！"
      );
    },
    // 编辑跳转
    toEdit(row) {
      this.$router.push({
        name: "addProject",
        query: {
          uuid: row.uuid
        }
      });
    },
    batchCategory() {
      this.dialogFormVisible = false;
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        data: {
          uuids: this.uuids,
          category_uuid: this.category_uuid
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Item/category_branch`
      })
        .then(res => {
          this.$message.success("修改成功！");
          this.getItemList();
          this.category_uuid = "";
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取项目分类
    getProjectCategory() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ItemCategory`
      })
        .then(res => {
          this.options = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getCount() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ItemCount?search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}`
      })
        .then(res => {
          this.countData = res.data.result;
          this.tabs = [`全部`, `出售中`, `已下架`, `已售完`];
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getData(methods, data, queryStry, mark, tableData, msg = "") {
      this.axios({
        method: methods, //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        data,
        url: ` https://time.api.boyalife.cn/v1/cms/Item${mark}${queryStry}`
      })
        .then(res => {
          console.log(res);
          if (msg !== "") {
            this.$message.success(msg);
          }
          if (tableData !== null) {
            this.tableData = res.data.result;
          }
          if (methods === "delete" || methods == "put") {
            this.getItemList();
            this.getCount();
          }
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    // 列表
    getItemList(row) {
      let data = {};
      let queryStry = `search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}&search_status=${this.status}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}&search_start_price=${this.customerGroupForm.search_start_price}&search_end_price=${this.customerGroupForm.search_end_price}&search_type=${this.customerGroupForm.search_type}`;
      this.getData("get", data, queryStry, "?", this.tableData);
    },
    // 上下架
    upOrDown(row) {
      let data = {
        status: row.status == 1 ? 2 : 1
      };
      this.getData(
        "put",
        data,
        row.uuid,
        "/status/",
        this.tableData,
        "操作成功！"
      );
    },

    batchUpOrDown(type) {
      let status = type == 1 ? 1 : 2;
      this.$confirm("确认执行操作吗？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`
          },
          data: {
            uuids: this.uuids,
            status
          },
          url: ` https://time.api.boyalife.cn/v1/cms/Item/status_branch`
        })
          .then(res => {
            this.$message.success("操作成功！");
            this.getItemList();
            this.getCount();
          })
          .catch(err => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    batchDel() {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`
          },
          data: {
            uuids: this.uuids
          },
          url: ` https://time.api.boyalife.cn/v1/cms/Item/delete_branch`
        })
          .then(res => {
            this.$message.success("操作成功！");
            this.getItemList();
            this.getCount();
          })
          .catch(err => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    deleteItem(row) {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true
      })
        .then(() => {
          let queryStry = row.uuid;
          this.getData("delete", {}, queryStry, "/", {}, "删除成功！");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除"
          });
        });
    },

    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getItemList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getItemList();
    }
  }
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.nickAndAvatar .el-image {
  border-radius: 0;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.projectList .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
.dataTable {
  position: relative;
}
.dataTable .tabs {
  position: static;
}
.dataTable .tabs p {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.dataTable .tabs p:after {
  content: "";
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.dataTable .tabs p.on:after {
  background: #409eff;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
</style>
