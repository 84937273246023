<template>
  <div class="sampleDynamics">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>细胞</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/cellList'">细胞列表</el-breadcrumb-item>
      <el-breadcrumb-item>添加样本动态</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <h3 class="title-t">动态信息</h3>
      <div class="box mg-t-30 mg-l-30">
        <div class="flex">
          <span>样本动态</span>
          <el-button type="text" class="mg-l-20" @click="add"
            >+ 添加动态</el-button
          >
        </div>
        <draggable v-model="dynamicsForm" group="people" @change="change">
          <el-form
            :model="dynamicsForm"
            v-for="(dynamics, index) in dynamicsForm"
            :key="dynamics"
            label-width="100px"
          >
            <el-form-item label="进度名称">
              <el-select
                v-model="dynamics.region"
                placeholder="请选择样本进度"
                :disabled="dynamics.disabled"
              >
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="进度描述">
              <el-input
                v-model="dynamics.desc"
                placeholder="请输入"
                :disabled="dynamics.disabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="进度图片/视频">
              <el-upload
                class="upload-demo"
                action="https://jsonplaceholder.typicode.com/posts/"
                :limit="5"
                multiple
                :disabled="dynamics.disabled"
              >
                <img
                  v-if="dynamics.imageUrl"
                  :src="dynamics.imageUrl"
                  class="avatar"
                />
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item label="进度附件">
              <el-upload
                class="upload-demo"
                action="https://jsonplaceholder.typicode.com/posts/"
                :disabled="dynamics.disabled"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item class="btn">
              <el-button
                type="text"
                v-if="!dynamics.disabled"
                @click="save(index)"
                >保存</el-button
              >
              <el-button
                type="text"
                v-if="dynamics.disabled"
                @click="edit(index)"
                >编辑</el-button
              >
              <el-button type="text">删除</el-button>
            </el-form-item>
          </el-form>
        </draggable>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
export default {
  name: "sampleDynamics",
  components: {
    draggable
  },
  data() {
    return {
      dynamicsForm: [
        {
          region: "",
          desc: "",
          imgUrl: [],
          pdf: "",
          disabled: true
        }
      ],
      fileList: []
    };
  },
  methods: {
    add() {
      let data = {
        region: "",
        desc: "",
        imgUrl: [],
        pdf: "",
        disabled: false
      };
      this.dynamicsForm.unshift(data);
    },
    edit(index) {
      this.dynamicsForm[index].disabled = false;
    },
    save(index) {
      this.dynamicsForm[index].disabled = true;
    },
    del(index) {
      this.dynamicsForm.splice(index, 1);
    },
    // 监听拖拽
    change(event) {
      console.log("change");
      console.log(event);
      console.log(this.dynamicsForm);
    }
  }
};
</script>
<style scoped>
.el-button.el-button--text {
  padding: 0 10px;
}
.el-form {
  margin-left: 86px;
  padding: 20px;
  background: rgb(240, 248, 252);
  margin-top: 20px;
  position: relative;
}
.el-form .btn {
  position: absolute;
  right: 20px;
  top: 20px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
</style>
