<template>
  <div class="virtualInventoryRecords">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item >商品</el-breadcrumb-item>
      <el-breadcrumb-item >库存管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form :inline="true" :model="customerGroupForm" class="demo-form-inline">
        <el-form-item label="商品分类">
          <el-select v-model="value" placeholder="请选择">
            <el-option-group
              v-for="group in options"
              :key="group.label"
              :label="group.label">
              <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="价格区间">
          <rangeInput @input="rangeNumber" :placeholderMin="'最低金额'" :placeholderMax="'最高金额'"/>
        </el-form-item>
        <el-form-item label="发布时间">
          <el-date-picker
            v-model="customerGroupForm.registerDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input placeholder="请输入内容" v-model="customerGroupForm.content">
            <i slot="append" class="el-icon-search"></i>
            </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium">查询</el-button>
        <el-button size="medium">清空</el-button>
        <el-button size="medium">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="tabs flex">
        <p v-for="(item,index) in tabs" :key="index" @click="addClass(index)" :class="{'on': currentClass === index}">{{ item }}</p>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        tooltip-effect="dark"
        style="width: 100%">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          label="商品描述"
          :align="'center'"
          prop="nickName"
          width="300">
          <template slot-scope="scope">
              <div class="nickAndAvatar">
                <el-image :src="scope.row.avatar"></el-image>
                <div style="width: 200px">
                  <el-tooltip :content="scope.row.nickName" placement="top">
                    <p class="ellipsis">
                      {{ scope.row.nickName }}
                    </p>
                  </el-tooltip>
                  <p>规格：红色*套餐1</p>
                </div>
              </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="slat"
          label="商品编码"
          :align="'center'"
          width="200"
          >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="数量"
          :align="'center'"
          sortable
          >
          <template slot-scope="scope">
            <span style="color: #2ED477;">{{ scope.row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="times"
          label="可用库存"
          :align="'center'"
          sortable
          width="200"
          >
        </el-table-column>
        <el-table-column
          prop="money"
          label="库存类型"
          :align="'center'"
          >
        </el-table-column>
        <el-table-column
          prop="name"
          label="操作类型"
          :align="'center'"
          width="200"
          >
        </el-table-column>
        <el-table-column 
          prop="identity"
          label="操作时间"
          :align="'center'"
          width="150"
          >
        </el-table-column>
        <el-table-column 
          prop="number"
          label="单号"
          :align="'center'"
          width="150"
          >
        </el-table-column>
      </el-table>
      <div class="text-center mg-t-20">
        <!-- @size-change="handleSizeChange" -->
        <!-- @current-change="handleCurrentChange" -->
        <el-pagination
          :current-page="1"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="400">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="库存管理" :visible.sync="dialogTableVisible">
      <el-table :data="gridData">
        <el-table-column 
          property="color" 
          label="颜色" 
          
          align="center"
        ></el-table-column>
        <el-table-column 
          property="tc" 
          label="套餐" 
          align="center"
          >
          </el-table-column>
        <el-table-column 
          property="gb" 
          label="规格编码"
          align="center"
        ></el-table-column>
        <el-table-column 
          property="kc" 
          label="库存"
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          >
          <template slot-scope="scope">
            <el-button type="text" @click="changeInventory(scope.row.kc)">修改库存</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="dialogTableVisible = false">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改库存" :visible.sync="inventoryDialog">
      <el-form :model="inventoryForm">
        <el-form-item>
          <el-input-number v-model="kc" @change="handleChange" :min="1" :max="100000" label="描述文字"></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-input v-model="inventoryForm.kc" placeholder="输入出/入库单号"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="inventoryDialog = false">确定</el-button>
      </span>
    </el-dialog>
   
  </div>
</template>
<script>
import rangeInput from '../../../components/rangeInput.vue';
export default {
  name: 'virtualInventoryRecords',
  components: { rangeInput },
  data () {
    return {
      customerGroupForm: {},
      inventoryForm: {},
      kc: null,
      dialogTableVisible: false,
      inventoryDialog: false,
      tabs: ['全部(1000)', '出售中(1000)', '已售完(0)', '已下架(0)'],
      currentClass: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - start.getHours()* 60 * 60 * 1000 - start.getMinutes() * 60 * 1000 - start.getSeconds() * 1000 - start.getMilliseconds());
              picker.$emit('pick', [start, end]);
            }
          },  
          {
            text: '昨天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1- start.getHours()* 60 * 60 * 1000 - start.getMinutes() * 60 * 1000 - start.getSeconds() * 1000 - start.getMilliseconds());
              picker.$emit('pick', [start, end]);
            }
          },  
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7- start.getHours()* 60 * 60 * 1000 - start.getMinutes() * 60 * 1000 - start.getSeconds() * 1000 - start.getMilliseconds());
              picker.$emit('pick', [start, end]);
            }
          }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30- start.getHours()* 60 * 60 * 1000 - start.getMinutes() * 60 * 1000 - start.getSeconds() * 1000 - start.getMilliseconds());
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      gridData: [
        {
          color: '黑色',
          tc: '官方标配',
          gb: 'CK100',
          kc: '200',
        },
        {
          color: '黑色',
          tc: '官方标配',
          gb: 'CK100',
          kc: '200',
        },
        {
          color: '黑色',
          tc: '官方标配',
          gb: 'CK100',
          kc: '200',
        }
      ],
      tableData: [{
        id: 9999999,
        avatar: 'https://axure-file.lanhuapp.com/06b9f5b8-0544-41f9-bee3-1322615ce006__b91b040875a6a0217563d85598fdfc7e.png',
        nickName: '索尼（SONY）Alpha 7 III(7M3K)全画幅微单数码索尼（SONY）Alpha 7 III(7M3K)全画幅微单数码',
        phone: '-1',
        level: '7099',
        label: '1222',
        times: '101',
        money: '减少库存',
        hasMoney: '2222',
        slat: 53434232,
        number: '200202020',
        identity: '2022/08/29 11:20:33',
        status: '减少入库',
        time: '2022-5-23 16:00:00',
        date: '2016-05-03',
        name: 'xx管理员操作',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        id: 3,
        avatar: 'https://axure-file.lanhuapp.com/06b9f5b8-0544-41f9-bee3-1322615ce006__b91b040875a6a0217563d85598fdfc7e.png',
        nickName: '索尼（SONY）Alpha 7 III(7M3K)全画幅微单数码索尼（SONY）Alpha 7 III(7M3K)全画幅微单数码',
        phone: '-1',
        level: '7099',
        label: '1222',
        times: '101',
        money: '减少库存',
        hasMoney: '2222',
        slat: 53434232,
        number: '200202020',
        identity: '2022/08/29 11:20:33',
        status: '减少入库',
        time: '2022-5-23 16:00:00',
        date: '2016-05-03',
        name: 'xx管理员操作',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        id: 2,
        avatar: 'https://axure-file.lanhuapp.com/06b9f5b8-0544-41f9-bee3-1322615ce006__b91b040875a6a0217563d85598fdfc7e.png',
        nickName: '索尼（SONY）Alpha 7 III(7M3K)全画幅微单数码索尼（SONY）Alpha 7 III(7M3K)全画幅微单数码索尼（SONY）Alpha 7 III(7M3K)全画幅微单数码索尼（SONY）Alpha 7 III(7M3K)全画幅微单数码',
        phone: '-1',
        level: '7099',
        label: '1222',
        times: '101',
        money: '减少库存',
        hasMoney: '2222',
        slat: 53434232,
        number: '200202020',
        identity: '2022/08/29 11:20:33',
        status: '减少入库',
        time: '2022-5-23 16:00:00',
        date: '2016-05-03',
        name: 'xx管理员操作',
        address: '上海市普陀区金沙江路 1518 弄'
      }, ],
    }
  },
  methods: {
    addClass (index) {
      this.currentClass = index
    },
    rangeNumber (val) {
      console.log(val)
    },
    handleChange(value) {
      console.log(value);
    },
    changeInventory (num) {
      this.inventoryDialog = true
      this.kc = num
    }
  }
}
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,.1);
  border-radius: 8px;
  font-size: 12px!important;
}
>>> .el-input-group {
  width: 400px;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.virtualInventoryRecords .el-table .product  .el-image {
  width: 60px;
  height: auto;
  border-radius: 0!important;
}
.dataTable {
  position: relative;
  padding-top: 80px;
}
.dataTable .tabs {
  position: absolute;
  top: 35px;
  left: 20px;
}
.dataTable .tabs p {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.dataTable .tabs p:after {
  content: '';
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.dataTable .tabs p.on:after {
  background: #409eff;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
</style>