<template>
  <div class="authentication">
    <div class="search-container">
      <el-form :inline="true" :model="authorForm" class="demo-form-inline">
        <el-form-item label="更新日期">
          <el-date-picker
            v-model="dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="search_keyword">
          <el-input
            placeholder="请输入"
            v-model="authorForm.search_keyword"
            class="input-with-select"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="text-right mg-b-20">
        <el-tabs v-model="authorForm.search_status" @tab-click="getList">
          <el-tab-pane label="上架" name="1"></el-tab-pane>
          <el-tab-pane label="下架" name="0"></el-tab-pane>
        </el-tabs>
        <el-button
          type="primary"
          @click="$router.push('/addDatum?uuid=' + uuid + '&type=add')"
          >添加资料</el-button
        >
      </div>
      <el-table
        class="mg-t-20"
        :data="tableData.data"
        border
        style="width: 100%"
      >
        <el-table-column type="index" label="序号" width="60" align="center">
        </el-table-column>
        <el-table-column prop="name" label="资料标题" align="center">
        </el-table-column>
        <el-table-column prop="image_src" label="图片" align="center">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.image_src.length > 0"
              style="width: 200px; height: 100px; border-radius: 0"
              :src="scope.row.image_src"
              fit="contain"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="user_name"
          label="操作人"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="update_time"
          label="更新日期"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="180">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="
                $router.push('/addDatum?uuid=' + scope.row.id + '&type=edit')
              "
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="putaway(scope.row.id, '0')"
              v-if="scope.row.status != '0'"
              >下架</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="putaway(scope.row.id, '1')"
              v-if="scope.row.status == '0'"
              >上架</el-button
            >
            <el-button type="text" size="small" @click="deleted(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../components/pagination.vue";

export default {
  name: "authentication",
  data() {
    return {
      uuid: this.$route.query.uuid,
      dataTime: "",
      authorForm: {
        search_lab_id: this.$route.query.uuid,
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
        search_status: "1",
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  components: {
    pagination,
  },
  created() {
    this.getList();
  },
  methods: {
    clear() {
      this.authorForm.search_keyword = "";
      this.authorForm.search_start_time = "";
      this.authorForm.search_end_time = "";
      this.getList();
    },
    // 选择时间
    getTimes(v) {
      this.authorForm.search_start_time = v[0] + " 00:00:00";
      this.authorForm.search_end_time = v[1] + " 23:59:59";
    },
    //上、下架切换操作
    putaway(uuid, status) {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: { status: status },
        url: ` https://time.api.boyalife.cn/v1/cms/LabStuff/status/${uuid}`,
      })
        .then((res) => {
          this.$message.success("修改成功！");
          this.getList();
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/LabStuff?page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.authorForm.search_keyword}&search_start_time=${this.authorForm.search_start_time}&search_end_time=${this.authorForm.search_end_time}&search_status=${this.authorForm.search_status}&search_lab_id=${this.authorForm.search_lab_id}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    deleted(uuid) {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://time.api.boyalife.cn/v1/cms/LabStuff/${uuid}`,
          })
            .then(() => {
              this.$message.success("删除成功！");
              this.getList();
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.authentication {
  background-color: #f2f2f2;
}

.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
</style>
