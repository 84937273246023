import storage from 'store'
import { Message } from 'element-ui'
import get from 'lodash/get'
import axios from 'axios'
import router from '@/router'
import isEmpty from 'lodash/isEmpty'



const CancelToken = axios.CancelToken

let taskList = []

let errMap = {
    400: '请求错误',
    401: '未授权',
    403: '拒绝访问',
    404: '请求地址出错',
    405: '提交参数错误',
    408: '请求超时',
    500: '服务器内部错误',
    501: '服务未实现',
    502: '网关错误',
    503: '服务不可用',
    504: '网关超时',
    505: 'HTTP版本不受支持'
}

export const interceptors = {
    request: config => {
        const { headers, url, method } = config
        if (!config.data) {
            config.data = {}
        }
        config.cancelToken = new CancelToken(c => {
            taskList.push({
                url: `${url}_${method}`,
                cancel: c
            })
        })
        let token = storage.get('__token')
        // headers.Authorization = 'Bearer' + token,
        headers['X-Access-Token'] = token
        // headers['Content-Type'] = 'multipart/form-data;application/json;text/html;charset=UTF-8'
        headers['Authorization'] = `Bearer ${token}`
        return Promise.resolve(config)
    },
    response: response => { 
        console.log(response)
        const { data, status} = response
        if (data.code == 401) {
            Message.error(response.data.error.message)
            storage.clearAll()
            setTimeout(() => {
              this.$router.replace({path: '/login'})
            })
            return false
          }
        if (status == 200) {
            return Promise.resolve(data.result)
        } else {
            return Promise.reject(data.error.message)
        }
    },
    error: error => {
        console.log(error + '333')
        if (typeof error === 'string') return Promise.reject(error)
        if (isEmpty(error)) error = {}
        if (isEmpty(error.response)) error.response = {}
        if (isEmpty(error.response.data)) error.response.data = {}
        let tempErrMsg = error.response.data.error.message
        const status = get(error, 'response.status')
        if (isEmpty(tempErrMsg)) {
            tempErrMsg = errMap[status + ''] || '其他错误'
        }
        console.log(status === 401)
        if (status === 401) {
            //这里失效了
            Message.error(tempErrMsg, 0.9)
            router.replace({ name: 'login' })
        }
        return Promise.reject(tempErrMsg)
    }
}
