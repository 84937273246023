<template>
  <div class="productOverview">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/informationList' }"
        >资讯列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>数据分析</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="overview boxLayout">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基础数据" name="first">
          <div
            class="analysis flex space-around mg-t-20"
            v-if="Object.keys(dataCount).length > 0"
          >
            <div class="text-center">
              <h6 class="font-12 color-999">转发量</h6>
              <p class="font-30">{{ dataCount.forwarding_num }}</p>
              <p class="font-12">
                今日
                <span style="color: #2561ef">{{
                  dataCount.forwarding_num_today
                }}</span>
              </p>
            </div>
            <div class="text-center">
              <h6 class="font-12 color-999">阅读量</h6>
              <p class="font-30">{{ dataCount.read_num }}</p>
              <p class="font-12">
                今日
                <span style="color: #2561ef">{{
                  dataCount.read_num_today
                }}</span>
              </p>
            </div>
            <div class="text-center">
              <h6 class="font-12 color-999">收藏量</h6>
              <p class="font-30">{{ dataCount.collect_num }}</p>
              <p class="font-12">
                今日
                <span style="color: #2561ef">{{
                  dataCount.collect_num_today
                }}</span>
              </p>
            </div>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="互动数据" name="fourth">
          <div>
            <span>选择日期</span>
            <el-date-picker
              class="mg-l-20"
              v-model="value"
              type="daterange"
              align="right"
              unlink-panels
              @change="getTimes"
              :clearable="false"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
              :picker-options="pickerOptions">
            </el-date-picker>
          </div>
          <div class="analysis flex space-around mg-t-20">
            <div class="text-center">
              <h6 class="font-12 color-999">获赞量</h6>
              <p class="font-30">14300</p>
              <p class="font-12">今日 <span style="color:#2561EF;">1000</span></p>
            </div>
            <div class="text-center">
              <h6 class="font-12 color-999"> 涨粉量</h6>
              <p class="font-30">14300</p>
              <p class="font-12">今日 <span style="color:#2561EF;">1000</span></p>
            </div>
            <div class="text-center">
              <h6 class="font-12 color-999">转发量   </h6>
              <p class="font-30">14300</p>
              <p class="font-12">今日 <span style="color:#2561EF;">1000</span></p>
            </div>
            <div class="text-center">
              <h6 class="font-12 color-999">评论量    </h6>
              <p class="font-30">14300</p>
              <p class="font-12">今日 <span style="color:#2561EF;">1000</span></p>
            </div>
            <div class="text-center">
              <h6 class="font-12 color-999">收藏量   </h6>
              <p class="font-30">14300</p>
              <p class="font-12">今日 <span style="color:#2561EF;">1000</span></p>
            </div>
          </div>

        </el-tab-pane> -->
      </el-tabs>
      <div class="mg-t-50">
        <span>选择日期</span>
        <el-date-picker
          class="mg-l-20"
          v-model="value"
          type="daterange"
          align="right"
          unlink-panels
          :clearable="false"
          @change="getTimes"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy/MM/dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
      <div
        id="transactionOverview"
        class="mg-t-30"
        ref="myChart"
        style="width: 100%; height: 600px"
      ></div>
      <el-table
        class="mg-t-20"
        :data="tableData.data"
        style="width: 100%"
        border
      >
        <el-table-column prop="count_date" label="日期" align="center">
        </el-table-column>
        <el-table-column prop="forwarding_num" label="转发量" align="center">
        </el-table-column>
        <el-table-column prop="read_num" label="阅读量" align="center">
        </el-table-column>
        <el-table-column prop="collect_num" label="收藏量" align="center">
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import pagination from "../../../components/pagination.vue";
import china from "../../../../static/china.json";
let currentTime = new Date();
let year = currentTime.getFullYear();
let month = currentTime.getMonth() + 1;
let day = currentTime.getDate();
let formattedDate = `${year}/${month.toString().padStart(2, "0")}/${day
  .toString()
  .padStart(2, "0")}`;

const start = new Date();
start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
let timed = start.toLocaleDateString();
export default {
  name: "informationData",
  components: {
    pagination,
  },
  data() {
    return {
      activeName: "first",
      dateArray: [],
      numberArray: [],
      china,
      typeData: "转发量",
      dataCount: {},
      chinaData: [],
      customerForm: {
        search_start_time: timed,
        search_end_time: formattedDate,
        search_start_time2: timed,
        search_end_time2: formattedDate,
      },
      transactionData: {},
      value: [timed, formattedDate],
      value2: "",
      type: "pay_amount",
      isReady: false,
      options: [
        {
          label: "一级分类A",
          options: [
            {
              value: "Shanghai",
              label: "二级分类A",
            },
            {
              value: "Beijing",
              label: "二级分类B",
            },
          ],
        },
        {
          label: "一级分类B",
          options: [
            {
              value: "Chengdu",
              label: "二级分类C",
            },
          ],
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getTransactionData();
    this.getDatacount();
    this.getTableData();
  },
  mounted() {
    // 交易数据
    setTimeout(() => {
      this.initTransactionData(); // 树状
    }, 1000);
  },
  methods: {
    getDatacount() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Information/datacount/${this.$route.query.uuid}`,
      })
        .then((res) => {
          this.dataCount = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getTableData() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Information/datas/${this.$route.query.uuid}?search_start_time=${this.customerForm.search_start_time}&search_end_time=${this.customerForm.search_end_time}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    initTransactionData() {
      // 交易概况
      let chartDom = document.getElementById("transactionOverview");
      let transactionOverview = echarts.init(chartDom);
      let transactionOverviewOption = {
        tooltip: {
          trigger: "axis",
        },

        legend: {
          data: ["转发量", "阅读量", "收藏量"],
          bottom: "20px",
          selectedMode: "single",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          height: "500px",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.dateArray,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "转发量",
            smooth: true,
            type: "line",
            data: this.numberArray,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16,
              },
            },
          },
          {
            name: "阅读量",
            smooth: true,
            type: "line",
            data: this.numberArray,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16,
              },
            },
          },
          {
            name: "收藏量",
            smooth: true,
            type: "line",
            data: this.numberArray,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16,
              },
            },
          },
        ],
      };
      let that = this;
      transactionOverview.clear();
      transactionOverviewOption &&
        transactionOverview.setOption(transactionOverviewOption);
      transactionOverview.on("legendselectchanged", function (params) {
        this.typeData = params.name;
        that.getTransactionData();
      });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getTableData();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getTableData();
    },
    // 数据概览
    getTransactionData() {
      let currentTime = new Date();
      let year = currentTime.getFullYear();
      let month = currentTime.getMonth() + 1;
      let day = currentTime.getDate();
      let formattedDate = `${year}/${month.toString().padStart(2, "0")}/${day
        .toString()
        .padStart(2, "0")}`;
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      if (this.customerForm.search_start_time == "") {
        this.customerForm.search_start_time =
          start.toLocaleDateString() + " 00:00:00";
      }
      if (this.customerForm.search_end_time == "") {
        this.customerForm.search_end_time = formattedDate + " 23:59:59";
      }

      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/information/chart/${this.$route.query.uuid}?search_start_time=${this.customerForm.search_start_time}&search_end_time=${this.customerForm.search_end_time}`,
      })
        .then((res) => {
          this.dateArray = [];
          this.transactionData = res.data.result;
          if (this.typeData == "阅读量") {
            this.dateArray = this.transactionData.read.map((item) => item.date);
            this.numberArray = this.transactionData.read.map(
              (item) => item.number
            );
          } else if (this.typeData == "转发量") {
            this.dateArray = this.transactionData.forwarding.map(
              (item) => item.date
            );
            this.numberArray = this.transactionData.forwarding.map(
              (item) => item.number
            );
          } else {
            this.dateArray = this.transactionData.collect.map(
              (item) => item.date
            );
            this.numberArray = this.transactionData.collect.map(
              (item) => item.number
            );
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },

    // 选择时间
    getTimes(v) {
      this.customerForm.search_start_time = v[0] + " 00:00:00";
      this.customerForm.search_end_time = v[1] + " 23:59:59";
      this.getTransactionData();
      this.getTableData();
      setTimeout(() => {
        this.initTransactionData(); // 树状
      }, 1000);
    },
  },
};
</script>
<style scoped></style>
