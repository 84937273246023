<template>
  <!-- 添加客户 -->
  <div class="invitateIsPolite">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item>签到有礼</el-breadcrumb-item>
      <el-breadcrumb-item>签到设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicInfo">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form ref="form" :model="basicInfoForm" label-width="150px">
        <el-form-item label="" prop="name" style="margin-left: -84px">
          <div class="flex align-center mg-t-10">
            <p>
              每次签到可获得<el-input
                v-model="basicInfoForm.per_growth"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">成长值</template></el-input
              >
            </p>
            <p>
              和<el-input
                v-model="basicInfoForm.per_score"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <h2 class="color-333 mg-b-20" style="margin-left: 66px">
          阶段奖励设置
        </h2>
        <el-form-item label="阶段奖励一" prop="stage_day1">
          <div class="flex align-center">
            <p>
              累计签到<el-input
                v-model="basicInfoForm.stage_day1"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >
            </p>
            <p>
              可额外获得<el-input
                v-model="basicInfoForm.stage_growth1"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">成长值</template></el-input
              >
            </p>
            <p>
              和<el-input
                v-model="basicInfoForm.stage_score1"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="阶段奖励二" prop="stage_day2">
          <div class="flex align-center">
            <p>
              累计签到<el-input
                v-model="basicInfoForm.stage_day2"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >
            </p>
            <p>
              可额外获得<el-input
                v-model="basicInfoForm.stage_growth2"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">成长值</template></el-input
              >
            </p>
            <p>
              和<el-input
                v-model="basicInfoForm.stage_score2"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="阶段奖励三" prop="stage_day3">
          <div class="flex align-center">
            <p>
              累计签到<el-input
                v-model="basicInfoForm.stage_day3"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >
            </p>
            <p>
              可额外获得<el-input
                v-model="basicInfoForm.stage_growth3"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">成长值</template></el-input
              >
            </p>
            <p>
              和<el-input
                v-model="basicInfoForm.stage_score3"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="阶段奖励四" prop="stage_day4">
          <div class="flex align-center">
            <p>
              累计签到<el-input
                v-model="basicInfoForm.stage_day4"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >
            </p>
            <p>
              可额外获得<el-input
                v-model="basicInfoForm.stage_growth4"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">成长值</template></el-input
              >
            </p>
            <p>
              和<el-input
                v-model="basicInfoForm.stage_score4"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="阶段奖励五" prop="stage_day5">
          <div class="flex align-center">
            <p>
              累计签到<el-input
                v-model="basicInfoForm.stage_day5"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >
            </p>
            <p>
              可额外获得<el-input
                v-model="basicInfoForm.stage_growth5"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">成长值</template></el-input
              >
            </p>
            <p>
              和<el-input
                v-model="basicInfoForm.stage_score5"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="阶段奖励六" prop="stage_day6">
          <div class="flex align-center">
            <p>
              累计签到<el-input
                v-model="basicInfoForm.stage_day6"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >
            </p>
            <p>
              可额外获得<el-input
                v-model="basicInfoForm.stage_growth6"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">成长值</template></el-input
              >
            </p>
            <p>
              和<el-input
                v-model="basicInfoForm.stage_score6"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="是否开启阶段奖励">
          <el-switch
            v-model="basicInfoForm.is_stage"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="阶段奖励循环完成">
          <el-switch
            v-model="basicInfoForm.is_loop"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="阶段奖励重置" prop="stage_day6">
          <div class="flex align-center">
            <p>
              <el-input
                v-model="basicInfoForm.stage_score6"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >
            </p>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit">保存</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "invitateIsPolite",
  data() {
    return {
      basicInfoForm: {
        per_growth: "",
        per_score: "",
        stage_day1: "",
        stage_day2: "",
        stage_day3: "",
        stage_day4: "",
        stage_day5: "",
        stage_day6: "",
        stage_score1: "",
        stage_score2: "",
        stage_score3: "",
        stage_score4: "",
        stage_score5: "",
        stage_score6: "",
        stage_growth1: "",
        stage_growth2: "",
        stage_growth3: "",
        stage_growth4: "",
        stage_growth5: "",
        stage_growth6: "",
        is_stage: 2,
        is_loop: 2,
      },
    };
  },
  created() {
    this.axios({
      method: "get", //请求方法
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": this.storage.get("__token"),
        Authorization: `Bearer ${this.storage.get("__token")}`,
      },
      url: ` https://time.api.boyalife.cn/v1/cms/SignInSetting/1`,
    })
      .then((res) => {
        let form = res.data.result;
        for (let i in form) {
          this.basicInfoForm[i] = form[i];
        }
        this.basicInfoForm.is_loop = Number(this.basicInfoForm.is_loop);
        this.basicInfoForm.is_stage = Number(this.basicInfoForm.is_stage);
      })
      .catch((err) => {
        this.$message.error(err.response.data.error.message);
      });
  },
  methods: {
    submit() {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.basicInfoForm,
        url: ` https://time.api.boyalife.cn/v1/cms/SignInSetting/1`,
      })
        .then((res) => {
          this.$message.success("保存成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
  },
};
</script>
<style scoped>
.invitateIsPolite {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form {
  width: 100%;
}
>>> .ipt {
  width: 200px;
  margin: 0 10px;
}
>>> .el-checkbox {
  margin-right: 0;
}
p {
  line-height: 40px;
}
</style>
